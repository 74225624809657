import React, {Component} from 'react';
import './Homepage.css';
import { Container, Nav, NavItem, NavLink, Row, Col,  Jumbotron } from 'reactstrap';


class Homepage extends Component {
    
    render() {
        return <div className="homepage">
        <Container>
        <Row>
            <Col>
                <Nav className="homepage-nav">
                    <NavItem>
                        <NavLink href="#">FleetIT</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="/login">Login</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="/register">Register</NavLink>
                    </NavItem>
                </Nav>
            </Col>
        </Row>
        <div>
            <Jumbotron>
                <h1 className="display-3">Efficient Fleet Management Starts Here!</h1>
                <p className="lead">We provide suite of tools to maintain an effective and profitable fleet.</p>
                <hr className="my-2" />
                <p className="lead">
                    <a href="mailto:info@fleet-it.co,Josh@FleetIT.co?Subject=Hello%FleetIt">Contact Us</a>
                </p>
            </Jumbotron>
        </div>
      </Container>
      </div>
  }
}

export default Homepage