import { LoadingButton } from '@mui/lab';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { startCase } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { MdArrowOutward, MdPayment } from 'react-icons/md';

import { createPaymentUrl } from '@/services/billing';

import {
  PAYMENT_STATUS_COLOR_MAP,
  PAYMENT_STATUS_ICON_MAP,
  PAYMENT_STATUS_WORDING_MAP,
} from './constants';
import { PaymentButtonProps } from './types';

function PayButton({ invoice, isLoading = false }: PaymentButtonProps) {
  const [isPaymentOptionsOpen, setIsPaymentOptionsOpen] = useState(false);

  const paymentStatus = invoice?.payment_status || 'pending';
  const PaymentIcon = PAYMENT_STATUS_ICON_MAP[paymentStatus];

  const onClosePaymentOptions = () => {
    setIsPaymentOptionsOpen(false);
  };

  const onClickPay = () => {
    setIsPaymentOptionsOpen(true);
  };

  const { mutate: payWithCard, isLoading: isCardUrlLoading } = useMutation({
    mutationFn: async () => {
      if (!invoice) {
        return null;
      }
      return createPaymentUrl(
        invoice?.lago_id,
        {
          successUrl: window.location.href,
          cancelUrl: window.location.href,
        },
      );
    },
    mutationKey: [invoice?.lago_id],
    onSuccess: (data) => {
      if (data?.data.payment_url) {
        window.location.href = data.data.payment_url;
      }
    },
    onError: (error) => {
      if (isAxiosError(error)) {
        enqueueSnackbar({
          message: startCase(error.response?.data.payment_status || error.message),
          variant: 'error',
        });
      }
    },
  });

  return (
    <>
      <Dialog
        open={isPaymentOptionsOpen}
        title="Payment Options"
        onClose={onClosePaymentOptions}
        fullWidth
      >
        <DialogTitle>
          Payment Options
        </DialogTitle>
        <DialogContent>
          <List>
            <ListItem onClick={() => payWithCard()}>
              <ListItemButton sx={{ borderRadius: 2 }}>
                <ListItemIcon>
                  <MdPayment size={24} />
                </ListItemIcon>
                <ListItemText primary="Card" secondary="+3% Processing Fee" />
                <ListItemSecondaryAction>
                  {isCardUrlLoading
                    ? <CircularProgress size={24} />
                    : <MdArrowOutward size={24} />}
                </ListItemSecondaryAction>
              </ListItemButton>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClosePaymentOptions}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {paymentStatus === 'pending'
        ? (
          <LoadingButton
            variant="contained"
            disableElevation
            endIcon={<MdPayment />}
            loading={isLoading || !invoice}
            onClick={onClickPay}
          >
            Pay
          </LoadingButton>
        )
        : (
          <Button
            color={PAYMENT_STATUS_COLOR_MAP[paymentStatus]}
            startIcon={<PaymentIcon />}
          >
            {PAYMENT_STATUS_WORDING_MAP[paymentStatus]}
          </Button>
        )}
    </>
  );
}

export default PayButton;
