export const USA_STATES = [
  {
    id: 'AA',
    text: 'AA',
  },
  {
    id: 'AE',
    text: 'AE',
  },
  {
    id: 'AK',
    text: 'AK',
  },
  {
    id: 'AL',
    text: 'AL',
  },
  {
    id: 'AP',
    text: 'AP',
  },
  {
    id: 'AR',
    text: 'AR',
  },
  {
    id: 'AS',
    text: 'AS',
  },
  {
    id: 'AZ',
    text: 'AZ',
  },
  {
    id: 'CA',
    text: 'CA',
  },
  {
    id: 'CO',
    text: 'CO',
  },
  {
    id: 'CT',
    text: 'CT',
  },
  {
    id: 'DC',
    text: 'CT',
  },
  {
    id: 'DE',
    text: 'DE',
  },
  {
    id: 'FL',
    text: 'FL',
  },
  {
    id: 'GA',
    text: 'GA',
  },
  {
    id: 'GS',
    text: 'GS',
  },
  {
    id: 'GU',
    text: 'GU',
  },
  {
    id: 'HI',
    text: 'HI',
  },
  {
    id: 'IA',
    text: 'IA',
  },
  {
    id: 'ID',
    text: 'ID',
  },
  {
    id: 'IL',
    text: 'IL',
  },
  {
    id: 'IN',
    text: 'IN',
  },
  {
    id: 'KS',
    text: 'KS',
  },
  {
    id: 'KY',
    text: 'KY',
  },
  {
    id: 'LA',
    text: 'LA',
  },
  {
    id: 'MA',
    text: 'MA',
  },
  {
    id: 'MD',
    text: 'MD',
  },
  {
    id: 'ME',
    text: 'ME',
  },
  {
    id: 'MI',
    text: 'MI',
  },
  {
    id: 'MN',
    text: 'MN',
  },
  {
    id: 'MO',
    text: 'MO',
  },
  {
    id: 'MS',
    text: 'MS',
  },
  {
    id: 'MT',
    text: 'MT',
  },
  {
    id: 'NC',
    text: 'NC',
  },
  {
    id: 'ND',
    text: 'ND',
  },
  {
    id: 'NE',
    text: 'NE',
  },
  {
    id: 'NH',
    text: 'NH',
  },
  {
    id: 'NJ',
    text: 'NJ',
  },
  {
    id: 'NM',
    text: 'NM',
  },
  {
    id: 'NV',
    text: 'NV',
  },
  {
    id: 'NY',
    text: 'NY',
  },
  {
    id: 'OH',
    text: 'OH',
  },
  {
    id: 'OK',
    text: 'OK',
  },
  {
    id: 'OR',
    text: 'OR',
  },
  {
    id: 'PA',
    text: 'PA',
  },
  {
    id: 'PR',
    text: 'PR',
  },
  {
    id: 'RI',
    text: 'RI',
  },
  {
    id: 'SC',
    text: 'SC',
  },
  {
    id: 'SD',
    text: 'SD',
  },
  {
    id: 'TN',
    text: 'TN',
  },
  {
    id: 'TX',
    text: 'TX',
  },
  {
    id: 'UT',
    text: 'UT',
  },
  {
    id: 'VA',
    text: 'VA',
  },
  {
    id: 'VI',
    text: 'VI',
  },
  {
    id: 'VT',
    text: 'VT',
  },
  {
    id: 'WA',
    text: 'WA',
  },
  {
    id: 'WI',
    text: 'WI',
  },
  {
    id: 'WV',
    text: 'WV',
  },
  {
    id: 'WY',
    text: 'WY',
  },
] as const;
