import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select, Stack, TextField,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { entries } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MdDelete } from 'react-icons/md';

import useConfirmation from '@/hooks/useConfirmation';
import { createCustomField, deleteCustomField, updateCustomField } from '@/services/customFields';

import addCustomFieldSchema, { CustomFieldFormValues } from './customFieldFormSchema';
import { CustomFieldFormDialogProps } from './types';

function CustomFieldFormDialog({ initialField, ...dialogProps }: CustomFieldFormDialogProps) {
  const queryClient = useQueryClient();

  const {
    confirm: confirmDelete,
    dialog: confirmDeleteDialog,
  } = useConfirmation({
    title: 'Are you sure?',
    description: `Are you sure you want to delete '${initialField?.name}'?
    This will remove the field from all records.`,
  });

  const { handleSubmit, control } = useForm<CustomFieldFormValues>({
    defaultValues: {
      fieldType: initialField?.field_type || 'TEXT',
      name: initialField?.name,
    },
    resolver: zodResolver(addCustomFieldSchema),
  });

  const { mutate: submitCustomField, isLoading } = useMutation({
    mutationFn: (formValues: CustomFieldFormValues) => {
      if (initialField) {
        return updateCustomField(initialField.id, formValues);
      }
      return createCustomField({
        ...formValues,
        entityType: 'vehicle',
      });
    },
    mutationKey: ['customFields', 'vehicle'],
    onError: (error) => {
      let errorMessage = 'An unknown error occurred';

      if (isAxiosError(error)) {
        if (error.response?.status === 400) {
          entries(error.response.data).forEach(
            ([key, message]) => enqueueSnackbar({
              variant: 'error',
              message: `${key}: ${message}`,
            }),
          );

          return;
        }

        errorMessage = error.message;
      }

      enqueueSnackbar({ variant: 'error', message: errorMessage });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['customFields', 'vehicle'],
      });

      queryClient.invalidateQueries({
        queryKey: ['columns', 'vehicle'],
      });

      queryClient.invalidateQueries({
        queryKey: ['custom_fields', 'vehicle'],
      });

      enqueueSnackbar({ variant: 'success', message: 'New field has been added!' });
      dialogProps.onClose?.({}, 'backdropClick');
    },
  });

  const { mutate: deleteField, isLoading: isDeleting } = useMutation({
    mutationFn: async () => {
      if (initialField) {
        return deleteCustomField(initialField.id);
      }

      return null;
    },
    onError: (error) => {
      let errorMessage = 'An unknown error occurred';

      if (isAxiosError(error)) {
        if (error.response?.status === 400) {
          entries(error.response.data).forEach(
            ([key, message]) => enqueueSnackbar({
              variant: 'error',
              message: `${key}: ${message}`,
            }),
          );

          return;
        }

        errorMessage = error.message;
      }

      enqueueSnackbar({ variant: 'error', message: errorMessage });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['customFields', 'vehicle'],
      });

      queryClient.invalidateQueries({
        queryKey: ['columns', 'vehicle'],
      });

      dialogProps.onClose?.({}, 'backdropClick');
    },
  });

  const onSubmit = useCallback((formValues: CustomFieldFormValues) => {
    submitCustomField(formValues);
  }, [submitCustomField]);

  const onClickDelete = async () => {
    if (await confirmDelete()) {
      deleteField();
    }
  };

  return (
    <>
      {confirmDeleteDialog}
      <Dialog {...dialogProps}>
        <DialogTitle>
          {initialField ? `Edit '${initialField.name}'` : 'Add new field'}
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Stack spacing={3} marginTop={1}>
              <Controller
                control={control}
                name="name"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label="Field Name"
                    fullWidth
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onChange={(e) => field.onChange(e.target.value.replace(/\s/g, '_'))}
                  />
                )}
              />

              <Controller
                control={control}
                name="fieldType"
                render={({ field, fieldState }) => (
                  <FormControl>
                    <InputLabel>Type</InputLabel>
                    <Select
                      {...field}
                      label="Type"
                      fullWidth
                      error={!!fieldState.error}
                      disabled={!!initialField}
                    >
                      <MenuItem value="TEXT">
                        Text
                      </MenuItem>
                      {fieldState.error?.message
                    && (
                    <FormHelperText>
                      {fieldState.error?.message}
                    </FormHelperText>
                    )}
                    </Select>
                  </FormControl>
                )}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            {!!initialField
          && (
            <LoadingButton
              color="error"
              startIcon={<MdDelete />}
              loading={isDeleting}
              onClick={onClickDelete}
            >
              Delete
            </LoadingButton>
          )}
            <Box flexGrow={1} />
            <Button
              onClick={(e) => dialogProps.onClose?.(e, 'backdropClick')}
              type="button"
            >
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              loading={isLoading}
            >
              Save
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default CustomFieldFormDialog;
