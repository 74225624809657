import { startCase } from "lodash";
import React from "react";
import { useFormContext } from "react-hook-form";

const AuthenticationForm = () => {
  const formField = "authFields";
  const { watch, register, formState } = useFormContext();

  const authFields = watch(formField);

  return (
    <>
      {formState.errors.authFieldsProxy && (
        <div className="alert alert-danger">
          {formState.errors.authFieldsProxy.message}
        </div>
      )}
      {authFields.map(({ name, type }, index) => (
        <div className="form-group" key={name}>
          <label>{startCase(name)}</label>
          <input
            {...register(`authFields.${index}.value`)}
            className="form-control"
            type={type || "text"}
          />
          <p className="text-danger">
            {formState.errors.authFields?.[index]?.value?.message}
          </p>
          <input
            {...register(`authFields.${index}.name`)}
            value={name}
            className="form-control hidden"
            type="text"
          />
        </div>
      ))}
    </>
  );
};

export default AuthenticationForm;
