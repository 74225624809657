import React from 'react';

import BuggyApi from '../../services/BuggyApi';
import BuggyAuth from '../../services/BuggyAuth';
import * as actionTypes from '../actions/actions';

const initialState = {
  isLoggedIn: BuggyAuth.isAuthenticated(),
  ticket: null,
  tickets_meta: null,
  links: [
    /* [0] */ {
      icon: <i className="fa fa-dashboard" />,
      path: '/dashboard',
      text: 'Dashboard',
      isActive: true,
    },
    /* [1] */ {
      icon: <i className="fa fa-ticket" />,
      path: '/violations',
      text: 'Toll Transactions',
      isActive: false,
    },
    /* [2] */ {
      icon: <i className="fa fa-ticket" />,
      path: '/liability-bot',
      text: 'Liability Bot',
      isActive: false,
    },
    /* [3] */ {
      icon: <i className="fa fa-ticket" />,
      path: '/liability-bot/violations',
      text: 'Liability Bot Violations',
      isActive: false,
    },
    /* [4] */ {
      icon: <i className="fa fa-user" />,
      path: '/drivers',
      text: 'Drivers',
      isActive: false,
    },
    /* [5] */ {
      icon: <i className="fa fa-car" />,
      path: '/vehicles',
      text: 'Vehicles',
      isActive: false,
    },
    /* [6] */ {
      icon: <i className="fa fa-feed" />,
      path: '/transponders',
      text: 'Transponders',
      isActive: false,
    },
    /* [7] */ {
      icon: <i className="fa fa-truck" />,
      path: '/shipments',
      text: 'Shipments',
      isActive: false,
    },
    /* [8] */ {
      icon: <i className="fa fa-folder" />,
      path: '/logs',
      text: 'Logs',
      isActive: false,
    },
    /* [9] */ {
      icon: <i className="fa fa-ticket" />,
      path: '/parking_traffic_tickets',
      text: 'Parking & Photo Enforcement Violations',
      isActive: false,
    },
    /* [10] */ {
      icon: <i className="fa fa-money" />,
      path: '/billing',
      text: 'Billing',
      isActive: false,
    },
    /* [11] */ {
      icon: <i className="fa fa-chain" />,
      path: '/integration',
      text: 'Integration',
      isActive: false,
    },
    /* [12] */ {
      icon: <i className="fa fa-gear" />,
      path: '/settings',
      text: 'Settings',
      isActive: false,
    },
  ],
  componentIndex: 0,
  fleetTitle: null,
  signupSuccess: false,
  userInfo: {
    fleet_title: null,
    fleet_email: null,
    fleet_id: 0,
    user_id: 0,
    has_associated_account: false,
    vehicles_change: false,
    emails: [],
    is_onboarded: true,
  },
  sidebarOpened: false,
  pusherInfo: {
    key: null,
    cluster: null,
  },
  isPageLoading: false,
};
const getLeftNavigationLinks = (links, pathName) => {
  for (const j in links) {
    links[j].isActive = pathName.startsWith(links[j].path);
  }
  const leftLinks = [];
  for (const key in links) {
    leftLinks.push(links[key]);
  }
  return leftLinks;
};

const reducer = (state = initialState, action) => {
  let loggedIn = BuggyAuth.isAuthenticated();
  let { links } = state;
  let mountedComponentIndex = 0;
  switch (action.type) {
    case actionTypes.LOGIN:
      loggedIn = BuggyAuth.isAuthenticated();
      return {
        ...state,
        isLoggedIn: loggedIn,
      };
    case actionTypes.LOGOUT:
      loggedIn = false;
      const fleet_info = {
        fleet_title: null,
        fleet_email: null,
        fleet_id: 0,
      };
      const pusher_info = {
        key: null,
        cluster: null,
      };

      BuggyAuth.signout();
      return {
        ...state,
        isLoggedIn: loggedIn,
        userInfo: fleet_info,
        pusherInfo: pusher_info,
      };

    case actionTypes.UPDATE_NAVIGATION:
      const pathNameD = window.location.pathname;
      links = getLeftNavigationLinks(state.links, pathNameD);
      return {
        ...state,
        links,
      };

    case actionTypes.GET_COMPONENT_INDEX:
      links = { ...state.links };
      for (const j in links) {
        if (links[j].path === action.path) {
          mountedComponentIndex = j;
          break;
        }
      }
      return {
        ...state,
        componentIndex: mountedComponentIndex,
      };
    case actionTypes.SIGNUP_SUCCESS:
      const signupSuccess = true;
      return {
        ...state,
        signupSuccess,
      };
    case actionTypes.SIDEBAR_OPENED:
      const sidebarOpened = action.toggle;
      return {
        ...state,
        sidebarOpened,
      };
    case actionTypes.UPDATE_USER_DETAILS:
      const fleetInfo = action.userInfo;
      return {
        ...state,
        userInfo: fleetInfo,
      };
    case actionTypes.PAGE_LOADING:
      const { isPageLoading } = action;
      return {
        ...state,
        isPageLoading,
      };
    default:
      const pathName = window.location.pathname;
      links = getLeftNavigationLinks(state.links, pathName);
      return {
        ...state,
        links,
      };
  }

  // return {
  //     ...state,
  //     isLoggedIn: loggedIn,
  //     links: links,
  //     componentIndex: mountedComponentIndex,
  //     userInfo:fleetInfo,
  //     signupSuccess: signupSuccess,
  //     sidebarOpened: sidebarOpened
  // };
};

export default reducer;
