import { AxiosRequestConfig } from 'axios';
import QueryString from 'qs';

import fleetAxios from '../axios';
import { APIQuery, APIResponse, DocumentResponse } from '../types';

const baseUrl = process.env.REACT_APP_BASE_URL_ROOT;

export function uploadDocuments(
  files: File[],
  documentType: 'TOLL' | 'VIOLATION',
  callbacks?: { onUploadProgress: AxiosRequestConfig<FormData>['onUploadProgress'] },
) {
  const { onUploadProgress } = callbacks || {};
  const uploadUrl = 'document/upload/multi/';
  const formData = new FormData();
  formData.append('type', documentType);

  files.forEach((file) => formData.append('files', file));

  return fleetAxios.post(
    baseUrl + uploadUrl,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    },
  );
}

export function listDocuments({ pagination }: APIQuery) {
  const url = `${baseUrl}document/`;
  const queryParam = QueryString.stringify({
    limit: pagination?.pageSize,
    offset: pagination?.page,
  });

  return fleetAxios.get <APIResponse<DocumentResponse>>(`${url}?${queryParam}`);
}
