import { Chip, ChipProps } from '@mui/material';
import { startCase } from 'lodash';
import React, { ReactElement } from 'react';
import {
  MdBlock,
  MdCircle,
  MdDoneAll,
  MdOutlineBeenhere,
} from 'react-icons/md';

import { ShipmentStatus, ShipmentType } from '@/services/transponders/types';

type ShipmentStatusProps = {
  shipment: ShipmentType
};

const ICON_MAP: Record<ShipmentType['status'], ReactElement> = {
  [ShipmentStatus.PENDING]: <MdCircle />,
  [ShipmentStatus.SHIPPED]: <MdOutlineBeenhere />,
  [ShipmentStatus.RECEIVED]: <MdDoneAll />,
  [ShipmentStatus.CANCELLED]: <MdBlock />,
};

const COLOR_MAP: Record<ShipmentType['status'], ChipProps['color']> = {
  [ShipmentStatus.PENDING]: 'default',
  [ShipmentStatus.SHIPPED]: 'success',
  [ShipmentStatus.RECEIVED]: 'success',
  [ShipmentStatus.CANCELLED]: 'error',
};

export default function ShipmentStatusIndicator({ shipment }: ShipmentStatusProps) {
  return (
    <Chip
      icon={ICON_MAP[shipment.status]}
      label={startCase(shipment.status)}
      size="small"
      variant="outlined"
      sx={{
        fontSize: 12,
      }}
      color={COLOR_MAP[shipment.status]}
    />
  );
}
