import {
  // Button,
  Chip,
  CircularProgress, MenuItem, Select, Stack, Tooltip, Typography,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { format, parseISO } from 'date-fns';
import React from 'react';
import { HiSparkles } from 'react-icons/hi2';
import { MdCalendarMonth, MdPerson } from 'react-icons/md';
import { Button } from 'reactstrap';

import { getCustomFields } from '@/services/customFields';
import { CustomField } from '@/services/customFields/types';
import { FeatureFlagMap } from '@/types/FeatureFlags';

type Params = {
  billingStatusOptions: { key: string, value: string }[];
  onBillingStatusChange: (id: string, status: string) => void;
  onClickAssociateDriver: (id: string) => void;
  onClickViewDriverDetails: (driverId: string) => void;
  featureFlags?: FeatureFlagMap
};

function getDriverColumn({
  onClickAssociateDriver,
  onClickViewDriverDetails, featureFlags,
}: Params) {
  if (featureFlags?.tolls_driver_email) {
    return {
      field: 'driver',
      headerName: 'Driver',
      minWidth: 200,
      sortable: false,
      editable: true,
      valueGetter: ({ value }) => value?.email,
      valueSetter: ({ value, row }) => ({ ...row, driver: { ...row?.driver, email: value } }),
      renderCell: ({ value }) => (value ? (
        <Chip
          icon={<MdPerson />}
          label={value}
          size="small"
        />
      ) : null),
    } as GridColDef;
  }

  return {
    field: 'driver',
    headerName: 'Driver',
    minWidth: 200,
    sortable: false,
    renderCell: ({ row, value }) => (
      value
        ? (
          <Button
            size="small"
            onClick={() => onClickViewDriverDetails(value.id)}
            fullWidth
          >
            {`${value.driver_id} - ${value.first_name} ${value.last_name}`}
          </Button>
        )
        : (
          <Button
            size="small"
            variant="contained"
            sx={{ color: 'white' }}
            disableElevation
            onClick={() => onClickAssociateDriver(row.ticketID)}
            fullWidth
          >
            Associate Driver
          </Button>
        )),
  } as GridColDef;
}

async function getCustomVehicleColumns() {
  let customFields: CustomField[] = [];

  try {
    const { data } = await getCustomFields('vehicle');
    customFields = data;
  } catch (e) {
    customFields = [];
  }

  return customFields.map<GridColDef>(({ name }) => ({
    field: name,
    flex: 1,
    headerName: name,
    minWidth: 150,
    valueGetter: ({ row }) => row.vehicle.custom_fields?.[name],
    renderHeader: ({ field }) => (
      <Stack direction="row" spacing={1} alignItems="center" alignContent="center">
        <Tooltip title="Custom Field">
          <Typography color="grey">
            <HiSparkles />
          </Typography>
        </Tooltip>
        <Tooltip title={field}>
          <Typography>{field}</Typography>
        </Tooltip>
      </Stack>
    ),
  }));
}

async function createTollTableColumns(params: Params) {
  const {
    billingStatusOptions,
    onBillingStatusChange,
    featureFlags,
  } = params;

  const customColumns = await getCustomVehicleColumns();

  return [
    {
      field: 'ticketID',
      headerName: 'Ticket ID',
    },
    {
      field: 'amount',
      headerName: 'Amount',
      renderCell: ({ value }) => `$${value}`,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'our_cost',
      headerName: 'Fee',
      renderCell: ({ value }) => `$${parseFloat(value).toFixed(2)}`,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'exit_plaza',
      headerName: 'Exit Plaza',
    },
    {
      field: 'cash_cost',
      headerName: 'Cash Cost',
      renderCell: ({ value }) => `$${parseFloat(value).toFixed(2)}`,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'posting_date',
      headerName: 'Posting Date',
      flex: 1,
      minWidth: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ value }) => (
        <Tooltip title={value}>
          <Typography
            overflow="auto"
            variant="subtitle2"
          >
            {value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'transaction_date',
      headerName: 'Transaction Date',
      flex: 1,
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ value }) => (
        <Tooltip title={value}>
          <Typography
            overflow="auto"
            variant="subtitle2"
          >
            {value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'transponder',
      headerName: 'Transponder',
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'vehicle_plate_number',
      headerName: 'Vehicle Plate #',
      minWidth: 150,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'vehicle_tracking_code',
      valueGetter: ({ row }) => row.vehicle.tracking_code,
      headerName: 'Tracking Code',
    },
    {
      field: 'vehicle_lessee_renter',
      valueGetter: ({ row }) => row.vehicle.lessee_renter,
      headerName: 'Lessee/Renter',
    },
    getDriverColumn(params),
    ...(featureFlags?.tolls_notes
      ? [{
        field: 'system_remarks',
        headerName: 'Notes',
        minWidth: 200,
        sortable: false,
        editable: true,
        valueGetter: ({ value }) => value?.notes,
        valueSetter: ({ value, row }) => (
          {
            ...row,
            system_remarks: { ...row?.system_remarks, notes: value },
          }),
      }] as GridColDef[] : []),
    ...(featureFlags?.tolls_history ? [{
      field: 'history',
      headerName: 'Updated By',
      minWidth: 200,
      renderCell: ({ value }) => (value ? (
        <Tooltip title={format(parseISO(value.date), 'MM-dd-yyyy hh:mm:ss')}>
          <Stack direction="row" spacing={1}>
            <Typography variant="caption">{value.username}</Typography>
            <Chip label={format(parseISO(value.date), 'MM-dd-yyyy')} size="small" icon={<MdCalendarMonth />} />
          </Stack>
        </Tooltip>
      ) : null),
    }] as GridColDef[] : []),
    {
      field: 'billing_status',
      headerName: 'Billing Status',
      minWidth: 200,
      sortable: false,
      renderCell: ({ value, row }) => (billingStatusOptions ? (
        <Select
          value={value}
          size="small"
          onChange={(e) => onBillingStatusChange(row.ticketID, e.target.value)}
          fullWidth
          sx={{
            borderRadius: 100,
          }}
        >
          {billingStatusOptions.map(
            ({ key, value: label }) => (
              <MenuItem value={key} key={key}>{label}</MenuItem>
            ),
          )}
        </Select>
      ) : <CircularProgress size={10} />),
    },
    ...customColumns,
  ] satisfies GridColDef[];
}

export default createTollTableColumns;
