import { LoadingButton } from '@mui/lab';
import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { acceptInvite } from '@/services/fleet';
import { FleetInvitation } from '@/services/fleet/types';

type AcceptFormProps = {
  invitation: FleetInvitation
};

export default function AcceptInviteButton({ invitation }: AcceptFormProps) {
  const history = useHistory();

  const { mutate: acceptInvitation, isLoading } = useMutation({
    mutationFn: async () => acceptInvite(
      invitation.code,
    ),
    onSuccess: () => {
      history.replace('/');
    },
    onError: (error) => {
      if (isAxiosError(error)) {
        if (error.response?.status === 404) {
          enqueueSnackbar({
            message: 'This invitation has already been accepted.',
            variant: 'error',
          });

          return;
        }

        if (error.response?.status === 401 || error.response?.status === 403) {
          enqueueSnackbar({
            message: 'You don\'t have permission to accept this invitation.',
            variant: 'error',
          });

          return;
        }
      }

      enqueueSnackbar({
        variant: 'error',
        message: 'Something went wrong',
      });
    },
  });

  return (
    <LoadingButton
      color="success"
      variant="contained"
      disableElevation
      size="large"
      onClick={() => acceptInvitation()}
      loading={isLoading}
    >
      Accept
    </LoadingButton>
  );
}
