import fleetAxios from '../axios';
import {
  CreateCustomFieldParams, CustomField, EntityType, UpdateCustomFieldParams,
} from './types';

export function createCustomField({ name, entityType, fieldType }: CreateCustomFieldParams) {
  return fleetAxios.post(
    '/custom_fields/',
    {
      name,
      entity_type: entityType,
      field_type: fieldType,
    },
  );
}

export function getCustomFields(entityType: EntityType) {
  return fleetAxios.get<CustomField[]>(
    '/custom_fields/',
    {
      params: { entity_type: entityType },
    },
  );
}

export function updateCustomField(id: number, updates: UpdateCustomFieldParams) {
  return fleetAxios.patch(
    `/custom_fields/${id}/`,
    updates,
  );
}

export function deleteCustomField(id: number) {
  return fleetAxios.delete(
    `/custom_fields/${id}/`,
  );
}

export default {};
