import './tags.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actionTypes from '../../store/actions/actions';
import { VehiclesTable } from './components';

class Vehicles extends Component {
  constructor() {
    super();

    this.state = {
      search: undefined,
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const {
      location,
      componentIndex,
      getComponentIndex,
      onLinkChange,
    } = this.props;
    getComponentIndex(location.pathname);
    onLinkChange(componentIndex);
  }

  searchClickHandler = () => {
    const searchString = this.searchString.value;
    this.setState({
      search: searchString,
    });
  };

  render() {
    return (
      <div
        className={
            this.props.sidebar_status
              ? 'page-wrapper slide-nav-toggle'
              : 'page-wrapper'
          }
      >
        <div className="content container-fluid" id="vehicles-layout">
          <div className="row">
            <div className="col-sm-4 col-xs-3">
              <h4 className="page-title">Vehicles</h4>
            </div>
          </div>
          <div className="row filter-row">
            <div className="col-sm-4 col-xs-6">
              <div className="form-group form-focus">
                <label className="control-label">
                  Plate# or Vehicle Tracking
                </label>
                <input
                  type="text"
                  className="form-control floating"
                  ref={(el) => (this.searchString = el)}
                />
              </div>
            </div>
            <div className="col-sm-2 col-xs-6">
              <button
                type="button"
                className="btn btn-success btn-block"
                onClick={this.searchClickHandler}
              >
                {' '}
                Search
              </button>
            </div>
          </div>
          <VehiclesTable search={this.state.search} />
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => ({
  componentIndex: state.componentIndex,
  sidebar_status: state.sidebarOpened,
  fleet_info: state.userInfo,
});
const mapDispatchToProps = (dispatch) => ({
  onLinkChange: (i) => dispatch({ type: actionTypes.UPDATE_NAVIGATION, i }),
  getComponentIndex: (path) => dispatch({ type: actionTypes.GET_COMPONENT_INDEX, path }),
});
export default connect(mapStatesToProps, mapDispatchToProps)(Vehicles);
