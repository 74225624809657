import { GridColDef } from '@mui/x-data-grid-pro';

import { TransponderType } from '@/services/transponders/types';

export default function generateShipmentDetailsColumns() {
  return [
    {
      field: 'number',
      headerName: 'Number',
      flex: 1,
    },
  ] satisfies GridColDef<TransponderType>[];
}
