import React, { Component, Fragment } from "react";
import $ from "jquery";
import Pagination from "react-js-pagination";
import BuggyApi from "../../services/BuggyApi";
import "./dateTimePicker.css";
import Datetime from "react-datetime";
import Aux from "../hoc/Auxiliary";
import BlockUi from "../BlockUi";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions/actions";
import AddDriver from "./AddDriver";
import DeleteDriver from "./DeleteDriver";
import Select2 from "react-select2-wrapper";
import DriverBulkUpload from "./DriverBulkUpload";
import DriverDetails from "../Tickets/DriverDetails";

class Driver extends Component {
  state = {
    isSuccessAssign: false,
    assign_message: "",
    isSuccess: false,
    errorMessage: [],
    drivers: [],
    itemsCountPerPage: 20,
    totalItemsCount: 0,
    pageRangeDisplayed: 10,
    activePage: 1,
    focusedFrom: false,
    focusedTo: false,
    blocking: false,
    focusedSearchWith: false,
    agreementStatus: "",
    isAllowed: false,
    responseError:
      "You're not authorized to use this feature please contact admin",
  };

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber });
    pageNumber -= 1;
    const agreementStatus = this.refs.agreementStatus.el.val() || "";
    new BuggyApi()
      .searchDriver(
        this.state.itemsCountPerPage * pageNumber,
        this.searchWith.value,
        this.dateFrom.state.inputValue,
        this.dateTo.state.inputValue,
        agreementStatus
      )
      .then((response) => {
        this.setState({
          isAllowed: true,
          drivers: response.data.objects,
          itemsCountPerPage: response.data.meta.limit,
          totalItemsCount: response.data.meta.total_count,
        });
      })
      .catch((error) => {
        /*console.log('Error', error);*/
        if (error.response) {
          let error_msg =
            "You're not authorized to use this feature please contact admin";
          if (error.response.data) {
            if (error.response.data.detail) {
              error_msg = error.response.data.detail;
            }
          }
          this.setState({
            isAllowed: false,
            responseError: error_msg,
          });
        } else if (error.request) {
        } else {
          /*console.log('Error', error.message);*/
        }
      });
  };

  onFocusHandlerDateFrom = (e) => {
    this.setState({ focusedFrom: true });
  };

  onFocusHandlerToggleSearchWith = (e) => {
    this.setState({ focusedSearchWith: !this.state.focusedSearchWith });
  };

  onFocusHandlerDateTo = (e) => {
    this.setState({ focusedTo: true });
  };

  onBlurHandlerDateFrom = (e) => {
    this.setState({ focusedFrom: false });
  };

  onBlurHandlerDateTo = (e) => {
    this.setState({ focusedTo: false });
  };

  componentDidMount() {
    this.is_mounted = true;
    window.$ = window.jQuery = $;
  }

  onEditClickHandler = (index) => {
    this.addDriverModal.onModalShow(this.state.drivers[index]);
  };

  onDeleteClickHandler = (index) => {
    this.deleteLDriverModal.onModalShow(this.state.drivers[index].driver_id);
  };

  componentWillMount() {
    this.props.getComponentIndex(this.props.location.pathname);
    this.props.onLinkChange(this.props.componentIndex);
    new BuggyApi()
      .searchDriver()
      .then((response) => {
        if (this.is_mounted) {
          this.setState({
            isAllowed: true,
            drivers: response.data.objects,
            itemsCountPerPage: response.data.meta.limit,
            totalItemsCount: response.data.meta.total_count,
          });
        }
      })
      .catch((error) => {
        /*console.log('Error', error);*/
        if (error.response) {
          let error_msg =
            "You're not authorized to use this feature please contact admin";
          if (error.response.data) {
            if (error.response.data.detail) {
              error_msg = error.response.data.detail;
            }
          }
          this.setState({
            isAllowed: false,
            responseError: error_msg,
          });
        } else if (error.request) {
        } else {
          /*console.log('Error', error.message);*/
        }
      });
  }

  componentWillUnmount() {
    this.is_mounted = false;
  }

  searchClickHandler = () => {
    const agreementStatus = this.refs.agreementStatus.el.val() || "";
    this.setState({ agreementStatus: agreementStatus });
    new BuggyApi()
      .searchDriver(
        0,
        this.searchWith.value,
        this.dateFrom.state.inputValue,
        this.dateTo.state.inputValue,
        agreementStatus
      )
      .then((response) => {
        if (this.is_mounted) {
          this.setState({
            drivers: response.data.objects,
            activePage: 1,
            isAllowed: true,
            itemsCountPerPage: response.data.meta.limit,
            totalItemsCount: response.data.meta.total_count,
          });
        }
      })
      .catch((error) => {
        /*console.log('Error', error);*/
        if (error.response) {
          let error_msg =
            "You're not authorized to use this feature please contact admin";
          if (error.response.data) {
            if (error.response.data.detail) {
              error_msg = error.response.data.detail;
            }
          }
          this.setState({
            isAllowed: false,
            responseError: error_msg,
          });
        } else if (error.request) {
        } else {
          /*console.log('Error', error.message);*/
        }
      });
  };
  runAssignDrivers = () => {
    this.setState({ blocking: true, errorMessage: [] });
    new BuggyApi()
      .runAssignDrivers()
      .then((response) => {
        if (response.status === 200) {
          if (response.data) {
            if (response.data.report) {
              if (response.data.report.time) {
                this.setState({
                  assign_message: "0 Drivers Found",
                  showAnimation: false,
                  blocking: false,
                  isSuccessAssign: true,
                });
              } else {
                this.setState({
                  assign_message: response.data.report,
                  showAnimation: false,
                  blocking: false,
                  isSuccessAssign: true,
                });
              }
            } else {
              this.setState({
                showAnimation: false,
                assign_message: "Drivers Assigned.",
                blocking: false,
                isSuccessAssign: true,
              });
            }
          } else {
            this.setState({
              showAnimation: false,
              blocking: false,
              isSuccessAssign: false,
            });
          }
        } else {
          this.setState({
            showAnimation: false,
            blocking: false,
            isSuccessAssign: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          showAnimation: false,
          isSuccess: false,
        });
      });
  };

  onAddDriverClickHandler = () => {
    this.addDriverModal.onModalShow();
  };

  onBulkUploadClickHandler = () => {
    this.bulkUploadDriver.onModalShow();
  };

  refreshDriver = () => {
    this.setState({ isLoading: true });
    new BuggyApi()
      .searchDriver()
      .then((response) => {
        if (response.data) {
          let data_to_set = { isLoading: false };
          if (response.data.objects) {
            data_to_set["drivers"] = response.data.objects;
          }
          if (response.data.meta) {
            data_to_set["itemsCountPerPage"] = response.data.meta.limit;
            data_to_set["totalItemsCount"] = response.data.meta.total_count;
          }
          this.setState(data_to_set);
        }
      })
      .catch((error) => {
        // console.log(error.response);
      });
  };
  onDriverDetailClickHandler = (evt, driver_id) => {
    this.driverDetail.onModalShow(driver_id);
  };
  render() {
    let rows = [];
    this.state.drivers.map((driver, index) => {
      return rows.push(
        <tr key={index}>
          <td>{driver.driver_id}</td>
          <td>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                className="avatar"
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  minWidth: 38,
                  backgroundColor: driver.initials_color
                    ? driver.initials_color
                    : "#" +
                      (0x1000000 + Math.random() * 0xffffff)
                        .toString(16)
                        .substr(1, 6),
                }}
              >
                {driver.first_name ? (
                  <h4
                    style={{
                      fontSize: "18px",
                      fontWeight: 600,
                      margin: "0 auto",
                    }}
                  >
                    {driver.first_name.charAt(0)}
                  </h4>
                ) : (
                  <h4
                    style={{
                      fontSize: "18px",
                      fontWeight: 600,
                      margin: "0 auto",
                    }}
                  >
                    A
                  </h4>
                )}
              </div>
              <div style={{ display: "inline-flex" }}>
                <button
                  href="#"
                  className="btn btn-outline-info"
                  onClick={(event) => {
                    this.onDriverDetailClickHandler(event, driver.id);
                  }}
                >
                  {driver.first_name + " " + driver.last_name}
                </button>
              </div>
            </div>
          </td>
          <td>{driver.agreement_vehicle_plate}</td>
          <td>{driver.email}</td>
          <td>{driver.contact_number}</td>
          <td>{driver.agreement_start_date}</td>
          <td>{driver.agreement_end_date}</td>
          <td>{driver.date_added}</td>
          <td>{driver.last_updated}</td>
          <td>
            {driver.agreement_status === "ACTIVE" ? (
              <span className="label label-success-border">Active</span>
            ) : (
              <span className="label label-danger-border">In-active</span>
            )}
          </td>
          <td>
            <div className="dropdown">
              <button
                href="#"
                className="action-icon dropdown-toggle btn btn-outline-info"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fa fa-ellipsis-v"></i>
              </button>
              <ul className="dropdown-menu pull-right">
                <li>
                  <button
                    className="btn btn-outline-info"
                    onClick={() => this.onEditClickHandler(index)}
                  >
                    <i className="fa fa-pencil m-r-5"></i> Edit
                  </button>
                </li>
                <li>
                  <button
                    className="btn btn-outline-info"
                    onClick={() => this.onDeleteClickHandler(index)}
                  >
                    <i className="fa fa-trash-o m-r-5"></i> Delete
                  </button>
                </li>
              </ul>
            </div>
          </td>
        </tr>
      );
    });

    return (
      <Aux>
        <div
          className={
            this.props.sidebar_status
              ? "page-wrapper slide-nav-toggle"
              : "page-wrapper"
          }
        >
          <div className="content container-fluid">
            <BlockUi tag="div" blocking={this.state.blocking}>
              <div className="row">
                <div className="col-sm-4 col-xs-3">
                  <h4 className="page-title">Drivers</h4>
                </div>

                <div className="col-sm-8 col-xs-9 text-right m-b-20">
                  <button
                    className="btn btn-primary rounded "
                    onClick={this.onAddDriverClickHandler}
                  >
                    <i className="fa fa-plus"></i>&nbsp; Add Driver
                  </button>
                  <br className="hidden-lg hidden-md hidden-sm" />
                  <br className="hidden-lg hidden-md hidden-sm" />
                  {/*https://docs.google.com/spreadsheets/d/1LBaUtBZVV2K7kdHwR-U345V67y7TmUmD42pDFPfswXM/edit#gid=0*/}
                  <a
                    className="btn btn-primary rounded pull-right"
                    href="https://storage.googleapis.com/fleet-it-app-bucket/static/templates/driver_upload_template.csv"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-cloud-download"></i>&nbsp; Download
                    Template
                  </a>
                  <br className="hidden-lg hidden-md hidden-sm" />
                  <br className="hidden-lg hidden-md hidden-sm" />
                  <br className="hidden-lg hidden-md hidden-sm" />
                  <span
                    className="pull-right hidden-xs"
                    style={{
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  <button
                    className="btn btn-primary rounded pull-right"
                    onClick={this.onBulkUploadClickHandler}
                  >
                    <i className="fa fa-cloud-upload"></i>&nbsp; Bulk Add Driver
                  </button>
                  <br className="hidden-lg hidden-md hidden-sm" />
                  <br className="hidden-lg hidden-md hidden-sm" />
                  <br className="hidden-lg hidden-md hidden-sm" />
                  <span
                    className="pull-right hidden-xs"
                    style={{
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  <button
                    className="btn btn-primary rounded pull-right"
                    onClick={this.runAssignDrivers}
                  >
                    <i className="fa fa-refresh"></i>&nbsp; Assign Drivers
                  </button>
                  <br className="hidden-lg hidden-md hidden-sm" />
                  <br className="hidden-lg hidden-md hidden-sm" />
                  <span
                    className="pull-right hidden-xs"
                    style={{
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </div>
              </div>
              {this.state.isSuccess ? (
                <div className="row">
                  <div className="col-sm-6 col-sm-offset-3">
                    <div className="alert alert-success">
                      <strong>Success!</strong> Driver{" "}
                      {this.state.editMode ? "updated" : "added"} successfully!
                    </div>
                  </div>
                </div>
              ) : null}
              {this.state.isSuccessAssign ? (
                <div className="row">
                  <div className="col-sm-6 col-sm-offset-3">
                    <div className="alert alert-success">
                      <strong>Success!</strong>
                      {this.state.assign_message}
                    </div>
                  </div>
                </div>
              ) : null}
              {this.state.errorMessage.length > 0 ? (
                <div className="row">
                  <div className="col-sm-6 col-sm-offset-3">
                    <div className="alert alert-danger">
                      <ul>
                        {this.state.errorMessage.map((message, i) => (
                          <li key={i}>{message}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ) : null}
              {this.state.isAllowed ? (
                <Fragment>
                  <div className="row filter-row">
                    <div className="col-sm-3 col-xs-12">
                      <div
                        className={
                          this.state.focusedSearchWith
                            ? "form-group form-focus focused"
                            : "form-group form-focus"
                        }
                      >
                        <label className="control-label">
                          Plate/Name/ID/Email
                        </label>
                        <input
                          type="text"
                          onFocus={this.onFocusHandlerToggleSearchWith}
                          onBlur={this.onFocusHandlerToggleSearchWith}
                          className="form-control"
                          ref={(el) => (this.searchWith = el)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-5 col-xs-12">
                      <div className="row">
                        <div className="col-sm-6 col-xs-12">
                          <div
                            className={
                              this.state.focusedFrom
                                ? "form-group form-focus focused"
                                : "form-group form-focus"
                            }
                          >
                            <label className="control-label">
                              Agreement Start Date
                            </label>
                            <div className="cal-icon">
                              <Datetime
                                dateFormat="YYYY-MM-DD"
                                onFocus={this.onFocusHandlerDateFrom}
                                onBlur={this.onBlurHandlerDateFrom}
                                timeFormat={false}
                                ref={(el) => (this.dateFrom = el)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                          <div
                            className={
                              this.state.focusedTo
                                ? "form-group form-focus focused"
                                : "form-group form-focus "
                            }
                          >
                            <label className="control-label">
                              Agreement End Date
                            </label>
                            <div className="cal-icon">
                              <Datetime
                                dateFormat="YYYY-MM-DD"
                                onFocus={this.onFocusHandlerDateTo}
                                onBlur={this.onBlurHandlerDateTo}
                                timeFormat={false}
                                ref={(el) => (this.dateTo = el)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-2 col-xs-12">
                      <Select2
                        ref="agreementStatus"
                        className="selector"
                        data={[
                          { id: "", text: "All" },
                          { id: "ACTIVE", text: "Active" },
                          { id: "INACTIVE", text: "In-Active" },
                        ]}
                        value={this.state.agreementStatus}
                        options={{
                          width: "100%",
                          minimumResultsForSearch: 5,
                        }}
                      />
                    </div>
                    <div className=" col-sm-2 col-xs-12">
                      <button
                        type="button"
                        className="btn btn-success btn-block"
                        onClick={this.searchClickHandler}
                      >
                        {" "}
                        Search{" "}
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <table className="table table-striped custom-table m-b-0 ">
                          <thead>
                            <tr>
                              <th>Driver ID</th>
                              <th>Full Name</th>
                              <th>Plate#</th>
                              <th>Email</th>
                              <th>Phone#</th>
                              <th>Agreement Start</th>
                              <th>Agreement End</th>
                              <th>Date Added</th>
                              <th>Last Updated</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>{rows}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-5">
                      <div className="dataTables_info">
                        Showing{" "}
                        {this.state.itemsCountPerPage *
                          (this.state.activePage - 1)}{" "}
                        to&nbsp;
                        {this.state.itemsCountPerPage *
                          this.state.activePage}{" "}
                        of&nbsp;
                        {this.state.totalItemsCount} entries
                      </div>
                    </div>
                    <div className="col-sm-7">
                      <div className="dataTables_paginate">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={this.state.itemsCountPerPage}
                          totalItemsCount={this.state.totalItemsCount}
                          pageRangeDisplayed={this.state.pageRangeDisplayed}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    </div>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div style={{ textAlign: "lef", color: "#9e9e9e" }}>
                    <h2>Error: {this.state.responseError}</h2>
                  </div>
                </Fragment>
              )}
            </BlockUi>
          </div>
        </div>
        <DriverDetails
          ref={(el) => (this.driverDetail = el)}
          refresh={this.refreshDriver}
        />
        <AddDriver
          ref={(el) => (this.addDriverModal = el)}
          refresh={this.refreshDriver}
        />

        <DeleteDriver
          ref={(el) => (this.deleteLDriverModal = el)}
          refresh={this.refreshDriver}
        />

        <DriverBulkUpload
          ref={(el) => (this.bulkUploadDriver = el)}
          refresh={this.refreshDriver}
        />
      </Aux>
    );
  }
}
const mapStatesToProps = (state) => {
  return {
    componentIndex: state.componentIndex,
    sidebar_status: state.sidebarOpened,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onLinkChange: (i) => dispatch({ type: actionTypes.UPDATE_NAVIGATION, i }),
    getComponentIndex: (path) =>
      dispatch({ type: actionTypes.GET_COMPONENT_INDEX, path }),
  };
};
export default connect(mapStatesToProps, mapDispatchToProps)(Driver);
