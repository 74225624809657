import $ from 'jquery';
import React, { Component } from 'react';
import PasswordChecklist from 'react-password-checklist';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import BuggyApi from '../../services/BuggyApi';
import BuggyAuth from '../../services/BuggyAuth';
import * as actionTypes from '../../store/actions/actions';
import BlockUi from '../BlockUi';
import Captcha from '../Captcha/Captcha';
import Aux from '../hoc/Auxiliary';
import SidebarOverlay from '../Sidebar/SidebarOverlay/SidebarOverlay';

class Signup extends Component {
  state = {
    titleValue: '',
    usernameValue: '',
    passwordValue: '',
    emailValue: '',
    phoneNumberValue: '',
    blocking: false,
    isValid: false,
    disableButton: true,
    errorMessage: [],
    checkCaptcha: false,
    captchaResponseToken: '',
  };

  isValidOnChangeHandler = (event) => {
    this.setState({
      isValid: event,
    });
    if (this.state.checkCaptcha) {
      this.setState({
        disableButton: false,
      });
    }
  };

  titleOnChangeHandler = (event) => {
    this.setState({
      titleValue: event.target.value,
    });
  };

  usernameOnChangeHandler = (event) => {
    this.setState({
      usernameValue: event.target.value,
    });
  };

  passwordOnChangeHandler = (event) => {
    this.setState({
      passwordValue: event.target.value,
    });
  };

  emailOnChangeHandler = (event) => {
    this.setState({
      emailValue: event.target.value,
    });
  };

  phoneNumberOnChangeHandler = (event) => {
    this.setState({
      phoneNumberValue: event.target.value,
    });
  };

  onCaptchaClick = (responseToken) => {
    this.setState({ checkCaptcha: true, captchaResponseToken: responseToken });
    if (this.state.isValid) {
      this.setState({
        disableButton: false,
      });
    }
  };

  resetCaptch = () => {
    this.setState({ checkCaptcha: false });
    this.setState({
      disableButton: true,
    });
    this.captchaInstance.reset();
  };

  signupOnClickHandler = (event) => {
    event.preventDefault();
    this.setState({ blocking: true });
    if (this.state.checkCaptcha) {
      const formData = {
        fleet_name: this.state.titleValue,
        username: this.state.usernameValue,
        password: this.state.passwordValue,
        email: this.state.emailValue,
        phone_number: this.state.phoneNumberValue,
        responseToken: this.state.captchaResponseToken,
      };

      new BuggyApi()
        .createFleet(formData)
        .then((response) => {
          this.resetCaptch();
          this.setState({
            errorMessage: [],
            titleValue: '',
            usernameValue: '',
            passwordValue: '',
            emailValue: '',
            phoneNumberValue: '',
            blocking: false,
          });
          this.props.onSignupSuccess();
          this.props.history.push('/');
        })
        .catch((error) => {
          this.setState({ blocking: false });
          const errorObj = error.response.data;
          if (errorObj && Object.keys(errorObj).length > 0) {
            const formattedOutput = `${Object.keys(errorObj)[0]} : ${Object.values(errorObj)[0]}`;
            const em = `Oops! ${formattedOutput}`;
            this.setState({
              errorMessage: [em],
            });
          } else if (error.request) {
            const em = 'Oops! Something went wrong!';
            this.setState({
              errorMessage: [em],
            });
          } else {
            /* console.log('Error', error.message); */
          }
        });
    } else {
      const em = "Oops! Looks like you haven't filled captcha!";
      this.setState({
        errorMessage: [em],
      });
    }
  };

  componentDidMount() {
    window.$ = window.jQuery = $;
    (function ($) {
      const Sidemenu = function () {
        this.$menuItem = $('#sidebar-menu a');
      };

      Sidemenu.prototype.init = function () {
        const $this = this;
        $this.$menuItem.on('click', function (e) {
          if ($(this).parent().hasClass('submenu')) {
            e.preventDefault();
          }
          if (!$(this).hasClass('subdrop')) {
            $('ul', $(this).parents('ul:first')).slideUp(350);
            $('a', $(this).parents('ul:first')).removeClass('subdrop');
            $(this).next('ul').slideDown(350);
            $(this).addClass('subdrop');
          } else if ($(this).hasClass('subdrop')) {
            $(this).removeClass('subdrop');
            $(this).next('ul').slideUp(350);
          }
        });
        $('#sidebar-menu ul li.submenu a.active')
          .parents('li:last')
          .children('a:first')
          .addClass('active')
          .trigger('click');
      };
      $.Sidemenu = new Sidemenu();
    }(window.jQuery));

    $(document).ready(() => {
      // Sidebar overlay

      const $sidebarOverlay = $('.sidebar-overlay');
      $('#mobile_btn, .task-chat').on('click', function (e) {
        const $target = $($(this).attr('href'));
        if ($target.length) {
          $target.toggleClass('opened');
          $sidebarOverlay.toggleClass('opened');
          $('html').toggleClass('menu-opened');
          $sidebarOverlay.attr('data-reff', $(this).attr('href'));
        }
        e.preventDefault();
      });

      $sidebarOverlay.on('click', function (e) {
        const $target = $($(this).attr('data-reff'));
        if ($target.length) {
          $target.removeClass('opened');
          $('html').removeClass('menu-opened');
          $(this).removeClass('opened');
          $('.main-wrapper').removeClass('slide-nav-toggle');
        }
        e.preventDefault();
      });

      // Sidebar Initiate

      $.Sidemenu.init();

      // Select2

      if ($('.select').length > 0) {
        $('.select').select2({
          minimumResultsForSearch: -1,
          width: '100%',
        });
      }

      // Modal Popup hide show

      if ($('.modal').length > 0) {
        const modalUniqueClass = '.modal';
        $('.modal').on('show.bs.modal', function (e) {
          const $element = $(this);
          const $uniques = $(`${modalUniqueClass}:visible`).not($(this));
          if ($uniques.length) {
            $uniques.modal('hide');
            $uniques.one('hidden.bs.modal', (e) => {
              $element.modal('show');
            });
            return false;
          }
        });
      }

      // Floating Label

      if ($('.floating').length > 0) {
        $('.floating')
          .on('focus blur', function (e) {
            $(this)
              .parents('.form-focus')
              .toggleClass(
                'focused',
                e.type === 'focus' || this.value.length > 0,
              );
          })
          .trigger('blur');
      }

      // Message Notification Slimscroll

      // Sidebar Slimscroll

      // Page Content Height

      if ($('.page-wrapper').length > 0) {
        const height = $(window).height();
        $('.page-wrapper').css('min-height', height);
      }

      $(window).resize(() => {
        if ($('.page-wrapper').length > 0) {
          const height = $(window).height();
          $('.page-wrapper').css('min-height', height);
        }
      });

      // Date Time Picker

      // Datatable

      // Tooltip

      // Button Switch

      if ($('.btn-toggle').length > 0) {
        $('.btn-toggle').click(function () {
          $(this).find('.btn').toggleClass('active');
          if ($(this).find('.btn-success').size() > 0) {
            $(this).find('.btn').toggleClass('btn-success');
          }
        });
      }

      // Mobile Menu

      if ($('.main-wrapper').length > 0) {
        const $wrapper = $('.main-wrapper');
        $(document).on('click', '#mobile_btn', (e) => {
          $('.dropdown.open > .dropdown-toggle').dropdown('toggle');
          return false;
        });
        $(document).on('click', '#mobile_btn', (e) => {
          $wrapper.toggleClass('slide-nav-toggle');
          $('#chat_sidebar').removeClass('opened');
          return false;
        });
        $(document).on('click', '#open_msg_box', (e) => {
          $wrapper.toggleClass('open-msg-box').removeClass('');
          $('.dropdown').removeClass('open');
          return false;
        });
      }

      // Message box remove

      if ($('.dropdown-toggle').length > 0) {
        $('.dropdown-toggle').click(() => {
          if ($('.main-wrapper').hasClass('open-msg-box')) {
            $('.main-wrapper').removeClass('open-msg-box');
          }
        });
      }

      // Table Dropdown

      $('.table-responsive').on('shown.bs.dropdown', function (e) {
        const $table = $(this);
        const $dropmenu = $(e.target).find('.dropdown-menu');
        const tableOffsetHeight = $table.offset().top + $table.height();
        const menuOffsetHeight = $dropmenu.offset().top + $dropmenu.outerHeight(true);

        if (menuOffsetHeight > tableOffsetHeight) { $table.css('padding-bottom', menuOffsetHeight - tableOffsetHeight); }
      });
      $('.table-responsive').on('hide.bs.dropdown', function () {
        $(this).css('padding-bottom', 0);
      });

      // Custom Modal Backdrop

      $('a[data-toggle="modal"]').on('click', () => {
        setTimeout(() => {
          if ($('.modal.custom-modal').hasClass('in')) {
            $('.modal-backdrop').addClass('custom-backdrop');
          }
        }, 500);
      });

      // Email Inbox

      if ($('.clickable-row').length > 0) {
        $('.clickable-row').click(function () {
          window.location = $(this).data('href');
        });
      }

      if ($('.checkbox-all').length > 0) {
        $('.checkbox-all').click(() => {
          $('.checkmail').click();
        });
      }
      if ($('.checkmail').length > 0) {
        $('.checkmail').each(function () {
          $(this).click(function () {
            if ($(this).closest('tr').hasClass('checked')) {
              $(this).closest('tr').removeClass('checked');
            } else {
              $(this).closest('tr').addClass('checked');
            }
          });
        });
      }
      if ($('.mail-important').length > 0) {
        $('.mail-important').click(function () {
          $(this).find('i.fa').toggleClass('fa-star');
          $(this).find('i.fa').toggleClass('fa-star-o');
        });
      }

      // Incoming call popup

      if ($('#incoming_call').length > 0) {
        $(window).on('load', () => {
          $('#incoming_call').modal('show');
          $('body').addClass('call-modal');
        });
      }
    });
    if (BuggyAuth.isAuthenticated() === true) {
      this.props.history.push('/dashboard');
    }
  }

  render() {
    return (
      <Aux>
        <div className="main-wrapper">
          <div className="account-page">
            <div className="container">
              <h3 className="account-title">FleetIT Register</h3>
              <BlockUi tag="div" blocking={this.state.blocking}>
                <div className="account-box">
                  <div className="account-wrapper">
                    <div className="account-logo">
                      <a href="/">
                        {/* <img src="assets/img/logo2.png" alt="Focus Technologies"/> */}
                      </a>
                    </div>

                    {this.state.errorMessage.length > 0 ? (
                      <div className="row">
                        <div className="col-xs-12">
                          <div className="alert alert-danger">
                            <ul>
                              {this.state.errorMessage.map((message, i) => (
                                <li key={i}>{message}</li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <form action="">
                      <div className="form-group form-focus">
                        <label className="control-label">Fleet Title</label>
                        <input
                          className="form-control floating"
                          onChange={this.titleOnChangeHandler}
                          value={this.state.titleValue}
                          type="text"
                        />
                      </div>
                      <div className="form-group form-focus">
                        <label className="control-label">Username</label>
                        <input
                          className="form-control floating"
                          onChange={this.usernameOnChangeHandler}
                          value={this.state.usernameValue}
                          type="text"
                        />
                      </div>
                      <div className="form-group form-focus">
                        <label className="control-label">Password</label>
                        <input
                          className="form-control floating"
                          onChange={this.passwordOnChangeHandler}
                          value={this.state.passwordValue}
                          type="password"
                        />
                      </div>
                      <PasswordChecklist
                        rules={[
                          'minLength',
                          'specialChar',
                          'number',
                          'capital',
                        ]}
                        minLength={8}
                        value={this.state.passwordValue}
                        onChange={this.isValidOnChangeHandler}
                      />
                      <br />
                      <div className="form-group form-focus">
                        <label className="control-label">Email</label>
                        <input
                          className="form-control floating"
                          onChange={this.emailOnChangeHandler}
                          value={this.state.emailValue}
                          type="text"
                        />
                      </div>
                      <div className="form-group form-focus">
                        <label className="control-label">Phone Number</label>
                        <input
                          className="form-control floating"
                          onChange={this.phoneNumberOnChangeHandler}
                          value={this.state.phoneNumberValue}
                          type="text"
                        />
                      </div>
                      <Captcha
                        ref={(el) => (this.captchaInstance = el)}
                        successCallback={this.onCaptchaClick}
                      />

                      <div className="form-group text-center">
                        <button
                          className="btn btn-primary btn-block account-btn"
                          onClick={this.signupOnClickHandler}
                          type="submit"
                          disabled={this.state.disableButton}
                        >
                          Signup
                        </button>
                      </div>
                      <div className="text-center">
                        <Link to="/">Already have an account?</Link>
                      </div>
                    </form>
                  </div>
                </div>
              </BlockUi>
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
        <SidebarOverlay />
      </Aux>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  onSignupSuccess: () => dispatch({ type: actionTypes.SIGNUP_SUCCESS }),
});
export default connect(null, mapDispatchToProps)(Signup);
