import { getParkingTicketsDownloadLink, GetParkingTicketsParams } from '../../../services/tickets';
import { downloadFileFromURL } from '../../../utils/download';

export async function downloadParkingTicketsCSV(
  params: GetParkingTicketsParams & { columns: string[] },
) {
  const { data: { url } } = await getParkingTicketsDownloadLink(params);

  downloadFileFromURL(url);
}

export default {};
