import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { isEmpty, map, set } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  MdCheck,
  MdDownload, MdRestore, MdSave, MdSend,
} from 'react-icons/md';
import { useSelector } from 'react-redux';

import { GetParkingTicketsParams } from '@/services/tickets';

import { getUserSettings, setUserSettings } from '../../../services/userSettings';
import { TollsOptions, TollsSettings } from '../settings';
import { DownloadCSVParams } from './helpers';
import { useDialogSelector } from './useDialogSelector';

export type DownloadCSVProps = ({
  queryParams: Omit<DownloadCSVParams, 'columns'>;
  settingsName: 'ticket';
} | {
  queryParams: GetParkingTicketsParams;
  settingsName: 'parking';
}) & {
  downloadCSV: (params: DownloadCSVProps['queryParams'] & { columns: string[] }) => Promise<void>;
  emailCSV?: (params: DownloadCSVProps['queryParams'] & { columns: string[], email: string }) => Promise<AxiosResponse<any, any>>
};

type FormValues = {
  columns: string[];
};

export function DownloadCSV({
  queryParams,
  settingsName,
  downloadCSV,
  emailCSV,
}: DownloadCSVProps) {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const { userInfo } = useSelector((state) => state as any);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      columns: [],
    },
  });

  const SETTINGS_NAME = settingsName;

  const fleetData = useSelector((state: any) => state.userInfo);

  const selectedColumns = watch('columns');

  const { getEmail, selectEmailDialog } = useDialogSelector({
    CTAMessage: 'send',
    options: [
      { value: 'self', label: userInfo.fleet_email },
      ...(userInfo
        ? userInfo.emails.map(({ email }: { email: string }) => ({
          value: email,
          label: email,
        }))
        : []
      ),
    ],
    message: 'Select an email to send the CSV file to:',
    title: 'Select Email',
  });

  const { data: settingsData } = useQuery({
    queryKey: ['settings', SETTINGS_NAME, fleetData],
    queryFn: () => getUserSettings<TollsSettings, TollsOptions>(SETTINGS_NAME),
    staleTime: Infinity,
    onSuccess: ({ data }) => {
      const columns = data.settings.download?.columns || data.defaults.download?.columns || [];
      setValue(
        'columns',
        columns,
      );
    },
  });

  const { isLoading: isCSVDownloading, mutate: download } = useMutation({
    mutationFn: (columns: string[]) => downloadCSV({
      ...queryParams,
      columns,
    }),
    onSuccess: () => {
      setIsOptionsOpen(false);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar({
        message: err.message,
        variant: 'error',
      });
    },
  });

  const { isLoading: isEmailSending, mutate: sendEmail } = useMutation({
    mutationFn: async ({ columns, email }: { columns: string[]; email: string }) => emailCSV?.({
      ...queryParams,
      columns,
      email,
    }),
    onSuccess: (res) => {
      if (!res) return;
      setIsOptionsOpen(false);
      enqueueSnackbar({
        message: res.data.message,
        variant: 'success',
      });
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar({
        message: err.message,
        variant: 'error',
      });
    },
  });

  const resetSettings = useCallback(() => {
    if (!settingsData?.data) {
      return;
    }

    const { defaults, settings } = settingsData.data;

    setValue(
      'columns',
      settings.download?.columns || defaults.download?.columns || [],
    );
  }, [setValue, settingsData]);

  const {
    mutate: updateSettings,
    isLoading: isSettingsUpdateLoading,
  } = useMutation({
    mutationKey: ['settings', SETTINGS_NAME],
    mutationFn: ({ columns }: FormValues) => setUserSettings(
      SETTINGS_NAME,
      settingsData?.data.settings,
      'download.columns',
      columns,
    ),
    onError: (err: AxiosError) => {
      enqueueSnackbar({
        message: err.message,
        variant: 'error',
      });
      resetSettings();
    },
    onSuccess: (data, { columns }) => {
      queryClient.setQueryData(['settings', SETTINGS_NAME], (prev: typeof settingsData) => {
        if (!prev) {
          return prev;
        }

        return set(prev, 'data.settings.download.columns', columns);
      });
    },
  });

  const columnsOptions = useMemo(
    () => settingsData?.data?.options?.download?.columns || [],
    [settingsData?.data?.options?.download?.columns],
  );

  const onSave = useCallback((values: FormValues) => {
    updateSettings(values);
    reset(values);
  }, [reset, updateSettings]);

  const onCloseDownload = () => {
    if (isCSVDownloading) {
      return;
    }

    setIsOptionsOpen(false);
  };

  const onClickOpenDownload = () => setIsOptionsOpen(true);

  const onSubmitColumnsDownload = useCallback(
    ({ columns }: FormValues) => {
      download(columns);
    },
    [download],
  );

  const onSubmitColumnsSendEmail = useCallback(
    async ({ columns }: FormValues) => {
      const selectedEmail = await getEmail();
      sendEmail({ columns, email: selectedEmail });
    },
    [getEmail, sendEmail],
  );

  const onChangeSelectAll = useCallback((
    event: React.SyntheticEvent,
    checked: boolean,
  ) => {
    if (checked) {
      setValue('columns', map(columnsOptions, 'key'));
    } else {
      setValue('columns', []);
    }
  }, [columnsOptions, setValue]);

  return (
    <>
      {selectEmailDialog}
      <Button
        onClick={onClickOpenDownload}
        variant="text"
        startIcon={<MdDownload />}
        disableElevation
        size="small"
      >
        Download
      </Button>
      <Dialog open={isOptionsOpen} onClose={onCloseDownload} fullWidth>
        <DialogTitle>Select columns</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select columns to include in the CSV file.
          </DialogContentText>
          <FormGroup>
            <Stack direction="row" spacing={1} py={1}>
              <FormControlLabel
                control={(
                  <Checkbox
                    indeterminate={
                      !isEmpty(selectedColumns)
                      && selectedColumns.length !== columnsOptions.length
                    }
                  />
                )}
                disabled={isCSVDownloading || isEmailSending}
                label="Select All"
                onChange={onChangeSelectAll}
                checked={selectedColumns.length === columnsOptions.length}
              />
              <Box flexGrow={1} />
              <Button
                startIcon={<MdRestore />}
                onClick={resetSettings}
              >
                Reset
              </Button>
              <LoadingButton
                startIcon={formState.isDirty ? <MdSave /> : <MdCheck />}
                disabled={!formState.isDirty}
                loading={isSettingsUpdateLoading}
                onClick={handleSubmit(onSave)}
              >
                {formState.isDirty ? 'Save' : 'Saved'}
              </LoadingButton>
            </Stack>
            <Divider />
            {columnsOptions.map(({ key, name }) => (
              <FormControlLabel
                key={key}
                value={key}
                control={<Switch />}
                label={name}
                disabled={isCSVDownloading || isEmailSending}
                checked={selectedColumns.includes(key)}
                {...register('columns')}
              />
            ))}
          </FormGroup>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          {emailCSV && (
            <LoadingButton
              startIcon={<MdSend />}
              onClick={handleSubmit(onSubmitColumnsSendEmail)}
              loading={isEmailSending}
              disabled={isCSVDownloading || isEmpty(selectedColumns)}
            >
              Send to email
            </LoadingButton>
          )}
          <LoadingButton
            startIcon={<MdDownload />}
            onClick={handleSubmit(onSubmitColumnsDownload)}
            loading={isCSVDownloading}
            disabled={isEmailSending || isEmpty(selectedColumns)}
          >
            Download
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DownloadCSV;
