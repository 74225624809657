import { map } from 'lodash';
import { z } from 'zod';

import { USA_STATES } from '@/services/USA_STATS';
import { vehicleType } from '@/services/VehicleTypes';

function createAddVehicleSchema() {
  const [firstState, ...otherStates] = map(USA_STATES, 'id');
  const [firstVehicleType, ...otherTypes] = map(vehicleType, 'id');

  return z.object({
    plateNumber: z.string().min(1),
    plateState: z.enum([firstState, ...otherStates]),
    vehicleMake: z.string(),
    vehicleModel: z.string(),
    year: z.coerce.number(),
    vehicleType: z.enum([firstVehicleType, ...otherTypes]),
    trackingCode: z.string(),
    customFields: z.record(z.string()).optional(),
    lesseeRenter: z.string().optional().nullish(),
    id: z.number().optional(),
  });
}

export type AddVehicleFormValues = z.TypeOf<ReturnType<typeof createAddVehicleSchema>>;

export default createAddVehicleSchema;
