import React, { Component } from "react";
import BuggyApi from "../../services/BuggyApi";
import "react-select2-wrapper/css/select2.css";
import BlockUi from "../BlockUi";
import Select2 from "react-select2-wrapper";
import "./modal.css";

import $ from "jquery";
import Datetime from "react-datetime";
import Pagination from "react-js-pagination";

import Aux from "../hoc/Auxiliary";

class DriverDetails extends Component {
  state = {
    driver_id: null,
    driver_details: null,
    isShown: false,
    editMode: false,
    isSuccess: false,
    errorMessage: [],
    blocking: false,
    tickets: [],
    activeTab: 1,
    exitPlazas: [],
    exitPlazaValue: "",
    itemsCountPerPage: 20,
    totalItemsCount: 0,
    pageRangeDisplayed: 10,
    activePage: 1,
    violationItemsCountPerPage: 20,
    violationTotalItemsCount: 0,
    violationPageRangeDisplayed: 10,
    violationActivePage: 1,
    focusedFrom: false,
    focusedTo: false,
    focusedIssueFrom: false,
    showAnimation: false,
    botReport: null,
    violations: [],
    focusedIssueTo: false,
    focusedRespondByFrom: false,
    focusedRespondByTo: false,
    focusedReportDateFrom: false,
    focusedReportDateTo: false,
    focusedViolationTimeFrom: false,
    focusedViolationTimeTo: false,
    liabilityBots: [{ id: "", text: "All Accounts" }],
    ticketStatuses: [
      { id: "", text: "All" },
      { id: "FILLED", text: "Filled" },
      { id: "UNFILLED", text: "Un-Filled" },
    ],
    selectedBot: "",
    selectedTicketStatus: "",
    focusedPlate: false,
    isAllowed: false,
    responseError:
      "You're not authorized to use this feature please contact admin",
    avatar_background:
      "#" + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6),
  };

  onModalShow = (driver_id, is_edit = false) => {
    document.body.classList.add("modal-open");
    this.setState({
      driver_id: driver_id,
      avatar_background:
        "#" + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6),
    });
    if (is_edit) {
      this.setState({
        editMode: true,
      });
    } else {
    }
    new BuggyApi()
      .getDriverDetails(driver_id)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            driver_details: response.data,
          });
        }
      })
      .catch((error) => {
        console.log("error", error.response);
      });
    new BuggyApi().searchTicketsForDriverDetails(driver_id).then((response) => {
      if (this.is_mounted) {
        this.setState({
          tickets: response.data.objects,
          itemsCountPerPage: response.data.meta.limit,
          totalItemsCount: response.data.meta.total_count,
        });
      }
    });
    new BuggyApi().getExitPlazas().then((response) => {
      if (this.is_mounted) {
        const exitPlazas = response.data.objects;
        const data = [];
        data.push({ id: "", text: "All Exit Plazas" });
        exitPlazas.forEach(function (exitPlaza) {
          data.push({ id: exitPlaza.code, text: exitPlaza.location });
        });
        this.setState({
          exitPlazas: data,
        });
      }
    });
    new BuggyApi()
      .getBotLogin()
      .then((response) => {
        const liabilityBots = response.data.objects;
        const data = [];
        data.push({ id: "", text: "All Accounts" });
        liabilityBots.forEach(function (bot) {
          data.push({ id: bot.id, text: bot.username });
        });
        this.setState({
          isAllowed: true,
          liabilityBots: data,
        });
        new BuggyApi()
          .searchViolationsForDriverDetails(driver_id)
          .then((response) => {
            if (this.is_mounted) {
              this.setState({
                violations: response.data.objects,
                itemsCountPerPage: response.data.meta.limit,
                totalItemsCount: response.data.meta.total_count,
              });
            }
          });
      })
      .catch((error) => {
        /*console.log('Error', error);*/
        if (error.response) {
          let error_msg =
            "You're not authorized to use this feature please contact admin";
          if (error.response.data) {
            if (error.response.data.detail) {
              error_msg = error.response.data.detail;
            }
          }
          this.setState({
            isAllowed: false,
            responseError: error_msg,
          });
        } else if (error.request) {
        } else {
          /*console.log('Error', error.message);*/
        }
      });
    this.setState({
      isShown: true,
    });
  };
  onModalClose = () => {
    document.body.classList.remove("modal-open");
    this.setState({
      isSuccess: false,
      isShown: false,
      errorMessage: [],
      editMode: false,
    });
  };
  onTabChange = (tab_id) => {
    if (tab_id === 1) {
      new BuggyApi()
        .searchTicketsForDriverDetails(this.state.driver_id)
        .then((response) => {
          if (this.is_mounted) {
            this.setState({
              tickets: response.data.objects,
              itemsCountPerPage: response.data.meta.limit,
              totalItemsCount: response.data.meta.total_count,
            });
          }
        });
    }
    if (tab_id === 2) {
      new BuggyApi()
        .searchViolationsForDriverDetails(this.state.driver_id)
        .then((response) => {
          if (this.is_mounted) {
            this.setState({
              violations: response.data.objects,
              itemsCountPerPage: response.data.meta.limit,
              totalItemsCount: response.data.meta.total_count,
            });
          }
        });
    }
    this.setState({ activeTab: tab_id });
  };
  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber });
    pageNumber -= 1;
    new BuggyApi()
      .searchTicketsForDriverDetails(
        this.state.driver_id,
        this.state.itemsCountPerPage * pageNumber,
        this.searchPlateNumber.value,
        this.dateFrom.state.inputValue,
        this.dateTo.state.inputValue,
        this.state.exitPlazaValue
      )
      .then((response) => {
        this.setState({
          tickets: response.data.objects,
          itemsCountPerPage: response.data.meta.limit,
          totalItemsCount: response.data.meta.total_count,
        });
      });
  };

  handleViolationPageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber });
    pageNumber -= 1;
    new BuggyApi()
      .searchViolationsForDriverDetails(
        this.state.driver_id,
        this.state.violationItemsCountPerPage * pageNumber,
        this.searchPlate.value,
        this.dateIssueFrom.state.inputValue,
        this.dateIssueTo.state.inputValue,
        this.dateRespondByTo.state.inputValue,
        this.dateRespondByTo.state.inputValue,
        this.dateReportDateFrom.state.inputValue,
        this.dateReportDateTo.state.inputValue,
        this.violationTimeFrom.state.inputValue,
        this.violationTimeTo.state.inputValue,
        this.state.selectedBot,
        this.state.selectedTicketStatus
      )
      .then((response) => {
        this.setState({
          violations: response.data.objects,
          violationItemsCountPerPage: response.data.meta.limit,
          violationTotalItemsCount: response.data.meta.total_count,
        });
      });
  };

  searchViolationClickHandler = (evt, offset = 0) => {
    const selectedBot = this.liabilityBotDropdown.el.val() || "";
    const selectedTicketStatus =
      this.selectedTicketStatusDropdown.el.val() || "";
    this.setState({
      selectedBot: selectedBot,
      selectedTicketStatus: selectedTicketStatus,
    });
    new BuggyApi()
      .searchViolationsForDriverDetails(
        this.state.driver_id,
        offset,
        this.searchPlate.value,
        this.dateIssueFrom.state.inputValue,
        this.dateIssueTo.state.inputValue,
        this.dateRespondByTo.state.inputValue,
        this.dateRespondByTo.state.inputValue,
        this.dateReportDateFrom.state.inputValue,
        this.dateReportDateTo.state.inputValue,
        this.violationTimeFrom.state.inputValue,
        this.violationTimeTo.state.inputValue,
        selectedBot,
        selectedTicketStatus
      )
      .then((response) => {
        if (this.is_mounted) {
          this.setState({
            violations: response.data.objects,
            violationActivePage: 1,
            violationItemsCountPerPage: response.data.meta.limit,
            violationTotalItemsCount: response.data.meta.total_count,
          });
        }
      })
      .catch((error) => {
        /*console.log('Error', error);*/
        if (error.response) {
        } else if (error.request) {
        } else {
          /*console.log('Error', error.message);*/
        }
      });
  };

  onFocusHandlerToggle = (name) => {
    let obj = {};
    obj[name.toString()] = !this.state[name.toString()];
    this.setState(obj);
  };
  componentDidMount() {
    this.is_mounted = true;
    window.$ = window.jQuery = $;
  }
  downloadCsvClickHandler = () => {
    this.setState({ blocking: true });
    new BuggyApi()
      .getTicketsDownloadLink(
        0,
        this.searchPlateNumber.value,
        this.dateFrom.state.inputValue,
        this.dateTo.state.inputValue,
        this.state.exitPlazaValue
      )
      .then((response) => {
        if (this.is_mounted) {
          this.setState({
            blocking: false,
          });
        }
        window.open(response.data.url, "_blank");
      })
      .catch((error) => {
        this.setState({
          blocking: false,
        });
        if (error.response) {
        } else if (error.request) {
        } else {
          /*console.log('Error', error.message);*/
        }
      });
  };
  componentWillUnmount() {
    this.is_mounted = false;
  }
  searchClickHandler = () => {
    const exitPlazaValue = this.refs.exitPlazaDropdown.el.val() || "";
    this.setState({ exitPlazaValue: exitPlazaValue });
    new BuggyApi()
      .searchTicketsForDriverDetails(
        this.state.driver_id,
        0,
        this.searchPlateNumber.value,
        this.dateFrom.state.inputValue,
        this.dateTo.state.inputValue,
        exitPlazaValue
      )
      .then((response) => {
        if (this.is_mounted) {
          this.setState({
            tickets: response.data.objects,
            activePage: 1,
            itemsCountPerPage: response.data.meta.limit,
            totalItemsCount: response.data.meta.total_count,
          });
        }
      })
      .catch((error) => {
        /*console.log('Error', error);*/
        if (error.response) {
        } else if (error.request) {
        } else {
          /*console.log('Error', error.message);*/
        }
      });
  };

  dissociateDriver = (ticket_id) => {
    this.setState({
      blocking: true,
      errorMessage: [],
      success: false,
    });
    let data = {
      driver_id: this.state.driver_id,
      ticket_type: this.state.activeTab === 1 ? "EZ_PASS" : "STATE",
    };
    new BuggyApi()
      .dissociateDriver(ticket_id, data)
      .then((response) => {
        if (response.status === 204) {
          this.setState({
            blocking: false,
            success: true,
            errorMessage: [],
            search_with: "",
            searchResults: [],
          });
          this.onTabChange(this.state.activeTab);
          if (this.props.refresh) {
            this.props.refresh();
          }
        } else {
          this.setState({
            blocking: false,
            errorMessage: [],
            success: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          blocking: false,
        });
        if (error.response) {
          console.log(error.response);
          if (error.response.data) {
            let messages = [];
            if (error.response.data.driver_id) {
              messages.push(error.response.data.driver_id);
            }
            if (error.response.data.ticket_id) {
              messages.push(error.response.data.ticket_id);
            }
            if (error.response.data.ticket_type) {
              messages.push(error.response.data.ticket_type);
            }
            this.setState({
              errorMessage: messages,
            });
          }
        }
      });
  };

  render() {
    return (
      <div
        className={
          this.state.isShown
            ? "modal custom-modal fade in showModal"
            : "modal custom-modal fade hideModal"
        }
        role="dialog"
      >
        <div className="modal-dialog">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={this.onModalClose}
          >
            &times;
          </button>
          <div className="modal-content">
            <div className="modal-body">
              <div className="content container-fluid">
                <div className="row">
                  <div className="col-sm-8">
                    <h4 className="page-title">Driver Details</h4>
                  </div>
                  <div className="col-sm-4 text-right m-b-30"></div>
                </div>
                {this.state.driver_details ? (
                  <Aux>
                    <div className="card-box m-b-0">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="profile-view">
                            <div className="profile-img-wrap">
                              <div className="profile-img">
                                {this.state.driver_details.avatar ? (
                                  <img
                                    className="avatar"
                                    src={this.state.driver_details.avatar}
                                    alt=""
                                  />
                                ) : (
                                  <div
                                    className="avatar"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      backgroundColor: this.state.driver_details
                                        .initials_color
                                        ? this.state.driver_details
                                            .initials_color
                                        : this.state.avatar_background,
                                    }}
                                  >
                                    {this.state.driver_details.first_name ? (
                                      <h1
                                        style={{
                                          fontSize: "70px",
                                          fontWeight: 600,
                                          margin: "0 auto",
                                        }}
                                      >
                                        {this.state.driver_details.first_name.charAt(
                                          0
                                        )}
                                      </h1>
                                    ) : (
                                      <h1>A</h1>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="profile-basic">
                              <div className="row">
                                <div className="col-md-5">
                                  <div className="profile-info-left">
                                    <h3 className="user-name m-t-0">
                                      {this.state.driver_details.first_name +
                                        " " +
                                        this.state.driver_details.last_name}
                                    </h3>
                                    {this.state.driver_details.fleet ? (
                                      <Aux>
                                        <h5 className="company-role m-t-0 m-b-0">
                                          {this.state.driver_details.fleet
                                            .first_name +
                                            " " +
                                            this.state.driver_details.fleet
                                              .last_name}
                                        </h5>
                                        <small className="text-muted">
                                          Fleet
                                        </small>
                                      </Aux>
                                    ) : (
                                      <h5 className="company-role m-t-0 m-b-0">
                                        N/A
                                      </h5>
                                    )}

                                    <div className="staff-id">
                                      Driver ID :{" "}
                                      {this.state.driver_details.driver_id ||
                                        "N/A"}
                                    </div>
                                    <div className="staff-id">
                                      Mobile# :{" "}
                                      {this.state.driver_details
                                        .contact_number || "N/A"}
                                    </div>
                                    <div className="staff-id">
                                      Email :{" "}
                                      <a
                                        href={`mailto:${
                                          this.state.driver_details.email || ""
                                        }`}
                                      >
                                        {this.state.driver_details.email ||
                                          "N/A"}
                                      </a>
                                    </div>
                                    <div className="staff-msg">
                                      <button
                                        type="button"
                                        onClick={() => {
                                          alert("Send message here");
                                        }}
                                        className="btn btn-custom"
                                      >
                                        Send Message
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-7">
                                  <ul className="personal-info">
                                    <li>
                                      <span className="title">
                                        Agreement Status:
                                      </span>
                                      <span className="text">
                                        <span
                                          className={`label label-${
                                            this.state.driver_details
                                              .agreement_status === "ACTIVE"
                                              ? "success"
                                              : "danger"
                                          }-border`}
                                        >
                                          {this.state.driver_details
                                            .agreement_status || "N/A"}
                                        </span>
                                      </span>
                                    </li>
                                    <li>
                                      <span className="title">
                                        Agreement Started:
                                      </span>
                                      <span className="text">
                                        {this.state.driver_details
                                          .agreement_start_date || "N/A"}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="title">
                                        Agreement Ended:
                                      </span>
                                      <span className="text">
                                        {this.state.driver_details
                                          .agreement_end_date || "N/A"}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="title">
                                        Address Line 1:
                                      </span>
                                      <span className="text">
                                        {this.state.driver_details
                                          .address_line_1 || "N/A"}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="title">
                                        Address Line 2:
                                      </span>
                                      <span className="text">
                                        {this.state.driver_details
                                          .address_line_2 || "N/A"}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="title">Added Date:</span>
                                      <span className="text">
                                        {this.state.driver_details.date_added ||
                                          "N/A"}
                                      </span>
                                    </li>
                                    <li>
                                      <span className="title">
                                        Last Updated:
                                      </span>
                                      <span className="text">
                                        {this.state.driver_details
                                          .last_updated || "N/A"}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-box tab-box">
                      <div className="row user-tabs">
                        <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                          <ul className="nav nav-tabs tabs nav-tabs-bottom">
                            <li
                              className={`col-sm-3 ${
                                this.state.activeTab === 1 ? "active" : ""
                              }`}
                            >
                              <a
                                href
                                data-toggle="tab"
                                onClick={() => {
                                  this.onTabChange(1);
                                }}
                              >
                                EZ-Pass Tickets
                              </a>
                            </li>
                            <li
                              className={`col-sm-3 ${
                                this.state.activeTab === 2 ? "active" : ""
                              }`}
                            >
                              <a
                                href
                                data-toggle="tab"
                                onClick={() => {
                                  this.onTabChange(2);
                                }}
                              >
                                State Violations
                              </a>
                            </li>
                            <li
                              className={`col-sm-3 ${
                                this.state.activeTab === 3 ? "active" : ""
                              }`}
                            >
                              <a
                                href
                                data-toggle="tab"
                                onClick={() => {
                                  this.onTabChange(3);
                                }}
                              >
                                Invoices
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="tab-content  profile-tab-content">
                          <div
                            id="tickets"
                            className={`tab-pane fade ${
                              this.state.activeTab === 1 ? "in active" : ""
                            }`}
                          >
                            <div className="row">
                              <div className="col-xs-12">
                                <BlockUi
                                  tag="div"
                                  blocking={this.state.blocking}
                                >
                                  <div className="row">
                                    <div className="col-xs-8">
                                      <h4 className="page-title">
                                        EZ-Pass Tickets
                                      </h4>
                                    </div>
                                    <div className="col-xs-4 text-right m-b-30">
                                      <button
                                        type="button"
                                        className="btn btn-primary rounded pull-right"
                                        onClick={this.downloadCsvClickHandler}
                                      >
                                        <i className="fa fa-cloud-download"></i>{" "}
                                        Download CSV
                                      </button>
                                    </div>
                                  </div>
                                  <div className="row filter-row">
                                    <div className="col-sm-3 col-xs-6">
                                      <div className="form-group form-focus">
                                        <label className="control-label">
                                          Plate#
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control floating"
                                          ref={(el) =>
                                            (this.searchPlateNumber = el)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-sm-3 col-xs-6">
                                      <Select2
                                        ref="exitPlazaDropdown"
                                        data={this.state.exitPlazas}
                                        value={this.state.exitPlazaValue}
                                        options={{
                                          width: "100%",
                                          minimumResultsForSearch: 5,
                                        }}
                                      />
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                      <div className="row">
                                        <div className="col-sm-6 col-xs-6">
                                          <div
                                            className={
                                              this.state.focusedFrom
                                                ? "form-group form-focus focused"
                                                : "form-group form-focus"
                                            }
                                          >
                                            <label className="control-label">
                                              From
                                            </label>
                                            <div className="cal-icon">
                                              <Datetime
                                                dateFormat="YYYY-MM-DD"
                                                onFocus={() =>
                                                  this.onFocusHandlerToggle(
                                                    "focusedFrom"
                                                  )
                                                }
                                                onBlur={() =>
                                                  this.onFocusHandlerToggle(
                                                    "focusedFrom"
                                                  )
                                                }
                                                timeFormat={false}
                                                ref={(el) =>
                                                  (this.dateFrom = el)
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-sm-6 col-xs-6">
                                          <div
                                            className={
                                              this.state.focusedTo
                                                ? "form-group form-focus focused"
                                                : "form-group form-focus "
                                            }
                                          >
                                            <label className="control-label">
                                              To
                                            </label>
                                            <div className="cal-icon">
                                              <Datetime
                                                dateFormat="YYYY-MM-DD"
                                                onFocus={() =>
                                                  this.onFocusHandlerToggle(
                                                    "focusedTo"
                                                  )
                                                }
                                                onBlur={() =>
                                                  this.onFocusHandlerToggle(
                                                    "focusedTo"
                                                  )
                                                }
                                                timeFormat={false}
                                                ref={(el) => (this.dateTo = el)}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-2 col-xs-6">
                                      <button
                                        type="button"
                                        className="btn btn-success btn-block"
                                        onClick={this.searchClickHandler}
                                      >
                                        {" "}
                                        Search{" "}
                                      </button>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="table-responsive">
                                        <table className="table table-striped custom-table m-b-0 ">
                                          <thead>
                                            <tr>
                                              <th>ticketID</th>
                                              <th>Amount</th>
                                              <th>Fee</th>
                                              <th>Exit Plaza</th>
                                              <th>Cash Cost</th>
                                              <th>Posting Date</th>
                                              <th>Trans Date Time</th>
                                              <th>Vehicle Plate#</th>
                                              <th>Action</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {this.state.tickets.map(
                                              (ticket, index) => {
                                                return (
                                                  <tr key={index}>
                                                    <td>{ticket.ticketID}</td>
                                                    <td>{ticket.amount}</td>
                                                    <td>{ticket.our_cost}</td>
                                                    <td>{ticket.exit_plaza}</td>
                                                    <td>{ticket.cash_cost}</td>
                                                    <td>
                                                      {ticket.posting_date}
                                                    </td>
                                                    <td>
                                                      {ticket.transaction_date}
                                                    </td>
                                                    <td>
                                                      {
                                                        ticket.vehicle_plate_number
                                                      }
                                                    </td>
                                                    <td>
                                                      <button
                                                        className="btn btn-sm btn-primary"
                                                        onClick={() =>
                                                          this.dissociateDriver(
                                                            ticket.ticketID
                                                          )
                                                        }
                                                      >
                                                        Dissociate Driver
                                                      </button>
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-sm-5">
                                      <div className="dataTables_info">
                                        Showing{" "}
                                        {this.state.itemsCountPerPage *
                                          (this.state.activePage - 1)}{" "}
                                        to&nbsp;
                                        {this.state.itemsCountPerPage *
                                          this.state.activePage}{" "}
                                        of&nbsp;
                                        {this.state.totalItemsCount} entries
                                      </div>
                                    </div>
                                    <div className="col-sm-7">
                                      <div className="dataTables_paginate">
                                        <Pagination
                                          activePage={this.state.activePage}
                                          itemsCountPerPage={
                                            this.state.itemsCountPerPage
                                          }
                                          totalItemsCount={
                                            this.state.totalItemsCount
                                          }
                                          pageRangeDisplayed={
                                            this.state.pageRangeDisplayed
                                          }
                                          onChange={this.handlePageChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </BlockUi>
                              </div>
                            </div>
                          </div>
                          <div
                            id="violations"
                            className={`tab-pane fade ${
                              this.state.activeTab === 2 ? "in active" : ""
                            }`}
                          >
                            <div className="row">
                              <div className="col-xs-12">
                                <BlockUi
                                  tag="div"
                                  blocking={this.state.blocking}
                                >
                                  <div className="row m-b-20">
                                    <div className="col-sm-4 col-xs-12">
                                      <h4 className="page-title">
                                        State Violations
                                      </h4>
                                    </div>
                                    <div className="col-sm-8 text-right  col-xs-12">
                                      <button
                                        type="button"
                                        className="btn btn-primary rounded pull-right"
                                        onClick={this.downloadCsvClickHandler}
                                      >
                                        <i className="fa fa-cloud-download"></i>{" "}
                                        Download CSV
                                      </button>
                                    </div>
                                  </div>
                                  <div className="row filter-row">
                                    <div className="col-sm-4 col-xs-12">
                                      <div className="row">
                                        <div className="col-sm-6 col-xs-6">
                                          <div
                                            className={
                                              this.state.focusedIssueFrom
                                                ? "form-group form-focus focused"
                                                : "form-group form-focus"
                                            }
                                          >
                                            <label className="control-label">
                                              Issue From
                                            </label>
                                            <div className="cal-icon">
                                              <Datetime
                                                dateFormat="YYYY-MM-DD"
                                                onFocus={() =>
                                                  this.onFocusHandlerToggle(
                                                    "focusedIssueFrom"
                                                  )
                                                }
                                                onBlur={() =>
                                                  this.onFocusHandlerToggle(
                                                    "focusedIssueFrom"
                                                  )
                                                }
                                                timeFormat={false}
                                                ref={(el) =>
                                                  (this.dateIssueFrom = el)
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-sm-6 col-xs-6">
                                          <div
                                            className={
                                              this.state.focusedIssueTo
                                                ? "form-group form-focus focused"
                                                : "form-group form-focus "
                                            }
                                          >
                                            <label className="control-label">
                                              Issue To
                                            </label>
                                            <div className="cal-icon">
                                              <Datetime
                                                dateFormat="YYYY-MM-DD"
                                                onFocus={() =>
                                                  this.onFocusHandlerToggle(
                                                    "focusedIssueTo"
                                                  )
                                                }
                                                onBlur={() =>
                                                  this.onFocusHandlerToggle(
                                                    "focusedIssueTo"
                                                  )
                                                }
                                                timeFormat={false}
                                                ref={(el) =>
                                                  (this.dateIssueTo = el)
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                      <div className="row">
                                        <div className="col-sm-6 col-xs-6">
                                          <div
                                            className={
                                              this.state.focusedRespondByFrom
                                                ? "form-group form-focus focused"
                                                : "form-group form-focus"
                                            }
                                          >
                                            <label className="control-label">
                                              Respond BY From
                                            </label>
                                            <div className="cal-icon">
                                              <Datetime
                                                dateFormat="YYYY-MM-DD"
                                                onFocus={() =>
                                                  this.onFocusHandlerToggle(
                                                    "focusedRespondByFrom"
                                                  )
                                                }
                                                onBlur={() =>
                                                  this.onFocusHandlerToggle(
                                                    "focusedRespondByFrom"
                                                  )
                                                }
                                                timeFormat={false}
                                                ref={(el) =>
                                                  (this.dateRespondByFrom = el)
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-sm-6 col-xs-6">
                                          <div
                                            className={
                                              this.state.focusedRespondByTo
                                                ? "form-group form-focus focused"
                                                : "form-group form-focus "
                                            }
                                          >
                                            <label className="control-label">
                                              Respond BY To
                                            </label>
                                            <div className="cal-icon">
                                              <Datetime
                                                dateFormat="YYYY-MM-DD"
                                                onFocus={() =>
                                                  this.onFocusHandlerToggle(
                                                    "focusedRespondByTo"
                                                  )
                                                }
                                                onBlur={() =>
                                                  this.onFocusHandlerToggle(
                                                    "focusedRespondByTo"
                                                  )
                                                }
                                                timeFormat={false}
                                                ref={(el) =>
                                                  (this.dateRespondByTo = el)
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                      <div className="row">
                                        <div className="col-sm-6 col-xs-6">
                                          <div
                                            className={
                                              this.state.focusedReportDateFrom
                                                ? "form-group form-focus focused"
                                                : "form-group form-focus"
                                            }
                                          >
                                            <label className="control-label">
                                              Report Date From
                                            </label>
                                            <div className="cal-icon">
                                              <Datetime
                                                dateFormat="YYYY-MM-DD"
                                                onFocus={() =>
                                                  this.onFocusHandlerToggle(
                                                    "focusedReportDateFrom"
                                                  )
                                                }
                                                onBlur={() =>
                                                  this.onFocusHandlerToggle(
                                                    "focusedReportDateFrom"
                                                  )
                                                }
                                                timeFormat={false}
                                                ref={(el) =>
                                                  (this.dateReportDateFrom = el)
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-sm-6 col-xs-6">
                                          <div
                                            className={
                                              this.state.focusedReportDateTo
                                                ? "form-group form-focus focused"
                                                : "form-group form-focus "
                                            }
                                          >
                                            <label className="control-label">
                                              Report Date To
                                            </label>
                                            <div className="cal-icon">
                                              <Datetime
                                                dateFormat="YYYY-MM-DD"
                                                onFocus={() =>
                                                  this.onFocusHandlerToggle(
                                                    "focusedReportDateTo"
                                                  )
                                                }
                                                onBlur={() =>
                                                  this.onFocusHandlerToggle(
                                                    "focusedReportDateTo"
                                                  )
                                                }
                                                timeFormat={false}
                                                ref={(el) =>
                                                  (this.dateReportDateTo = el)
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                      <div className="row">
                                        <div className="col-sm-6 col-xs-6">
                                          <div
                                            className={
                                              this.state
                                                .focusedViolationTimeFrom
                                                ? "form-group form-focus focused"
                                                : "form-group form-focus"
                                            }
                                          >
                                            <label className="control-label">
                                              Violation Time From
                                            </label>
                                            <div className="cal-icon">
                                              <Datetime
                                                dateFormat={false}
                                                timeFormat={"HH:mm"}
                                                onFocus={() =>
                                                  this.onFocusHandlerToggle(
                                                    "focusedViolationTimeFrom"
                                                  )
                                                }
                                                onBlur={() =>
                                                  this.onFocusHandlerToggle(
                                                    "focusedViolationTimeFrom"
                                                  )
                                                }
                                                ref={(el) =>
                                                  (this.violationTimeFrom = el)
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-sm-6 col-xs-6">
                                          <div
                                            className={
                                              this.state.focusedViolationTimeTo
                                                ? "form-group form-focus focused"
                                                : "form-group form-focus "
                                            }
                                          >
                                            <label className="control-label">
                                              Violation Time To
                                            </label>
                                            <div className="cal-icon">
                                              <Datetime
                                                dateFormat={false}
                                                timeFormat={"HH:mm"}
                                                onFocus={() =>
                                                  this.onFocusHandlerToggle(
                                                    "focusedViolationTimeTo"
                                                  )
                                                }
                                                onBlur={() =>
                                                  this.onFocusHandlerToggle(
                                                    "focusedViolationTimeTo"
                                                  )
                                                }
                                                ref={(el) =>
                                                  (this.violationTimeTo = el)
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-2 col-xs-12">
                                      <div
                                        className={
                                          this.state.focusedPlate
                                            ? "form-group form-focus focused"
                                            : "form-group form-focus"
                                        }
                                      >
                                        <label className="control-label">
                                          Plate#
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control floating"
                                          onFocus={() =>
                                            this.onFocusHandlerToggle(
                                              "focusedPlate"
                                            )
                                          }
                                          onBlur={() =>
                                            this.onFocusHandlerToggle(
                                              "focusedPlate"
                                            )
                                          }
                                          ref={(el) => (this.searchPlate = el)}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-sm-2 col-xs-12">
                                      <Select2
                                        ref={(el) =>
                                          (this.liabilityBotDropdown = el)
                                        }
                                        className="selector"
                                        data={this.state.liabilityBots}
                                        value={this.state.selectedBot}
                                        options={{
                                          width: "100%",
                                          minimumResultsForSearch: 5,
                                        }}
                                      />
                                    </div>
                                    <div className="col-sm-2 col-xs-12">
                                      <Select2
                                        ref={(el) =>
                                          (this.selectedTicketStatusDropdown =
                                            el)
                                        }
                                        className="selector"
                                        data={this.state.ticketStatuses}
                                        value={this.state.selectedTicketStatus}
                                        options={{
                                          width: "100%",
                                          minimumResultsForSearch: 5,
                                        }}
                                      />
                                    </div>
                                    <div className="col-sm-2 col-xs-12">
                                      <button
                                        type="button"
                                        className="btn btn-success btn-block"
                                        onClick={
                                          this.searchViolationClickHandler
                                        }
                                      >
                                        {" "}
                                        Search
                                      </button>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="table-responsive">
                                        <table className="table table-striped custom-table m-b-0 ">
                                          <thead>
                                            <tr>
                                              <th>Plate#</th>
                                              <th>ID-ST-TYP</th>
                                              <th>Summons#</th>
                                              <th>Open</th>
                                              <th>Issue Date</th>
                                              <th>Violation Time</th>
                                              <th>Respond BY</th>
                                              <th>Report Date</th>
                                              <th>Status</th>
                                              <th>Action</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {this.state.violations.map(
                                              (violation, index) => {
                                                let fill_status = null;

                                                if (
                                                  violation.filled_status ===
                                                  "FILLED"
                                                ) {
                                                  fill_status = (
                                                    <span className="label label-success-border">
                                                      Filled
                                                    </span>
                                                  );
                                                } else if (
                                                  violation.filled_status ===
                                                  "FILLED_MANUALLY"
                                                ) {
                                                  fill_status = (
                                                    <span className="label label-warning-border">
                                                      Manual Filled
                                                    </span>
                                                  );
                                                } else {
                                                  fill_status = (
                                                    <span className="label label-danger-border">
                                                      Unfilled
                                                    </span>
                                                  );
                                                }

                                                return (
                                                  <tr key={index}>
                                                    <td>{violation.plate}</td>
                                                    <td>
                                                      {violation.id_st_typ}
                                                    </td>
                                                    <td>
                                                      {violation.summons_no}
                                                    </td>
                                                    <td>
                                                      {
                                                        violation.open_with_currency
                                                      }
                                                    </td>
                                                    <td>
                                                      {violation.issue_date}
                                                    </td>
                                                    <td>
                                                      {violation.violation_time}
                                                    </td>
                                                    <td>
                                                      {violation.respond_by}
                                                    </td>
                                                    <td>
                                                      {violation.report_date}
                                                    </td>
                                                    <td>{fill_status}</td>
                                                    <td>
                                                      <button
                                                        className="btn btn-sm btn-primary"
                                                        onClick={() =>
                                                          this.dissociateDriver(
                                                            violation.id
                                                          )
                                                        }
                                                      >
                                                        Dissociate Driver
                                                      </button>
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-sm-5">
                                      <div className="dataTables_info">
                                        Showing{" "}
                                        {this.state.violationItemsCountPerPage *
                                          (this.state.violationActivePage -
                                            1)}{" "}
                                        to&nbsp;
                                        {this.state.violationItemsCountPerPage *
                                          this.state.violationActivePage}{" "}
                                        of&nbsp;
                                        {
                                          this.state.violationTotalItemsCount
                                        }{" "}
                                        entries
                                      </div>
                                    </div>
                                    <div className="col-sm-7">
                                      <div className="dataTables_paginate">
                                        <Pagination
                                          activePage={
                                            this.state.violationActivePage
                                          }
                                          itemsCountPerPage={
                                            this.state
                                              .violationItemsCountPerPage
                                          }
                                          totalItemsCount={
                                            this.state.violationTotalItemsCount
                                          }
                                          pageRangeDisplayed={
                                            this.state
                                              .violationPageRangeDisplayed
                                          }
                                          onChange={
                                            this.handleViolationPageChange
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </BlockUi>
                              </div>
                            </div>
                          </div>
                          <div
                            id="invoices"
                            className={`tab-pane fade ${
                              this.state.activeTab === 3 ? "in active" : ""
                            }`}
                          >
                            <div className="row">
                              <div className="col-xs-12">
                                <div className="row">
                                  <div className="col-sm-8">
                                    <h4 className="page-title">Invoices</h4>
                                  </div>
                                  <div className="col-sm-4 text-right m-b-30"></div>
                                </div>
                                <div className="row filter-row">
                                  <div className="col-sm-3 col-xs-6">
                                    <div className="form-group form-focus">
                                      <label className="control-label">
                                        From
                                      </label>
                                      <div className="cal-icon">
                                        <input
                                          className="form-control floating datetimepicker"
                                          type="text"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-3 col-xs-6">
                                    <div className="form-group form-focus">
                                      <label className="control-label">
                                        To
                                      </label>
                                      <div className="cal-icon">
                                        <input
                                          className="form-control floating datetimepicker"
                                          type="text"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-3 col-xs-6">
                                    <div className="form-group form-focus select-focus">
                                      <label className="control-label">
                                        Status
                                      </label>
                                      <select className="select floating">
                                        <option value="">Select Status</option>
                                        <option value="">Pending</option>
                                        <option value="1">Paid</option>
                                        <option value="1">
                                          Partially Paid
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-sm-3 col-xs-6">
                                    <a
                                      href
                                      className="btn btn-success btn-block"
                                    >
                                      {" "}
                                      Search{" "}
                                    </a>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="table-responsive">
                                      <table className="table table-striped custom-table m-b-0">
                                        <thead>
                                          <tr>
                                            <th>#</th>
                                            <th>Invoice Number</th>
                                            <th>Client</th>
                                            <th>Created Date</th>
                                            <th>Due Date</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                            <th className="text-right">
                                              Action
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>1</td>
                                            <td>
                                              <a href="invoice-view.html">
                                                #INV-0001
                                              </a>
                                            </td>
                                            <td>Global Technologies</td>
                                            <td>1 Sep 2017</td>
                                            <td>7 Sep 2017</td>
                                            <td>$2099</td>
                                            <td>
                                              <span className="label label-success-border">
                                                Paid
                                              </span>
                                            </td>
                                            <td className="text-right">
                                              <div className="dropdown">
                                                <a
                                                  href
                                                  className="action-icon dropdown-toggle"
                                                  data-toggle="dropdown"
                                                  aria-expanded="false"
                                                >
                                                  <i className="fa fa-ellipsis-v"></i>
                                                </a>
                                                <ul className="dropdown-menu pull-right">
                                                  <li>
                                                    <a href>
                                                      <i className="fa fa-pencil m-r-5"></i>{" "}
                                                      Edit
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a href>
                                                      <i className="fa fa-eye m-r-5"></i>{" "}
                                                      View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a href>
                                                      <i className="fa fa-file-pdf-o m-r-5"></i>{" "}
                                                      Download
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a href>
                                                      <i className="fa fa-trash-o m-r-5"></i>{" "}
                                                      Delete
                                                    </a>
                                                  </li>
                                                </ul>
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>2</td>
                                            <td>
                                              <a href>#INV-0002</a>
                                            </td>
                                            <td>Delta Infotech</td>
                                            <td>1 Sep 2017</td>
                                            <td>7 Sep 2017</td>
                                            <td>$2099</td>
                                            <td>
                                              <span className="label label-info-border">
                                                Sent
                                              </span>
                                            </td>
                                            <td className="text-right">
                                              <div className="dropdown">
                                                <a
                                                  href
                                                  className="action-icon dropdown-toggle"
                                                  data-toggle="dropdown"
                                                  aria-expanded="false"
                                                >
                                                  <i className="fa fa-ellipsis-v"></i>
                                                </a>
                                                <ul className="dropdown-menu pull-right">
                                                  <li>
                                                    <a href>
                                                      <i className="fa fa-pencil m-r-5"></i>{" "}
                                                      Edit
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a href>
                                                      <i className="fa fa-eye m-r-5"></i>{" "}
                                                      View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a href>
                                                      <i className="fa fa-file-pdf-o m-r-5"></i>{" "}
                                                      Download
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a href>
                                                      <i className="fa fa-trash-o m-r-5"></i>{" "}
                                                      Delete
                                                    </a>
                                                  </li>
                                                </ul>
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>3</td>
                                            <td>
                                              <a href>#INV-0003</a>
                                            </td>
                                            <td>Cream Inc</td>
                                            <td>1 Sep 2017</td>
                                            <td>7 Sep 2017</td>
                                            <td>$2099</td>
                                            <td>
                                              <span className="label label-warning-border">
                                                Partially Paid
                                              </span>
                                            </td>
                                            <td className="text-right">
                                              <div className="dropdown">
                                                <a
                                                  href
                                                  className="action-icon dropdown-toggle"
                                                  data-toggle="dropdown"
                                                  aria-expanded="false"
                                                >
                                                  <i className="fa fa-ellipsis-v"></i>
                                                </a>
                                                <ul className="dropdown-menu pull-right">
                                                  <li>
                                                    <a href>
                                                      <i className="fa fa-pencil m-r-5"></i>{" "}
                                                      Edit
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a href>
                                                      <i className="fa fa-eye m-r-5"></i>{" "}
                                                      View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a href>
                                                      <i className="fa fa-file-pdf-o m-r-5"></i>{" "}
                                                      Download
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a href>
                                                      <i className="fa fa-trash-o m-r-5"></i>{" "}
                                                      Delete
                                                    </a>
                                                  </li>
                                                </ul>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Aux>
                ) : (
                  <Aux>
                    <h3>No Record Found!</h3>
                  </Aux>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DriverDetails;
