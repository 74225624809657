import { VehicleAPIResponse } from './vehicles';

// eslint-disable-next-line import/prefer-default-export
export const convertVehicleResponseToPayload = (row:VehicleAPIResponse) => ({
  plateNumber: row.plate_number,
  plateState: row.plate_state,
  vehicleMake: row.vehicle_make,
  vehicleModel: row.vehicle_model,
  year: Number(row.year),
  trackingCode: row.tracking_code,
  vehicleType: row?.vehicle_type,
});
