import React, { Component } from "react";
import BuggyApi from "../../services/BuggyApi";
import "react-select2-wrapper/css/select2.css";
import BlockUi from "../BlockUi";
import "./modal.css";
import Select2 from "react-select2-wrapper";
import { USA_STATES } from "../../services/USA_STATS";
import Datetime from "react-datetime";

class AddDriver extends Component {
  state = {
    isShown: false,
    editMode: false,
    isSuccess: false,
    errorMessage: [],
    blocking: false,
    firstNameValue: "",
    lastNameValue: "",
    emailValue: "",
    driverIdValue: "",
    contactNumberValue: "",
    cityValue: "",
    addressLine1Value: "",
    addressLine2Value: "",
    stateValue: "NY",
    plateNumberValue: "",
    zipCodeValue: "",
    agreementStartDateValue: "",
    agreementEndDateValue: "",
    agreementStartDateFocus: false,
    agreementEndDateFocus: false,
    driverID: 0,
  };

  onModalShow = (driverPreData = null) => {
    document.body.classList.add("modal-open");
    if (driverPreData != null) {
      this.agreementStartDateValue.defaultValue =
        driverPreData.agreement_start_date;
      this.agreementEndDateValue.defaultValue =
        driverPreData.agreement_end_date;
      this.setState({
        loginUrlValue: driverPreData.login_url,
        usernameValue: driverPreData.username,
        passwordValue: driverPreData.password,
        securityCodeValue: driverPreData.security_code,
        firstNameValue: driverPreData.first_name,
        lastNameValue: driverPreData.last_name,
        emailValue: driverPreData.email,
        driverIdValue: driverPreData.driver_id,
        contactNumberValue: driverPreData.contact_number,
        cityValue: driverPreData.city,
        addressLine1Value: driverPreData.address_line_1,
        addressLine2Value: driverPreData.address_line_2,
        stateValue: driverPreData.state,
        plateNumberValue: driverPreData.agreement_vehicle_plate,
        zipCodeValue: driverPreData.zip,
        agreementStartDateValue: driverPreData.agreement_start_date,
        agreementEndDateValue: driverPreData.agreement_end_date,
        driverID: driverPreData.driver_id,
        editMode: true,
      });
    }
    this.setState({
      isShown: true,
    });
  };
  onModalClose = () => {
    document.body.classList.remove("modal-open");
    this.setState({
      isSuccess: false,
      isShown: false,
      errorMessage: [],
      firstNameValue: "",
      lastNameValue: "",
      emailValue: "",
      driverIdValue: "",
      contactNumberValue: "",
      cityValue: "",
      addressLine1Value: "",
      addressLine2Value: "",
      stateValue: "NY",
      plateNumberValue: "",
      zipCodeValue: "",
      agreementStartDateValue: "",
      agreementEndDateValue: "",
      agreementStartDateFocus: false,
      agreementEndDateFocus: false,
      driverID: 0,
      editMode: false,
    });
  };

  onValueChangeHandler = (name, event) => {
    let new_value = {};
    new_value[name] = event.target.value;
    this.setState(new_value);
  };
  onFocusToggleHandler = (name) => {
    let obj_ = {};
    obj_[name] = !this.state[name];
    this.setState(obj_);
  };

  addDriverClickHandler = (event) => {
    event.preventDefault();
    const stateValue = this.refs.stateValue.el.val() || "NY";

    const formData = {
      first_name: this.state.firstNameValue,
      last_name: this.state.lastNameValue,
      driver_id: this.state.driverIdValue,
      contact_number: this.state.contactNumberValue,
      agreement_vehicle_plate: this.state.plateNumberValue,
      address_line_1: this.state.addressLine1Value,
      city: this.state.cityValue,
      address_line_2: this.state.addressLine2Value,
      state: stateValue,
      zip: this.state.zipCodeValue,
      email: this.state.emailValue,
      agreement_start_date: this.agreementStartDateValue.state.inputValue,
      agreement_end_date: this.agreementEndDateValue.state.inputValue,
    };

    this.setState({ blocking: true, errorMessage: [] });
    new BuggyApi()
      .addDriver(formData)
      .then((response) => {
        this.setState({ blocking: false });
        if (response.status === 201) {
          this.setState({
            isSuccess: true,
            errorMessage: [],
            firstNameValue: "",
            lastNameValue: "",
            emailValue: "",
            driverIdValue: "",
            contactNumberValue: "",
            cityValue: "",
            addressLine1Value: "",
            addressLine2Value: "",
            stateValue: "NY",
            plateNumberValue: "",
            zipCodeValue: "",
            agreementStartDateValue: "",
            agreementEndDateValue: "",
            agreementStartDateFocus: false,
            agreementEndDateFocus: false,
            editMode: false,
          });
          this.props.refresh();
        }
      })
      .catch((error) => {
        this.setState({ blocking: false });
        if (error.response) {
          if (error.response.data) {
            if (error.response.data["driver"]) {
              let errors = [];
              const errorList = error.response.data["driver"];
              for (let key in errorList) {
                let em = "";
                switch (key) {
                  case "agreement_start_date":
                    em = "Agreement Start Date: ";
                    break;
                  case "agreement_end_date":
                    em = "Agreement End Date: ";
                    break;
                  case "driver_id":
                    em = "Driver ID: ";
                    break;
                  case "first_name":
                    em = "First Name: ";
                    break;
                  case "last_name":
                    em = "Last Name: ";
                    break;
                  case "address_line_1":
                    em = "Address Line 1: ";
                    break;
                  case "address_line_2":
                    em = "Address Line 2: ";
                    break;
                  case "city":
                    em = "City: ";
                    break;
                  case "state":
                    em = "State: ";
                    break;
                  case "zip":
                    em = "Zip Code: ";
                    break;
                  case "contact_number":
                    em = "Contact Number: ";
                    break;
                  case "email":
                    em = "Email: ";
                    break;
                  case "agreement_vehicle_plate":
                    em = "Vehicle Plate#: ";
                    break;
                  case "agreement_status":
                    em = "Agreement Status: ";
                    break;
                  default:
                    em = "";
                    break;
                }
                em += errorList[key];
                errors.push(em);
              }
              this.setState({
                errorMessage: errors,
              });
            } else {
              const em = "Oops! " + error.response.data;
              this.setState({
                errorMessage: [em],
              });
            }
          } else {
            const em = "Oops! Something went wrong!";
            this.setState({
              errorMessage: [em],
            });
          }
        } else if (error.request) {
          const em = "Oops! Something went wrong!";
          this.setState({
            errorMessage: [em],
          });
        } else {
          /*console.log('Error', error.message);*/
        }
      });
  };

  updateDriverClickHandler = (event) => {
    event.preventDefault();
    const stateValue = this.refs.stateValue.el.val() || "NY";

    const formData = {
      first_name: this.state.firstNameValue,
      last_name: this.state.lastNameValue,
      // driver_id: this.state.driverIdValue,
      contact_number: this.state.contactNumberValue,
      agreement_vehicle_plate: this.state.plateNumberValue,
      address_line_1: this.state.addressLine1Value,
      city: this.state.cityValue,
      address_line_2: this.state.addressLine2Value,
      state: stateValue,
      zip: this.state.zipCodeValue,
      email: this.state.emailValue,
      agreement_start_date: this.agreementStartDateValue.state.inputValue,
      agreement_end_date: this.agreementEndDateValue.state.inputValue,
    };

    this.setState({ blocking: true, errorMessage: [] });
    new BuggyApi()
      .updateDriver(this.state.driverID, formData)
      .then((response) => {
        this.setState({ blocking: false });
        if (response.status > 199 && response.status < 300) {
          this.setState({
            isSuccess: true,
            errorMessage: [],
            firstNameValue: "",
            lastNameValue: "",
            emailValue: "",
            driverIdValue: "",
            contactNumberValue: "",
            cityValue: "",
            addressLine1Value: "",
            addressLine2Value: "",
            stateValue: "NY",
            plateNumberValue: "",
            zipCodeValue: "",
            agreementStartDateValue: "",
            agreementEndDateValue: "",
            agreementStartDateFocus: false,
            agreementEndDateFocus: false,
          });

          this.props.refresh();
        }
      })
      .catch((error) => {
        this.setState({ blocking: false });
        if (error.response) {
          if (error.response.data) {
            if (error.response.data["driver"]) {
              let errors = [];
              const errorList = error.response.data["driver"];
              for (let key in errorList) {
                let em = "";
                switch (key) {
                  case "agreement_start_date":
                    em = "Agreement Start Date: ";
                    break;
                  case "agreement_end_date":
                    em = "Agreement End Date: ";
                    break;
                  case "driver_id":
                    em = "Driver ID: ";
                    break;
                  case "first_name":
                    em = "First Name: ";
                    break;
                  case "last_name":
                    em = "Last Name: ";
                    break;
                  case "address_line_1":
                    em = "Address Line 1: ";
                    break;
                  case "address_line_2":
                    em = "Address Line 2: ";
                    break;
                  case "city":
                    em = "City: ";
                    break;
                  case "state":
                    em = "State: ";
                    break;
                  case "zip":
                    em = "Zip Code: ";
                    break;
                  case "contact_number":
                    em = "Contact Number: ";
                    break;
                  case "email":
                    em = "Email: ";
                    break;
                  case "agreement_vehicle_plate":
                    em = "Vehicle Plate#: ";
                    break;
                  case "agreement_status":
                    em = "Agreement Status: ";
                    break;
                  default:
                    em = "";
                    break;
                }
                em += errorList[key];
                errors.push(em);
              }
              this.setState({
                errorMessage: errors,
              });
            } else {
              const em = "Oops! " + error.response.data;
              this.setState({
                errorMessage: [em],
              });
            }
          } else {
            const em = "Oops! Something went wrong!";
            this.setState({
              errorMessage: [em],
            });
          }
        } else if (error.request) {
          const em = "Oops! Something went wrong!";
          this.setState({
            errorMessage: [em],
          });
        } else {
          /*console.log('Error', error.message);*/
        }
      });
  };

  render() {
    return (
      <div
        className={
          this.state.isShown
            ? "modal custom-modal fade in showModal"
            : "modal custom-modal fade hideModal"
        }
        role="dialog"
      >
        <div className="modal-dialog">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={this.onModalClose}
          >
            &times;
          </button>
          <div className="modal-content modal-lg">
            <br />
            <br />
            <br />
            <div className="modal-header text-center">
              <h4 className="modal-title">
                {this.state.editMode ? "Update" : "Add New"} Driver
              </h4>
            </div>
            <div className="modal-body">
              <form>
                {this.state.isSuccess ? (
                  <div className="row">
                    <div className="col-sm-6 col-sm-offset-3">
                      <div className="alert alert-success">
                        <strong>Success!</strong> Driver{" "}
                        {this.state.editMode ? "updated" : "added"}{" "}
                        successfully!
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.state.errorMessage.length > 0 ? (
                  <div className="row">
                    <div className="col-sm-6 col-sm-offset-3">
                      <div className="alert alert-danger">
                        <ul>
                          {this.state.errorMessage.map((message, i) => (
                            <li key={i}>{message}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ) : null}
                <BlockUi tag="div" blocking={this.state.blocking}>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>First Name</label>
                        <input
                          className="form-control"
                          type="text"
                          required={true}
                          value={this.state.firstNameValue}
                          maxLength={20}
                          onChange={(event) =>
                            this.onValueChangeHandler("firstNameValue", event)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Last Name</label>
                        <input
                          className="form-control"
                          required={true}
                          type="text"
                          value={this.state.lastNameValue}
                          maxLength={20}
                          onChange={(event) =>
                            this.onValueChangeHandler("lastNameValue", event)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Email</label>
                        <input
                          className="form-control"
                          type="text"
                          value={this.state.emailValue}
                          maxLength={400}
                          onChange={(event) =>
                            this.onValueChangeHandler("emailValue", event)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Mobile #</label>
                        <input
                          className="form-control"
                          type="text"
                          value={this.state.contactNumberValue}
                          maxLength={16}
                          onChange={(event) =>
                            this.onValueChangeHandler(
                              "contactNumberValue",
                              event
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Driver ID</label>
                        <input
                          className="form-control"
                          type="text"
                          required={true}
                          disabled={this.state.editMode}
                          value={this.state.driverIdValue}
                          maxLength={255}
                          onChange={(event) =>
                            this.onValueChangeHandler("driverIdValue", event)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Vehicle Plate# </label>
                        <input
                          className="form-control"
                          type="text"
                          required={true}
                          maxLength={15}
                          value={this.state.plateNumberValue}
                          onChange={(event) =>
                            this.onValueChangeHandler("plateNumberValue", event)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Address Line 1 </label>
                        <input
                          className="form-control"
                          type="text"
                          required={true}
                          maxLength={20}
                          value={this.state.addressLine1Value}
                          onChange={(event) =>
                            this.onValueChangeHandler(
                              "addressLine1Value",
                              event
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Address Line 2</label>
                        <input
                          className="form-control"
                          type="text"
                          required={true}
                          maxLength={20}
                          value={this.state.addressLine2Value}
                          onChange={(event) =>
                            this.onValueChangeHandler(
                              "addressLine2Value",
                              event
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label>City</label>
                        <input
                          className="form-control"
                          type="text"
                          required={true}
                          maxLength={15}
                          value={this.state.cityValue}
                          onChange={(event) =>
                            this.onValueChangeHandler("cityValue", event)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label>State</label>
                        <Select2
                          ref="stateValue"
                          className="selector"
                          data={USA_STATES}
                          maxLength={2}
                          value={this.state.stateValue}
                          options={{
                            width: "100%",
                            minimumResultsForSearch: 5,
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label>ZIP Code</label>
                        <input
                          className="form-control"
                          type="text"
                          maxLength={9}
                          value={this.state.zipCodeValue}
                          onChange={(event) =>
                            this.onValueChangeHandler("zipCodeValue", event)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div
                        className={
                          this.state.agreementStartDateFocus
                            ? "form-group form-focus focused"
                            : "form-group form-focus "
                        }
                      >
                        <label className="control-label">
                          Agreement Start Date
                        </label>
                        <div className="cal-icon">
                          <Datetime
                            dateFormat={"MM/DD/YYYY"}
                            timeFormat={"HH:mm:SS"}
                            required={true}
                            onFocus={() =>
                              this.onFocusToggleHandler(
                                "agreementStartDateFocus"
                              )
                            }
                            onBlur={() =>
                              this.onFocusToggleHandler(
                                "agreementStartDateFocus"
                              )
                            }
                            ref={(el) => (this.agreementStartDateValue = el)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div
                        className={
                          this.state.agreementEndDateFocus
                            ? "form-group form-focus focused"
                            : "form-group form-focus "
                        }
                      >
                        <label className="control-label">
                          Agreement End Date
                        </label>
                        <div className="cal-icon">
                          <Datetime
                            required={true}
                            dateFormat={"MM/DD/YYYY"}
                            timeFormat={"HH:mm:SS"}
                            onFocus={() =>
                              this.onFocusToggleHandler("agreementEndDateFocus")
                            }
                            onBlur={() =>
                              this.onFocusToggleHandler("agreementEndDateFocus")
                            }
                            ref={(el) => (this.agreementEndDateValue = el)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="m-t-20 text-center">
                    <button
                      className="btn btn-primary"
                      type="submit"
                      onClick={
                        this.state.editMode
                          ? this.updateDriverClickHandler
                          : this.addDriverClickHandler
                      }
                    >
                      {this.state.editMode ? "Update" : "Add"} Driver
                    </button>
                  </div>
                </BlockUi>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddDriver;
