import { Grid, Paper } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import currency from 'currency.js';
import React, { useMemo, useRef, useState } from 'react';
import { MdAccountBalanceWallet, MdAttachMoney, MdPayments } from 'react-icons/md';

import { getBalance, getWallet } from '@/services/billing';

import {
  InvoiceList, InvoiceListRef, Statistic, WalletModal,
} from './components';
import Usage from './components/Usage';

function UsageDashboard() {
  const invoiceListRef = useRef<InvoiceListRef>(null);

  const onClickUnpaidBalance = () => {
    invoiceListRef.current?.scrollIntoView({ behavior: 'smooth' });
    invoiceListRef.current?.setSelectedStatus('pending');
  };

  const { data: balanceData, isLoading: isBalanceLoading } = useQuery({
    queryFn: () => getBalance(),
    queryKey: ['balance'],
  });

  const balanceDataString = useMemo(() => {
    if (isBalanceLoading) {
      return '...';
    }

    return currency(
      balanceData?.data.balance_cents || 0,
      { fromCents: true },
    ).format({ symbol: '' });
  }, [balanceData?.data.balance_cents, isBalanceLoading]);

  const [open, setOpen] = useState(false);

  const toggleWallet = () => {
    setOpen(!open);
  };

  const { data: walletData, isLoading: isWalletBalanceLoading } = useQuery({
    queryFn: () => getWallet(),
    queryKey: ['wallet'],
  });

  const walletBalanceDataString = useMemo(() => {
    if (isWalletBalanceLoading) {
      return '...';
    }

    if (walletData) {
      return currency(
        walletData?.data.balance_cents || 0,
        { fromCents: true },
      ).format({ symbol: '' });
    }
    return '--';
  }, [walletData, isWalletBalanceLoading]);

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12} md={6} spacing={2} alignContent="flex-start">
        <Grid item xs={6}>
          <Statistic
            label="Unpaid Balance"
            labelIcon={<MdPayments size={18} />}
            data={balanceDataString}
            dataIcon={<MdAttachMoney size={32} />}
            onClick={onClickUnpaidBalance}
            isLoading={isBalanceLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <Statistic
            label="Wallet"
            labelIcon={<MdAccountBalanceWallet size={18} />}
            data={walletBalanceDataString}
            dataIcon={<MdAttachMoney size={32} />}
            onClick={toggleWallet}
            isLoading={isWalletBalanceLoading}
          />
          <WalletModal
            open={open}
            onClose={toggleWallet}
            walletId={walletData ? walletData.data.lago_id : ''}
            balance={walletData ? walletData.data.balance_cents : 0}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <InvoiceList ref={invoiceListRef} />
          </Paper>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper elevation={0}>
          <Usage />
        </Paper>
      </Grid>
    </Grid>
  );
}

export default UsageDashboard;
