import React, { Component } from "react";
import BuggyApi from "../../services/BuggyApi";
import "react-select2-wrapper/css/select2.css";
import BlockUi from "../BlockUi";

import "./modal.css";

class VehicleBulkUpload extends Component {
  state = {
    isShown: false,
    isSuccess: false,
    errorMessage: [],
    blocking: false,
    lesseeShow: false,
  };

  onModalShow = (lessee = false) => {
    if (lessee) {
      this.setState({
        lesseeShow: lessee,
      });
    }
    document.body.classList.add("modal-open");
    this.setState({
      isShown: true,
    });
  };
  onModalClose = () => {
    document.body.classList.remove("modal-open");
    this.setState({
      isShown: false,
      isSuccess: false,
      errorMessage: [],
      blocking: false,
    });
  };

  uploadBtnClickHandler = (event) => {
    event.preventDefault();
    /*const formData = {
            csv_upload: this.state.csv_upload
        };*/
    let formData = new FormData();
    formData.set("csv_upload", this.csv_upload.files[0]);
    this.setState({ blocking: true, errorMessage: [] });
    new BuggyApi()
      .uploadVehiclesCSV(formData)
      .then((response) => {
        this.setState({ blocking: false });
        if (response.status === 201) {
          this.setState({
            isSuccess: true,
            errorMessage: [],
          });
          this.props.refresh();
        } else if (response.status === 200) {
          if (response.data.csv_upload) {
            let errors = [];
            const errorList = response.data;
            for (let key in errorList) {
              let em = "";
              switch (key) {
                case "csv_upload":
                  em = "Upload CSV: ";

                  break;
                case "error":
                  em = "Upload CSV: ";
                  break;
                default:
                  em = "";
                  break;
              }
              if (typeof errorList[key] === "string") {
                em += errorList[key];
                errors.push(em);
              }
              if (typeof errorList[key] === "object") {
                for (let error in errorList[key]) {
                  em = errorList[key][error];
                  errors.push(em);
                }
              }
            }
            this.setState({
              errorMessage: errors,
            });
          }
        }
      })
      .catch((error) => {
        this.setState({ blocking: false });
        if (error.response) {
          if (error.response.data !== null) {
            let errors = [];
            const errorList = error.response.data.csv_upload;
            for (let key in errorList) {
              let em = "";
              switch (key) {
                case "csv_upload":
                  em = "Upload CSV: ";
                  break;
                case "error":
                  em = "Upload CSV: ";
                  break;
                default:
                  em = "";
                  break;
              }
              em += errorList[key];
              errors.push(em);
            }
            this.setState({
              errorMessage: errors,
            });
          } else {
            const em = "You don't have permissions";
            this.setState({
              errorMessage: [em],
            });
          }
        } else if (error.request) {
          const em = "Oops! Something went wrong!";
          this.setState({
            errorMessage: [em],
          });
        } else {
          /*console.log('Error', error.message);*/
        }
      });
  };

  render() {
    return (
      <div
        className={
          this.state.isShown
            ? "modal custom-modal fade in showModal"
            : "modal custom-modal fade hideModal"
        }
        role="dialog"
      >
        <div className="modal-dialog">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={this.onModalClose}
          >
            &times;
          </button>
          <div className="modal-content modal-lg">
            <br />
            <br />
            <br />
            <div className="modal-header text-center">
              <h4 className="modal-title">Upload CSV</h4>
            </div>
            <div className="modal-body">
              <form method="post" encType="multipart/form-data">
                {this.state.isSuccess ? (
                  <div className="row">
                    <div className="col-sm-6 col-sm-offset-3">
                      <div className="alert alert-success">
                        <strong>Success!</strong> Vehicles added successfully!
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.state.errorMessage.length > 0 ? (
                  <div className="row">
                    <div className="col-sm-6 col-sm-offset-3">
                      <div className="alert alert-danger">
                        <ul>
                          {this.state.errorMessage.map((message, i) => (
                            <li key={i}>{message}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ) : null}
                <BlockUi tag="div" blocking={this.state.blocking}>
                  <div className="row">
                    <div className="col-sm-6 col-sm-offset-3">
                      <div className="form-group">
                        <label>Upload CSV File</label>
                        <input
                          className="form-control"
                          type="file"
                          ref={(el) => (this.csv_upload = el)}
                          accept=".csv"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="m-t-20 text-center">
                    <button
                      className="btn btn-primary"
                      type="submit"
                      onClick={this.uploadBtnClickHandler}
                    >
                      Upload
                    </button>
                  </div>
                </BlockUi>
              </form>
              <div className="col-md-12">
                <br />
                <h4>Instructions : </h4>
                <p>
                  plate_number: License plate number of the vehicle
                  (alphanumeric; no special characters)
                </p>
                <p>
                  state: License plate state of the vehicle (two digit state
                  code)
                </p>
                <p>
                  year (optional): Model year of the vehicle (four digit year
                  code)
                </p>
                <p>
                  vehicle_make (optional): Make of the vehicle (No character
                  restrictions)
                </p>
                <p>
                  vehicle_model (optional): Model of vehicle (No character
                  restrictions)
                </p>
                <p>
                  vehicle type: For passenger vehicles, enter 72_72, for trucks
                  enter 520_520
                </p>
                <p>
                  vehicle_number (optional): Vehicle identifier of your choosing
                  (ie VIN, Unit Number; no character restrictions)
                </p>
                {this.state.lesseeShow ? (
                  <p>
                    renter_lessee (optional): Renter or lessee associated to
                    vehicle (No character restrictions)
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VehicleBulkUpload;
