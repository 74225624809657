import { lighten, styled } from '@mui/material/styles';
import { DataGridProps, GridValidRowModel } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import React from 'react';

interface DataGridComponent {
  <R extends GridValidRowModel = any>(
    props: DataGridProps<R> & React.RefAttributes<HTMLDivElement>
  ): JSX.Element;
  propTypes?: any;
}

export default styled(DataGridPro)(({ theme }) => ({
  '& .highlighted': {
    backgroundColor: lighten(theme.palette.primary.light, 0.5),
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.light, 0.3),
    },
  },
})) as DataGridComponent;
