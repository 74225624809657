import {
  Box,
  Button,
  ButtonProps,
  lighten,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { MdArrowOutward } from 'react-icons/md';

type Props = {
  label: string,
  labelIcon?: React.ReactNode,
  data: string,
  dataIcon?: React.ReactNode,
  isLoading?: Boolean,
} & ButtonProps;

export function Statistic(
  {
    label,
    labelIcon = undefined,
    data,
    dataIcon = undefined,
    isLoading = false,
    ...buttonProps
  }: Props,
) {
  return (
    <Button
      sx={({ palette, spacing }) => ({
        backgroundColor: lighten(palette.secondary.light, 0.4),
        padding: spacing(1),
        color: palette.secondary.dark,
      })}
      color="secondary"
      fullWidth
      {...buttonProps}
    >
      <Stack spacing={4} width="100%">
        <Stack direction="row" alignItems="center" spacing={1}>
          {labelIcon}
          <Typography variant="caption" fontWeight="bold">{label}</Typography>
          <Box flexGrow={1} />
          <MdArrowOutward size={18} />
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          {dataIcon}
          {isLoading
            ? (
              <Skeleton
                variant="text"
                sx={{ fontSize: '1.2rem' }}
                width="50%"
              />
            )
            : (
              <Typography variant="h5" fontFamily="monospace">
                {data}
              </Typography>
            )}
        </Stack>
      </Stack>
    </Button>
  );
}

export default Statistic;
