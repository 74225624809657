import { createContext, useContext } from 'react';

import { VehicleAPIResponse } from '@/services/vehicles';
import { EditAction } from '@/types/EditAction';

export type VehiclesTableContextType = {
  refetch: () => void;
  search?: string;
  showLessee?: boolean;
  editedRows?: VehicleAPIResponse[];
  dispatchRowEdit: React.Dispatch<EditAction<VehicleAPIResponse>>;
};

export const vehiclesTableContext = createContext<VehiclesTableContextType>({
  refetch: () => undefined,
  dispatchRowEdit: () => undefined,
});

export const useVehiclesTableContext = () => useContext(vehiclesTableContext);

export default vehiclesTableContext;
