import axios from 'axios';
import { isEmpty } from 'lodash';
import queryString from 'query-string';

import fleetAxios from './axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const BASE_URL_ROOT = process.env.REACT_APP_BASE_URL_ROOT;

class BuggyApi {
  getUserInfo = () => fleetAxios.get('/whoami/');

  getLogs = (
    offset = 0,
    date = '',
    start = '',
    end = '',
    response_code = '',
    endPoint = '',
    download = false,
  ) => {
    let filter = `?offset=${offset}`;
    if (date !== '') {
      filter += `&date=${date}`;
    }
    if (start && end) {
      filter += `&start=${start}&end=${end}`;
    }
    if (response_code) {
      filter += `&response_code=${response_code}`;
    }
    if (endPoint) {
      filter += `&remote_address=${endPoint}`;
    }
    if (download) {
      filter += '&download=csv';
    }

    return fleetAxios.get(`/logs/${filter}`);
  };

  getVehicles = (offset = 0, searchString = '', download = false) => {
    let filter = `?offset=${offset}`;
    if (searchString !== '') {
      filter += `&q=${searchString}`;
    }
    if (download) {
      filter += '&download=csv';
    }

    return fleetAxios.get(`/vehicle/${filter}`);
  };

  addVehicle = (formData = null) => fleetAxios.post('/vehicle/', formData);

  updateVehicle = (id = 0, formData = null) => fleetAxios.put(`/vehicle/${id}/`, formData);

  deleteVehicle = (id = 0) => fleetAxios.delete(`/vehicle/${id}/`);

  deleteMultipleVehicles = () => true;

  searchTickets = (
    offset = 0,
    plate_number = '',
    lessee_renter = '',
    date_from = '',
    date_to = '',
    exit_plaza = '',
    limit = 20,
  ) => {
    let filter = `?offset=${offset}&limit=${limit}`;
    if (plate_number !== '') {
      filter += `&plate_number=${plate_number?.toUpperCase()}`;
    }
    if (lessee_renter !== '') {
      filter += `&lessee_renter=${lessee_renter}`;
    }
    if (date_from !== '') {
      filter += `&date_from=${date_from}`;
    }
    if (date_to !== '') {
      filter += `&date_to=${date_to}`;
    }
    if (exit_plaza !== '') {
      filter += `&exitplaza=${exit_plaza}`;
    }

    return fleetAxios.get(`/ticket/${filter}`);
  };

  searchParkingTickets = (
    offset = 0,
    plate_number = '',
    date_from = '',
    date_to = '',
    limit = 20,
  ) => {
    let filter = `?offset=${offset}&limit=${limit}`;
    if (plate_number !== '') {
      filter += `&plate_number=${plate_number}`;
    }
    if (date_from !== '') {
      filter += `&date_from=${date_from}`;
    }
    if (date_to !== '') {
      filter += `&date_to=${date_to}`;
    }

    return fleetAxios.get(`/camera_traffic_ticket/${filter}`);
  };

  searchTicketsForDriverDetails = (
    driver_id,
    offset = 0,
    plate_number = '',
    date_from = '',
    date_to = '',
    exit_plaza = '',
  ) => {
    let filter = `?driver_id=${driver_id}&offset=${offset}`;

    if (plate_number !== '') {
      filter += `&plate_number=${plate_number}`;
    }
    if (date_from !== '') {
      filter += `&date_from=${date_from}`;
    }
    if (date_to !== '') {
      filter += `&date_to=${date_to}`;
    }
    if (exit_plaza !== '') {
      filter += `&exitplaza=${exit_plaza}`;
    }

    return fleetAxios.get(`/ticket/${filter}`);
  };

  getExitPlazas = () => fleetAxios.get('/exitplaza/?limit=0');

  getTicketsDownloadLink = (
    offset = 0,
    plate_number = '',
    lessee_renter = '',
    date_from = '',
    date_to = '',
    exit_plaza = '',
    // "download" means it'll be sent to the user's email
    // "link" means a download link will be generated
    channel = 'download',
    email = undefined,
    columns = undefined,
  ) => {
    const query = queryString.stringify({
      offset,
      plate_number: !isEmpty(plate_number) ? plate_number : undefined,
      lessee_renter: !isEmpty(lessee_renter) ? lessee_renter : undefined,
      date_from: !isEmpty(date_from) ? date_from : undefined,
      date_to: !isEmpty(date_to) ? date_to : undefined,
      exit_plaza: !isEmpty(exit_plaza) ? exit_plaza : undefined,
      [channel]: 'csv',
      email,
      columns: columns ? columns.join(',') : undefined,
    });

    return fleetAxios.get(`/ticket?${query}`);
  };

  getParkingTicketsDownloadLink = (
    offset = 0,
    plate_number = '',
    date_from = '',
    date_to = '',
  ) => {
    let filter = `?offset=${offset}`;
    if (plate_number !== '') {
      filter += `&plate_number=${plate_number}`;
    }
    if (date_from !== '') {
      filter += `&date_from=${date_from}`;
    }
    if (date_to !== '') {
      filter += `&date_to=${date_to}`;
    }

    return fleetAxios.get(`/camera_traffic_ticket/${filter}&download=csv`);
  };

  getViolationDownloadLink = (
    offset = 0,
    plate = '',
    issue_date_from = '',
    issue_date_to = '',
    respond_by_date_from = '',
    respond_by_date_to = '',
    report_date_from = '',
    report_date_to = '',
    violation_time_from = '',
    violation_time_to = '',
    bot_login = '',
    ticket_status = '',
  ) => {
    let filter = `?offset=${offset.toString()}`;
    if (plate !== '') {
      filter += `&plate=${plate}`;
    }
    if (issue_date_from !== '') {
      filter += `&issue_date_from=${issue_date_from}`;
    }
    if (issue_date_to !== '') {
      filter += `&issue_date_to=${issue_date_to}`;
    }
    if (respond_by_date_from !== '') {
      filter += `&respond_by_date_from=${respond_by_date_from}`;
    }
    if (respond_by_date_to !== '') {
      filter += `&respond_by_date_to=${respond_by_date_to}`;
    }
    if (report_date_from !== '') {
      filter += `&report_date_from=${report_date_from}`;
    }
    if (report_date_to !== '') {
      filter += `&report_date_to=${report_date_to}`;
    }
    if (violation_time_from !== '') {
      filter += `&violation_time_from=${violation_time_from}`;
    }
    if (violation_time_to !== '') {
      filter += `&violation_time_to=${violation_time_to}`;
    }
    if (bot_login !== '') {
      filter += `&bot_login=${bot_login}`;
    }
    if (ticket_status !== '') {
      filter += `&ticket_status=${ticket_status}`;
    }

    return fleetAxios.get(`/violation/${filter}&download=csv`);
  };

  createFleet = (formData) => fleetAxios({
    url: '/fleet/signup/',
    baseURL: BASE_URL_ROOT,
    method: 'post',
    data: formData,
  });

  forgotPasswordRequest = (formData) => fleetAxios({
    url: '/forgotpasswordrequest/',
    method: 'post',
    data: formData,
  });

  setNewPassword = (formData) => fleetAxios({
    url: `/forgotpasswordrequest/${formData.token}/`,
    method: 'put',
    data: formData,
  });

  verifyResetPasswordToken = (token) => fleetAxios.get(`/forgotpasswordrequest/${token}/`);

  getStats = (compare = '', from = '', to = '') => {
    let filter = '';
    if (compare !== '') {
      filter += `?compare=${compare}`;
    }
    if (from !== '') {
      filter += `&custom_range_from=${from}`;
    }
    if (to !== '') {
      filter += `&custom_range_to=${to}`;
    }
    return fleetAxios.get(`/stats/${filter}`);
  };

  uploadVehiclesCSV = (formData) => fleetAxios({
    url: '/vehicles/import/',
    method: 'post',
    data: formData,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
    responseType: 'json',
  });

  updateSettings = (formData) => fleetAxios({
    url: '/v2/fleet/settings/',
    method: 'put',
    data: formData,
    baseURL: BASE_URL_ROOT,
  });

  getSettings = () => fleetAxios.get('/v2/fleet/settings/', {
    baseURL: BASE_URL_ROOT,
  });

  getFleets = () => fleetAxios({
    url: '/fleet/userfleets/?fleet__is_active=True',
    method: 'get',
    baseURL: BASE_URL_ROOT,
    responseType: 'json',
  });

  updateDefaultFleet = (id = 0) => fleetAxios({
    url: `/fleet/userfleets/${id}/`,
    method: 'put',
    data: { is_default: true },
    baseURL: BASE_URL_ROOT,
    responseType: 'json',
  });

  getDefaultFleet = () => fleetAxios({
    url: '/fleet/userfleets/?is_default=True',
    method: 'get',
    baseURL: BASE_URL_ROOT,
    responseType: 'json',
  });

  getPlaidPublicKey = () => fleetAxios.get('/stripetoken/');

  updateUserInfo = (id, formData) => fleetAxios.put(`/whoami/${id}/`, formData);

  updateEmailInfo = (id, formData) => fleetAxios.put(`/email/${id}/`, formData);

  getBillingInfo = () => fleetAxios.get('/billingsummary/');

  getPayment = (formData) => fleetAxios.post('/stripetoken/', formData);

  getOrders = (offset = 0) => fleetAxios.get(`/orders/?${queryString.stringify({ offset })}`);

  getCardPayment = (formData) => fleetAxios.post('/getcardpayment/', formData);

  getPusherCredentials = () => fleetAxios.get('/pushercredentials/');

  getBotLogin = (offset = 0, searchString) => {
    const query = queryString.stringify({
      offset,
      q: searchString,
    });

    return fleetAxios.get(`/liability-bot/?${query}`);
  };

  addBotLogin = (formData = null) => fleetAxios.post('/liability-bot/', formData);

  updateBotLogin = (id = 0, formData = null) => fleetAxios.put(`/liability-bot/${id}/`, formData);

  deleteBotLogin = (id = 0) => axios.delete(`/liability-bot/${id}/`);

  searchViolations = (
    offset = 0,
    plate = '',
    issue_date_from = '',
    issue_date_to = '',
    respond_by_date_from = '',
    respond_by_date_to = '',
    report_date_from = '',
    report_date_to = '',
    violation_time_from = '',
    violation_time_to = '',
    bot_login = '',
    ticket_status = '',
  ) => {
    let filter = `?offset=${offset.toString()}`;
    if (plate !== '') {
      filter += `&plate=${plate}`;
    }
    if (issue_date_from !== '') {
      filter += `&issue_date_from=${issue_date_from}`;
    }
    if (issue_date_to !== '') {
      filter += `&issue_date_to=${issue_date_to}`;
    }
    if (respond_by_date_from !== '') {
      filter += `&respond_by_date_from=${respond_by_date_from}`;
    }
    if (respond_by_date_to !== '') {
      filter += `&respond_by_date_to=${respond_by_date_to}`;
    }
    if (report_date_from !== '') {
      filter += `&report_date_from=${report_date_from}`;
    }
    if (report_date_to !== '') {
      filter += `&report_date_to=${report_date_to}`;
    }
    if (violation_time_from !== '') {
      filter += `&violation_time_from=${violation_time_from}`;
    }
    if (violation_time_to !== '') {
      filter += `&violation_time_to=${violation_time_to}`;
    }
    if (bot_login !== '') {
      filter += `&bot_login=${bot_login}`;
    }
    if (ticket_status !== '') {
      filter += `&ticket_status=${ticket_status}`;
    }

    return fleetAxios.get(`/violation/${filter}`);
  };

  searchViolationsForDriverDetails = (
    driver_id,
    offset = 0,
    plate = '',
    issue_date_from = '',
    issue_date_to = '',
    respond_by_date_from = '',
    respond_by_date_to = '',
    report_date_from = '',
    report_date_to = '',
    violation_time_from = '',
    violation_time_to = '',
    bot_login = '',
    ticket_status = '',
  ) => {
    let filter = `?driver_id=${driver_id}&offset=${offset.toString()}`;
    if (plate !== '') {
      filter += `&plate=${plate}`;
    }
    if (issue_date_from !== '') {
      filter += `&issue_date_from=${issue_date_from}`;
    }
    if (issue_date_to !== '') {
      filter += `&issue_date_to=${issue_date_to}`;
    }
    if (respond_by_date_from !== '') {
      filter += `&respond_by_date_from=${respond_by_date_from}`;
    }
    if (respond_by_date_to !== '') {
      filter += `&respond_by_date_to=${respond_by_date_to}`;
    }
    if (report_date_from !== '') {
      filter += `&report_date_from=${report_date_from}`;
    }
    if (report_date_to !== '') {
      filter += `&report_date_to=${report_date_to}`;
    }
    if (violation_time_from !== '') {
      filter += `&violation_time_from=${violation_time_from}`;
    }
    if (violation_time_to !== '') {
      filter += `&violation_time_to=${violation_time_to}`;
    }
    if (bot_login !== '') {
      filter += `&bot_login=${bot_login}`;
    }
    if (ticket_status !== '') {
      filter += `&ticket_status=${ticket_status}`;
    }

    return fleetAxios.get(`/violation/${filter}`);
  };

  searchInvoicesForDriverDetails = (
    driver_id,
    offset = 0,
    invoice_no = '',
    date_created = '',
    due_date = '',
  ) => {
    let filter = `?driver_id=${driver_id}&offset=${offset.toString()}`;
    if (invoice_no !== '') {
      filter += `&invoice_no=${invoice_no}`;
    }
    if (date_created !== '') {
      filter += `&date_created=${date_created}`;
    }
    if (due_date !== '') {
      filter += `&due_date=${due_date}`;
    }

    return fleetAxios.get(`/driverinvoice/${filter}`);
  };

  searchDriver = (
    offset = 0,
    search_with = '',
    agreement_start_date = '',
    agreement_end_date = '',
    agreement_status = '',
  ) => {
    let filter = `?offset=${offset}`;
    if (search_with !== '') {
      filter += `&search_with=${search_with}`;
    }
    if (agreement_start_date !== '') {
      filter += `&agreement_start_date=${agreement_start_date}`;
    }
    if (agreement_end_date !== '') {
      filter += `&agreement_end_date=${agreement_end_date}`;
    }
    if (agreement_status !== '') {
      filter += `&agreement_status=${agreement_status}`;
    }

    return fleetAxios.get(`/driver/${filter}`);
  };

  uploadDriverCSV = (formdata) => fleetAxios.post('/driverbulkupload/', formdata, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  addDriver = (formData = null) => fleetAxios.post('/driver/', formData);

  requestDriver = (plate) => fleetAxios.get(`/request/driver/${plate}/`);

  getIntegrations = () => fleetAxios({
    url: '/integrations/api/v1/company_integration/',
    method: 'get',
    baseURL: BASE_URL_ROOT,
    responseType: 'json',
  });

  getIntegration = (id) => fleetAxios({
    url: `/integrations/api/v1/company_integration/${id}/`,
    method: 'get',
    baseURL: BASE_URL_ROOT,
    responseType: 'json',
  });

  getCustomerActiveIntegrations = () => fleetAxios({
    url: 'integrations/api/v1/integration/',
    method: 'get',
    baseURL: BASE_URL_ROOT,
    responseType: 'json',
  });

  addIntegrationSettings = (integrationFormData) => fleetAxios({
    url: '/integrations/api/v1/integration/',
    method: 'post',
    baseURL: BASE_URL_ROOT,
    responseType: 'json',
    data: integrationFormData,
  });

  updateIntegrationSettings = (id, integrationFormData) => fleetAxios({
    url: `/integrations/api/v1/integration/${id}/`,
    method: 'put',
    baseURL: BASE_URL_ROOT,
    responseType: 'json',
    data: integrationFormData,
  });

  deleteIntegrationSettings = (id) => fleetAxios.delete(`/integrations/api/v1/integration/${id}/`, {
    baseURL: BASE_URL_ROOT,
  });

  getIntegrationLogs = (
    fleetId,
    integrationId,
    search = {},
    page = 1,
    pageSize = 20,
  ) => {
    const limit = pageSize;
    const offset = (page - 1) * pageSize;

    const query = queryString.stringify({
      fleet: fleetId,
      integration: integrationId,
      limit,
      offset,
      action__icontains: !isEmpty(search?.ticket) ? search?.ticket : undefined,
      timestamp__range:
        search?.from && search?.to
          ? `${search.from.toISOString()},${search.to.toISOString()}`
          : undefined,
    });

    return fleetAxios.get(`integrations/api/v1/customer_log/?${query}`, {
      baseURL: BASE_URL_ROOT,
    });
  };

  /**
   * @param {Object} params
   * @param {number | undefined} params.id
   * @returns
   */
  runLiabilityBot = ({ id } = {}) => {
    const query = queryString.stringify({ bot_id: id });
    return fleetAxios.get(`/fill_tickets/?${query}`, { baseURL: BASE_URL_ROOT });
  };

  runAssignDrivers = () => fleetAxios.get('/assign_dirvers/');

  updateDriver = (driverId = 0, formData = null) => fleetAxios.put(`/driver/${driverId}/`, formData);

  getDriverDetails = (driverId) => fleetAxios.get(`/driver/${driverId}/`);

  associateDriver = (data) => fleetAxios.post('/driverassociate/', data);

  dissociateDriver = (ticketId, data) => fleetAxios.put(`/driverassociate/${ticketId}/`, data);

  getAllBillingStatuses = () => fleetAxios.get('/billing_statuses/');

  updateBillingStatus = (ticketId, data) => fleetAxios.put(`/ticket/${ticketId}/`, data);
}

export const api = new BuggyApi();

export default BuggyApi;
