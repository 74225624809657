import { AxiosError } from 'axios';
import { cloneDeep, isEmpty, set } from 'lodash';

import fleetAxios from './axios';

export function getUserSettings<T = unknown, O = unknown>(settingsName: string) {
  return fleetAxios.get<{ settings: T; defaults: T, options: O }>(`/user/settings/${settingsName}/`);
}

export function createSettings(name: string, settings: Record<string, unknown>) {
  return fleetAxios.post('/user/settings/', {
    name,
    settings,
  });
}

export function updateSettings(name: string, settings: Record<string, unknown>) {
  return fleetAxios.patch(`/user/settings/${name}/`, {
    settings,
  });
}

export async function setUserSettings(
  name: string,
  baseSettings: Record<string, unknown> | undefined,
  path: string,
  newSettings: unknown,
) {
  const settings = set(cloneDeep(baseSettings) || {}, path, newSettings);
  try {
    if (isEmpty(baseSettings)) {
      await createSettings(name, settings);
    }

    await updateSettings(name, settings);
  } catch (e) {
    if (!(e instanceof AxiosError)) {
      throw e;
    }

    // Incase the above code chose the wrong method
    if (e.status === 409) {
      updateSettings(name, settings);
    }

    if (e.status === 404) {
      createSettings(name, settings);
    }

    throw e;
  }
}
