import { GridToolbarContainer } from '@mui/x-data-grid-pro';
import React from 'react';

import CreateReturnShipmentButton from './createReturnShipment/CreateReturnShipmentButton';

export default function ShipmentsTableToolbar() {
  return (
    <GridToolbarContainer>
      <CreateReturnShipmentButton />
    </GridToolbarContainer>
  );
}
