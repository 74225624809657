import {
  ListItem, ListItemButton, ListItemIcon, ListItemText,
} from '@mui/material';
import React, { useState } from 'react';

import { FIELD_ICON_MAP } from './constants';
import CustomFieldFormDialog from './CustomFieldFormDialog';
import { CustomFieldListItemProps } from './types';

function CustomFieldListIem({ field }: CustomFieldListItemProps) {
  const [isFormOpen, setIsFormOpen] = useState(false);

  const { name, field_type: fieldType } = field;

  const onClickListItem = () => {
    setIsFormOpen(true);
  };

  const onCloseFormDialog = () => {
    setIsFormOpen(false);
  };

  return (
    <>
      <ListItem disableGutters>
        <ListItemButton onClick={onClickListItem}>
          <ListItemIcon>
            {FIELD_ICON_MAP[fieldType]}
          </ListItemIcon>
          <ListItemText>
            {name}
          </ListItemText>
        </ListItemButton>
      </ListItem>
      <CustomFieldFormDialog
        open={isFormOpen}
        onClose={onCloseFormDialog}
        maxWidth="sm"
        fullWidth
        initialField={field}
      />
    </>
  );
}

export default CustomFieldListIem;
