import React, { Component } from "react";
import Aux from "../hoc/Auxiliary";

import BuggyAuth from "../../services/BuggyAuth";
import BuggyApi from "../../services/BuggyApi";
import BlockUi from "../BlockUi";
import $ from "jquery";
import SidebarOverlay from "../Sidebar/SidebarOverlay/SidebarOverlay";
import PasswordChecklist from "react-password-checklist";

import { Link } from "react-router-dom";

class SetNewPassword extends Component {
  state = {
    passwordValue: "",
    confirmPasswordValue: "",
    blocking: false,
    errorMessage: [],
    isSuccess: false,
    isTokenValid: false,
    fleet_id: null,
    user_id: null,
    isValid: false,
    token: "",
  };
  passwordOnChangeHandler = (event) => {
    this.setState({
      passwordValue: event.target.value,
    });
  };
  isValidOnChangeHandler = (event) => {
    this.setState({
      isValid: event,
    });
  };
  confirmPasswordOnChangeHandler = (event) => {
    this.setState({
      confirmPasswordValue: event.target.value,
    });
  };
  changePasswordOnClickHandler = (event) => {
    event.preventDefault();
    this.setState({ blocking: true });
    if (this.state.passwordValue === this.state.confirmPasswordValue) {
      const formData = {
        new_password: this.state.passwordValue,
        fleet_id: this.state.fleet_id,
        user_id: this.state.user_id,
        token: this.state.token,
      };
      new BuggyApi()
        .setNewPassword(formData)
        .then((response) => {
          this.setState({
            isSuccess: true,
            errorMessage: [],
            passwordValue: "",
            confirmPasswordValue: "",
            blocking: false,
          });
        })
        .catch((error) => {
          this.setState({ blocking: false });
          /*if (error.response) {
                    if(error.response.data.fleet){
                        let errors =[];
                        const errorList=error.response.data.fleet;
                        for (let key in errorList) {
                            let em="";
                            switch(key){
                                case 'email':
                                    em = "Email: ";
                                    break;
                                default:
                                    em = "";
                                    break;
                            }
                            em +=errorList[key];
                            errors.push(em);
                        }
                        this.setState({
                            errorMessage: errors
                        });
                    }else{
                        /!*console.log(error.response.data.error_message);*!/
                        const em = "Oops! "+error.response.data.error_message;
                        this.setState({
                            errorMessage: [em]
                        });
                    }
                } else if (error.request) {
                    const em = "Oops! Something went wrong!";
                    this.setState({
                        errorMessage: [em]
                    });
                } else {
                    /!*console.log('Error', error.message);*!/
                }*/
        });
    } else {
      this.setState({
        blocking: false,
        errorMessage: ["password doesn't match"],
      });
    }
  };
  componentWillMount() {
    const token = this.getParameterByName("token");
    if (token !== null) {
      new BuggyApi()
        .verifyResetPasswordToken(token)
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              isTokenValid: true,
              fleet_id: response.data.fleet_id,
              user_id: response.data.user_id,
              token: token,
            });
          }
        })
        .catch((error) => {
          this.setState({
            isTokenValid: false,
            errorMessage: ["This link has expired"],
          });
        });
    } else {
      this.props.history.push("/");
    }
  }
  componentDidMount() {
    window.$ = window.jQuery = $;
    (function ($) {
      var Sidemenu = function () {
        this.$menuItem = $("#sidebar-menu a");
      };

      Sidemenu.prototype.init = function () {
        var $this = this;
        $this.$menuItem.on("click", function (e) {
          if ($(this).parent().hasClass("submenu")) {
            e.preventDefault();
          }
          if (!$(this).hasClass("subdrop")) {
            $("ul", $(this).parents("ul:first")).slideUp(350);
            $("a", $(this).parents("ul:first")).removeClass("subdrop");
            $(this).next("ul").slideDown(350);
            $(this).addClass("subdrop");
          } else if ($(this).hasClass("subdrop")) {
            $(this).removeClass("subdrop");
            $(this).next("ul").slideUp(350);
          }
        });
        $("#sidebar-menu ul li.submenu a.active")
          .parents("li:last")
          .children("a:first")
          .addClass("active")
          .trigger("click");
      };
      $.Sidemenu = new Sidemenu();
    })(window.jQuery);
    $(document).ready(function () {
      // Sidebar overlay

      var $sidebarOverlay = $(".sidebar-overlay");
      $("#mobile_btn, .task-chat").on("click", function (e) {
        var $target = $($(this).attr("href"));
        if ($target.length) {
          $target.toggleClass("opened");
          $sidebarOverlay.toggleClass("opened");
          $("html").toggleClass("menu-opened");
          $sidebarOverlay.attr("data-reff", $(this).attr("href"));
        }
        e.preventDefault();
      });

      $sidebarOverlay.on("click", function (e) {
        var $target = $($(this).attr("data-reff"));
        if ($target.length) {
          $target.removeClass("opened");
          $("html").removeClass("menu-opened");
          $(this).removeClass("opened");
          $(".main-wrapper").removeClass("slide-nav-toggle");
        }
        e.preventDefault();
      });

      // Sidebar Initiate

      $.Sidemenu.init();

      // Select2

      if ($(".select").length > 0) {
        $(".select").select2({
          minimumResultsForSearch: -1,
          width: "100%",
        });
      }

      // Modal Popup hide show

      if ($(".modal").length > 0) {
        var modalUniqueClass = ".modal";
        $(".modal").on("show.bs.modal", function (e) {
          var $element = $(this);
          var $uniques = $(modalUniqueClass + ":visible").not($(this));
          if ($uniques.length) {
            $uniques.modal("hide");
            $uniques.one("hidden.bs.modal", function (e) {
              $element.modal("show");
            });
            return false;
          }
        });
      }

      // Floating Label

      if ($(".floating").length > 0) {
        $(".floating")
          .on("focus blur", function (e) {
            $(this)
              .parents(".form-focus")
              .toggleClass(
                "focused",
                e.type === "focus" || this.value.length > 0
              );
          })
          .trigger("blur");
      }

      // Message Notification Slimscroll

      // Sidebar Slimscroll

      // Page Content Height

      if ($(".page-wrapper").length > 0) {
        var height = $(window).height();
        $(".page-wrapper").css("min-height", height);
      }

      $(window).resize(function () {
        if ($(".page-wrapper").length > 0) {
          var height = $(window).height();
          $(".page-wrapper").css("min-height", height);
        }
      });

      // Date Time Picker

      // Datatable

      // Tooltip

      // Button Switch

      if ($(".btn-toggle").length > 0) {
        $(".btn-toggle").click(function () {
          $(this).find(".btn").toggleClass("active");
          if ($(this).find(".btn-success").size() > 0) {
            $(this).find(".btn").toggleClass("btn-success");
          }
        });
      }

      // Mobile Menu

      if ($(".main-wrapper").length > 0) {
        var $wrapper = $(".main-wrapper");
        $(document).on("click", "#mobile_btn", function (e) {
          $(".dropdown.open > .dropdown-toggle").dropdown("toggle");
          return false;
        });
        $(document).on("click", "#mobile_btn", function (e) {
          $wrapper.toggleClass("slide-nav-toggle");
          $("#chat_sidebar").removeClass("opened");
          return false;
        });
        $(document).on("click", "#open_msg_box", function (e) {
          $wrapper.toggleClass("open-msg-box").removeClass("");
          $(".dropdown").removeClass("open");
          return false;
        });
      }

      // Message box remove

      if ($(".dropdown-toggle").length > 0) {
        $(".dropdown-toggle").click(function () {
          if ($(".main-wrapper").hasClass("open-msg-box")) {
            $(".main-wrapper").removeClass("open-msg-box");
          }
        });
      }

      // Table Dropdown

      $(".table-responsive").on("shown.bs.dropdown", function (e) {
        var $table = $(this),
          $dropmenu = $(e.target).find(".dropdown-menu"),
          tableOffsetHeight = $table.offset().top + $table.height(),
          menuOffsetHeight =
            $dropmenu.offset().top + $dropmenu.outerHeight(true);

        if (menuOffsetHeight > tableOffsetHeight)
          $table.css("padding-bottom", menuOffsetHeight - tableOffsetHeight);
      });
      $(".table-responsive").on("hide.bs.dropdown", function () {
        $(this).css("padding-bottom", 0);
      });

      // Custom Modal Backdrop

      $('a[data-toggle="modal"]').on("click", function () {
        setTimeout(function () {
          if ($(".modal.custom-modal").hasClass("in")) {
            $(".modal-backdrop").addClass("custom-backdrop");
          }
        }, 500);
      });

      // Email Inbox

      if ($(".clickable-row").length > 0) {
        $(".clickable-row").click(function () {
          window.location = $(this).data("href");
        });
      }

      if ($(".checkbox-all").length > 0) {
        $(".checkbox-all").click(function () {
          $(".checkmail").click();
        });
      }
      if ($(".checkmail").length > 0) {
        $(".checkmail").each(function () {
          $(this).click(function () {
            if ($(this).closest("tr").hasClass("checked")) {
              $(this).closest("tr").removeClass("checked");
            } else {
              $(this).closest("tr").addClass("checked");
            }
          });
        });
      }
      if ($(".mail-important").length > 0) {
        $(".mail-important").click(function () {
          $(this).find("i.fa").toggleClass("fa-star");
          $(this).find("i.fa").toggleClass("fa-star-o");
        });
      }

      // Incoming call popup

      if ($("#incoming_call").length > 0) {
        $(window).on("load", function () {
          $("#incoming_call").modal("show");
          $("body").addClass("call-modal");
        });
      }
    });
    if (BuggyAuth.isAuthenticated() === true) {
      this.props.history.push("/dashboard");
    }
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\]]/g, "\\$&");
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
  render() {
    return (
      <Aux>
        <div className="main-wrapper">
          <div className="account-page">
            <div className="container">
              <h3 className="account-title">Set New Password</h3>
              <BlockUi tag="div" blocking={this.state.blocking}>
                <div className="account-box">
                  <div className="account-wrapper">
                    <div className="account-logo">
                      <a href="/">
                        {/*<img src="assets/img/logo2.png" alt="Focus Technologies"/>*/}
                      </a>
                    </div>
                    {this.state.isSuccess ? (
                      <div className="row">
                        <div className="col-xs-12">
                          <div className="alert alert-success">
                            <strong>Success!</strong> Your password has been
                            changed!
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.state.errorMessage.length > 0 ? (
                      <div className="row">
                        <div className="col-xs-12">
                          <div className="alert alert-danger">
                            <ul>
                              {this.state.errorMessage.map((message, i) => (
                                <li key={i}>{message}</li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.state.isTokenValid ? (
                      <form action="">
                        {this.state.isSuccess ? (
                          <div className="text-center">
                            <Link to="/">Back to Login</Link>
                          </div>
                        ) : (
                          <Aux>
                            <div className="form-group form-focus">
                              <label className="control-label">Password</label>
                              <input
                                className="form-control floating"
                                onChange={this.passwordOnChangeHandler}
                                value={this.state.passwordValue}
                                type="password"
                              />
                            </div>
                            <div className="form-group form-focus">
                              <label className="control-label">
                                Confirm Password
                              </label>
                              <input
                                className="form-control floating"
                                onChange={this.confirmPasswordOnChangeHandler}
                                value={this.state.confirmPasswordValue}
                                type="password"
                              />
                            </div>
                            <PasswordChecklist
                              rules={[
                                "minLength",
                                "specialChar",
                                "number",
                                "capital",
                                "match",
                              ]}
                              minLength={8}
                              value={this.state.passwordValue}
                              valueAgain={this.state.confirmPasswordValue}
                              onChange={this.isValidOnChangeHandler}
                            />
                            <br />
                            <div className="form-group text-center">
                              <a
                                href
                                className="btn btn-primary btn-block account-btn"
                                onClick={this.changePasswordOnClickHandler}
                                disabled={!this.state.isValid}
                                type="submit"
                              >
                                Change Password
                              </a>
                            </div>
                          </Aux>
                        )}
                      </form>
                    ) : null}
                  </div>
                </div>
              </BlockUi>
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
        <SidebarOverlay />
      </Aux>
    );
  }
}

export default SetNewPassword;
