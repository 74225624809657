import {
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import BlockUi from '@/components/BlockUi';
import { getEntryTypes, getVehicleStatuses } from '@/services/fleetio.api';

import { useIntegrationFormContext } from '../IntegrationFormContext';

function FleetioConfigForm() {
  const {
    watch, register, setError, formState, clearErrors, control,
  } = useFormContext();
  const { setIsFetching } = useIntegrationFormContext();

  const authFields = watch('authFields');

  const apiKey = useMemo(
    () => authFields.find(({ name }) => name === 'api_key')?.value,
    [authFields],
  );

  const accountToken = useMemo(
    () => authFields.find(({ name }) => name === 'account_token')?.value,
    [authFields],
  );

  useEffect(() => {
    setIsFetching(true);

    return () => {
      setIsFetching(false);
    };
  }, [setIsFetching]);

  const onSettledEntryTypes = useCallback(() => {
    setIsFetching(false);
  }, [setIsFetching]);

  const { data: entryTypeData, isLoading, isSuccess } = useQuery({
    queryKey: ['integration', 'fleetio', accountToken, apiKey, 'entry_types'],
    queryFn: () => getEntryTypes(accountToken, apiKey),
    onError: () => {
      setError('authFieldsProxy', {
        type: 'custom',
        message: 'Could not validate your credentials.',
      });
    },
    onSuccess: () => {
      clearErrors('authFieldsProxy');
    },
    onSettled: onSettledEntryTypes,
  });

  const { data: vehicleStatusData } = useQuery({
    queryKey: ['integration', 'fleetio', accountToken, apiKey, 'vehicle_status'],
    queryFn: () => getVehicleStatuses(accountToken, apiKey),
    onError: () => {
      setError('authFieldsProxy', {
        type: 'custom',
        message: 'Could not validate your credentials.',
      });
    },
    onSuccess: () => {
      clearErrors('authFieldsProxy');
    },
    onSettled: onSettledEntryTypes,
  });

  const entryTypeOptions = entryTypeData?.data || [];
  const vehicleStatusOptions = vehicleStatusData?.data || [];

  return (
    <BlockUi tag="div" blocking={isLoading}>
      {formState.errors.authFieldsProxy?.message && (
        <div className="alert alert-danger">
          {formState.errors.authFieldsProxy.message}
        </div>
      )}
      {isSuccess && (
        <div className="alert alert-success">
          Your Fleetio credentials have been verified.
        </div>
      )}
      <h4>Expense entry types</h4>
      <div className="row m-t-10">
        <div className="form-group">
          <label
            className="control-label col-md-4"
            htmlFor="tolls-dropdown"
          >
            Tolls
          </label>
          <div className="col-md-8">
            <select
              id="tolls-dropdown"
              className="form-control"
              {...register('config.entryTypes.toll')}
            >
              <option disabled>Select one</option>
              {entryTypeOptions.map(({ id, name }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="row m-t-10">
        <div className="form-group">
          <label
            className="control-label col-md-4"
            htmlFor="parking-tickets-dropdown"
          >
            Parking Tickets
          </label>
          <div className="col-md-8">
            <select
              id="parking-tickets-dropdown"
              className="form-control"
              {...register('config.entryTypes.parking')}
            >
              <option selected disabled>
                Select one
              </option>
              {entryTypeOptions.map(({ id, name }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <Divider sx={{ marginY: 2 }} />
      <Box>
        <Typography variant="caption" />
        <Controller
          control={control}
          name="config.vehicleStatusIds"
          render={({ field: { value = [], onChange } }) => (
            <FormControl fullWidth>
              <InputLabel>Active Vehicle Status</InputLabel>
              <Select
                multiple
                input={(
                  <OutlinedInput
                    label="Active Vehicle Status"
                  />
                )}
                renderValue={(selected) => {
                  const selectedOptions = vehicleStatusOptions.filter(
                    ({ id }) => selected?.includes(id),
                  );

                  return selectedOptions.map(
                    ({ name }) => (
                      <Chip
                        label={name}
                        size="small"
                        sx={{ marginRight: 0.5 }}
                        key={name}
                      />
                    ),
                  );
                }}
                value={value}
                onChange={onChange}
              >
                {vehicleStatusOptions.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    <Checkbox checked={value.includes(id)} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </Box>
    </BlockUi>
  );
}

export default FleetioConfigForm;
