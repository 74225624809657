import BuggyApi from '../../../services/BuggyApi';
import { downloadFileFromURL } from '../../../utils/download';

const api = new BuggyApi();

export type DownloadCSVParams = {
  offset: number;
  plateNumber: string;
  lessee: string;
  dateFrom: string;
  dateTo: string;
  exitPlaza: string;
  columns: string[];
};

type EmailCSVParams = DownloadCSVParams & {
  email: string;
};

export async function downloadCSV({
  offset,
  plateNumber,
  lessee,
  dateFrom,
  dateTo,
  exitPlaza,
  columns,
}: DownloadCSVParams) {
  const {
    data: { url },
  } = await api.getTicketsDownloadLink(
    offset,
    plateNumber,
    lessee,
    dateFrom,
    dateTo,
    exitPlaza,
    'link',
    undefined,
    // @ts-ignore
    columns,
  );

  downloadFileFromURL(url);
}

export async function emailCSV({
  offset,
  plateNumber,
  lessee,
  dateFrom,
  dateTo,
  exitPlaza,
  columns,
  email,
}: EmailCSVParams) {
  // let the server decided how to send to the currently signed in user
  const targetEmail = email !== 'self' ? email : undefined;

  return api.getTicketsDownloadLink(
    offset,
    plateNumber,
    lessee,
    dateFrom,
    dateTo,
    exitPlaza,
    'download',
    // @ts-ignore
    targetEmail,
    columns,
  );
}
