import axios from 'axios';

const BASE_URL = 'https://secure.fleetio.com/api/v1';

export const getEntryTypes = (token, apiKey) => axios.get('/expense_entry_types', {
  baseURL: BASE_URL,
  headers: {
    'Account-Token': token,
    Authorization: `Token ${apiKey}`,
  },
  responseType: 'json',
});

export const getVehicleStatuses = (token, apiKey) => axios.get('/vehicle_statuses', {
  baseURL: BASE_URL,
  headers: {
    'Account-Token': token,
    Authorization: `Token ${apiKey}`,
  },
  responseType: 'json',
});
