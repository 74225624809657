import {
  IconButton, Tooltip,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import { HiSparkles } from 'react-icons/hi2';

import CustomFieldListDialog from './CustomFIeldListDialog';

function AddColumnButton() {
  const menuAnchorRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const onClickOpen = () => {
    setIsOpen(true);
  };

  const onCloseDialog = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Tooltip title="Custom Fields">
        <IconButton
          size="small"
          onClick={onClickOpen}
          ref={menuAnchorRef}
          color="primary"
        >
          <HiSparkles />
        </IconButton>
      </Tooltip>
      <CustomFieldListDialog
        open={isOpen}
        onClose={onCloseDialog}
        fullWidth
        maxWidth="xs"
      />
    </>
  );
}

export default AddColumnButton;
