import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export default function useQueryParam(key: string) {
  const { search } = useLocation();

  return useMemo(() => {
    const queryParams = new URLSearchParams(search);
    return queryParams.get(key);
  }, [search, key]);
}
