import {
  Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useRef, useState } from 'react';
import { HiTemplate } from 'react-icons/hi';
import { MdAdd } from 'react-icons/md';
import { RiCheckboxMultipleBlankLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';

import VehicleBulkUpload from '../../VehicleBulkUpload';
import { downloadTemplate } from '../helpers';
import AddVehicleFormDialog from './AddVehicleFormDialog';

type AddVehicleButtonProps = {
  refresh: () => void;
  showLessee?: boolean;
};

function AddVehicleButton({ refresh, showLessee = false }: AddVehicleButtonProps) {
  const bulkAddVehiclesRef = useRef<VehicleBulkUpload>(null);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const fleetId = useSelector((state: any) => state.userInfo?.fleet_id);

  const onClickDownloadTemplate = useCallback(async () => {
    try {
      await downloadTemplate(fleetId);
    } catch {
      enqueueSnackbar({
        message: 'Failed to download the template.',
        variant: 'error',
      });
    }
  }, [fleetId]);

  const menuAnchorRef = useRef(null);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onClickNewVehicle = () => {
    setIsMenuOpen(true);
  };

  const onCloseMenu = () => {
    setIsMenuOpen(false);
  };

  const onClickAddVehicles = () => {
    setIsFormOpen(true);
  };

  const onCloseAddVehicles = () => {
    setIsFormOpen(false);
  };

  const onClickBulkAddVehicles = () => bulkAddVehiclesRef.current?.onModalShow(showLessee);

  return (
    <>
      <Button
        startIcon={<MdAdd />}
        onClick={onClickNewVehicle}
        size="small"
        id="add-new-vehicle"
        ref={menuAnchorRef}
      >
        New Vehicle
      </Button>
      <Menu
        open={isMenuOpen}
        anchorEl={menuAnchorRef.current}
        onClose={onCloseMenu}
      >
        <MenuItem onClick={onClickAddVehicles}>
          <ListItemIcon>
            <MdAdd />
          </ListItemIcon>
          <ListItemText>
            Add one
          </ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={onClickBulkAddVehicles}>
          <ListItemIcon>
            <RiCheckboxMultipleBlankLine />
          </ListItemIcon>
          <ListItemText>
            Bulk Add
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={onClickDownloadTemplate}>
          <ListItemIcon>
            <HiTemplate />
          </ListItemIcon>
          <ListItemText>
            Download Bulk Template
          </ListItemText>
        </MenuItem>
      </Menu>
      <VehicleBulkUpload ref={bulkAddVehiclesRef} refresh={refresh} />
      <AddVehicleFormDialog
        open={isFormOpen}
        onClose={onCloseAddVehicles}
        maxWidth="xs"
        fullWidth
        showLessee={showLessee}
      />
    </>
  );
}

export default AddVehicleButton;
