import { MdCheck, MdClose } from 'react-icons/md';
import { RxDotFilled } from 'react-icons/rx';

import { InvoicePaymentStatus } from '@/services/billing';

export const PAYMENT_STATUS_WORDING_MAP: Record<InvoicePaymentStatus, string> = {
  pending: 'Waiting for payment',
  failed: 'Payment Failed',
  succeeded: 'Paid',
};

export const PAYMENT_STATUS_ICON_MAP: Record<InvoicePaymentStatus, React.ElementType> = {
  pending: RxDotFilled,
  failed: MdClose,
  succeeded: MdCheck,
};

export const PAYMENT_STATUS_COLOR_MAP = {
  pending: 'primary',
  failed: 'error',
  succeeded: 'success',
} as const;

export const INVOICE_CHARGE_TYPE = {
  toll: { charge: 'toll', label: 'Tolls' },
  parking: { charge: 'parking', label: 'Violations' },
} as const;
