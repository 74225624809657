import React, { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { useIntegrationFormContext } from "../IntegrationFormContext";
import BlockUi from "../../../../BlockUi";
import { getAccessToken } from "../../../../../services/rentcentric.api";

const RentCentric = () => {
  const { watch, setError, formState, clearErrors } = useFormContext();
  const { setIsFetching } = useIntegrationFormContext();

  const authFields = watch("authFields");

  useEffect(() => {
    setIsFetching(true);

    return () => {
      setIsFetching(false);
    };
  }, [setIsFetching]);

  const username = useMemo(
    () => authFields.find(({ name }) => name === "username")?.value,
    [authFields]
  );

  const password = useMemo(
    () => authFields.find(({ name }) => name === "password")?.value,
    [authFields]
  );

  const clientId = useMemo(
    () => authFields.find(({ name }) => name === "client_id")?.value,
    [authFields]
  );

  const { isLoading, isSuccess } = useQuery({
    queryKey: ["integration", "rent_centric", username, password],
    queryFn: () => getAccessToken(username, password, clientId),
    onError: () => {
      setError("authFieldsProxy", {
        type: "custom",
        message: "Could not validate your credentials.",
      });
    },
    onSuccess: () => {
      clearErrors("authFieldsProxy");
    },
    onSettled: () => {
      setIsFetching(false);
    },
  });

  return (
    <BlockUi tag="div" blocking={isLoading}>
      {formState.errors.authFieldsProxy?.message && (
        <div className="alert alert-danger">
          {formState.errors.authFieldsProxy.message}
        </div>
      )}
      {isSuccess && (
        <div className="alert alert-success">
          Your Rent Centric credentials have been verified.
        </div>
      )}
    </BlockUi>
  );
};

export default RentCentric;
