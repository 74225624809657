import {
  Chip,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import currency from 'currency.js';
import { format, parse } from 'date-fns';
import { range } from 'lodash';
import React, {
  forwardRef, useImperativeHandle, useRef, useState,
} from 'react';
import { MdChevronRight } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { InvoicePaymentStatus, listInvoices } from '@/services/billing';

import { InvoiceModal } from '../Invoice';
import { statusFilterValues } from './constants';
import { mapStatusToColor, mapStatusToIcon } from './helpers';
import { InvoiceListProps, InvoiceListRef } from './types';

export const InvoiceList = forwardRef<InvoiceListRef, InvoiceListProps>(
  ({ paymentStatus }, ref) => {
    const rootRef = useRef<HTMLDivElement>(null);
    const [selectedStatus, setSelectedStatus] = useState<InvoicePaymentStatus | undefined>(
      paymentStatus,
    );

    useImperativeHandle(ref, () => ({
      scrollIntoView: (args) => rootRef.current?.scrollIntoView(args),
      setSelectedStatus,
    }));

    const { data, isLoading } = useQuery({
      queryKey: ['invoices', selectedStatus],
      queryFn: () => listInvoices({ paymentStatus: selectedStatus }),
    });

    const onClickStatusChip = (status: InvoicePaymentStatus) => {
      setSelectedStatus((prevStatus) => {
        if (status === prevStatus) {
          return undefined;
        }

        return status;
      });
    };

    return (
      <Stack ref={rootRef}>
        <InvoiceModal />
        <Stack direction="row" spacing={2} padding={2}>
          {statusFilterValues.map((status) => (
            <Chip
              label={status}
              sx={{ textTransform: 'capitalize' }}
              variant={selectedStatus === status ? 'outlined' : 'filled'}
              color={selectedStatus === status ? 'primary' : 'default'}
              onClick={() => onClickStatusChip(status)}
              key={status}
            />
          ))}
        </Stack>
        {isLoading
          ? <Stack spacing={2} padding={2}>{range(5).map(() => <Skeleton variant="rounded" height={60} />)}</Stack>
          : (
            <List>
              {data?.data.invoices?.map(({
                lago_id, issuing_date, payment_status, total_amount_cents,
              }) => (
                <ListItem disablePadding dense key={lago_id}>
                  <ListItemButton
                    divider
                    component={Link}
                    to={`?invoice=${lago_id}`}
                    replace
                  >
                    <Tooltip title={payment_status}>
                      <ListItemIcon
                        sx={({ palette }) => ({
                          color: mapStatusToColor(payment_status, palette),
                        })}
                      >
                        {mapStatusToIcon(payment_status)}
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText
                      primary={(
                        <Typography color="primary" fontFamily="monospace">
                          {currency(total_amount_cents, { fromCents: true }).format()}
                        </Typography>
                      )}
                      secondary={format(parse(issuing_date, 'yyyy-MM-dd', new Date()), 'MMM dd, yyyy')}
                    />
                    <ListItemSecondaryAction>
                      <MdChevronRight size={24} />
                    </ListItemSecondaryAction>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
      </Stack>
    );
  },
);

export * from './types';

export default InvoiceList;
