export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const API_GET_TICKETS = 'API_GET_TICKETS';
export const UPDATE_NAVIGATION = 'UPDATE_NAVIGATION';
export const GET_COMPONENT_INDEX = 'GET_COMPONENT_INDEX';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIDEBAR_OPENED = 'SIDEBAR_OPENED';
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export const UPDATE_PUSHER_CREDENTIALS = 'UPDATE_PUSHER_CREDENTIALS';
export const PAGE_LOADING = 'PAGE_LOADING';
