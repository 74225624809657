import { createTheme, ThemeProvider } from '@mui/material';
import { blueGrey, orange } from '@mui/material/colors';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actionTypes from '../../store/actions/actions';
import UsageDashboard from './Dashboard';

const usageTheme = createTheme({
  palette: {
    primary: orange,
    secondary: blueGrey,
  },
  shape: {
    borderRadius: 8,
  },
});

function UsagePage() {
  const dispatch = useDispatch();
  const componentIndex = useSelector((state:any) => state.componentIndex);

  useEffect(() => {
    dispatch({ type: actionTypes.GET_COMPONENT_INDEX, payload: window.location.pathname });
    dispatch({ type: actionTypes.UPDATE_NAVIGATION, payload: componentIndex });
  }, [componentIndex, dispatch]);

  return (
    <ThemeProvider theme={usageTheme}>
      <div className="page-wrapper slide-nav-toggle">
        <div className="content container-fluid">
          <UsageDashboard />
        </div>
      </div>
    </ThemeProvider>
  );
}

export default UsagePage;
