import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  IconButton as MuiIconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosProgressEvent } from 'axios';
import { filter, isEmpty, isNil } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { HiDocument } from 'react-icons/hi';
import { MdClose, MdUploadFile } from 'react-icons/md';

import FileUploader from '@/components/FileUploader';
import { uploadDocuments } from '@/services/documents';
import formatBytes from '@/utils/formatBytes';

import uploadFormSchema, { UploadFormType } from './validationSchema';

function UploadDocument() {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState<number | undefined>();
  const {
    register, formState, handleSubmit, setValue,
  } = useForm<UploadFormType>({
    resolver: zodResolver(uploadFormSchema),
  });

  const onClickOpenUploadDialog = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setIsOpen(false);
    setSelectedFiles([]);
    setUploadProgress(undefined);
  }, []);

  const onDropFiles = useCallback((files: File[]) => {
    setSelectedFiles((prev) => [...prev, ...files]);
  }, []);

  const onClickRemoveFile = useCallback((file: File) => {
    setSelectedFiles((prev) => filter(prev, (f) => f !== file));
  }, []);

  useEffect(() => {
    setValue('files', selectedFiles);
  }, [selectedFiles, setValue]);

  const onUploadProgress = useCallback((progress: AxiosProgressEvent) => {
    setUploadProgress(progress.progress);
  }, []);

  const onUploadSuccess = useCallback(() => {
    onClose();
    queryClient.invalidateQueries({
      queryKey: ['documents', 'list'],
    });
    enqueueSnackbar({ variant: 'success', message: 'Documents sent for approval successfully' });
  }, [onClose, queryClient]);

  const { mutate: uploadDocument, isLoading } = useMutation({
    mutationKey: ['documents', 'list'],
    mutationFn: (
      { files, documentType }: UploadFormType,
    ) => uploadDocuments(files, documentType, { onUploadProgress }),
    onSuccess: onUploadSuccess,
  });

  const onSubmit = useCallback((formValues: UploadFormType) => {
    uploadDocument(formValues);
  }, [uploadDocument]);

  return (
    <>
      <Button
        onClick={onClickOpenUploadDialog}
        variant="contained"
        startIcon={<MdUploadFile />}
        color="primary"
      >
        Upload Document
      </Button>
      <Dialog
        open={isOpen}
        onClose={onClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Upload a new file</DialogTitle>
        <DialogContent>
          <FormControl
            fullWidth
            sx={{ marginY: 1 }}
            error={!isEmpty(formState.errors.documentType)}
          >
            <InputLabel>Document Type</InputLabel>
            <Select
              label="Document Type"
              {...register('documentType')}
            >
              <MenuItem value="VIOLATION">Violation</MenuItem>
              <MenuItem value="TOLL">Toll</MenuItem>
            </Select>
            <FormHelperText>{formState.errors.documentType?.message}</FormHelperText>
          </FormControl>
          <FileUploader fileError={formState?.errors?.files} onDropFiles={onDropFiles} />
          <Box>
            {selectedFiles.map((file) => (
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  py={2}
                >
                  <Box mr={2}>
                    <HiDocument size={42} />
                  </Box>
                  <Box>
                    <Typography>{file.name}</Typography>
                    <Typography fontWeight="bold">{formatBytes(file.size)}</Typography>
                  </Box>
                  <Box flexGrow={1} />
                  <MuiIconButton size="small" onClick={() => onClickRemoveFile(file)}>
                    <MdClose size={16} />
                  </MuiIconButton>
                </Box>
                <Divider />
              </>
            ))}
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: theme.spacing(3) }}>
          {!isNil(uploadProgress) && (
          <Box flexGrow={1} mr={2}>
            <LinearProgress value={uploadProgress * 100} variant="determinate" />
            <Typography align="right">
              {(uploadProgress * 100).toFixed(2)}
              %
            </Typography>
          </Box>
          )}
          <Button
            variant="contained"
            color="primary"
            disableElevation
            disabled={isEmpty(selectedFiles) || isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UploadDocument;
