import fleetAxios from '../axios';
import { AcceptInvitePayload, FleetInvitation } from './types';

const baseURL = process.env.REACT_APP_BASE_URL_ROOT;

export function getInvitation(code: string) {
  return fleetAxios.get<FleetInvitation>(`/fleet/invitations/${code}/`, {
    baseURL,
  });
}

export function acceptInvite(code: string, payload?: AcceptInvitePayload) {
  const postData = payload
    ? {
      username: payload.username,
      password: payload.password,
      phone_number: payload.phoneNumber,
      recaptcha_token: payload.recaptchaToken,
    }
    : undefined;

  return fleetAxios.post<void>(
    `/fleet/invitations/${code}/accept/`,
    postData,
    {
      baseURL,
    },
  );
}

export default {};
