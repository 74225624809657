import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { MdOpenInNew } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import useQueryParam from '@/hooks/useQueryParams';
import { downloadInvoice } from '@/services/billing';

import ExportButton from './ExportButton';
import Invoice from './Invoice';
import PayButton from './PayButton';

export function InvoiceModal() {
  const history = useHistory();
  const invoiceId = useQueryParam('invoice');

  const { data, isLoading } = useQuery({
    queryKey: ['invoice', invoiceId],
    queryFn: async () => {
      if (!invoiceId) {
        return null;
      }
      return downloadInvoice(invoiceId);
    },
  });

  const isExportable = data?.data.invoice.invoice_type === 'subscription';

  const fileUrl = data?.data.invoice.file_url || '';

  const onClose = () => history.replace('?');

  return (
    <Dialog
      open={Boolean(invoiceId)}
      onClose={onClose}
      maxWidth="lg"
    >
      <DialogContent>
        {isLoading
          ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          )
          : <Invoice file={fileUrl} />}
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          spacing={1}
          padding={2}
          alignItems="center"
          justifyContent="flex-end"
        >
          {isExportable
            && (
              <Box flexGrow={1}>
                <ExportButton invoice={invoiceId} />
              </Box>
            )}
          <Button onClick={onClose}>
            Close
          </Button>
          <Button
            startIcon={<MdOpenInNew />}
            href={fileUrl}
            target="_blank"
            rel="noopener noreferrer"
            sx={({ palette }) => ({
              '&:visited': {
                color: palette.primary.main,
              },
              '&:hover': {
                color: palette.primary.main,
              },
            })}
          >
            Download
          </Button>
          <PayButton
            invoice={data?.data.invoice}
            isLoading={isLoading}
          />
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default InvoiceModal;
