import {
  Paper, Skeleton, Stack, Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

import { getCurrentUsage } from '../../../../services/billing';
import SubscriptionUsage from './SubscriptionUsage';

function Usage() {
  const { data, isLoading } = useQuery({
    queryKey: ['billing', 'usage'],
    queryFn: () => getCurrentUsage(),
  });

  return (
    <Stack padding={2} spacing={2}>
      <Typography variant="h6" color="primary">
        Current Usage
      </Typography>
      {isLoading
        ? (
          <Stack>
            <Skeleton height={96} />
            <Skeleton variant="circular" height={350} width={350} />
            <Skeleton height={64} />
          </Stack>
        )
        : data?.data.current_usage_list.map(
          ({ customer_usage, subscription }) => (
            <Paper key={subscription.lago_id}>
              <SubscriptionUsage
                usage={customer_usage}
              />
            </Paper>
          ),
        )}
    </Stack>
  );
}

export default Usage;
