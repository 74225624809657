import React, { Component } from "react";
import Pagination from "react-js-pagination";
import BuggyApi from "../../services/BuggyApi";
import Aux from "../hoc/Auxiliary";

import { connect } from "react-redux";
import * as actionTypes from "../../store/actions/actions";
import Datetime from "react-datetime";
import BlockUi from "../BlockUi";
import ShowMoreText from "react-show-more-text";

class Logs extends Component {
  state = {
    logs: [],
    itemsCountPerPage: 10,
    totalItemsCount: 100,
    pageRangeDisplayed: 10,
    activePage: 1,
    focusedFrom: false,
    isLoading: false,
  };
  refreshVehicle = () => {
    this.setState({ isLoading: true });
    new BuggyApi().getLogs().then((response) => {
      this.setState({ logs: response.data.objects });
      this.setState({ itemsCountPerPage: response.data.meta.limit });
      this.setState({ totalItemsCount: response.data.meta.total_count });
      this.setState({ isLoading: false });
    });
  };
  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber });
    pageNumber -= 1;
    const searchString = this.searchString.value;
    const searchDate = this.searchDate.value;
    const responseCode = this.responseCode.value;
    this.setState({ isLoading: true });
    new BuggyApi()
      .getLogs(
        this.state.itemsCountPerPage * pageNumber,
        searchDate,
        "",
        "",
        responseCode,
        searchString,
        false
      )
      .then((response) => {
        this.setState({ vehicles: response.data.objects });
        this.setState({ itemsCountPerPage: response.data.meta.limit });
        this.setState({ totalItemsCount: response.data.meta.total_count });
        this.setState({ isLoading: false });
        this.setState({ logs: response.data.objects });
      });
  };

  downloadCsvClickHandler = () => {
    const searchString = this.searchString.value;
    const searchDate = this.searchDate.value;
    const responseCode = this.responseCode.value;
    this.setState({ blocking: true });
    new BuggyApi()
      .getLogs(0, searchDate, "", "", responseCode, searchString, true)
      .then((response) => {
        if (this.is_mounted) {
          this.setState({
            blocking: false,
          });
        }
        window.open(response.data.url, "_blank");
      })
      .catch((error) => {
        this.setState({
          blocking: false,
        });
        if (error.response) {
        } else if (error.request) {
        } else {
          /*console.log('Error', error.message);*/
        }
      });
  };

  componentWillMount() {
    this.props.getComponentIndex(this.props.location.pathname);
    this.props.onLinkChange(this.props.componentIndex);
    this.setState({ isLoading: true });

    new BuggyApi().getLogs().then((response) => {
      this.setState({ logs: response.data.objects });
      this.setState({ itemsCountPerPage: response.data.meta.limit });
      this.setState({ totalItemsCount: response.data.meta.total_count });
      this.setState({ isLoading: false });
    });
  }

  onFocusHandlerDateFrom = (e) => {
    this.setState({ focusedFrom: true });
  };

  onBlurHandlerDateFrom = (e) => {
    this.setState({ focusedFrom: false });
  };
  componentDidMount() {
    this.is_mounted = true;
  }

  componentWillUnmount() {
    this.is_mounted = false;
  }

  searchClickHandler = () => {
    const searchString = this.searchString.value;
    const searchDate = this.searchDate.value;
    const responseCode = this.responseCode.value;
    this.setState({ isLoading: true });
    new BuggyApi()
      .getLogs(0, searchDate, "", "", responseCode, searchString)
      .then((response) => {
        this.setState({ logs: response.data.objects });
        this.setState({ itemsCountPerPage: response.data.meta.limit });
        this.setState({ totalItemsCount: response.data.meta.total_count });
        this.setState({ isLoading: false });
      });
  };

  render() {
    let rows = [];
    this.state.logs.forEach(
      function (log, i) {
        rows.push(
          <tr key={log.id}>
            <td>{log.remote_address}</td>
            <td>{log.method}</td>
            <td>{log.response_code}</td>

            <td>{log.date}</td>
            <td>
              <ShowMoreText
                /* Default options */
                lines={1}
                more="Show more"
                less="Show less"
                className="content-css"
                anchorClass="my-anchor-css-class"
                onClick={this.executeOnClick}
                expanded={false}
                truncatedEndingComponent={"... "}
              >
                {log.body_request}
              </ShowMoreText>
            </td>
            <td>
              {" "}
              <ShowMoreText
                /* Default options */
                lines={1}
                more="Show more"
                less="Show less"
                className="content-css"
                anchorClass="my-anchor-css-class"
                onClick={this.executeOnClick}
                expanded={false}
                truncatedEndingComponent={"... "}
              >
                {log.body_response}{" "}
              </ShowMoreText>
            </td>
          </tr>
        );
      }.bind(this)
    );

    return (
      <Aux>
        <div
          className={
            this.props.sidebar_status
              ? "page-wrapper slide-nav-toggle"
              : "page-wrapper"
          }
        >
          <div className="content container-fluid">
            <div className="row">
              <div className="col-sm-4 col-xs-3">
                <h4 className="page-title">Logs</h4>
              </div>
              <div className="col-sm-8 col-xs-9 text-right m-b-20"></div>
            </div>
            <div className="row filter-row">
              <div className="col-sm-3 col-xs-6">
                <div className="form-group form-focus">
                  <label className="control-label">Endpoint</label>
                  <input
                    type="text"
                    className="form-control floating"
                    ref={(el) => (this.searchString = el)}
                  />
                </div>
              </div>
              <div className="col-sm-2 col-xs-6">
                <div className="form-group form-focus">
                  <label className="control-label">Response Code</label>
                  <input
                    type="text"
                    className="form-control floating"
                    ref={(el) => (this.responseCode = el)}
                  />
                </div>
              </div>
              <div className="col-sm-3 col-xs-6">
                <div
                  className={
                    this.state.focusedFrom
                      ? "form-group form-focus focused"
                      : "form-group form-focus"
                  }
                >
                  <label className="control-label">Date</label>
                  <div className="cal-icon">
                    <Datetime
                      dateFormat="YYYY-MM-DD"
                      onFocus={this.onFocusHandlerDateFrom}
                      onBlur={this.onBlurHandlerDateFrom}
                      timeFormat={false}
                      ref={(el) => (this.searchDate = el)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-2 col-xs-6">
                <button
                  type="button"
                  className="btn btn-success btn-block"
                  onClick={this.searchClickHandler}
                >
                  {" "}
                  Search
                </button>
              </div>
              <div className="col-sm-2 col-xs-6">
                <button
                  type="button"
                  className="btn btn-success btn-block"
                  onClick={this.downloadCsvClickHandler}
                >
                  {" "}
                  Download CSV
                </button>
              </div>
            </div>

            <BlockUi tag="div" blocking={this.state.isLoading}>
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table table-striped custom-table m-b-0 ">
                      <thead>
                        <tr>
                          <th>Endpoint#</th>
                          <th>Method</th>
                          <th>Response Code</th>
                          <th>Date Time</th>
                          <th>Request</th>
                          <th>Response</th>
                        </tr>
                      </thead>
                      <tbody>{rows}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </BlockUi>
            <div className="row">
              <div className="col-sm-5">
                <div className="dataTables_info">
                  Showing{" "}
                  {this.state.itemsCountPerPage * (this.state.activePage - 1)}{" "}
                  to&nbsp;
                  {this.state.itemsCountPerPage * this.state.activePage}{" "}
                  of&nbsp;
                  {this.state.totalItemsCount} entries
                </div>
              </div>
              <div className="col-sm-7">
                <div className="dataTables_paginate">
                  <Pagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.itemsCountPerPage}
                    totalItemsCount={this.state.totalItemsCount}
                    pageRangeDisplayed={this.state.pageRangeDisplayed}
                    onChange={this.handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    componentIndex: state.componentIndex,
    sidebar_status: state.sidebarOpened,
    fleet_info: state.userInfo,
    LesseShow:
      state.userInfo.fleet_id === 158 ||
      state.userInfo.fleet_id === 141 ||
      state.userInfo.fleet_id === 171
        ? true
        : false,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onLinkChange: (i) => dispatch({ type: actionTypes.UPDATE_NAVIGATION, i }),
    getComponentIndex: (path) =>
      dispatch({ type: actionTypes.GET_COMPONENT_INDEX, path }),
  };
};
export default connect(mapStatesToProps, mapDispatchToProps)(Logs);
