import React, { Component } from "react";

import { connect } from "react-redux";
import * as actionTypes from "../../store/actions/actions";
import $ from "jquery";
import BuggyApi from "../../services/BuggyApi";
import BlockUi from "../BlockUi";

class MyProfile extends Component {
  state = {
    isSuccess: false,
    errorMessage: [],
    blocking: false,
    rows: [],
  };
  emailOnChangeHandler = (e) => {
    this.emailInput.value = e.target.value;
  };
  findArrayElementByKey(array, key) {
    return array.find((element) => {
      return element.key === key;
    });
  }

  componentWillMount() {
    this.props.getComponentIndex(this.props.location.pathname);
    this.props.onLinkChange(this.props.componentIndex);
    if (
      this.props.fleet_info.fleet_title === undefined ||
      this.props.fleet_info.fleet_title === "" ||
      this.props.fleet_info.fleet_title === null
    ) {
      if (this.props.fleet_info.emails.length !== this.state.rows.length) {
        let rows = [];

        if (this.props.fleet_info.emails) {
          this.props.fleet_info.emails.forEach(function (email) {
            rows.push(email.email);
          }, this);
        }
        this.setState({
          rows: rows,
        });
      }
    }
  }
  emailOnChangeHandler = (counter) => (e) => {
    var rows = [...this.state.rows];
    rows[counter] = e.target.value;
    this.setState({ rows });
  };
  deleteBtnHandler = (counter) => (index) => {
    var rows = [...this.state.rows];
    rows.splice(counter, 1);
    this.setState({ rows });
  };

  updateBtnOnClickHandler = () => {
    this.setState({
      blocking: true,
    });

    if (
      this.emailInput.value !== this.props.fleet_info.fleet_email &&
      this.props.fleet_info.fleet_id !== 0
    ) {
      const formData = {
        email: this.emailInput.value,
      };

      new BuggyApi()
        .updateEmailInfo(this.props.fleet_info.user_id, formData)
        .then((response) => {
          this.setState({
            isSuccess: true,
            errorMessage: [],
            blocking: false,
          });
        })
        .catch((error) => {
          this.setState({ blocking: false });
          if (error.response) {
            if (error.response.data.whoami) {
              let errors = [];
              const errorList = error.response.data.whoami;
              for (let key in errorList) {
                let em = "";
                switch (key) {
                  case "email":
                    em = "Email: ";
                    break;
                  default:
                    em = "";
                    break;
                }
                em += errorList[key];
                errors.push(em);
              }
              this.setState({
                errorMessage: errors,
              });
            } else {
              /*console.log(error.response.data.error_message);*/
              const em = "Oops! " + error.response.data.error_message;
              this.setState({
                errorMessage: [em],
              });
            }
          } else if (error.request) {
            const em = "Oops! Something went wrong!";
            this.setState({
              errorMessage: [em],
            });
          } else {
            /*console.log('Error', error.message);*/
          }
        });
    }
    this.setState({
      blocking: false,
    });
  };
  updateBtnOnClickHandler_emails = () => {
    this.setState({
      blocking: true,
    });
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    for (let row of this.state.rows) {
      if (!pattern.test(row)) {
        const em = "Error! Please Enter Valid Email Address.";
        this.setState({
          errorMessage: [em],
          blocking: false,
        });
        return false;
      }
    }

    if (this.state.rows.length >= 1) {
      const formData = {
        emails: this.state.rows,
      };
      console.log(this.props, "before");
      new BuggyApi()
        .updateUserInfo(this.props.fleet_info.fleet_id, formData)
        .then((response) => {
          this.setState({
            isSuccess: true,
            errorMessage: [],
            blocking: false,
          });

          if (response.status === 200) {
            console.log(this.props, "before");
            let rows = [];

            if (response.data.emails) {
              response.data.emails.forEach(function (email) {
                rows.push(email.email);
              }, this);
            }
            this.setState({
              rows: rows,
            });
          }
        })
        .catch((error) => {
          this.setState({ blocking: false });
          if (error.response) {
            if (error.response.data.whoami) {
              let errors = [];
              const errorList = error.response.data.whoami;
              for (let key in errorList) {
                let em = "";
                switch (key) {
                  case "email":
                    em = "Email: ";
                    break;
                  default:
                    em = "";
                    break;
                }
                em += errorList[key];
                errors.push(em);
              }
              this.setState({
                errorMessage: errors,
              });
            } else {
              /*console.log(error.response.data.error_message);*/
              const em = "Oops! " + error.response.data.error_message;
              this.setState({
                errorMessage: [em],
              });
            }
          } else if (error.request) {
            const em = "Oops! Something went wrong!";
            this.setState({
              errorMessage: [em],
            });
          } else {
            /*console.log('Error', error.message);*/
          }
        });
    }

    this.setState({
      blocking: false,
    });
  };
  fetchEmailDiv = (email) => {
    let counter = 1;
    counter =
      this.state.rows.length >= 1
        ? this.state.rows[this.state.rows.length - 1].key
        : 1;
    counter++;

    let message = (
      <div className="row" key={counter}>
        <div className="col-md-6">
          <div className="form-group form-focus focused">
            <label className="control-label">Email </label>
            <input
              type="text"
              ref={(el) => (this.emailInput = el)}
              value={email ? email.email : ""}
              className="form-control floating"
              index={counter}
              onChange={this.emailOnChangeHandler(counter)}
            />
          </div>
        </div>
        <div className="col-md-6">
          <button
            className="btn btn-danger btn-lg"
            type="button"
            onClick={this.deleteBtnHandler.bind(this)}
            index={counter}
          >
            Delete
          </button>
        </div>
      </div>
    );
    return message;
  };

  addMoreEmailsHandler = () => {
    let rows = [...this.state.rows];
    rows.push("");
    this.setState({
      rows: rows,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (
      this.props.fleet_info.fleet_title === undefined ||
      this.props.fleet_info.fleet_title === "" ||
      this.props.fleet_info.fleet_title === null
    ) {
      this.emailInput.value = nextProps.fleet_info.fleet_email;

      if (nextProps.fleet_info.emails.length !== this.state.rows.length) {
        let rows = [];

        if (nextProps.fleet_info.emails) {
          nextProps.fleet_info.emails.forEach(function (email) {
            rows.push(email.email);
          }, this);
        }
        this.setState({
          rows: rows,
        });
      }
    }

    // console.log(nextProps)
  }
  componentDidMount() {
    this.is_mounted = true;
    if (this.props.fleet_info.fleet_email !== undefined) {
      this.emailInput.value = this.props.fleet_info.fleet_email;
      let rows = [];

      if (this.props.fleet_info.emails) {
        this.props.fleet_info.emails.forEach(function (email) {
          rows.push(email.email);
        }, this);
      }
      this.setState({
        rows: rows,
      });
    } else {
      this.emailInput.value = "";
    }
    window.$ = window.jQuery = $;

    (function ($) {
      var Sidemenu = function () {
        this.$menuItem = $("#sidebar-menu a");
      };

      Sidemenu.prototype.init = function () {
        var $this = this;
        $this.$menuItem.on("click", function (e) {
          if ($(this).parent().hasClass("submenu")) {
            e.preventDefault();
          }
          if (!$(this).hasClass("subdrop")) {
            $("ul", $(this).parents("ul:first")).slideUp(350);
            $("a", $(this).parents("ul:first")).removeClass("subdrop");
            $(this).next("ul").slideDown(350);
            $(this).addClass("subdrop");
          } else if ($(this).hasClass("subdrop")) {
            $(this).removeClass("subdrop");
            $(this).next("ul").slideUp(350);
          }
        });
        $("#sidebar-menu ul li.submenu a.active")
          .parents("li:last")
          .children("a:first")
          .addClass("active")
          .trigger("click");
      };
      $.Sidemenu = new Sidemenu();
    })(window.jQuery);

    $(document).ready(function () {
      // Sidebar overlay

      var $sidebarOverlay = $(".sidebar-overlay");
      $("#mobile_btn, .task-chat").on("click", function (e) {
        var $target = $($(this).attr("href"));
        if ($target.length) {
          $target.toggleClass("opened");
          $sidebarOverlay.toggleClass("opened");
          $("html").toggleClass("menu-opened");
          $sidebarOverlay.attr("data-reff", $(this).attr("href"));
        }
        e.preventDefault();
      });

      $sidebarOverlay.on("click", function (e) {
        var $target = $($(this).attr("data-reff"));
        if ($target.length) {
          $target.removeClass("opened");
          $("html").removeClass("menu-opened");
          $(this).removeClass("opened");
          $(".main-wrapper").removeClass("slide-nav-toggle");
        }
        e.preventDefault();
      });

      // Sidebar Initiate

      $.Sidemenu.init();

      // Select2

      // Modal Popup hide show

      if ($(".modal").length > 0) {
        var modalUniqueClass = ".modal";
        $(".modal").on("show.bs.modal", function (e) {
          var $element = $(this);
          var $uniques = $(modalUniqueClass + ":visible").not($(this));
          if ($uniques.length) {
            $uniques.modal("hide");
            $uniques.one("hidden.bs.modal", function (e) {
              $element.modal("show");
            });
            return false;
          }
        });
      }

      // Floating Label

      if ($(".floating").length > 0) {
        $(".floating")
          .on("focus blur", function (e) {
            $(this)
              .parents(".form-focus")
              .toggleClass(
                "focused",
                e.type === "focus" || this.value.length > 0
              );
          })
          .trigger("blur");
      }

      if ($(".page-wrapper").length > 0) {
        var height = $(window).height();
        $(".page-wrapper").css("min-height", height);
      }

      $(window).resize(function () {
        if ($(".page-wrapper").length > 0) {
          var height = $(window).height();
          $(".page-wrapper").css("min-height", height);
        }
      });

      // Button Switch

      if ($(".btn-toggle").length > 0) {
        $(".btn-toggle").click(function () {
          $(this).find(".btn").toggleClass("active");
          if ($(this).find(".btn-success").size() > 0) {
            $(this).find(".btn").toggleClass("btn-success");
          }
        });
      }

      // Mobile Menu

      if ($(".main-wrapper").length > 0) {
        var $wrapper = $(".main-wrapper");
        $(document).on("click", "#mobile_btn", function (e) {
          $(".dropdown.open > .dropdown-toggle").dropdown("toggle");
          return false;
        });
        $(document).on("click", "#mobile_btn", function (e) {
          $wrapper.toggleClass("slide-nav-toggle");
          $("#chat_sidebar").removeClass("opened");
          return false;
        });
        $(document).on("click", "#open_msg_box", function (e) {
          $wrapper.toggleClass("open-msg-box").removeClass("");
          $(".dropdown").removeClass("open");
          return false;
        });
      }

      // Message box remove

      if ($(".dropdown-toggle").length > 0) {
        $(".dropdown-toggle").click(function () {
          if ($(".main-wrapper").hasClass("open-msg-box")) {
            $(".main-wrapper").removeClass("open-msg-box");
          }
        });
      }

      // Table Dropdown

      $(".table-responsive").on("shown.bs.dropdown", function (e) {
        var $table = $(this),
          $dropmenu = $(e.target).find(".dropdown-menu"),
          tableOffsetHeight = $table.offset().top + $table.height(),
          menuOffsetHeight =
            $dropmenu.offset().top + $dropmenu.outerHeight(true);

        if (menuOffsetHeight > tableOffsetHeight)
          $table.css("padding-bottom", menuOffsetHeight - tableOffsetHeight);
      });
      $(".table-responsive").on("hide.bs.dropdown", function () {
        $(this).css("padding-bottom", 0);
      });

      // Custom Modal Backdrop

      $('a[data-toggle="modal"]').on("click", function () {
        setTimeout(function () {
          if ($(".modal.custom-modal").hasClass("in")) {
            $(".modal-backdrop").addClass("custom-backdrop");
          }
        }, 500);
      });

      // Email Inbox

      if ($(".clickable-row").length > 0) {
        $(".clickable-row").click(function () {
          window.location = $(this).data("href");
        });
      }

      if ($(".checkbox-all").length > 0) {
        $(".checkbox-all").click(function () {
          $(".checkmail").click();
        });
      }
      if ($(".checkmail").length > 0) {
        $(".checkmail").each(function () {
          $(this).click(function () {
            if ($(this).closest("tr").hasClass("checked")) {
              $(this).closest("tr").removeClass("checked");
            } else {
              $(this).closest("tr").addClass("checked");
            }
          });
        });
      }
      if ($(".mail-important").length > 0) {
        $(".mail-important").click(function () {
          $(this).find("i.fa").toggleClass("fa-star");
          $(this).find("i.fa").toggleClass("fa-star-o");
        });
      }

      // Incoming call popup

      if ($("#incoming_call").length > 0) {
        $(window).on("load", function () {
          $("#incoming_call").modal("show");
          $("body").addClass("call-modal");
        });
      }
    });
  }
  render() {
    return (
      <div
        className={
          this.props.sidebar_status
            ? "page-wrapper slide-nav-toggle"
            : "page-wrapper"
        }
      >
        <div className="content container-fluid">
          <div className="row">
            <div className="col-sm-8">
              <h4 className="page-title">Edit Profile</h4>
            </div>
          </div>
          <form>
            {this.state.isSuccess ? (
              <div className="row">
                <div className="col-sm-6 col-sm-offset-3">
                  <div className="alert alert-success">
                    <strong>Success!</strong> Email updated successfully!
                  </div>
                </div>
              </div>
            ) : null}
            {this.state.errorMessage.length > 0 ? (
              <div className="row">
                <div className="col-sm-6 col-sm-offset-3">
                  <div className="alert alert-danger">
                    <ul>
                      {this.state.errorMessage.map((message, i) => (
                        <li key={i}>{message}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ) : null}
            <BlockUi tag="div" blocking={this.state.blocking}>
              <div className="card-box">
                <h3 className="card-title">Basic Informations</h3>
                <div className="row">
                  <div className="col-md-6">
                    <div
                      className={
                        this.props.fleet_info.fleet_email !== undefined
                          ? "form-group form-focus focused"
                          : "form-group form-focus"
                      }
                    >
                      <label className="control-label">Email</label>
                      <input
                        ref={(el) => (this.emailInput = el)}
                        type="text"
                        className="form-control floating"
                        onChange={this.emailOnChangeHandler}
                      />
                    </div>
                  </div>
                  <div className="col-md-6"></div>
                </div>
              </div>

              <div className="text-center m-t-20">
                <button
                  className="btn btn-primary btn-lg"
                  type="button"
                  onClick={this.updateBtnOnClickHandler}
                >
                  Save &amp; update
                </button>
              </div>
            </BlockUi>
            <br></br>

            <BlockUi tag="div" blocking={this.state.blocking}>
              <div className="card-box">
                <h3 className="card-title">Optional Emails</h3>

                {this.state.rows.map((x, i) => (
                  <div className="row" key={i}>
                    <div className="col-md-6">
                      <div className="form-group form-focus focused">
                        <label className="control-label">Email </label>
                        <input
                          type="text"
                          value={x}
                          className="form-control floating"
                          index={i}
                          onChange={this.emailOnChangeHandler(i)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <button
                        className="btn btn-danger btn-lg"
                        type="button"
                        onClick={this.deleteBtnHandler(i).bind(this)}
                        index={i}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                ))}
              </div>

              <div className="text-center m-t-20">
                <button
                  className="btn btn-primary btn-lg mr-2"
                  style={{ marginRight: "10px" }}
                  type="button"
                  onClick={this.addMoreEmailsHandler}
                >
                  Add More
                </button>
                <button
                  className="btn btn-primary btn-lg"
                  type="button"
                  onClick={this.updateBtnOnClickHandler_emails}
                >
                  Save &amp; update
                </button>
              </div>
            </BlockUi>
          </form>
        </div>
      </div>
    );
  }
}
const mapStatesToProps = (state) => {
  return {
    componentIndex: state.componentIndex,
    sidebar_status: state.sidebarOpened,
    fleet_info: state.userInfo,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onLinkChange: (i) => dispatch({ type: actionTypes.UPDATE_NAVIGATION, i }),
    getComponentIndex: (path) =>
      dispatch({ type: actionTypes.GET_COMPONENT_INDEX, path }),
    updateUserInfo: (user_info) =>
      dispatch({ type: actionTypes.UPDATE_USER_DETAILS, userInfo: user_info }),
  };
};
export default connect(mapStatesToProps, mapDispatchToProps)(MyProfile);
