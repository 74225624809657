import { Box, CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useParams } from 'react-router-dom';

import { getInvitation } from '@/services/fleet';

import AcceptCard from './AcceptCard';
import PageWrapper from './PageWrapper';

export default function AcceptInvitation() {
  const { code } = useParams<{ code: string }>();

  const { data, isLoading } = useQuery({
    queryKey: [code],
    queryFn: () => getInvitation(code),
  });

  return (
    <PageWrapper>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        {isLoading
          ? (<CircularProgress />)
          : data && <AcceptCard invitation={data.data} />}
      </Box>
    </PageWrapper>
  );
}
