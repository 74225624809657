import { Box } from '@mui/material';
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector } from '@mui/x-data-grid';
import React from 'react';

import GridSaveChangesButton from '../../GridSaveChangesButton';
import RefreshToolbarButton from '../../RefreshToolbarButton';
import { DownloadCSV, DownloadCSVProps } from '../../Tickets/components/DownloadCSV';

type Props = {
  downloadCSVProps: DownloadCSVProps,
  refetch: () => void;
  onSave: () => Promise<void>;
  canSave: boolean;
};

function ParkingTicketsTableToolbar({
  downloadCSVProps, refetch, onSave, canSave,
}: Props) {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <RefreshToolbarButton onRefresh={refetch} />
      <DownloadCSV {...downloadCSVProps} />
      <Box flexGrow={1} />
      <GridSaveChangesButton
        onSave={onSave}
        canSave={canSave}
        hidden={!canSave}
      />
    </GridToolbarContainer>
  );
}

export default ParkingTicketsTableToolbar;
