import './Captcha.css';

import React, { Component } from 'react';
import Recaptcha from 'react-recaptcha';

class Captcha extends Component {
  callback = () => {
    /* console.log('Done!!!!'); */
  };

  reset = () => {
    this.captcaInstance.reset();
  };

  render() {
    return (
      <div className="captcha-center">
        <Recaptcha
          ref={(el) => this.captcaInstance = el}
          sitekey={process.env.REACT_APP_CAPTCHA_SITEKEY}
          render="explicit"
          verifyCallback={this.props.successCallback}
          onloadCallback={this.callback}
        />
      </div>
    );
  }
}

export default Captcha;
