import { Box, CircularProgress } from '@mui/material';
import React from 'react';

type PageLoadingProps = {
  children:React.ReactNode,
  isPageLoading:boolean
};

function PageLoading({ children, isPageLoading }:PageLoadingProps) {
  if (!isPageLoading) {
    return children;
  }
  return (
    <Box>
      <Box style={{
        position: 'absolute', width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 999, background: 'white', opacity: 0.7, flexDirection: 'column',
      }}
      >
        <Box>
          <CircularProgress color="primary" />
        </Box>
        <Box style={{ color: 'black', fontWeight: 700 }}>
          Loading...Please wait...!
        </Box>
      </Box>
      {children}
    </Box>
  );
}

export default PageLoading;
