import {
  Chip, Link, Tooltip,
} from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams, GridTreeNodeWithRender,
} from '@mui/x-data-grid-pro';
import React from 'react';
import { HiExternalLink } from 'react-icons/hi';
import { MdDone } from 'react-icons/md';

import { DocumentResponse } from '@/services/types';

const booleanFilterOperators: GridColDef<DocumentResponse>['filterOperators'] = [
  {
    label: 'Is checked',
    value: 'checked',
    getApplyFilterFn: (item) => ({ row }) => !!row[item.field as keyof DocumentResponse],
  },
  {
    label: 'Is not checked',
    value: 'notchecked',
    getApplyFilterFn: (item) => ({ row }) => !row[item.field as keyof DocumentResponse],
  },
];

function generateDocumentsTableColumns() {
  return [
    {
      field: 'title',
      headerName: 'Title',
      minWidth: 350,
      flex: 1,
    },
    {
      field: 'user',
      headerName: 'Owner',
      align: 'left',
      headerAlign: 'left',
      valueGetter: ({ value }:any) => value.username,
      renderCell: ({ value, row }:any) => (
        <Tooltip title={row.user.email}>
          <Chip label={value} size="small" />
        </Tooltip>
      ),
    },
    {
      field: 'processed',
      headerName: 'Received',
      width: 90,
      sortable: false,
      align: 'center',
      renderCell: ({ row }) => (
        row.processed && <MdDone />
      ),
      filterOperators: booleanFilterOperators,
    },
    {
      field: 'approved',
      headerName: 'Processed',
      width: 90,
      sortable: false,
      align: 'center',
      renderCell: ({ row }) => (
        row.approved && <MdDone />
      ),
      filterOperators: booleanFilterOperators,
    },
    {
      field: 'file',
      headerName: 'File',
      width: 90,
      sortable: false,
      renderCell: (
        { row }: GridRenderCellParams<DocumentResponse, any, any, GridTreeNodeWithRender>,
      ) => (
        <Link href={row.file} target="_blank">
          <HiExternalLink size={25} />
        </Link>
      ),
    },
  ] satisfies GridColDef<DocumentResponse>[];
}

export default generateDocumentsTableColumns;
