import {
  Avatar,
} from '@mui/material';
import Pusher from 'pusher-js';
import React, { Component } from 'react';
import { MdManageAccounts } from 'react-icons/md';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import BuggyApi from '../../services/BuggyApi';
import * as actionTypes from '../../store/actions/actions';
import FleetSwitch from '../FleetSwitch';
import Aux from '../hoc/Auxiliary';

// Enable pusher logging - don't include this in production
Pusher.logToConsole = false;

let pusher = null;

class Topbar extends Component {
  state = {
    userDataLoaded: false,
    notifications: [],
  };

  componentDidUpdate() {}

  addNotification = (message) => {
    const notifications = [...this.state.notifications];
    notifications.push(message);
    this.setState({
      notifications,
    });
  };

  openNotificationBox = (event) => {
    if (this.notificationBox.classList.contains('open')) {
      this.notificationBox.classList.remove('open');
    } else {
      this.notificationBox.classList.add('open');
    }
  };

  componentWillMount() {
    if (this.props.loggedIn) {
      new BuggyApi()
        .getUserInfo()
        .then((response) => {
          if (response.status === 200) {
            this.props.onUserInfoUpdate(response.data);
          }
        })
        .catch((error) => {
        });

      new BuggyApi()
        .getPusherCredentials()
        .then((response) => {
          if (response.status === 200) {
            const pusherInfo = {
              key: response.data.pusher_key,
              cluster: response.data.pusher_cluster,
            };
            this.props.onPusherInfoUpdate(pusherInfo);

            pusher = new Pusher(pusherInfo.key, {
              cluster: pusherInfo.cluster,
              encrypted: true,
            });

            const channel = pusher.subscribe('fleet-it');
            channel.bind(
              `order-event${this.props.fleet_info.fleet_id}`,
              (data) => {
                this.addNotification(data.message);
              },
            );
            channel.bind(
              `vehicle-event${this.props.fleet_info.fleet_id}`,
              (data) => {
                this.addNotification(data.message);
              },
            );
          }
        })
        .catch((errors) => {});
    }
  }

  mobileMenuOnClickHandler = (e) => {
    e.preventDefault();
    this.props.onSidebarOpen(this.props.sidebar_status);
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.pusherInfo.key != null
      && nextProps.pusherInfo.cluster != null
    ) {
      pusher = new Pusher(nextProps.pusherInfo.key, {
        cluster: nextProps.pusherInfo.cluster,
        encrypted: true,
      });

      const channel = pusher.subscribe('fleet-it');
      channel.bind(`order-event-${nextProps.fleet_info.fleet_id}`, (data) => {
        this.addNotification(data.message);
      });
      channel.bind(`vehicle-event-${nextProps.fleet_info.fleet_id}`, (data) => {
        this.addNotification(data.message);
      });
    }
  }

  render() {
    const {
      notifications,
    } = this.state;

    if (this.props.sidebar_status) {
      document.getElementById('docRootTag').classList.add('menu-opened');
    } else {
      document.getElementById('docRootTag').classList.remove('menu-opened');
    }

    return this.props.loggedIn ? (
      <Aux>
        <div className="header">
          <div className="header-left">
            <FleetSwitch />
          </div>

          <div className="page-title-box pull-left">
            <h3>FleetIT</h3>
          </div>
          <a
            href
            id="mobile_btn"
            className="mobile_btn pull-left"
            onClick={this.mobileMenuOnClickHandler}
          >
            <i className="fa fa-bars" aria-hidden="true" />
          </a>
          <ul className="nav navbar-nav navbar-right user-menu pull-right">
            <li
              className="dropdown hidden-xs"
              ref={(el) => (this.notificationBox = el)}
              onClick={this.openNotificationBox}
            >
              <a href className="dropdown-toggle" id="notification-bell">
                <i className="fa fa-bell-o" />
                {notifications.length > 0 ? (
                  <span className="badge bg-purple pull-right">
                    {notifications.length}
                  </span>
                ) : null}
              </a>
              <div className="dropdown-menu notifications">
                <div className="topnav-dropdown-header">
                  <span>Notifications</span>
                </div>
                <div className="drop-scroll">
                  <ul className="media-list">
                    {notifications.map((data, i) => (
                      <li className="media notification-message" key={i}>
                        <Link to="billing">
                          <div className="media-left">
                            <span className="avatar">$</span>
                          </div>
                          <div className="media-body">
                            <p className="m-0 noti-details">{data}</p>
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="topnav-dropdown-footer" />
              </div>
            </li>
            <li>
              <a
                href="profile.html"
                className="dropdown-toggle user-link"
                data-toggle="dropdown"
                title="Profile"
                id="account-settings"
              >
                <div style={{ paddingTop: '8px' }}>
                  <MdManageAccounts size={27} color="whitesmoke" />
                </div>
              </a>

              <ul className="dropdown-menu">
                <li>
                  <div style={{ display: 'flex', alignItems: 'center', padding: '10px 20px' }}>
                    <Avatar sx={{ width: '20px', height: '20px' }} />
                    {' '}
                    <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>
                      {this.props.fleet_info.fleet_title}
                    </span>
                  </div>
                </li>
                <li>
                  <Link to="/my-profile">My Profile</Link>
                </li>
                <li>
                  <Link
                    to="/"
                    onClick={(event) => {
                      this.props.onLogout();
                      this.setState({ userDataLoaded: false });
                    }}
                  >
                    Logout
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
          <div className="dropdown mobile-user-menu pull-right">
            <a
              href
              className="dropdown-toggle"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v" />
            </a>
            <ul className="dropdown-menu pull-right">
              <li id="my-profile">
                <Link to="/my-profile">My Profile</Link>
              </li>
              <li>
                <Link
                  to="/"
                  onClick={(event) => {
                    this.props.onLogout();
                    this.setState({ userDataLoaded: false });
                  }}
                >
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </Aux>
    ) : null;
  }
}

const mapStatesToProps = (state) => ({
  loggedIn: state.isLoggedIn,
  sidebar_status: state.sidebarOpened,
  fleet_info: state.userInfo,
  pusherInfo: state.pusherInfo,
});
const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch({ type: actionTypes.LOGOUT }),
  onSidebarOpen: (value) => dispatch({ type: actionTypes.SIDEBAR_OPENED, toggle: !value }),
  onUserInfoUpdate: (userInfo) => dispatch({ type: actionTypes.UPDATE_USER_DETAILS, userInfo }),
  onPusherInfoUpdate: (pusherInfo) => dispatch({
    type: actionTypes.UPDATE_PUSHER_CREDENTIALS,
    pusher_details: pusherInfo,
  }),
});

export default connect(mapStatesToProps, mapDispatchToProps)(Topbar);
