import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import * as actionTypes from './store/actions/actions';
import { setUnauthorizedHandler } from "./services/axios";

const SetUpAxiosInterceptors = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleUnauthorized = useCallback(() => {
    dispatch({ type: actionTypes.LOGOUT });
    history.push('/login');
  }, [history, dispatch]);

  useEffect(() => {
    setUnauthorizedHandler(handleUnauthorized);
  }, [handleUnauthorized]);

  return null;
}

export default SetUpAxiosInterceptors;