import React, { Component } from 'react';
import { connect } from 'react-redux';

import PageLoading from '../PageLoading/PageLoading';

class MainWrapper extends Component {
  render() {
    return (
      <PageLoading isPageLoading={this.props.isPageLoading}>
        <div className={(this.props.sidebar_status) ? 'main-wrapper slide-nav-toggle' : 'main-wrapper'}>
          {this.props.children}
        </div>
      </PageLoading>
    );
  }
}
const mapStatesToProps = (state) => ({
  sidebar_status: state.sidebarOpened,
  isPageLoading: state.isPageLoading,

});
export default connect(mapStatesToProps, null)(MainWrapper);
