import { Box, Divider } from '@mui/material';
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector } from '@mui/x-data-grid';
import { isAxiosError } from 'axios';
import { isEmpty } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback } from 'react';

import GridSaveChangesButton from '@/components/GridSaveChangesButton';
import { updateVehicle } from '@/services/vehicles';

import RefreshToolbarButton from '../../RefreshToolbarButton';
import AddVehicle from './AddVehicle';
import DownloadVehiclesButton from './DownloadVehiclesButton';
import { useVehiclesTableContext } from './vehiclesTableContext';

export function VehiclesTableToolbar() {
  const {
    refetch,
    search,
    showLessee,
    editedRows,
    dispatchRowEdit,
  } = useVehiclesTableContext();

  const canSave = !isEmpty(editedRows);

  const onSaveEdits = useCallback(async () => {
    if (!isEmpty(editedRows)) {
      try {
        const updatePromises = editedRows?.map(
          ({ id, custom_fields }) => updateVehicle(id, { custom_fields }),
        ) || [];
        await Promise.allSettled(updatePromises);
        dispatchRowEdit({ type: 'RESET' });

        enqueueSnackbar({ variant: 'success', message: 'Your changes have been saved.' });
      } catch (e) {
        if (isAxiosError(e)) {
          enqueueSnackbar({ variant: 'error', message: e.message });
        }
      }
    }
  }, [dispatchRowEdit, editedRows]);

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <Divider
        orientation="vertical"
        flexItem
        sx={{ my: 1 }}
      />
      <AddVehicle refresh={refetch} showLessee={showLessee} />
      <DownloadVehiclesButton search={search} />
      <Divider
        orientation="vertical"
        flexItem
        sx={{ my: 1 }}
      />
      <RefreshToolbarButton onRefresh={refetch} />
      <Box flexGrow={1} />
      <GridSaveChangesButton
        onSave={onSaveEdits}
        canSave={canSave}
        hidden={!canSave}
      />
    </GridToolbarContainer>
  );
}

export default VehiclesTableToolbar;
