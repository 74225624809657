import {
  Autocomplete, AutocompleteProps, TextField,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';

type VehicleAutocompleteFieldProps<
  TOption,
> = Omit<AutocompleteProps<TOption, false, false, false>, 'renderInput' | 'options'> & {
  errorMessage?: string,
  fetchOptions: (input?: string) => Promise<TOption[]>,
  label?: string,
  /**
   * Used for caching the results of the fetch options function
   */
  queryKey: string[],
};

/**
 * Autocomplete field that fetches values from the fetch function provided
 */
export default function FetchingAutocompleteField<TOption extends { id: number }>({
  errorMessage = undefined,
  label = undefined,
  fetchOptions,
  queryKey,
  ...props
}: VehicleAutocompleteFieldProps<TOption>) {
  const [input, setInput] = useState<string | undefined>();

  const { data, isFetching } = useQuery({
    queryKey: [...queryKey, input],
    queryFn: () => fetchOptions(input),
  });

  const onInputChange = useCallback((event: React.SyntheticEvent, newInput: string) => {
    setInput(newInput);
  }, []);

  return (
    <Autocomplete
      options={data || []}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          fullWidth
          helperText={errorMessage}
          error={Boolean(errorMessage)}
        />
      )}
      onInputChange={debounce(onInputChange, 500)}
      filterOptions={(values) => values}
      loading={isFetching}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      {...props}
    />
  );
}
