import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actionTypes from '../../store/actions/actions';
import TranspondersTable from './TransponderTable';

function Transponders() {
  const dispatch = useDispatch();
  const componentIndex = useSelector((state:any) => state.componentIndex);

  useEffect(() => {
    dispatch({ type: actionTypes.GET_COMPONENT_INDEX, payload: window.location.pathname });
    dispatch({ type: actionTypes.UPDATE_NAVIGATION, payload: componentIndex });
  }, [componentIndex, dispatch]);

  return (
    <div className="page-wrapper slide-nav-toggle">
      <div className="content container-fluid">
        <Typography>
          Transponders
        </Typography>
        <TranspondersTable />
      </div>
    </div>
  );
}

export default Transponders;
