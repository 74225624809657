import { Dialog } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import clsx from 'clsx';
import { isObject } from 'lodash';
import React, { useCallback, useState } from 'react';
import ReactSwitch from 'react-switch';

import { Modal } from '../../Modal';
import { Logs } from '../Logs';
import styles from './AppIntegrations.module.css';
import { setIntegrationEnable } from './data';
import IntegrationForm from './IntegrationForm';
import { App } from './types';

type AppCardProps = {
  app: App;
  refreshIntegrations: () => void
};

function AppCard({ app, refreshIntegrations }: AppCardProps) {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isLogsOpen, setIsLogsOpen] = useState(false);
  const [isEnabled, setIsEnable] = useState(!!app.userSettings?.enabled);

  const { mutateAsync } = useMutation({
    mutationKey: ['integrations', app.id],
    mutationFn: (enableIntegration: boolean) => {
      if (!app.userSettings) {
        throw Error('No user settings');
      }
      return setIntegrationEnable(app, enableIntegration);
    },
  });

  const onChangeIsEnabled = useCallback((enabled: boolean) => {
    setIsEnable(enabled);
    mutateAsync(enabled)
      .catch(() => setIsEnable(!enabled));
  }, [mutateAsync]);

  const onClickOpenForm = useCallback(() => {
    setIsFormOpen(true);
  }, []);

  const onCloseFormModal = useCallback(() => {
    setIsFormOpen(false);
  }, []);

  const onClickOpenLogs = useCallback(() => {
    setIsLogsOpen(true);
  }, []);

  const onCloseLogs = useCallback(() => {
    setIsLogsOpen(false);
  }, []);

  const onSuccessIntegration = useCallback(() => {
    onCloseFormModal();
    refreshIntegrations();
  }, [onCloseFormModal, refreshIntegrations]);

  return (
    <>
      <div className={styles.card}>
        <div className={styles.card_overlay}>
          <button
            className={styles.card_action}
            onClick={onClickOpenForm}
            type="button"
          >
            <i className="fa fa-pencil" />
          </button>
          <button
            className={styles.card_action}
            onClick={onClickOpenLogs}
            type="button"
          >
            <i className="fa fa-list" />
          </button>
          {isObject(app.userSettings) && (
          <ReactSwitch
            onChange={onChangeIsEnabled}
            checked={isEnabled}
            type="button"
          />
          )}
        </div>
        <div className={styles.card_image}>
          <img src={app.logo} alt={app.name} />
        </div>
        <div className={styles.title_container}>
          <h3 className="m-b-0">{app.title}</h3>
          {isEnabled && (
            <i
              className={clsx(
                'fa fa-check confirmation',
                styles.active_indicator,
              )}
            />
          )}
        </div>
      </div>
      {/* Form Modal */}
      <Dialog open={isFormOpen} fullWidth>
        <IntegrationForm
          app={app}
          onClose={onCloseFormModal}
          onSuccess={onSuccessIntegration}
        />
      </Dialog>
      {/* Logs Modal */}
      <Modal
        isOpen={isLogsOpen}
        onRequestClose={onCloseLogs}
        maxHeight="90vh"
        padding={0}
      >
        <Logs app={app} onClose={onCloseLogs} />
      </Modal>
    </>
  );
}

export default AppCard;
