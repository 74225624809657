import BuggyApi from '../../services/BuggyApi';

/**
 * Checks the appIntegration against the active integrations and returns the matching active integration
 * @param {Object} appIntegration
 * @param {Array} activeIntegrations
 * @returns {Object | undefined}
 */
const getActiveSettings = (appIntegration, activeIntegrations) => activeIntegrations.find(
  ({ integration_schema }) => integration_schema === appIntegration.resource_uri,
);

/**
 * Fetch and preprocess the integrations
 * @returns {Array}
 */
export const getIntegrations = async () => {
  const api = new BuggyApi();

  let { data: integrationApps } = await api.getIntegrations();

  integrationApps = integrationApps.objects.map((integration) => ({
    ...integration,
    fields: JSON.parse(
      integration.fields.replaceAll("'", '"').replaceAll('False', 'false'),
    ),
  }));

  let { data: activeUserIntegrationSettings } = await api.getCustomerActiveIntegrations();

  activeUserIntegrationSettings = activeUserIntegrationSettings.objects.map(
    (integration) => ({
      ...integration,
      data: JSON.parse(integration.data.replaceAll("'", '"')),
    }),
  );

  return integrationApps.map((app) => ({
    ...app,
    userSettings: getActiveSettings(app, activeUserIntegrationSettings),
  }));
};

export const getIntegration = async (integrationId) => {
  const api = new BuggyApi();

  const { data } = await api.getIntegration(integrationId);

  const fields = JSON.parse(data?.fields.replaceAll("'", '"') || '{}');

  return { ...data, fields };
};
