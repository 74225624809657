export const productTourSteps=[
    {
      selector: ".header-left",
      content: "Easily switch between your fleets from here if you have multiple fleets!",
      highlightedSelectors:["#fleet-select-dropdown-open"],
      mutationObservables: ["#fleet-select-dropdown"]
    },
    {
      selector: "#notification-bell",
      content: "Stay up to date with notifications"
    },
    {   
      selector: "#account-settings",
      content: "Manage your account settings here"
    },
    {
      selector: "#Dashboard",
      content: "Keep track of your fleet data analytics in the dashboard",
      highlightedSelectors:["#dashboard-layout"],
      mutationObservables: ["#dashboard-layout"]
    },
    {
      selector: "#Toll-Transactions",
      content: "You can view all your Tolls generated",
      highlightedSelectors:["#tickets-layout"],
      mutationObservables: ["#tickets-layout"]
    },
    {
      selector: "#Vehicles",
      content: "Here you can manage all your vehicles, you can also Add/Remove Vehicles",
      highlightedSelectors:["#vehicles-layout"],
      mutationObservables: ["#vehicles-layout"]

    },
    {
      selector: "#Billing",
      content: "Here you can manage all your Invoices and Billing related info",
    },
    {
      selector: "#Integration",
      content: "Here you can easily integrate with Fleetio, Rent Centric or any other webhooks",
      highlightedSelectors:["#integration-layout"],
      mutationObservables: ["#integration-layout"]
    },
    {
      selector: "#Settings",
      content: "Here you can manage general settings like Email frequency",
    },
  ]