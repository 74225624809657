import secureLocalStorage from "react-secure-storage";

export const fetchDefaultFleetId = () => {
  const fleetId = secureLocalStorage.getItem('defaultFleetId');
  return fleetId;
};

export const setDefaultFleetId = (fleetId) => {
  secureLocalStorage.setItem('defaultFleetId', fleetId);
};
