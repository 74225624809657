import React, {Component} from 'react';
import BuggyApi from '../../services/BuggyApi';
import './modal.css';


class DeleteDriver extends Component{
    state={
        isShown: false,
        isSuccess: false,
        errorMessage: [],
        botLoginId: 0,

    };

    onModalShow=(id)=>{
        document.body.classList.add('modal-open');
        this.setState({
            isShown:true,
            botLoginId: id
        });
    };

    onModalClose = ()=> {
        document.body.classList.remove('modal-open');
        this.setState({
            isShown: false,
            isSuccess: false,
            botLoginId: 0,
        });
    };

    deleteLiabilityBotLoginClickHandler = (event) => {
        event.preventDefault();
        new BuggyApi().deleteBotLogin(this.state.botLoginId).then((response)=>{
            if(response.status===204){
                this.setState({
                    isSuccess: true,
                    errorMessage: [],
                    botLoginId: 0
                });

                this.props.refresh();
            }
        }).catch((error)=>{
            if (error.response) {
                if(error.response.data){
                    if(error.response.data['liability-bot']){
                        let errors =[];
                        const errorList=error.response.data['liability-bot'];
                        for (let key in errorList) {
                            let em="";
                            switch(key){
                                case 'detail':
                                    em = "Details: ";
                                    break;
                                case 'username':
                                    em = "Username: ";
                                    break;
                                default:
                                    em = "";
                                    break;
                            }
                            em +=errorList[key];
                            errors.push(em);
                        }
                        this.setState({
                            errorMessage: errors
                        });
                    }else{
                        const em = "Oops, An error occurred";
                        this.setState({
                            errorMessage: [em]
                        });
                    }

                }else{
                    const em = "Oops, An error occurred";
                    this.setState({
                        errorMessage: [em]
                    });
                }
            } else if (error.request) {
                const em = "Oops! Something went wrong!";
                this.setState({
                    errorMessage: [em]
                });
            } else {
                /*console.log('Error', error.message);*/
            }
        });
    };

    render(){
        return (
            <div className={(this.state.isShown)? "modal custom-modal fade in showModal": "modal custom-modal fade hideModal"}
                 role="dialog" >
                <div className="modal-dialog">
                    <div className="modal-content modal-md">
                        <div className="modal-header">
                            <h4 className="modal-title">Delete Bot Login</h4>
                        </div>
                        {(this.state.isSuccess)?
                            <div className="row">
                                <div className="col-sm-6 col-sm-offset-3">
                                    <div className="alert alert-success">
                                        <strong>Success!</strong> Bot Login deleted successfully!
                                    </div>
                                </div>
                            </div>:null
                        }
                        {(this.state.errorMessage.length>0)?
                            <div className="row">
                                <div className="col-sm-6 col-sm-offset-3">
                                    <div className="alert alert-danger">
                                        <ul>
                                            {this.state.errorMessage.map((message,i) =><li key={i}>{message}</li>)}
                                        </ul>
                                    </div>
                                </div>
                            </div>:null
                        }
                        <form>
                            <div className="modal-body card-box">
                                <p>Are you sure want to delete this liability bot login?</p>
                                <div className="m-t-20">
                                    <button className="btn btn-default" onClick={this.onModalClose}>Close</button>
                                    &nbsp;&nbsp;&nbsp;
                                    {(this.state.isSuccess)?
                                        null:<button type="submit" onClick={this.deleteLiabilityBotLoginClickHandler}
                                                className="btn btn-danger">Delete</button>}

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default DeleteDriver;