import React, {Component} from 'react';
import './modal.css';

class VehicleMessages extends Component{
    state={
        isShown: false,
    };
    onModalShow=()=>{
        document.body.classList.add('modal-open');
        this.setState({
            isShown:true
        });
    };

    onModalClose = ()=> {
        document.body.classList.remove('modal-open');
        this.setState({
            isShown: false
        });
    };

    render(){
        return (
            <div className={(this.state.isShown)? "modal custom-modal fade in showModal": "modal custom-modal fade hideModal"}
                 role="dialog" >
                <div className="modal-dialog">
                    <button type="button" className="close" data-dismiss="modal" onClick={this.onModalClose}>&times;</button>
                    <div className="modal-content modal-lg">
                        <div className="modal-header text-center">
                            <h4 className="modal-title">Vehicle Messages</h4>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="activity">
                                        <div className="activity-box">
                                            <ul className="activity-list">
                                                {this.props.vehicleMessage.map((message,i) =>{
                                                    return (
                                                        <li key={i}>
                                                            <div className="activity-user">
                                                                <a href  className="avatar "
                                                                   title="" data-toggle="tooltip"
                                                                   style={{backgroundColor: "#ff6d00"}}>{i+1}</a>
                                                            </div>
                                                            <div className="activity-content">
                                                                <div className="timeline-content">
                                                                    <p style={
                                                                        {
                                                                            paddingTop:"5px",
                                                                            paddingBottom: "5px",
                                                                            margin:"5px 0px 5px 0px",
                                                                        }
                                                                    }>
                                                                        {message}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    );
                                                })}

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default VehicleMessages;