import { GridPaginationModel } from '@mui/x-data-grid';
import { deepClone } from '@mui/x-data-grid/utils/utils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isEmpty, uniqBy } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React, {
  useEffect, useReducer, useState,
} from 'react';
import { useSelector } from 'react-redux';

import { EditAction } from '@/types/EditAction';

import { api } from '../../../services/BuggyApi';
import { getParkingTickets, partialUpdateParkingTicketList, updateParkingTicketBillingStatus } from '../../../services/tickets';
import { APIListResponse, ParkingTicket } from '../../../types/api';
import HighlightableDataGrid from '../../HighlightableDataGrid';
import { downloadParkingTicketsCSV } from './helpers';
import createParkingTableColumns from './parkingTicketsTableColumns';
import ParkingTicketsTableToolbar from './ParkingTicketsTableToolbar';

type Props = {
  filter: {
    plateNumber: string;
    dateFrom: string;
    dateTo: string;
  };
};

function ParkingTicketsTable({ filter }: Props) {
  const userInfo = useSelector((state: any) => state.userInfo);
  const [rowCount, setRowCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 25,
  });
  const queryClient = useQueryClient();

  const [editedRows, dispatch] = useReducer(
    (state: ParkingTicket[], action: EditAction<ParkingTicket>) => {
      if (action.type === 'RESET') {
        return [] as ParkingTicket[];
      }

      return uniqBy([...state, action.payload], 'ticketID');
    },
    [],
  );

  const { data: parkingTicketsData, isFetching, refetch } = useQuery({
    queryKey: ['parking', paginationModel, filter],
    queryFn: () => getParkingTickets({
      limit: paginationModel.pageSize,
      offset: paginationModel.page * paginationModel.pageSize,
      ...filter,
    }),
  });

  const { data: billingStatuses } = useQuery({
    queryKey: ['billingStatuses'],
    queryFn: () => api.getAllBillingStatuses(),
    staleTime: Infinity,
  });

  const { mutate: mutateBilling } = useMutation({
    mutationKey: [],
    mutationFn: (
      { id, status }:
      { id: number, status: string },
    ) => updateParkingTicketBillingStatus(id, { billing_status: status }),
    onMutate: async ({ id, status }) => {
      const queryKey = ['parking', paginationModel, filter];

      // Optimistic query update
      await queryClient.cancelQueries({ queryKey });

      const previousTickets = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(
        queryKey,
        (prevTickets: { data: APIListResponse<ParkingTicket> } | undefined) => {
          if (!prevTickets) {
            return prevTickets;
          }

          const prevTicketsClone = {
            ...prevTickets,
            data: deepClone(prevTickets.data),
          };

          const updatedTicketIndex = prevTickets.data.objects.findIndex(
            ({ ticketID }) => ticketID === id,
          );

          prevTicketsClone.data.objects[updatedTicketIndex] = {
            ...prevTickets.data.objects[updatedTicketIndex],
            billing_status: status,
          };

          return prevTicketsClone;
        },
      );

      return { previousTickets };
    },
    onError: (err, variables, context) => {
      const queryKey = ['parking', paginationModel, filter];

      queryClient.setQueryData(queryKey, context?.previousTickets);
      enqueueSnackbar({ variant: 'error', message: 'Failed to update ticket' });
    },
  });

  useEffect(() => {
    // This ensures that the pagination does not reset while the data is being fetch
    // Like when changing pages
    setRowCount((prevCount) => {
      const count = parkingTicketsData?.data.meta.total_count;
      return count !== undefined ? count : prevCount;
    });
  }, [parkingTicketsData]);

  const { data: columns } = useQuery({
    queryKey: ['columns', 'parking', userInfo?.feature_flags, billingStatuses?.data],
    queryFn: () => createParkingTableColumns({
      featureFlags: userInfo?.feature_flags,
      billingStatusOptions: billingStatuses?.data || [],
      onBillingStatusChange: (id, status) => mutateBilling({ id, status }),
    }),
  });

  const { mutate: onSaveEditedRows } = useMutation({
    mutationFn: () => partialUpdateParkingTicketList(
      editedRows.map(
        ({ description, driver, ticketID }) => ({
          id: ticketID,
          description,
          driverEmail: driver?.email,
        }),
      ),
    ),
    onSuccess: () => {
      dispatch({ type: 'RESET' });
      queryClient.invalidateQueries({ queryKey: ['parking', paginationModel, filter] });
    },
    onError: () => {
      enqueueSnackbar({ variant: 'error', message: 'Failed to save changes' });
    },
  });

  return (
    <HighlightableDataGrid
      rows={parkingTicketsData?.data.objects || []}
      columns={columns || []}
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      loading={isFetching}
      rowCount={rowCount}
      getRowId={({ ticketID }) => ticketID}
      autoHeight
      pagination
      processRowUpdate={(newRow) => {
        dispatch({ payload: newRow, type: 'ADD' });
        return newRow;
      }}
      getRowClassName={({ id }) => (editedRows.find(({ ticketID }) => id === ticketID) ? 'highlighted' : '')}
      sx={(theme) => ({
        '& p': {
          margin: 'unset',
        },
        backgroundColor: theme.palette.background.paper,
        overflowX: 'scroll',
      })}
      slots={{
        toolbar: ParkingTicketsTableToolbar,
      }}
      slotProps={{
        toolbar: {
          downloadCSVProps: {
            queryParams: { ...filter },
            settingsName: 'parking',
            downloadCSV: downloadParkingTicketsCSV,
          },
          refetch,
          onSave: onSaveEditedRows,
          canSave: !isEmpty(editedRows),
        },
      }}
      disableRowSelectionOnClick
    />
  );
}

export default ParkingTicketsTable;
