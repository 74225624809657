/* eslint-disable react/prop-types */
import './dateTimePicker.css';

import { Box } from '@mui/material';
import React, { Component } from 'react';
import Datetime from 'react-datetime';
import { connect } from 'react-redux';
import Select2 from 'react-select2-wrapper';

import BuggyApi from '../../services/BuggyApi';
import * as actionTypes from '../../store/actions/actions';
import BlockUi from '../BlockUi';
import UploadDocument from '../UploadDocuments';
import ViewDocumentButton from '../ViewDocumentStatus';
import { TollsTable } from './components';

class Tickets extends Component {
  constructor() {
    super();
    this.state = {
      focusedFrom: false,
      focusedTo: false,
      blocking: false,
      exitPlazas: [{ id: '', text: 'All Exit Plazas' }],
      exitPlazaValue: '',
    };
  }

  componentDidMount() {
    this.is_mounted = true;
    const {
      location,
      componentIndex,
      getComponentIndex,
      onLinkChange,
    } = this.props;
    getComponentIndex(location.pathname);
    onLinkChange(componentIndex);
  }

  componentWillUnmount() {
    this.is_mounted = false;
  }

  getExitPlazaHandler = () => {
    const { exitPlazas } = this.state;
    if (exitPlazas.length > 2) {
      console.log('the data  already exist.');
    } else {
      this.setState({
        blocking: true,
      });
      new BuggyApi().getExitPlazas().then((response) => {
        if (this.is_mounted) {
          const newExitPlazas = response.data.objects;
          const data = [];
          data.push({ id: '', text: 'All Exit Plazas' });
          newExitPlazas.forEach((exitPlaza) => {
            data.push({ id: exitPlaza.code, text: exitPlaza.location });
          });
          this.setState({
            exitPlazas: data,
          });
          this.setState({
            blocking: false,
          });
        }
      });
    }
  };

  onFocusHandlerDateTo = () => {
    this.setState({ focusedTo: true });
  };

  onBlurHandlerDateFrom = () => {
    this.setState({ focusedFrom: false });
  };

  onBlurHandlerDateTo = () => {
    this.setState({ focusedTo: false });
  };

  searchClickHandler = () => {
    // eslint-disable-next-line react/no-string-refs
    const exitPlazaValue = this.refs.exitPlazaDropdown.el.val() || '';
    this.setState({ exitPlazaValue });
  };

  render() {
    return (
      <div
        className={
            this.props.sidebar_status
              ? 'page-wrapper slide-nav-toggle'
              : 'page-wrapper'
          }
      >
        <div className="content container-fluid" id="tickets-layout">
          <BlockUi tag="div" blocking={this.state.blocking}>
            <div className="row">
              <div className="col-xs-6 ">
                <h4 className="page-title">Toll Transactions</h4>
              </div>
            </div>
            <div className="row filter-row">
              <div className="col-sm-3 col-xs-6">
                <div className="form-group form-focus">
                  <label className="control-label">Plate#</label>
                  <input
                    type="text"
                    className="form-control floating"
                    ref={(el) => (this.searchPlateNumber = el)}
                  />
                </div>
              </div>
              {this.props.fleet_info.fleet_id === 141 ? (
                <div className="col-sm-6 col-xs-6">
                  <div className="form-group form-focus">
                    <label className="control-label">Lessee/Renter</label>
                    <input
                      type="text"
                      className="form-control floating"
                      ref={(el) => (this.searchLesseeRenter = el)}
                    />
                  </div>
                </div>
              ) : null}
              <div className="col-sm-3 col-xs-6">
                <Select2
                  ref="exitPlazaDropdown"
                  data={this.state.exitPlazas}
                  value={this.state.exitPlazaValue}
                  onOpen={this.getExitPlazaHandler}
                  options={{
                    width: '100%',
                    minimumResultsForSearch: 5,
                  }}
                />
              </div>
              <div className="col-sm-4 col-xs-12">
                <div className="row">
                  <div className="col-sm-6 col-xs-6">
                    <div
                      className={
                          this.state.focusedFrom
                            ? 'form-group form-focus focused'
                            : 'form-group form-focus'
                        }
                    >
                      <label className="control-label">From</label>
                      <div className="cal-icon">
                        <Datetime
                          dateFormat="YYYY-MM-DD"
                          onFocus={this.onFocusHandlerDateFrom}
                          onBlur={this.onBlurHandlerDateFrom}
                          timeFormat={false}
                          ref={(el) => (this.dateFrom = el)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-xs-6">
                    <div
                      className={
                          this.state.focusedTo
                            ? 'form-group form-focus focused'
                            : 'form-group form-focus '
                        }
                    >
                      <label className="control-label">To</label>
                      <div className="cal-icon">
                        <Datetime
                          dateFormat="YYYY-MM-DD"
                          onFocus={this.onFocusHandlerDateTo}
                          onBlur={this.onBlurHandlerDateTo}
                          timeFormat={false}
                          ref={(el) => (this.dateTo = el)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-2 col-xs-6">
                <button
                  type="button"
                  className="btn btn-success btn-block"
                  onClick={this.searchClickHandler}
                >
                  {' '}
                  Search
                  {' '}
                </button>
              </div>
            </div>
            <Box marginBottom={2}>
              <UploadDocument />
              <ViewDocumentButton />
            </Box>
            <div className="row">
              <div className="col-md-12">
                <TollsTable
                  plateNumber={this.searchPlateNumber?.value}
                  exitPlaza={this.state.exitPlazaValue}
                  dateFrom={this.dateFrom?.state.inputValue}
                  dateTo={this.dateTo?.state.inputValue}
                  lessee={this.searchLesseeRenter?.value}
                />
              </div>
            </div>
          </BlockUi>
        </div>
      </div>
    );
  }
}
const mapStatesToProps = (state) => ({
  componentIndex: state.componentIndex,
  sidebar_status: state.sidebarOpened,
  fleet_info: state.userInfo,
});
const mapDispatchToProps = (dispatch) => ({
  onLinkChange: (i) => dispatch({ type: actionTypes.UPDATE_NAVIGATION, i }),
  getComponentIndex: (path) => dispatch({ type: actionTypes.GET_COMPONENT_INDEX, path }),
});
export default connect(mapStatesToProps, mapDispatchToProps)(Tickets);
