import { isArray, keyBy, mapValues } from "lodash";

export const generateFieldsFromIntegration = (app) => {
  let userSettingsMap = app.userSettings?.data?.auth || {};

  // For previous settings
  if (isArray(app.userSettings?.data?.auth)) {
    userSettingsMap = mapValues(
      keyBy(app.userSettings?.data?.auth, "name"),
      "value"
    );
  }

  return app.fields.auth.map(({ name, type }) => ({
    name,
    type,
    value: userSettingsMap[name] || null,
  }));
};
