import React, { Component } from 'react';
import Pagination from 'react-js-pagination';
import PlaidLink from 'react-plaid-link';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import BuggyApi from '../../services/BuggyApi';
import * as actionTypes from '../../store/actions/actions';
import BlockUi from '../BlockUi';
import { RECHARGE_AMOUNT_CHOICES } from '../Settings/constants';
import Checkout from './CheckoutForm';

class Billing extends Component {
  state = {
    current_balance: 0.0,
    blocking: false,
    blocking_button: false,
    errorMessage: [],
    isSuccess: false,
    orders: [],
    itemsCountPerPage: 20,
    totalItemsCount: 0,
    pageRangeDisplayed: 10,
    activePage: 1,
    plaid_public_key: '',
    strip_public_key: '',
    recharge_amount: 400,
    recharge_amount_choices: [],
  };

  componentWillMount() {
    this.props.getComponentIndex(this.props.location.pathname);
    this.props.onLinkChange(this.props.componentIndex);
  }

  componentDidMount() {
    this.is_mounted = true;
    if (this.is_mounted) {
      this.setState({
        blocking_button: true,
      });
    }
    new BuggyApi()
      .getPlaidPublicKey()
      .then((response) => {
        if (this.is_mounted) {
          this.setState({
            blocking_button: false,
          });
        }
        if (response.status === 200) {
          if (this.is_mounted) {
            this.setState({
              plaid_public_key: response.data.public_key,
              strip_public_key: response.data.strip_public_key,
            });
          }
        }
      })
      .catch((error) => {
        if (this.is_mounted) {
          this.setState({
            blocking_button: false,
          });
        }
      });
    new BuggyApi()
      .getBillingInfo()
      .then((response) => {
        if (this.is_mounted) {
          this.setState({ current_balance: response.data.current_balance });
        }
      })
      .catch((error) => {
        // console.log(error.response);
      });

    new BuggyApi()
      .getOrders()
      .then((response) => {
        if (this.is_mounted) {
          this.setState({
            orders: response.data.objects,
            activePage: 1,
            itemsCountPerPage: response.data.meta.limit,
            totalItemsCount: response.data.meta.total_count,
          });
        }
      })
      .catch((error) => {});
    new BuggyApi()
      .getSettings()
      .then((response) => {
        if (this.is_mounted) {
          if (response.data.recharge_amount) {
            this.setState({
              recharge_amount: response.data.recharge_amount,
              recharge_amount_choices: response.data.recharge_amount_choices,
            });
          } else {
            this.setState({
              recharge_amount_choices: response.data.recharge_amount_choices,
            });
          }
        }
      })
      .catch((error) => {
        // console.log(error.response)
      });
  }

  handlePageChange = (pageNumber) => {
    if (this.is_mounted) {
      this.setState({ activePage: pageNumber });
    }
    pageNumber -= 1;
    new BuggyApi()
      .getOrders(this.state.itemsCountPerPage * pageNumber)
      .then((response) => {
        if (this.is_mounted) {
          this.setState({
            orders: response.data.objects,
            itemsCountPerPage: response.data.meta.limit,
            totalItemsCount: response.data.meta.total_count,
          });
        }
      });
  };

  refreshOrders = () => {
    new BuggyApi()
      .getSettings()
      .then((response) => {
        if (this.is_mounted) {
          if (response.data.recharge_amount) {
            this.setState({
              recharge_amount: response.data.recharge_amount,
              recharge_amount_choices: response.data.recharge_amount_choices,
            });
          } else {
            this.setState({
              recharge_amount_choices: response.data.recharge_amount_choices,
            });
          }
        }
      })
      .catch((error) => {
        // console.log(error.response)
      });
    new BuggyApi()
      .getOrders()
      .then((response) => {
        if (this.is_mounted) {
          this.setState({
            orders: response.data.objects,
            activePage: 1,
            itemsCountPerPage: response.data.meta.limit,
            totalItemsCount: response.data.meta.total_count,
          });
        }
      })
      .catch((error) => {});
  };

  componentWillUnmount() {
    this.is_mounted = false;
  }

  amountChangedHandler = (e) => {
    if (this.is_mounted) {
      this.setState({
        recharge_amount: e.target.value,
      });
    }
  };

  handleOnSuccess = (token, metadata) => {
    // send token to client server
    // console.log("Token: "+token);
    // console.log(metadata);
    if (this.is_mounted) {
      this.setState({ blocking: true });
    }
    const formData = {
      public_token: token,
      account_id: metadata.account_id,
      recharge_amount: this.state.recharge_amount,
    };

    new BuggyApi()
      .getPayment(formData)
      .then((response) => {
        new BuggyApi().getUserInfo().then((response) => {
          if (this.is_mounted) {
            this.setState({ blocking: false, isSuccess: true });
          }
          if (response.status === 200) {
            this.props.onUserInfoUpdate(response.data);
          }
        });
        this.refreshOrders();
      })
      .catch((error) => {
        if (this.is_mounted) {
          this.setState({ blocking: false });
        }
        if (error.response) {
          if (error.response.data) {
            const errors = [];
            const errorList = error.response.data;
            for (const key in errorList) {
              let em = '';
              switch (key) {
                case 'error':
                  em = 'Payment Error: ';
                  break;
                default:
                  em = '';
                  break;
              }
              em += errorList[key];
              errors.push(em);
            }
            if (this.is_mounted) {
              this.setState({
                errorMessage: errors,
              });
            }
          } else {
            /* console.log(error.response.data.error_message); */
            const em = `Oops! ${error.response.data.error}`;
            if (this.is_mounted) {
              this.setState({
                errorMessage: [em],
              });
            }
          }
        } else if (error.request) {
          const em = 'Oops! Something went wrong!';
          if (this.is_mounted) {
            this.setState({
              errorMessage: [em],
            });
          }
        } else {
          /* console.log('Error', error.message); */
        }
      });
  };

  handleOnExit = () => {
    // handle the case when your user exits Link
  };

  render() {
    const rows = [];
    let conter = 0;

    this.state.orders.forEach((order) => {
      conter++;
      let status = '';
      if (order.status === 1) {
        status = <span className="label label-warning-border">Pending</span>;
      } else if (order.status === 2) {
        status = <span className="label label-success-border">Completed</span>;
      } else if (order.status === 3) {
        status = <span className="label label-danger-border">Not Valid</span>;
      } else if (order.status === 4) {
        status = <span className="label label-danger-border">Canceled</span>;
      } else if (order.status === 5) {
        status = <span className="label label-info-border">Returned</span>;
      } else {
        status = 'N/A';
      }
      rows.push(
        <tr key={conter}>
          <td>{order.id}</td>
          <td>{order.flat_name}</td>
          <td>{order.created}</td>
          <td>{status}</td>
          <td>{order.amount}</td>
        </tr>,
      );
    });
    return (
      <div
        className={
          this.props.sidebar_status
            ? 'page-wrapper slide-nav-toggle'
            : 'page-wrapper'
        }
      >
        <div className="content container-fluid">
          <div className="row">
            <div className="col-xs-8">
              <h4 className="page-title">Billing</h4>
            </div>
            <div className="col-xs-4 text-right m-b-30" />
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 col-lg-4">
              <div
                className="dash-widget clearfix card-box"
                style={{ height: '125px' }}
              >
                <div className="dash-widget-info" style={{ textAlign: 'left' }}>
                  {/* <h3 style={{fontSize: 18, color: '#2B60DE'}}>Current Balance</h3>
                                    <h3 style={{fontSize: 16}}>${this.state.current_balance}</h3>
                                    <span>remaining in your account</span> */}
                  <h3 style={{ fontSize: 16 }}>
                    For balance information, please reach out to Info@FleetIT.co
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-4">
              <BlockUi tag="div" blocking={this.state.blocking}>
                <div
                  className="dash-widget clearfix card-box"
                  style={{ paddingBottom: '12px' }}
                >
                  <div
                    className="dash-widget-info"
                    style={{ textAlign: 'left' }}
                  >
                    <h3 style={{ fontSize: 18, color: '#2B60DE' }}>
                      Pay with Bank Account
                    </h3>

                    <BlockUi tag="div" blocking={this.state.blocking_button}>
                      Add&nbsp;&nbsp;
                      <select
                        style={{
                          paddingRight: '10px',
                          paddingLeft: '10px',
                          borderTopLeftRadius: '25px',
                          borderBottomLeftRadius: '25px',
                          borderTopRightRadius: '25px',
                          borderBottomRightRadius: '25px',
                          paddingBottom: '3px',
                          paddingTop: '3px',
                        }}
                        onChange={this.amountChangedHandler}
                        value={this.state.recharge_amount}
                      >
                        {RECHARGE_AMOUNT_CHOICES.map((choice) => (
                          <option key={choice.value} value={choice.value}>
                            $
                            {choice.text}
                          </option>
                        ))}
                      </select>
                      {' '}
                      to your balance
                      {this.state.plaid_public_key !== ''
                      && this.state.plaid_public_key !== undefined ? (
                        <PlaidLink
                          style={{
                            outline: 'none',
                            backgroundColor: '#ff9b44',
                            borderRadius: '500px',
                            padding: '2px 10px 2px 10px',
                            marginTop: '10px',
                          }}
                          className="btn btn-primary rounded"
                          clientName="FleetIT"
                          env="production"
                          selectAccount
                          product={['auth', 'transactions']}
                          publicKey={this.state.plaid_public_key}
                          onExit={this.handleOnExit}
                          onSuccess={this.handleOnSuccess}
                        >
                          Recharge
                        </PlaidLink>
                        ) : (
                          <button
                            className="btn btn-primary rounded"
                            style={{
                              padding: '2px 10px 2px 10px',
                              marginTop: '10px',
                            }}
                          >
                            Recharge
                          </button>
                        )}
                    </BlockUi>
                  </div>
                </div>
              </BlockUi>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-4">
              <BlockUi tag="div" blocking={this.state.blocking}>
                <div
                  className="dash-widget clearfix card-box"
                  style={{ paddingBottom: '12px' }}
                >
                  <div
                    className="dash-widget-info"
                    style={{ textAlign: 'left' }}
                  >
                    <h3 style={{ fontSize: 18, color: '#2B60DE' }}>
                      Pay with Card
                    </h3>

                    <BlockUi tag="div" blocking={this.state.blocking_button}>
                      Add&nbsp;&nbsp;
                      <select
                        style={{
                          paddingRight: '10px',
                          paddingLeft: '10px',
                          borderTopLeftRadius: '25px',
                          borderBottomLeftRadius: '25px',
                          borderTopRightRadius: '25px',
                          borderBottomRightRadius: '25px',
                          paddingBottom: '3px',
                          paddingTop: '3px',
                        }}
                        onChange={this.amountChangedHandler}
                        value={this.state.recharge_amount}
                      >
                        {RECHARGE_AMOUNT_CHOICES.map((choice) => (
                          <option key={choice.value} value={choice.value}>
                            $
                            {choice.text}
                          </option>
                        ))}
                      </select>
                      {' '}
                      to your balance
                      <div>
                        <div style={{ display: 'inline' }}>
                          {this.state.strip_public_key !== '' ? (
                            <Checkout
                              name="Pay with Card"
                              description="FleetIT"
                              amount={this.state.recharge_amount}
                              public_key={this.state.strip_public_key}
                              refresh={this.refreshOrders}
                            />
                          ) : (
                            <button
                              className="btn btn-primary rounded"
                              style={{
                                padding: '2px 10px 2px 10px',
                                marginTop: '10px',
                              }}
                            >
                              Checkout
                            </button>
                          )}
                        </div>
                        <div
                          style={{
                            paddingLeft: '15px',
                            paddingTop: '12px',
                            fontSize: '11px',
                            display: 'inline',
                            position: 'absolute',
                          }}
                        >
                          <Link to="/billing"> 3% Fee Applies</Link>
                        </div>
                      </div>
                    </BlockUi>
                  </div>
                </div>
              </BlockUi>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-8">
              <h4 className="page-title">Transactions</h4>
            </div>
            <div className="col-xs-4 text-right m-b-30" />
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <table className="table table-striped custom-table m-b-0 ">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Created</th>
                      <th>Status</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>{rows}</tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-5">
              <div className="dataTables_info">
                Showing
                {' '}
                {this.state.itemsCountPerPage * (this.state.activePage - 1)}
                {' '}
                to&nbsp;
                {this.state.itemsCountPerPage * this.state.activePage}
                {' '}
                of&nbsp;
                {this.state.totalItemsCount}
                {' '}
                entries
              </div>
            </div>
            <div className="col-sm-7">
              <div className="dataTables_paginate">
                <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.itemsCountPerPage}
                  totalItemsCount={this.state.totalItemsCount}
                  pageRangeDisplayed={this.state.pageRangeDisplayed}
                  onChange={this.handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStatesToProps = (state) => ({
  componentIndex: state.componentIndex,
  sidebar_status: state.sidebarOpened,
  fleet_info: state.userInfo,
});
const mapDispatchToProps = (dispatch) => ({
  onLinkChange: (i) => dispatch({ type: actionTypes.UPDATE_NAVIGATION, i }),
  getComponentIndex: (path) => dispatch({ type: actionTypes.GET_COMPONENT_INDEX, path }),
  onUserInfoUpdate: (userInfo) => dispatch({ type: actionTypes.UPDATE_USER_DETAILS, userInfo }),
});
export default connect(mapStatesToProps, mapDispatchToProps)(Billing);
