import {
  Stack,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { format } from 'date-fns';
import { startCase } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React from 'react';

import { listWalletTransactions } from '@/services/billing';

import { formatAmount, mapTypeToIcon, transactionText } from './helpers';
import { WalletTransactionsListProps } from './types';

export function WalletTransactionsList(props: WalletTransactionsListProps) {
  const { walletId } = props;
  const { data } = useQuery({
    queryKey: ['transactions'],
    queryFn: () => listWalletTransactions(walletId),
    onError: (error) => {
      if (isAxiosError(error)) {
        enqueueSnackbar({
          message: startCase(error.message),
          variant: 'error',
        });
      }
    },
  });

  return (

    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Transactions</TableCell>
            <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
          data?.data.wallet_transactions?.map(({
            amount, lago_id, transaction_type, created_at, status,
          }) => (
            <TableRow
              key={lago_id}
            >
              <TableCell>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <div>{mapTypeToIcon(transaction_type, status)}</div>
                  <Stack>
                    {status === 'pending' ? (
                      <>
                        <Typography color="gray" fontWeight="bold" variant="body2">{transactionText(transaction_type)}</Typography>
                        <Typography color="gray" variant="caption">
                          {format(new Date(created_at), 'MMM dd, yyyy')}
                          {' '}
                          &bull; Pending
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography fontWeight="bold" variant="body2">{transactionText(transaction_type)}</Typography>
                        <Typography variant="caption">{format(new Date(created_at), 'MMM dd, yyyy')}</Typography>
                      </>
                    )}
                  </Stack>
                </Stack>
              </TableCell>
              <TableCell align="right">
                {formatAmount(transaction_type, amount)}
              </TableCell>
            </TableRow>
          ))
        }
        </TableBody>
      </Table>
    </TableContainer>

  );
}

export default WalletTransactionsList;
