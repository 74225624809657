import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { flatMap } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { confirmShipment, listCouriers } from '@/services/transponders';
import { ShipmentType } from '@/services/transponders/types';

import { confirmShipmentFormSchema, ConfirmShipmentFormType } from './confirmShipmentSchema';

type ConfirmShipmentFormDialogProps = DialogProps & {
  shipment: ShipmentType
};

export default function ConfirmShipmentFormDialog(
  {
    shipment,
    onClose,
    ...dialogProps
  }: ConfirmShipmentFormDialogProps,
) {
  const {
    register,
    handleSubmit,
    formState,
  } = useForm<ConfirmShipmentFormType>({
    resolver: zodResolver(confirmShipmentFormSchema),
  });
  const queryClient = useQueryClient();

  const { errors } = formState;

  const { data } = useQuery({
    queryFn: () => listCouriers(),
    queryKey: ['couriers'],
  });

  const courierOptions = useMemo(() => data?.data || [], [data?.data]);

  const { mutate: confirm, isLoading: isConfirmationLoading } = useMutation({
    mutationFn: (formValues: ConfirmShipmentFormType) => confirmShipment({
      ...formValues,
      shipment,
    }),
    onError: (e) => {
      if (isAxiosError(e) && e.response?.status === 400) {
        flatMap(e.response.data).forEach((message) => {
          enqueueSnackbar({
            variant: 'error',
            message: message as string,
          });
        });

        return;
      }

      enqueueSnackbar({ variant: 'error', message: 'Something went wrong' });
    },
    onSuccess: () => {
      onClose?.({}, 'backdropClick');
      queryClient.invalidateQueries({
        queryKey: ['shipments'],
      });
    },
  });

  const onSubmit = useCallback((formValues: ConfirmShipmentFormType) => {
    confirm(formValues);
  }, [confirm]);

  return (
    <Dialog {...dialogProps} onClose={onClose} fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          Confirm Shipment of
          {' '}
          <Typography
            sx={{ fontStyle: 'italic', fontWeight: 'bold' }}
            display="inline"
          >
            {shipment.transponders.length}
          </Typography>
          {' '}
          transponders
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2} mt={2}>
            <TextField
              {...register('courier')}
              fullWidth
              label="Courier"
              select
              defaultValue={null}
              error={!!errors.courier}
              helperText={errors.courier?.message}
            >
              {courierOptions.map(({ text, value }) => (
                <MenuItem
                  value={value}
                  key={value}
                >
                  {text}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              {...register('trackingCode')}
              label="Tracking Code"
              fullWidth
              error={!!errors.trackingCode}
              helperText={errors.trackingCode?.message}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            onClick={() => onClose?.({}, 'backdropClick')}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isConfirmationLoading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
