import React, {Component} from 'react';
import $ from "jquery";
import BuggyApi from "../../services/BuggyApi";
import * as actionTypes from "../../store/actions/actions";
import {connect} from 'react-redux';
import StatsWidget from "../Stats/StatsWidget";
import ReactChartkick from 'react-chartkick';
import Highcharts from 'highcharts'
import ToggleDatesGraph from "./ToggleDatesGraph";

ReactChartkick.addAdapter(Highcharts)


class dashboard extends Component {
    state = {
        tickets_today: 0,
        cost_last_month: 0,
        cost_current_month: 0,
        vehicle_count: 0,
        tickets_month_wise: [],
        initial_data_for_toggle_graph: [],
        initial_data_for_donut_graph: {},
        min_available_date: null,
    };

    objectify = (array) => {
        return array.reduce((p, c) => {
            let key = "";
            let year = c[2];
            switch (c[0]) {
                case 1:
                    key = "Jan " + year;
                    break;
                case 2:
                    key = "Feb " + year;
                    break;
                case 3:
                    key = "Mar " + year;
                    break;
                case 4:
                    key = "Apr " + year;
                    break;
                case 5:
                    key = "May " + year;
                    break;
                case 6:
                    key = "Jun " + year;
                    break;
                case 7:
                    key = "Jul " + year;
                    break;
                case 8:
                    key = "Aug " + year;
                    break;
                case 9:
                    key = "Sep " + year;
                    break;
                case 10:
                    key = "Oct " + year;
                    break;
                case 11:
                    key = "Nov " + year;
                    break;
                case 12:
                    key = "Dec " + year;
                    break;
                default:
                    break;
            }
            p[key] = c[1];
            return p;
        }, {});
    };

    componentWillMount() {

        this.props.getComponentIndex(this.props.location.pathname);
        this.props.onLinkChange(this.props.componentIndex);

        new BuggyApi().getStats().then((response) => {
            if (this.is_mounted) {
                this.setState({
                    tickets_today: response.data.tickets_today,
                    cost_last_month: parseFloat(response.data.cost_last_month).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                    vehicle_count: response.data.vehicle_count,
                    cost_current_month: parseFloat(response.data.cost_current_month).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                    tickets_month_wise: this.objectify(response.data.tickets_month_wise),
                    min_available_date: response.data.available_dates.date_from,
                    initial_data_for_donut_graph: response.data.toggle_graph_exit_plaza_values
                });
                if(response.data.toggle_graph_values.length > 0){
                    this.setState({
                        initial_data_for_toggle_graph: response.data.toggle_graph_values
                    });
                }else{
                    this.setState({
                        initial_data_for_toggle_graph: [['0:00', 0, '0']]
                    })
                }
            }
        });


    }

    componentWillUnmount() {
        this.is_mounted = false;
    }

    componentDidMount() {
        this.is_mounted = true;
        window.$ = window.jQuery = $;

        (function ($) {
            
            var Sidemenu = function () {
                this.$menuItem = $("#sidebar-menu a");
            };

            Sidemenu.prototype.init = function () {
                var $this = this;
                $this.$menuItem.on('click', function (e) {
                    if ($(this).parent().hasClass("submenu")) {
                        e.preventDefault();
                    }
                    if (!$(this).hasClass("subdrop")) {
                        $("ul", $(this).parents("ul:first")).slideUp(350);
                        $("a", $(this).parents("ul:first")).removeClass("subdrop");
                        $(this).next("ul").slideDown(350);
                        $(this).addClass("subdrop");
                    } else if ($(this).hasClass("subdrop")) {
                        $(this).removeClass("subdrop");
                        $(this).next("ul").slideUp(350);
                    }
                });
                $("#sidebar-menu ul li.submenu a.active").parents("li:last").children("a:first").addClass("active").trigger("click");
            };
            $.Sidemenu = new Sidemenu();

        })(window.jQuery);
        $(document).ready(function () {

                // Sidebar overlay

                var $sidebarOverlay = $(".sidebar-overlay");
                $("#mobile_btn, .task-chat").on("click", function (e) {
                    var $target = $($(this).attr("href"));
                    if ($target.length) {
                        $target.toggleClass("opened");
                        $sidebarOverlay.toggleClass("opened");
                        $("html").toggleClass("menu-opened");
                        $sidebarOverlay.attr("data-reff", $(this).attr("href"));
                    }
                    e.preventDefault();
                });

                $sidebarOverlay.on("click", function (e) {
                    var $target = $($(this).attr("data-reff"));
                    if ($target.length) {
                        $target.removeClass("opened");
                        $("html").removeClass("menu-opened");
                        $(this).removeClass("opened");
                        $(".main-wrapper").removeClass("slide-nav-toggle");
                    }
                    e.preventDefault();
                });

                // Sidebar Initiate

                $.Sidemenu.init();

                // Select2


                // Modal Popup hide show

                if ($('.modal').length > 0) {
                    var modalUniqueClass = ".modal";
                    $('.modal').on('show.bs.modal', function (e) {
                        var $element = $(this);
                        var $uniques = $(modalUniqueClass + ':visible').not($(this));
                        if ($uniques.length) {
                            $uniques.modal('hide');
                            $uniques.one('hidden.bs.modal', function (e) {
                                $element.modal('show');
                            });
                            return false;
                        }
                    });
                }

                // Floating Label

                if ($('.floating').length > 0) {
                    $('.floating').on('focus blur', function (e) {
                        $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
                    }).trigger('blur');
                }


                if ($('.page-wrapper').length > 0) {
                    var height = $(window).height();
                    $(".page-wrapper").css("min-height", height);
                }

                $(window).resize(function () {
                    if ($('.page-wrapper').length > 0) {
                        var height = $(window).height();
                        $(".page-wrapper").css("min-height", height);
                    }
                });


                // Button Switch

                if ($('.btn-toggle').length > 0) {
                    $('.btn-toggle').click(function () {
                        $(this).find('.btn').toggleClass('active');
                        if ($(this).find('.btn-success').size() > 0) {
                            $(this).find('.btn').toggleClass('btn-success');
                        }
                    });
                }

                // Mobile Menu

                if ($('.main-wrapper').length > 0) {
                    var $wrapper = $(".main-wrapper");
                    $(document).on('click', '#mobile_btn', function (e) {
                        $(".dropdown.open > .dropdown-toggle").dropdown("toggle");
                        return false;
                    });
                    $(document).on('click', '#mobile_btn', function (e) {
                        $wrapper.toggleClass('slide-nav-toggle');
                        $('#chat_sidebar').removeClass('opened');
                        return false;
                    });
                    $(document).on('click', '#open_msg_box', function (e) {
                        $wrapper.toggleClass('open-msg-box').removeClass('');
                        $('.dropdown').removeClass('open');
                        return false;
                    });
                }

                // Message box remove

                if ($('.dropdown-toggle').length > 0) {
                    $('.dropdown-toggle').click(function () {
                        if ($('.main-wrapper').hasClass('open-msg-box')) {
                            $('.main-wrapper').removeClass('open-msg-box');
                        }
                    });
                }

                // Table Dropdown

                $('.table-responsive').on('shown.bs.dropdown', function (e) {
                    var $table = $(this),
                        $dropmenu = $(e.target).find('.dropdown-menu'),
                        tableOffsetHeight = $table.offset().top + $table.height(),
                        menuOffsetHeight = $dropmenu.offset().top + $dropmenu.outerHeight(true);

                    if (menuOffsetHeight > tableOffsetHeight)
                        $table.css("padding-bottom", menuOffsetHeight - tableOffsetHeight);
                });
                $('.table-responsive').on('hide.bs.dropdown', function () {
                    $(this).css("padding-bottom", 0);
                });

                // Custom Modal Backdrop

                $('a[data-toggle="modal"]').on('click', function () {
                    setTimeout(function () {
                        if ($(".modal.custom-modal").hasClass('in')) {
                            $(".modal-backdrop").addClass('custom-backdrop');

                        }
                    }, 500);
                });

                // Email Inbox

                if ($('.clickable-row').length > 0) {
                    $(".clickable-row").click(function () {
                        window.location = $(this).data("href");
                    });
                }

                if ($('.checkbox-all').length > 0) {
                    $('.checkbox-all').click(function () {
                        $('.checkmail').click();
                    });
                }
                if ($('.checkmail').length > 0) {
                    $('.checkmail').each(function () {
                        $(this).click(function () {
                            if ($(this).closest('tr').hasClass("checked")) {
                                $(this).closest('tr').removeClass('checked');
                            } else {
                                $(this).closest('tr').addClass('checked');
                            }
                        });
                    });
                }
                if ($('.mail-important').length > 0) {
                    $(".mail-important").click(function () {
                        $(this).find('i.fa').toggleClass("fa-star");
                        $(this).find('i.fa').toggleClass("fa-star-o");
                    });
                }

                // Incoming call popup

                if ($('#incoming_call').length > 0) {
                    $(window).on('load', function () {
                        $('#incoming_call').modal('show');
                        $("body").addClass("call-modal");
                    });
                }
            });

    }

    render() {
        return (
            <div className={(this.props.sidebar_status) ? "page-wrapper slide-nav-toggle" : "page-wrapper"}>
                <div className="content container-fluid" id="dashboard-layout">
                    <div className="row">
                        <StatsWidget tickets_today={this.state.tickets_today}
                                     cost_last_month={this.state.cost_last_month}
                                     vehicle_count={this.state.vehicle_count}
                                     cost_current_month={this.state.cost_current_month}/>
                    </div>
                    <div className="row">
                        {
                            this.state.initial_data_for_toggle_graph.length > 0 &&
                                <ToggleDatesGraph donut_graph={this.state.initial_data_for_donut_graph} tickets_month_wise={this.state.tickets_month_wise} data={this.state.initial_data_for_toggle_graph} min_available_date={this.state.min_available_date} />
                        }
                         
                    </div>
                    
                </div>
            </div>
        );
    }

}

const mapStatesToProps = (state) => {
    return {
        componentIndex: state.componentIndex,
        sidebar_status: state.sidebarOpened
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLinkChange: (i) => dispatch({type: actionTypes.UPDATE_NAVIGATION, i}),
        getComponentIndex: (path) => dispatch({type: actionTypes.GET_COMPONENT_INDEX, path})
    };
};
export default connect(mapStatesToProps, mapDispatchToProps)(dashboard);