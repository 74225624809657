import { Box, Paper, Typography } from '@mui/material';
import { DataGridPro, useGridApiContext } from '@mui/x-data-grid-pro';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import { ShipmentType } from '@/services/transponders/types';

import generateShipmentDetailsColumns from './columns';

type Props = {
  row: ShipmentType
};

export default function ShipmentDetails({ row }: Props) {
  const apiRefContext = useGridApiContext();

  const [width, setWidth] = useState(() => {
    const dimensions = apiRefContext.current.getRootDimensions();
    return dimensions!.viewportInnerSize.width;
  });

  const handleViewportInnerSizeChange = useCallback(() => {
    const dimensions = apiRefContext.current.getRootDimensions();
    setWidth(dimensions!.viewportInnerSize.width);
  }, [apiRefContext]);

  useEffect(() => apiRefContext.current.subscribeEvent(
    'viewportInnerSizeChange',
    handleViewportInnerSizeChange,
  ), [apiRefContext, handleViewportInnerSizeChange]);

  const columns = useMemo(() => generateShipmentDetailsColumns(), []);

  return (
    <Box
      sx={{
        p: 2,
        maxHeight: '100%',
        boxSizing: 'border-box',
        position: 'sticky',
        left: 0,
        width,
      }}
    >
      <Paper
        elevation={1}
        sx={{ p: 2, height: 500, pb: 5 }}
      >
        <Typography display="block">
          Transponders
        </Typography>
        <DataGridPro
          rows={row.transponders}
          columns={columns}
        />
      </Paper>
    </Box>
  );
}
