import {
  Autocomplete, TextField,
} from '@mui/material';
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';
import { filter, includes, startCase } from 'lodash';
import React, { useMemo } from 'react';

import { TransponderStatusEnum } from '@/services/transponders/types';

const allowedFilterStatus = [
  TransponderStatusEnum.ACTIVE,
  TransponderStatusEnum.IN_SHIPMENT,
  TransponderStatusEnum.PENDING_VEHICLE,
  TransponderStatusEnum.RETURN_IN_PROGRESS,
];

function StatusSelect(props:GridFilterInputValueProps) {
  const { item, applyValue, focusElementRef } = props;

  const handleFilterChange = (event:any, value:string[]) => {
    applyValue({ ...item, value });
  };

  const statusOptions = useMemo(
    () => filter(
      Object.values(TransponderStatusEnum),
      (status) => includes(allowedFilterStatus, status),
    ),
    [],
  );

  return (
    <Autocomplete
      multiple
      limitTags={1}
      id="status-select"
      options={statusOptions}
      getOptionLabel={(option) => startCase(option)}
      renderInput={(params) => (
        <TextField {...params} label="Status" variant="standard" />
      )}
      onChange={handleFilterChange}
      value={item.value}
      ref={focusElementRef}
      sx={{ width: 350 }}
    />
  );
}

export default StatusSelect;
