import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button } from '@mui/material';
import { isAxiosError } from 'axios';
import {
  isEmpty, isNil, keyBy, mapValues, omit,
} from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { HiExternalLink } from 'react-icons/hi';
import { MdInfo } from 'react-icons/md';
import { useSelector } from 'react-redux';

import BlockUi from '@/components/BlockUi';
import BuggyApi from '@/services/BuggyApi';

import styles from '../AppIntegrations.module.css';
import { generateFieldsFromIntegration } from '../helpers';
import { App } from '../types';
import AuthenticationForm from './AuthenticationForm';
import ConfigurationForm from './ConfigurationForm';
import IntegrationFormContext from './IntegrationFormContext';
import { createIntegrationFormSchema, IntegrationFormType } from './integrationFormSchema';

type IntegrationFormProps = {
  app: App;
  onClose: () => void;
  onSuccess: () => void;
};

function IntegrationForm({ app, onClose, onSuccess }: IntegrationFormProps) {
  const fleetId = useSelector(({ userInfo }) => userInfo.fleet_id);
  const authFields = generateFieldsFromIntegration(app);
  const [stepIndex, setStepIndex] = useState(0);

  const formMethods = useForm({
    defaultValues: {
      fleetId,
      integrationId: app.id,
      authFields,
      config: {
        vehicle:
          app.userSettings?.data?.vehicle // This is to support previous structure
          || app.userSettings?.data?.config?.vehicle
          || 'all',
        fetchVehiclesOnSave: Boolean(app.userSettings?.data?.config?.fetch_vehicles_on_save),
        entryTypes: app.userSettings?.data?.config?.entryTypes,
        vehicleStatusIds: app.userSettings?.data?.config?.vehicleStatusIds || [],
      },
    },
    resolver: zodResolver(createIntegrationFormSchema(app)),
    mode: 'onBlur',
  });

  const { handleSubmit, formState, trigger } = formMethods;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<{ message: string }>();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const prepareIntegrationFormData = useCallback(
    (formValues: IntegrationFormType) => ({
      auth: mapValues(keyBy(formValues.authFields, 'name'), 'value'),
      urls: app.fields.urls,
      documentation: app.fields.documentation,
      config: app.title.toLowerCase().includes('webhook')
        ? {}
        : {
          ...omit(formValues.config, 'fetchVehiclesOnSave'),
          fetch_vehicles_on_save: formValues.config.fetchVehiclesOnSave,
        },
    }),
    [app],
  );

  const addSettings = useCallback(
    async (formValues: IntegrationFormType) => {
      const api = new BuggyApi();

      await api.addIntegrationSettings({
        integration_schema: formValues.integrationId,
        data: prepareIntegrationFormData(formValues),
      });
    },
    [prepareIntegrationFormData],
  );

  const updateSettings = useCallback(
    async (formValues: IntegrationFormType) => {
      if (!app.userSettings) {
        throw new Error('No user settings to update');
      }

      const api = new BuggyApi();

      await api.updateIntegrationSettings(app.userSettings.id, {
        integration_schema: formValues.integrationId,
        data: prepareIntegrationFormData(formValues),
      });
    },
    [prepareIntegrationFormData, app.userSettings],
  );

  const onSubmit = useCallback(
    async (formValues: IntegrationFormType) => {
      if (!isEmpty(formState.errors)) {
        return;
      }

      setIsSubmitting(true);
      setError(undefined);
      setIsSuccess(false);

      try {
        const submitStrategy = isEmpty(app.userSettings)
          ? addSettings
          : updateSettings;

        await submitStrategy(formValues);

        setIsSuccess(true);
        onSuccess();
      } catch (e) {
        if (isAxiosError(e)) {
          setError({
            message: e.response?.data.error_message,
          });
        }
      } finally {
        setIsSubmitting(false);
      }
    },
    [app, onSuccess, addSettings, updateSettings, formState],
  );

  const steps = useMemo<{ formField: keyof IntegrationFormType; component: React.ReactElement }[]>(
    () => (app.title.toLowerCase().includes('webhook') ? [
      {
        // the form fields to valid for the step
        formField: 'authFields',
        component: <AuthenticationForm key="auth" />,
      },
    ] : [
      {
        formField: 'authFields',
        component: <AuthenticationForm key="auth" />,
      },
      {
        formField: 'config',
        component: <ConfigurationForm app={app} key="configs" />,
      },
    ]),
    [app],
  );

  const onNextStep = useCallback(async () => {
    const { formField } = steps[stepIndex];

    const isValid = await trigger(formField);

    if (isValid) {
      setStepIndex((i) => (i < steps.length - 1 ? i + 1 : i));
    }
  }, [steps, stepIndex, trigger]);

  const onPrevStep = useCallback(
    () => setStepIndex((i) => (i >= 1 ? i - 1 : i)),
    [],
  );

  const context = useMemo(() => ({ isFetching, setIsFetching }), [isFetching]);

  return (
    <IntegrationFormContext.Provider value={context}>
      <BlockUi tag="div" blocking={isSubmitting} className={styles.main}>
        {!isEmpty(error) && (
          <div className="alert alert-danger">{error.message}</div>
        )}
        {isSuccess && (
          <div className="alert alert-success">
            Your settings have been saved!
          </div>
        )}
        <div
          className="m-b-30"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h3>
              {app.title}
              {' '}
              Credentials
              {' '}
            </h3>
            <div style={{ marginLeft: '5px' }}>
              <a
                href={app.fields.documentation}
                target="_blank"
                rel="noopener noreferrer"
              >
                <MdInfo size={26} />
              </a>
            </div>
          </div>
          <button
            style={{
              borderRadius: '100%',
              border: 'none',
              height: 30,
              width: 30,
            }}
            onClick={onClose}
            type="button"
          >
            <i className="fa fa-close" />
          </button>
        </div>
        {isNil(app.fields.urls.oauth)
          ? (
            <FormProvider {...formMethods}>
              {steps[stepIndex].component}
              <div className={styles.form_footer}>
                {stepIndex > 0 && (
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={onPrevStep}
                >
                  Back
                </button>
                )}
                {stepIndex === steps.length - 1 ? (
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={handleSubmit(onSubmit)}
                    disabled={!isEmpty(formState.errors) || isFetching}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={onNextStep}
                    disabled={
                    !isEmpty(formState.errors[steps[stepIndex].formField])
                  }
                  >
                    Next
                  </button>
                )}
              </div>
            </FormProvider>
          )
          : (
            <Box display="flex" justifyContent="center" mb={4}>
              <Button
                href={app.fields.urls.oauth}
                target="_blank"
                variant="contained"
                size="large"
                disableElevation
                endIcon={<HiExternalLink />}
              >
                Authorize
              </Button>
            </Box>
          )}
      </BlockUi>
    </IntegrationFormContext.Provider>
  );
}

export default IntegrationForm;
