import './dateTimePicker.css';

import $ from 'jquery';
import React, { Component, Fragment } from 'react';
import Datetime from 'react-datetime';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';

import BuggyApi from '../../services/BuggyApi';
import * as actionTypes from '../../store/actions/actions';
import BlockUi from '../BlockUi';
import Aux from '../hoc/Auxiliary';
import AddLiabilityBotLogin from './AddLiabilityBotLogin';
import DeleteLiabilityBotLogin from './DeleteLiabilityBotLogin';

class LiabilityBotLogin extends Component {
  state = {
    liabilityBotLoginData: [],
    itemsCountPerPage: 20,
    totalItemsCount: 0,
    pageRangeDisplayed: 10,
    activePage: 1,
    focusedFrom: false,
    focusedTo: false,
    blocking: false,
    focusedUsername: false,
    isAllowed: false,
    responseError:
      "You're not authorized to use this feature please contact admin",
  };

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber });
    pageNumber -= 1;
    new BuggyApi()
      .getBotLogin(
        this.state.itemsCountPerPage * pageNumber,
        this.searUsername.value,
        this.dateFrom.state.inputValue,
        this.dateTo.state.inputValue,
      )
      .then((response) => {
        this.setState({
          isAllowed: true,
          liabilityBotLoginData: response.data.objects,
          itemsCountPerPage: response.data.meta.limit,
          totalItemsCount: response.data.meta.total_count,
        });
      })
      .catch((error) => {
        /* console.log('Error', error); */
        if (error.response) {
          let error_msg = "You're not authorized to use this feature please contact admin";
          if (error.response.data) {
            if (error.response.data.detail) {
              error_msg = error.response.data.detail;
            }
          }
          this.setState({
            isAllowed: false,
            responseError: error_msg,
          });
        } else if (error.request) {
        } else {
          /* console.log('Error', error.message); */
        }
      });
  };

  onFocusHandlerDateFrom = (e) => {
    this.setState({ focusedFrom: true });
  };

  onFocusHandlerToggleUsername = (e) => {
    this.setState({ focusedUsername: !this.state.focusedUsername });
  };

  onFocusHandlerDateTo = (e) => {
    this.setState({ focusedTo: true });
  };

  onBlurHandlerDateFrom = (e) => {
    this.setState({ focusedFrom: false });
  };

  onBlurHandlerDateTo = (e) => {
    this.setState({ focusedTo: false });
  };

  componentDidMount() {
    this.is_mounted = true;
    window.$ = window.jQuery = $;
  }

  onEditClickHandler = (index) => {
    this.addLiabilityBot.onModalShow(this.state.liabilityBotLoginData[index]);
  };

  onDeleteClickHandler = (index) => {
    this.deleteLiabilityBot.onModalShow(
      this.state.liabilityBotLoginData[index].id,
    );
  };

  componentWillMount() {
    this.props.getComponentIndex(this.props.location.pathname);
    this.props.onLinkChange(this.props.componentIndex);
    new BuggyApi()
      .getBotLogin()
      .then((response) => {
        if (this.is_mounted) {
          this.setState({
            isAllowed: true,
            liabilityBotLoginData: response.data.objects,
            itemsCountPerPage: response.data.meta.limit,
            totalItemsCount: response.data.meta.total_count,
          });
        }
      })
      .catch((error) => {
        /* console.log('Error', error); */
        if (error.response) {
          let error_msg = "You're not authorized to use this feature please contact admin";
          if (error.response.data) {
            if (error.response.data.detail) {
              error_msg = error.response.data.detail;
            }
          }
          this.setState({
            isAllowed: false,
            responseError: error_msg,
          });
        } else if (error.request) {
        } else {
          /* console.log('Error', error.message); */
        }
      });
  }

  componentWillUnmount() {
    this.is_mounted = false;
  }

  searchClickHandler = () => {
    new BuggyApi()
      .getBotLogin(
        0,
        this.searUsername.value,
        this.dateFrom.state.inputValue,
        this.dateTo.state.inputValue,
      )
      .then((response) => {
        if (this.is_mounted) {
          this.setState({
            liabilityBotLoginData: response.data.objects,
            activePage: 1,
            isAllowed: true,
            itemsCountPerPage: response.data.meta.limit,
            totalItemsCount: response.data.meta.total_count,
          });
        }
      })
      .catch((error) => {
        /* console.log('Error', error); */
        if (error.response) {
          let error_msg = "You're not authorized to use this feature please contact admin";
          if (error.response.data) {
            if (error.response.data.detail) {
              error_msg = error.response.data.detail;
            }
          }
          this.setState({
            isAllowed: false,
            responseError: error_msg,
          });
        } else if (error.request) {
        } else {
          /* console.log('Error', error.message); */
        }
      });
  };

  onAddBotClickHandler = () => {
    this.addLiabilityBot.onModalShow();
  };

  onClickRunBot = (id) => {
    this.setState({ isLoading: true });
    new BuggyApi()
      .runLiabilityBot({ id })
      .catch(() => {
        this.setState({
          responseError: 'There was an error',
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  refreshBotLogin = () => {
    this.setState({ isLoading: true });
    new BuggyApi()
      .getBotLogin()
      .then((response) => {
        if (response.data) {
          const data_to_set = { isLoading: false };
          if (response.data.objects) {
            data_to_set.liabilityBotLoginData = response.data.objects;
          }
          if (response.data.meta) {
            data_to_set.itemsCountPerPage = response.data.meta.limit;
            data_to_set.totalItemsCount = response.data.meta.total_count;
          }
          this.setState(data_to_set);
        }
      })
      .catch((error) => {
        // console.log(error.response);
      });
  };

  render() {
    const rows = [];
    this.state.liabilityBotLoginData.map((login, index) => rows.push(
      <tr key={index}>
        <td>{login.username}</td>
        <td>{login.password}</td>
        <td>{login.security_code}</td>
        <td>{login.login_url}</td>
        <td>{login.date_added}</td>
        <td>{login.last_updated}</td>
        <td>{login.last_login}</td>
        <td>
          {login.status === 'ACTIVE' ? (
            <span className="label label-success-border">Active</span>
          ) : (
            <span className="label label-danger-border">Deactivated</span>
          )}
        </td>
        <td>
          <div className="dropdown">
            <a
              href
              className="action-icon dropdown-toggle btn btn-outline-info"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v" />
            </a>
            <ul className="dropdown-menu pull-right">
              <li>
                <a
                  href
                  className="btn btn-outline-info"
                  onClick={() => this.onEditClickHandler(index)}
                >
                  <i className="fa fa-pencil m-r-5" />
                  {' '}
                  Edit
                </a>
              </li>
              <li>
                <a
                  href
                  className="btn btn-outline-info"
                  onClick={() => this.onDeleteClickHandler(index)}
                >
                  <i className="fa fa-trash-o m-r-5" />
                  {' '}
                  Delete
                </a>
                <a
                  href
                  className="btn btn-outline-info"
                  onClick={() => this.onClickRunBot(login.id)}
                >
                  <i className="fa fa-refresh m-r-5" />
                  {' '}
                  Run Bot
                </a>
              </li>
            </ul>
          </div>
        </td>
      </tr>,
    ));

    return (
      <Aux>
        <div
          className={
            this.props.sidebar_status
              ? 'page-wrapper slide-nav-toggle'
              : 'page-wrapper'
          }
        >
          <div className="content container-fluid">
            <BlockUi tag="div" blocking={this.state.blocking}>
              <div className="row">
                <div className="col-sm-4 col-xs-3">
                  <h4 className="page-title">Liability Bots</h4>
                </div>
                <div className="col-sm-8 col-xs-9 text-right m-b-20">
                  <button
                    className="btn btn-primary rounded "
                    onClick={this.onAddBotClickHandler}
                  >
                    <i className="fa fa-plus" />
&nbsp; Add Bot Login
                  </button>
                </div>
              </div>
              {this.state.isAllowed ? (
                <>
                  <div className="row filter-row">
                    <div className="col-sm-4 col-xs-6">
                      <div
                        className={
                          this.state.focusedUsername
                            ? 'form-group form-focus focused'
                            : 'form-group form-focus'
                        }
                      >
                        <label className="control-label">Username</label>
                        <input
                          type="text"
                          onFocus={this.onFocusHandlerToggleUsername}
                          onBlur={this.onFocusHandlerToggleUsername}
                          className="form-control"
                          ref={(el) => (this.searUsername = el)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4 col-xs-12">
                      <div className="row">
                        <div className="col-sm-6 col-xs-6">
                          <div
                            className={
                              this.state.focusedFrom
                                ? 'form-group form-focus focused'
                                : 'form-group form-focus'
                            }
                          >
                            <label className="control-label">From</label>
                            <div className="cal-icon">
                              <Datetime
                                dateFormat="YYYY-MM-DD"
                                onFocus={this.onFocusHandlerDateFrom}
                                onBlur={this.onBlurHandlerDateFrom}
                                timeFormat={false}
                                ref={(el) => (this.dateFrom = el)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-xs-6">
                          <div
                            className={
                              this.state.focusedTo
                                ? 'form-group form-focus focused'
                                : 'form-group form-focus '
                            }
                          >
                            <label className="control-label">To</label>
                            <div className="cal-icon">
                              <Datetime
                                dateFormat="YYYY-MM-DD"
                                onFocus={this.onFocusHandlerDateTo}
                                onBlur={this.onBlurHandlerDateTo}
                                timeFormat={false}
                                ref={(el) => (this.dateTo = el)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-offset-2 col-sm-2 col-xs-6">
                      <button
                        type="button"
                        className="btn btn-success btn-block"
                        onClick={this.searchClickHandler}
                      >
                        {' '}
                        Search
                        {' '}
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <table className="table table-striped custom-table m-b-0 ">
                          <thead>
                            <tr>
                              <th>Username</th>
                              <th>Password</th>
                              <th>Security Code</th>
                              <th>URL</th>
                              <th>Date Added</th>
                              <th>Last Updated</th>
                              <th>Last Login</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>{rows}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-5">
                      <div className="dataTables_info">
                        Showing
                        {' '}
                        {this.state.itemsCountPerPage
                          * (this.state.activePage - 1)}
                        {' '}
                        to&nbsp;
                        {this.state.itemsCountPerPage
                          * this.state.activePage}
                        {' '}
                        of&nbsp;
                        {this.state.totalItemsCount}
                        {' '}
                        entries
                      </div>
                    </div>
                    <div className="col-sm-7">
                      <div className="dataTables_paginate">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={this.state.itemsCountPerPage}
                          totalItemsCount={this.state.totalItemsCount}
                          pageRangeDisplayed={this.state.pageRangeDisplayed}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div style={{ textAlign: 'lef', color: '#9e9e9e' }}>
                  <h2>
                    Error:
                    {this.state.responseError}
                  </h2>
                </div>
              )}
            </BlockUi>
          </div>
        </div>
        <AddLiabilityBotLogin
          ref={(el) => (this.addLiabilityBot = el)}
          refresh={this.refreshBotLogin}
        />

        <DeleteLiabilityBotLogin
          ref={(el) => (this.deleteLiabilityBot = el)}
          refresh={this.refreshBotLogin}
        />
      </Aux>
    );
  }
}
const mapStatesToProps = (state) => ({
  componentIndex: state.componentIndex,
  sidebar_status: state.sidebarOpened,
});
const mapDispatchToProps = (dispatch) => ({
  onLinkChange: (i) => dispatch({ type: actionTypes.UPDATE_NAVIGATION, i }),
  getComponentIndex: (path) => dispatch({ type: actionTypes.GET_COMPONENT_INDEX, path }),
});
export default connect(mapStatesToProps, mapDispatchToProps)(LiabilityBotLogin);
