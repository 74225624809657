import {
  useTheme,
} from '@mui/material';
import { DataGridPro, GridFilterModel } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';

import { listDocuments } from '@/services/documents';

import generateDocumentsTableColumns from './columns';

function DocumentsTable() {
  const theme = useTheme();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  });
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });

  const { data, isLoading } = useQuery({
    queryKey: [
      'documents',
      'list',
      paginationModel.page,
      paginationModel.pageSize,
      filterModel,
    ],
    queryFn: () => listDocuments({ pagination: paginationModel }),
  });

  const columns = useMemo(() => generateDocumentsTableColumns(), []);

  return (
    <DataGridPro
      columns={columns}
      rows={data?.data?.results || []}
      rowCount={data?.data.count || 0}
      loading={isLoading}
      autoHeight
      sx={{
        backgroundColor: theme.palette.background.paper,
      }}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      pagination
      paginationMode="server"
      filterMode="server"
      onFilterModelChange={setFilterModel}
      filterModel={filterModel}
    />
  );
}

export default DocumentsTable;
