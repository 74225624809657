import { Icon, Tooltip } from '@mui/material';
import currency from 'currency.js';
import React from 'react';
import { MdAddCircle, MdChangeCircle, MdRemoveCircle } from 'react-icons/md';

import { WalletTransactionType } from '@/services/billing';

export function formatAmount(transactionType: WalletTransactionType, amount: string) {
  return transactionType === 'inbound' ? `+${currency(amount).format()}` : `-${currency(amount).format()}`;
}

export function transactionText(transactionType: WalletTransactionType) {
  return transactionType === 'inbound' ? 'Credit purchased' : 'Credit consumed';
}

export function mapTypeToIcon(transactionType: WalletTransactionType, status: string) {
  const ICON_MAP = {
    inbound: MdAddCircle,
    outbound: MdRemoveCircle,
  } as const;

  const TransactionIcon = status === 'pending' ? MdChangeCircle : ICON_MAP[transactionType];

  return (
    <Tooltip title={status}>
      <Icon>
        <TransactionIcon size={24} />
      </Icon>
    </Tooltip>
  );
}
