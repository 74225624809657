import { isEmpty } from 'lodash';
import QueryString from 'qs';

import { APIListResponse, ParkingTicket } from '../types/api';
import fleetAxios from './axios';

export function partialUpdateTicketList(updates: Array<unknown>) {
  return fleetAxios.patch('/ticket/', updates);
}

export type GetParkingTicketsParams = {
  offset?: number;
  limit?: number;
  plateNumber?: string;
  dateFrom?: string;
  dateTo?: string;
};

export function getParkingTickets({
  plateNumber, dateFrom, dateTo, ...rest
}: GetParkingTicketsParams = {}) {
  const queryParam = QueryString.stringify({
    ...rest,
    plate_number: !isEmpty(plateNumber) ? plateNumber?.toUpperCase() : undefined,
    received_date_from: !isEmpty(dateFrom) ? dateFrom : undefined,
    received_date_to: !isEmpty(dateTo) ? dateTo : undefined,
  });

  return fleetAxios.get<APIListResponse<ParkingTicket>>(`/camera_traffic_ticket/?${queryParam}`);
}

export function getParkingTicketsDownloadLink(
  {
    plateNumber, dateFrom, dateTo, columns,
  }: GetParkingTicketsParams & { columns?: string[] } = {},
) {
  const queryParam = QueryString.stringify({
    plate_number: !isEmpty(plateNumber) ? plateNumber : undefined,
    received_date_from: !isEmpty(dateFrom) ? dateFrom : undefined,
    received_date_to: !isEmpty(dateTo) ? dateTo : undefined,
    limit: 0,
    download: 'csv',
    columns: columns ? columns.join(',') : undefined,
  });

  return fleetAxios.get(`/camera_traffic_ticket/?${queryParam}`);
}

export function updateParkingTicketBillingStatus(id: number, data: { billing_status: string }) {
  return fleetAxios.put(`/camera_traffic_ticket/${id}/`, data);
}

type ParkingTicketUpdate = {
  id: number;
  description?: string;
  driverEmail?: string;
};

export function partialUpdateParkingTicketList(updates: ParkingTicketUpdate[]) {
  return fleetAxios.patch('/camera_traffic_ticket/', updates.map(
    ({ driverEmail, ...rest }) => ({
      ...rest,
      driver__email: driverEmail,
    }),
  ));
}

export default {};
