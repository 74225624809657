import React, {Component} from 'react';

import StripeCheckout from 'react-stripe-checkout';

import BuggyApi from "../../services/BuggyApi";


const CURRENCY = 'USD';




// const Checkout = ({ name, description, amount, public_key }) =>
//   ;


class Checkout extends Component{

    fromUSDToCent = amount => amount * 100;

    successPayment = data => {
        this.props.refresh();
    };

    errorPayment = data => {
      alert('Payment Error');
    };

    onToken = (amount, description) => token =>{

        let formData = {
            description,
            source: token.id,
            amount: this.fromUSDToCent(this.props.amount)
        };
        new BuggyApi().getCardPayment(formData).then(this.successPayment).catch(this.errorPayment);
    };
    render(){
        return(
            <StripeCheckout
                name={this.props.name}
                style={{display: "inline"}}
                description={this.props.description}
                amount={this.fromUSDToCent(this.props.amount)+(this.fromUSDToCent(this.props.amount)* (3/100))}
                token={this.onToken(this.props.amount, this.props.description)}
                currency={CURRENCY}
                stripeKey={this.props.public_key}>
                  <button className="btn btn-primary rounded" style={{
                        padding: "2px 10px 2px 10px",
                        display: "inline",
                        marginTop: "10px"}}>

                      Recharge
                  </button>

            </StripeCheckout>
        );
    };
}

export default Checkout;