import { Stack, Typography } from '@mui/material';
import currency from 'currency.js';
import { format, parseISO } from 'date-fns';
import { map, sum } from 'lodash';
import React from 'react';
import Chart from 'react-apexcharts';

import { SubscriptionUsageProps } from './types';
import UsageDetails from './UsageDetails';

function SubscriptionUsage({ usage }: SubscriptionUsageProps) {
  return (
    <Stack>
      <Stack direction="row" marginX={2} spacing={1}>
        <Typography color="grey" variant="caption">
          {format(parseISO(usage.from_datetime), 'dd MMM, yyyy')}
        </Typography>
        <Typography color="grey" variant="caption">
          -
        </Typography>
        <Typography color="grey" variant="caption">
          {format(parseISO(usage.to_datetime), 'dd MMM, yyyy')}
        </Typography>
      </Stack>
      <Chart
        type="donut"
        series={map(
          usage.charges_usage,
          ({ amount_cents }) => currency(amount_cents, { fromCents: true }).value,
        )}
        width="100%"
        height="350"
        options={{
          labels: map(usage.charges_usage, 'billable_metric.name'),
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  total: {
                    showAlways: true,
                    show: true,
                    formatter({ config: { series } }) {
                      return currency(sum(series)).format();
                    },
                  },
                },
              },
            },
          },
          tooltip: {
            y: {
              formatter(val) {
                return currency(val).format();
              },
            },
          },
        }}
      />
      <UsageDetails usage={usage} />
    </Stack>
  );
}

export default SubscriptionUsage;
