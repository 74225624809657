import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import {
  filter, findIndex, includes, map,
} from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import {
  MdAdd,
  MdDelete,
} from 'react-icons/md';

import FetchingAutocompleteField from '@/components/FetchingAutocompleteField';
import { createReturnShipment, listTransponders } from '@/services/transponders';
import {
  TransponderStatusEnum,
  TransponderType,
} from '@/services/transponders/types';

import { shipmentFormSchema, ShipmentFormType } from './createReturnShipmentSchema';

type Props = DialogProps & {
  initialTransponders?: TransponderType[]
};

export default function CreateShipmentFormDialog({
  onClose,
  initialTransponders = [],
  open,
  ...rest
}: Props) {
  const queryClient = useQueryClient();

  const {
    control, handleSubmit, formState, reset, register, setError, watch,
  } = useForm<ShipmentFormType>({
    resolver: zodResolver(shipmentFormSchema),
  });

  const transponders = watch('transponders');

  const {
    append: appendTransponder,
    fields: addedTransponders,
    remove: removeTransponder,
    replace: replaceTransponders,
  } = useFieldArray({ control, name: 'transponders' });

  useEffect(() => {
    if (open) {
      replaceTransponders(initialTransponders);
    }
  }, [initialTransponders, open, replaceTransponders]);

  const [transponderToAdd, setTransponderToAdd] = useState<TransponderType | null>(null);

  const fetchTransponderOptions = useCallback(async (input?: string) => {
    const transponderResponse = await listTransponders({
      search: input,
      filter: {
        status: [
          TransponderStatusEnum.ACTIVE,
          TransponderStatusEnum.PENDING_VEHICLE,
        ],
      },
    });

    const addedTranspondersNumbers = map(addedTransponders, 'number');

    return filter(
      transponderResponse.data.results,
      ({ number }) => !includes(addedTranspondersNumbers, number),
    );
  }, [addedTransponders]);

  const onCloseDialog = useCallback((
    event: {},
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => {
    reset({ transponders: [] });
    onClose?.(event, reason);
  }, [onClose, reset]);

  const handlerTranspondersError = useCallback((transponderErrors: unknown) => {
    if (transponderErrors instanceof Array) {
      transponderErrors.forEach((error) => {
        if (error.object && error.message) {
          const { number } = error.object as Pick<TransponderType, 'number'>;

          const transponderIndex = findIndex(transponders, { number });

          if (transponderIndex > -1) {
            setError(
              `transponders.${transponderIndex}`,
              { message: error.message },
            );
          }
        }
      });
    }
  }, [setError, transponders]);

  const { mutate: submitShipmentForm, isLoading } = useMutation({
    mutationFn: (values: ShipmentFormType) => createReturnShipment(values),
    onError: (e) => {
      if (!isAxiosError(e)) {
        enqueueSnackbar({ variant: 'error', message: 'An unknown error occurred' });
        return;
      }

      if (e.response?.status === 400) {
        const transponderErrors = e.response.data.transponders;
        handlerTranspondersError(transponderErrors);
      }
    },
    onSuccess: () => {
      onClose?.({}, 'backdropClick');
      enqueueSnackbar({
        variant: 'success',
        message: 'Successfully created shipment',
      });

      queryClient.invalidateQueries({
        queryKey: ['transponders'],
      });

      queryClient.invalidateQueries({
        queryKey: ['shipments'],
      });
    },
  });

  const onClickAddTransponder = useCallback(() => {
    if (transponderToAdd) {
      appendTransponder(transponderToAdd);
      setTransponderToAdd(null);
    }
  }, [appendTransponder, transponderToAdd]);

  const onSubmit = useCallback((values: ShipmentFormType) => {
    submitShipmentForm(values);
  }, [submitShipmentForm]);

  return (
    <Dialog {...rest} onClose={onCloseDialog} open={open}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          Ship Transponders
        </DialogTitle>
        <DialogContent>
          <Stack mt={1} spacing={2}>
            <TextField
              {...register('remarks')}
              label="Remarks"
            />
            <Divider>
              <Typography variant="caption" color="GrayText">
                Transponders
              </Typography>
            </Divider>
            {formState.errors.transponders?.message && (
              <Alert severity="error">
                {formState.errors.transponders?.message}
              </Alert>
            )}
            {addedTransponders.map(({ id }, index) => (
              <Controller
                key={id}
                control={control}
                name={`transponders.${index}`}
                render={({ field, fieldState }) => (
                  <Stack direction="row" alignItems="center">
                    <FetchingAutocompleteField
                      fetchOptions={fetchTransponderOptions}
                      getOptionLabel={({ number }) => number}
                      value={field.value || null}
                      onChange={(e, value) => field.onChange(value)}
                      onBlur={field.onBlur}
                      errorMessage={fieldState.error?.message}
                      fullWidth
                      size="small"
                      queryKey={['transponders']}
                    />
                    <IconButton onClick={() => removeTransponder(index)}>
                      <MdDelete />
                    </IconButton>
                  </Stack>
                )}
              />
            ))}
            <Typography variant="subtitle2">
              Total Transponders:
              {' '}
              {addedTransponders.length}
            </Typography>
            <Stack direction="row" alignItems="center">
              <FetchingAutocompleteField
                fetchOptions={fetchTransponderOptions}
                getOptionLabel={({ number }) => number}
                value={transponderToAdd}
                onChange={(e, value) => setTransponderToAdd(value)}
                fullWidth
                size="small"
                queryKey={['transponders']}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    onClickAddTransponder();
                  }
                }}
              />
              <IconButton onClick={onClickAddTransponder}>
                <MdAdd />
              </IconButton>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            onClick={() => onCloseDialog({}, 'escapeKeyDown')}
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isLoading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
