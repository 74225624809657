import React, {Component} from 'react';
import {connect} from 'react-redux';
class sidebarOverlay extends Component{


    render(){
        return(
            <div className={(this.props.sidebar_status)?"sidebar-overlay opened": "sidebar-overlay "} data-reff="#sidebar">

            </div>
        );
    };
}
const mapStatesToProps =(state)=>{
    return{
        sidebar_status: state.sidebarOpened
    }
};
export default connect(mapStatesToProps,null)(sidebarOverlay);