import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  Skeleton,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { range } from 'lodash';
import React, { useState } from 'react';
import { MdAdd } from 'react-icons/md';

import { getCustomFields } from '@/services/customFields';

import CustomFieldFormDialog from './CustomFieldFormDialog';
import CustomFieldListIem from './CustomFieldListItem';
import { CustomFieldListDialogProps } from './types';

function CustomFieldListDialog({ ...dialogProps }: CustomFieldListDialogProps) {
  const [isCreateOpen, setIsCreateOpen] = useState(false);

  const { isLoading, data } = useQuery({
    queryKey: ['customFields', 'vehicle'],
    queryFn: () => getCustomFields('vehicle'),
  });

  const onClickNew = () => {
    setIsCreateOpen(true);
  };

  const onCloseNewFieldDialog = () => {
    setIsCreateOpen(false);
  };

  const fields = data?.data || [];

  const loader = () => range(3).map((i) => <Skeleton height={50} key={i} />);

  const list = () => (
    <List disablePadding>
      {fields.map((field) => (
        <CustomFieldListIem field={field} key={field.id} />
      ))}
    </List>
  );

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>
        Custom Fields
      </DialogTitle>
      <DialogContent>
        {isLoading
          ? loader()
          : list()}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => dialogProps.onClose?.(e, 'backdropClick')}
        >
          Close
        </Button>
        <Button
          startIcon={<MdAdd />}
          onClick={onClickNew}
        >
          New Field
        </Button>
      </DialogActions>
      <CustomFieldFormDialog
        open={isCreateOpen}
        onClose={onCloseNewFieldDialog}
        maxWidth="sm"
        fullWidth
      />
    </Dialog>
  );
}

export default CustomFieldListDialog;
