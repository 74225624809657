import { map } from 'lodash';
import qs from 'qs';

import { AssignVehicleParams, UnassignVehicleParams } from '@/pages/transponders/types';

import fleetAxios from '../axios';
import { APIQuery, APIResponse } from '../types';
import {
  ConfirmShipmentParams, CourierType, CreateReturnShipmentParams, ShipmentType, TransponderType,
} from './types';

const BASE_URL_ROOT = process.env.REACT_APP_BASE_URL_ROOT;

export function listTransponders({
  pagination = {
    pageSize: 25,
    page: 0,
  },
  search,
  filter,
  sortModel,
}:APIQuery) {
  const params = qs.stringify({
    limit: pagination?.pageSize,
    offset: pagination.page * pagination.pageSize,
    ordering: sortModel && sortModel.length > 0 ? `${sortModel[0].sort === 'desc' ? '-' : ''}${sortModel[0].field}` : undefined,
    search,
    ...filter,
  }, { arrayFormat: 'repeat' });
  const url = `/v2/transponders/?${params}`;

  return fleetAxios.get<APIResponse<TransponderType>>(url, {
    baseURL: BASE_URL_ROOT,
  });
}

export function assignVehicleToTransponder({
  transponderId,
  vehicleId,
}: AssignVehicleParams) {
  return fleetAxios.put(
    `/v2/transponders/${transponderId}/vehicle/`,
    {
      vehicle: vehicleId,
    },
    {
      baseURL: BASE_URL_ROOT,
    },
  );
}

export function listShipments({
  pagination = {
    pageSize: 25,
    page: 0,
  },
  search,
  filter,
}:APIQuery) {
  const params = qs.stringify({
    limit: pagination?.pageSize,
    offset: pagination.page * pagination.pageSize,
    search,
    ...filter,
  });
  const url = `/v2/transponders/shipments/?${params}`;

  return fleetAxios.get<APIResponse<ShipmentType>>(url, {
    baseURL: BASE_URL_ROOT,
  });
}

export function listCouriers() {
  return fleetAxios.get<CourierType[]>(
    '/v2/transponders/shipments/couriers/',
    {
      baseURL: BASE_URL_ROOT,
    },
  );
}

export function unassignVehicleFromTransponder({ transponderId }: UnassignVehicleParams) {
  return fleetAxios.delete(
    `/v2/transponders/${transponderId}/vehicle/`,
    {
      baseURL: BASE_URL_ROOT,
    },
  );
}

export function confirmShipment({
  courier,
  trackingCode,
  shipment,
}: ConfirmShipmentParams) {
  const url = `/v2/transponders/shipments/${shipment.id}/ship/`;

  return fleetAxios.post(
    url,
    { courier, tracking_code: trackingCode },
    { baseURL: BASE_URL_ROOT },
  );
}

export function receiveShipment(shipment: ShipmentType) {
  const url = `/v2/transponders/shipments/${shipment.id}/receive/`;

  return fleetAxios.post(
    url,
    undefined,
    { baseURL: BASE_URL_ROOT },
  );
}

export function createReturnShipment(params: CreateReturnShipmentParams) {
  const data = {
    ...params,
    transponders: map(params.transponders, 'id'),
  };

  return fleetAxios.post(
    '/v2/transponders/shipments/',
    data,
    { baseURL: BASE_URL_ROOT },
  );
}
