export const SUMMARY_EMAIL_CHOICES = [
  {
    id: 'NONE',
    text: 'None',
  },
  {
    id: 'DAILY',
    text: 'Daily',
  },
  {
    id: 'WEEKLY',
    text: 'Weekly',
  },
  {
    id: 'MONTHLY',
    text: 'Monthly',
  },
];

export const DRIVER_INVOICE_AMOUNT_TYPES = [
  {
    value: 'REAL_COST',
    text: 'Real Cost',
  },
  {
    value: 'CASH_COST',
    text: 'Cash Cost',
  },
  {
    value: 'CUSTOM_FEE',
    text: 'Custom Fee',
  },
];

export const RECHARGE_AMOUNT_CHOICES = [
  {
    value: '400',
    text: '400',
  },
  {
    value: '500',
    text: '500',
  },
  {
    value: '600',
    text: '600',
  },
  {
    value: '700',
    text: '700',
  },
  {
    value: '800',
    text: '800',
  },
  {
    value: '900',
    text: '900',
  },
  {
    value: '1000',
    text: '1000',
  },
  {
    value: '1200',
    text: '1200',
  },
  {
    value: '1500',
    text: '1500',
  },
  {
    value: '1800',
    text: '1800',
  },
  {
    value: '1900',
    text: '1900',
  },
];
