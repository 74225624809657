import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { getYear } from 'date-fns';
import {
  camelCase, entries, first, isEmpty, mapKeys,
} from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { HiSparkles } from 'react-icons/hi2';

import { getCustomFields } from '@/services/customFields';
import { USA_STATES } from '@/services/USA_STATS';
import { addVehicle, updateVehicle } from '@/services/vehicles';
import { vehicleType } from '@/services/VehicleTypes';

import createAddVehicleSchema, { AddVehicleFormValues } from './addVehicleSchema';
import { AddVehicleFormDialogProps } from './types';

function AddVehicleFormDialog({
  onClose, showLessee, updateData, ...dialogProps
}: AddVehicleFormDialogProps) {
  const queryClient = useQueryClient();
  const { data } = useQuery({
    queryKey: ['custom_fields', 'vehicle'],
    queryFn: () => getCustomFields('vehicle'),
  });

  const customFields = data?.data || [];

  const {
    register, handleSubmit, formState, reset,
  } = useForm<AddVehicleFormValues>({
    resolver: zodResolver(createAddVehicleSchema()),
    defaultValues: {
      plateNumber: '',
      plateState: 'AZ',
    },
  });

  useEffect(() => {
    if (updateData) {
      reset(mapKeys(updateData, (_, key) => camelCase(key)));
    }
  }, [updateData, reset]);

  const { mutate: createVehicle, isLoading: isSubmitting } = useMutation({
    mutationFn: async (formValues: AddVehicleFormValues) => addVehicle(formValues),
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate(query) {
          return first(query.queryKey) === 'vehicles';
        },
      });
      onClose?.({}, 'backdropClick');

      enqueueSnackbar({ variant: 'success', message: 'Successfully added a new vehicle' });

      reset();
    },
    onError: (error) => {
      let errorMessage = 'An unknown error occurred';

      if (isAxiosError(error)) {
        if (error.response?.status === 400) {
          entries(error.response.data).forEach(
            ([key, message]) => enqueueSnackbar({
              variant: 'error',
              message: `${key}: ${message}`,
            }),
          );

          return;
        }

        errorMessage = error.message;
      }

      enqueueSnackbar({ variant: 'error', message: errorMessage });
    },
  });

  const { mutate: editVehicle, isLoading: isSubmittingUpdate } = useMutation({
    mutationFn: async (formValues: AddVehicleFormValues) => {
      updateVehicle(Number(formValues?.id), formValues);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate(query) {
          return first(query.queryKey) === 'vehicles';
        },
      });
      onClose?.({}, 'backdropClick');

      enqueueSnackbar({ variant: 'success', message: 'Successfully updated the vehicle' });

      reset();
    },
    onError: (error) => {
      let errorMessage = 'An unknown error occurred';

      if (isAxiosError(error)) {
        if (error.response?.status === 400) {
          entries(error.response.data).forEach(
            ([key, message]) => enqueueSnackbar({
              variant: 'error',
              message: `${key}: ${message}`,
            }),
          );

          return;
        }

        errorMessage = error.message;
      }

      enqueueSnackbar({ variant: 'error', message: errorMessage });
    },
  });

  const onSubmit = (formValues: AddVehicleFormValues) => {
    if (updateData) {
      editVehicle(formValues);
    } else {
      createVehicle(formValues);
    }
  };

  return (
    <Dialog
      {...dialogProps}
      onClose={onClose}
      scroll="body"
    >
      <DialogTitle>
        {updateData ? 'Update' : 'Add'}
        {' '}
        a vehicle
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack spacing={2} marginY={2}>
            <TextField
              label="Plate Number"
              fullWidth
              {...register('plateNumber')}
              error={!!formState.errors.plateNumber}
              helperText={formState.errors.plateNumber?.message}
              disabled={Boolean(updateData)}
            />
            <TextField
              label="Plate State"
              select
              fullWidth
              {...register('plateState')}
              defaultValue="NY"
              error={!!formState.errors.plateState}
              helperText={formState.errors.plateState?.message}
              disabled={Boolean(updateData)}
            >
              {USA_STATES.map(({ id, text }) => (
                <MenuItem key={id} value={id}>
                  {text}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Year"
              fullWidth
              defaultValue={getYear(new Date())}
              type="number"
              {...register('year')}
              error={!!formState.errors.year}
              helperText={formState.errors.year?.message}
            />
            <TextField
              label="Vehicle Type"
              select
              fullWidth
              {...register('vehicleType')}
              error={!!formState.errors.vehicleType}
              helperText={formState.errors.vehicleType?.message}
              defaultValue={null}
            >
              {vehicleType.map(({ id, text }) => (
                <MenuItem key={id} value={id}>
                  {text}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Make"
              fullWidth
              {...register('vehicleMake')}
              error={!!formState.errors.vehicleMake}
              helperText={formState.errors.vehicleMake?.message}
            />
            <TextField
              label="Model"
              fullWidth
              {...register('vehicleModel')}
              error={!!formState.errors.vehicleModel}
              helperText={formState.errors.vehicleModel?.message}
            />
            <TextField
              label="Tracking Code"
              fullWidth
              {...register('trackingCode')}
              error={!!formState.errors.trackingCode}
              helperText={formState.errors.trackingCode?.message}
            />
            <TextField
              label="Lessee/Renter"
              fullWidth
              {...register('lesseeRenter')}
              error={!!formState.errors.lesseeRenter}
              helperText={formState.errors.lesseeRenter?.message}
              style={{
                display: showLessee ? 'block' : 'none',
              }}
            />
            {!isEmpty(customFields) && (
            <Divider>
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
              >
                <Typography variant="caption">Custom Fields</Typography>
                <HiSparkles />
              </Stack>
            </Divider>
            )}
            {customFields.map(({ id, name }) => (
              <TextField
                key={id}
                label={name}
                fullWidth
                {...register(`customFields.${name}`)}
              />
            ))}
          </Stack>

        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            onClick={(e) => onClose?.(e, 'backdropClick')}
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            loading={isSubmitting || isSubmittingUpdate}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
export default AddVehicleFormDialog;
