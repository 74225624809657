import React, {Component} from 'react';
import BuggyApi from '../../services/BuggyApi';
import 'react-select2-wrapper/css/select2.css';

import './modal.css';


class AssociateDriver extends Component{
    state = {
        isShown:false,
        ticket_id: null,
        editMode: false,
        isSuccess: false,
        errorMessage:[],
        searchResults: [],
        search_with: "",
        blocking: false,
        selectedDriver: null,
        ticket_type: null,
        success: false
    };

    onSearchBoxChangeHandler = (e) => {
        this.setState({search_with: e.target.value})
    };

    onSelectDriverClickHandler = (driver) => {
        this.setState({
            selectedDriver: driver
        });
    };



    onSearchBtnClickHandler = () => {
        this.setState({
            blocking: true
        });
        new BuggyApi().searchDriver(0,  this.state.search_with).then((response)=>{
            if(response.status === 200){
                if(response.data){
                    if(response.data.objects){
                        this.setState({
                            blocking: false,
                            searchResults: response.data.objects
                        });
                    }
                }
            }

        }).catch((error)=>{
            console.log("Error", error.response);
            this.setState({
                blocking: false,
                searchResults: []
            });
        })
    };
    onModalShow=(ticket_id, ticket_type, is_edit=false)=>{
        document.body.classList.add('modal-open');
        this.setState({
            ticket_id: ticket_id,
            ticket_type: ticket_type
        });
        if(is_edit){
            this.setState({
                editMode: true
            });
        }else{

        }
        this.setState({
            isShown:true
        });
    };
    onModalClose = ()=> {
        document.body.classList.remove('modal-open');
        this.setState({
            isSuccess: false,
            isShown:false,
            errorMessage:[],
            editMode: false,
            ticket_id: null
        });
    };

    assignDriver = () => {
        this.setState({
            blocking: true,
            errorMessage: [],
            success: false
        });
        let data = {
            driver_id: this.state.selectedDriver.id,
            ticket_id: this.state.ticket_id,
            ticket_type: this.state.ticket_type
        };
        new BuggyApi().associateDriver(data).then((response)=>{
            if(response.status === 201){
                this.setState({
                    blocking: false,
                    success: true,
                    errorMessage:[],
                    search_with:"",
                    searchResults: []
                });
                if(this.props.refresh){
                    this.props.refresh()
                }
            }
        }).catch((error)=>{
            this.setState({
                blocking: false
            });
            if(error.response){
                console.log(error.response);
                if(error.response.data){
                    let messages = [];
                    if(error.response.data.driver_id){
                        messages.push(error.response.data.driver_id)
                    }
                    if(error.response.data.ticket_id){
                        messages.push(error.response.data.ticket_id)
                    }
                    if(error.response.data.ticket_type){
                        messages.push(error.response.data.ticket_type)
                    }
                    this.setState({
                        errorMessage: messages
                    })
                }
            }
        });
    };

    render(){

        return(
            <div className={(this.state.isShown)? "modal custom-modal center-modal fade in showModal": "modal custom-modal center-modal fade hideModal"}
                 role="dialog" >
                <div className="modal-dialog">
                    <button type="button" className="close" data-dismiss="modal" onClick={this.onModalClose}>&times;</button>
                    <div className="modal-content modal-lg">
                        <div className="modal-header text-center">
                            <h4 className="modal-title">
                                Associate Driver
                            </h4>
                        </div>
                        <div className="modal-body">
                            {
                                this.state.errorMessage.length > 0 ? this.state.errorMessage.map((message)=>{
                                    return (
                                        <alert className="danger">
                                            {message}
                                        </alert>
                                    )
                                }) : null
                            }
                            {
                                this.state.success ? <div className="row">
                                    <div className="col-xs-12">
                                        <div className="alert alert-success">
                                            <strong>Success!</strong> Ticket associated successfully!
                                        </div>
                                    </div>
                                </div> : null
                            }
                            <div className="input-group m-b-30">
                                <input placeholder="Search to add"
                                       value={this.state.search_with}
                                       onChange={this.onSearchBoxChangeHandler}
                                       className="form-control search-input input-lg"
                                       type="text"/>
									<span className="input-group-btn">
										<button type="button"
                                                onClick={this.onSearchBtnClickHandler}
                                                className="btn btn-primary btn-lg">Search</button>
									</span>
                            </div>
                            <div>
                                <ul className="media-list media-list-linked chat-user-list">
                                    {
                                        this.state.searchResults.map((driver, index)=>{
                                            return (
                                                <li className={`media ${this.state.selectedDriver? this.state.selectedDriver.id === driver.id ? 'active' : '' : ''}`} key={index} onClick={()=>this.onSelectDriverClickHandler(driver)}>
                                                    <button href="#" className="media-link">
                                                        <div className="media-left" style={{verticalAlign: "middle"}}>
                                                            <span className="avatar" style={{backgroundColor: driver.initials_color? driver.initials_color: '#' + (0x1000000 + Math.random() * 0xFFFFFF).toString(16).substr(1,6)}}>
                                                                {driver.first_name? driver.first_name.charAt(0) : driver.last_name? driver.last_name.charAt(0): "A"}
                                                            </span>
                                                        </div>
                                                        <div className="media-body media-middle text-nowrap">
                                                            <div className="user-name">
                                                                {driver.first_name+" "+driver.last_name}
                                                            </div>
                                                            <span className="designation">Driver ID (fleet system): {driver.driver_id}</span><br/>
                                                            <span className="designation">Driver ID (our system): {driver.id}</span>
                                                        </div>
                                                        <div className=" media-right text-nowrap" style={{verticalAlign: "middle"}}>
                                                            <span className="designation">Currently Driving: {driver.agreement_vehicle_plate}</span>
                                                        </div>
                                                    </button>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            <div className="m-t-50 text-center">
                                <button type="button" disabled={!this.state.selectedDriver} onClick={this.assignDriver} className="btn btn-primary btn-lg">Assign</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AssociateDriver;