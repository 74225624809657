import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material';
import React, { useRef, useState } from 'react';

type ConfirmationProps = {
  title: string,
  description?: string
};

function useConfirmation({ title, description }: ConfirmationProps) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<(value:boolean)=>void>();

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleConfirm = (value:boolean) => {
    handleClose();
    ref.current?.(value);
  };

  const confirm = async () => {
    setIsOpen(true);
    return new Promise((resolve) => {
      ref.current = resolve;
    });
  };

  const dialog = (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      maxWidth="xs"
    >
      <DialogTitle id="dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => handleConfirm(false)}>
          No
        </Button>
        <Button onClick={() => handleConfirm(true)} color="error">Yes</Button>
      </DialogActions>
    </Dialog>
  );

  return {
    dialog, confirm,
  };
}

export default useConfirmation;
