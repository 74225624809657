import { Palette } from '@mui/material';
import React from 'react';
import { MdCheck, MdClose } from 'react-icons/md';
import { RxDotFilled } from 'react-icons/rx';

import { InvoicePaymentStatus } from '@/services/billing';

export function mapStatusToColor(status: InvoicePaymentStatus, palette: Palette) {
  if (status === 'succeeded') return palette.success.main;
  if (status === 'failed') return palette.warning.main;

  return palette.grey[500];
}

export function mapStatusToIcon(status: InvoicePaymentStatus) {
  if (status === 'succeeded') return <MdCheck size={24} />;
  if (status === 'failed') return <MdClose size={24} />;

  return <RxDotFilled size={24} />;
}
