import 'bootstrap/dist/js/bootstrap.min';
// import "bootstrap/dist/css/bootstrap.min.css";
import './assets/css/bootstrap.min.css';
import './assets/css/font-awesome.min.css';
import './assets/css/style.css';
import './App.css';

import { createTheme, ThemeProvider } from '@mui/material';
import { orange } from '@mui/material/colors';
import { LicenseInfo } from '@mui/x-license-pro';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import React, { Component } from 'react';
import { pdfjs } from 'react-pdf';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import Tour from 'reactour';

import Billing from './components/Billing/Billing';
import Dashboard from './components/Dashboard/Dashboard';
import Driver from './components/Drivers/Driver';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import Homepage from './components/Homepage/Homepage';
import IntegrationHome from './components/Integration/IntegrationHome';
import LiabilityBotLogin from './components/LiabilityBot/LiabilityBotLogin';
import Login from './components/Login/Login';
import Logs from './components/Logs/Logs';
import Mainwrapper from './components/Mainwrapper/Mainwrapper';
import Parking from './components/Parking/Parking';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import SetNewPassword from './components/SetNewPassword/SetNewPassword';
import Settings from './components/Settings/Settings';
import Sidebar from './components/Sidebar/Sidebar';
import SidebarOverlay from './components/Sidebar/SidebarOverlay/SidebarOverlay';
import Signup from './components/Signup/Signup';
import Tickets from './components/Tickets/Tickets';
import Topbar from './components/Topbar/Topbar';
import MyProfile from './components/UserProfile/MyProfile';
import Vehicles from './components/Vehicles/Vehicles';
import LiabilityBotViolations from './components/Violations/Viloations';
import MotiveAuth from './pages/integrations/motive/Auth';
import AcceptInvitation from './pages/invitations/Accept';
import Shipments from './pages/shipments';
import Transponders from './pages/transponders';
import UsagePage from './pages/usage';
import BuggyApi from './services/BuggyApi';
import SetUpAxiosInterceptors from './SetUpAxiosInterceptors';
import * as actionTypes from './store/actions/actions';
import { productTourSteps } from './utils/productTourSteps';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: 'always',
      refetchOnWindowFocus: false,
    },
    mutations: {
      networkMode: 'always',
    },
  },
});

const theme = createTheme({
  palette: {
    primary: orange,
  },
});

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

class App extends Component {
  constructor(props) {
    super(props);
    LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_LICENSE_KEY);
  }

  refreshTopbar = () => {
    this.topbar.refresh();
  };

  closeTour = () => {
    this.props.onUserInfoUpdate({
      ...this.props.fleet_info,
      is_onboarded: true,
    });

    new BuggyApi()
      .updateUserInfo(this.props.fleet_info.user_id, { is_onboarded: true })
      .then((response) => {})
      .catch((error) => {
        enqueueSnackbar({ variant: 'error', message: 'Failed to update' });
      });
  };

  render() {
    return (
      <>
        <SetUpAxiosInterceptors />
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider>
              {this.props.loggedIn ? (
                <Tour
                  steps={productTourSteps}
                  isOpen={!this.props.fleet_info.is_onboarded}
                  onRequestClose={this.closeTour}
                />
              ) : null}
              <div>
                <Route path="/" exact render={() => <Homepage />} />
                <Route
                  path="/invites/:code"
                  exact
                  component={AcceptInvitation}
                />
                <Route
                  path="/login"
                  exact
                  render={(props) => (
                    <Login {...props} refreshTopbar={this.refreshTopbar} />
                  )}
                />
                <Route
                  path="/register"
                  exact
                  render={(props) => <Signup {...props} />}
                />
                <Route
                  path="/forgot-password"
                  exact
                  render={(props) => <ForgotPassword {...props} />}
                />
                <Route
                  path="/enternewpassword"
                  exact
                  render={(props) => <SetNewPassword {...props} />}
                />
                <Route
                  path="/motive/auth"
                  exact
                  component={MotiveAuth}
                />
                <Mainwrapper>
                  <Topbar
                    ref={(el) => {
                      this.topbar = el;
                    }}
                  />
                  <Sidebar />
                  <PrivateRoute path="/dashboard" exact component={Dashboard} />
                  <PrivateRoute path="/violations" exact component={Tickets} />
                  {/* <PrivateRoute path="/violations" exact component={Tickets} /> */}
                  <PrivateRoute
                    path="/liability-bot"
                    exact
                    component={LiabilityBotLogin}
                  />
                  <PrivateRoute
                    path="/liability-bot/violations"
                    exact
                    component={LiabilityBotViolations}
                  />
                  <PrivateRoute path="/drivers" exact component={Driver} />
                  <PrivateRoute path="/vehicles" exact component={Vehicles} />
                  <PrivateRoute path="/transponders" exact component={Transponders} />
                  <PrivateRoute path="/shipments" exact component={Shipments} />
                  <PrivateRoute path="/logs" exact component={Logs} />
                  <PrivateRoute
                    path="/parking_traffic_tickets"
                    exact
                    component={Parking}
                  />
                  <PrivateRoute
                    path="/billing"
                    exact
                    component={
                      this.props?.fleet_info?.feature_flags?.billing_v2
                        ? UsagePage
                        : Billing
                      }
                  />
                  <PrivateRoute path="/usage" exact component={UsagePage} />
                  <PrivateRoute
                    path="/integration"
                    component={IntegrationHome}
                  />
                  <PrivateRoute path="/settings" exact component={Settings} />
                  <PrivateRoute
                    path="/my-profile"
                    exact
                    component={MyProfile}
                  />
                </Mainwrapper>
                <SidebarOverlay />
              </div>
            </SnackbarProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </>
    );
  }
}

const mapStatesToProps = (state) => ({
  fleet_info: state.userInfo,
  loggedIn: state.isLoggedIn,
});
const mapDispatchToProps = (dispatch) => ({
  onUserInfoUpdate: (userInfo) => dispatch({ type: actionTypes.UPDATE_USER_DETAILS, userInfo }),
});

export default connect(mapStatesToProps, mapDispatchToProps)(App);
