import styled from '@emotion/styled';
import { TextField } from '@mui/material';


const FleetTextField = styled(TextField)`
.MuiSvgIcon-root {
	color: white;
}
`;

export default FleetTextField