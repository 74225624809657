import $ from 'jquery';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { login } from '../../services/axios';
import BuggyApi from '../../services/BuggyApi';
import BuggyAuth from '../../services/BuggyAuth';
import * as actionTypes from '../../store/actions/actions';
import BlockUi from '../BlockUi';
import Aux from '../hoc/Auxiliary';
import SidebarOverlay from '../Sidebar/SidebarOverlay/SidebarOverlay';

class Login extends Component {
  state = {
    isInvalidUser: false,
    blocking: false,
    errorMessage: null,
  };

  componentDidUpdate() {}

  componentDidMount() {
    window.$ = window.jQuery = $;
    (function ($) {
      const Sidemenu = function () {
        this.$menuItem = $('#sidebar-menu a');
      };

      Sidemenu.prototype.init = function () {
        const $this = this;
        $this.$menuItem.on('click', function (e) {
          if ($(this).parent().hasClass('submenu')) {
            e.preventDefault();
          }
          if (!$(this).hasClass('subdrop')) {
            $('ul', $(this).parents('ul:first')).slideUp(350);
            $('a', $(this).parents('ul:first')).removeClass('subdrop');
            $(this).next('ul').slideDown(350);
            $(this).addClass('subdrop');
          } else if ($(this).hasClass('subdrop')) {
            $(this).removeClass('subdrop');
            $(this).next('ul').slideUp(350);
          }
        });
        $('#sidebar-menu ul li.submenu a.active')
          .parents('li:last')
          .children('a:first')
          .addClass('active')
          .trigger('click');
      };
      $.Sidemenu = new Sidemenu();
    }(window.jQuery));

    $(document).ready(() => {
      // Sidebar overlay

      const $sidebarOverlay = $('.sidebar-overlay');
      $('#mobile_btn, .task-chat').on('click', function (e) {
        const $target = $($(this).attr('href'));
        if ($target.length) {
          $target.toggleClass('opened');
          $sidebarOverlay.toggleClass('opened');
          $('html').toggleClass('menu-opened');
          $sidebarOverlay.attr('data-reff', $(this).attr('href'));
        }
        e.preventDefault();
      });

      $sidebarOverlay.on('click', function (e) {
        const $target = $($(this).attr('data-reff'));
        if ($target.length) {
          $target.removeClass('opened');
          $('html').removeClass('menu-opened');
          $(this).removeClass('opened');
          $('.main-wrapper').removeClass('slide-nav-toggle');
        }
        e.preventDefault();
      });

      // Sidebar Initiate

      $.Sidemenu.init();

      // Select2

      if ($('.select').length > 0) {
        $('.select').select2({
          minimumResultsForSearch: -1,
          width: '100%',
        });
      }

      // Modal Popup hide show

      if ($('.modal').length > 0) {
        const modalUniqueClass = '.modal';
        $('.modal').on('show.bs.modal', function (e) {
          const $element = $(this);
          const $uniques = $(`${modalUniqueClass}:visible`).not($(this));
          if ($uniques.length) {
            $uniques.modal('hide');
            $uniques.one('hidden.bs.modal', (e) => {
              $element.modal('show');
            });
            return false;
          }
        });
      }

      // Floating Label

      if ($('.floating').length > 0) {
        $('.floating')
          .on('focus blur', function (e) {
            $(this)
              .parents('.form-focus')
              .toggleClass(
                'focused',
                e.type === 'focus' || this.value.length > 0,
              );
          })
          .trigger('blur');
      }

      // Message Notification Slimscroll

      // Sidebar Slimscroll

      // Page Content Height

      if ($('.page-wrapper').length > 0) {
        const height = $(window).height();
        $('.page-wrapper').css('min-height', height);
      }

      $(window).resize(() => {
        if ($('.page-wrapper').length > 0) {
          const height = $(window).height();
          $('.page-wrapper').css('min-height', height);
        }
      });

      // Date Time Picker

      // Datatable

      // Tooltip

      // Button Switch

      if ($('.btn-toggle').length > 0) {
        $('.btn-toggle').click(function () {
          $(this).find('.btn').toggleClass('active');
          if ($(this).find('.btn-success').size() > 0) {
            $(this).find('.btn').toggleClass('btn-success');
          }
        });
      }

      // Mobile Menu

      if ($('.main-wrapper').length > 0) {
        const $wrapper = $('.main-wrapper');
        $(document).on('click', '#mobile_btn', (e) => {
          $('.dropdown.open > .dropdown-toggle').dropdown('toggle');
          return false;
        });
        $(document).on('click', '#mobile_btn', (e) => {
          $wrapper.toggleClass('slide-nav-toggle');
          $('#chat_sidebar').removeClass('opened');
          return false;
        });
        $(document).on('click', '#open_msg_box', (e) => {
          $wrapper.toggleClass('open-msg-box').removeClass('');
          $('.dropdown').removeClass('open');
          return false;
        });
      }

      // Message box remove

      if ($('.dropdown-toggle').length > 0) {
        $('.dropdown-toggle').click(() => {
          if ($('.main-wrapper').hasClass('open-msg-box')) {
            $('.main-wrapper').removeClass('open-msg-box');
          }
        });
      }

      // Table Dropdown

      $('.table-responsive').on('shown.bs.dropdown', function (e) {
        const $table = $(this);
        const $dropmenu = $(e.target).find('.dropdown-menu');
        const tableOffsetHeight = $table.offset().top + $table.height();
        const menuOffsetHeight = $dropmenu.offset().top + $dropmenu.outerHeight(true);

        if (menuOffsetHeight > tableOffsetHeight) $table.css('padding-bottom', menuOffsetHeight - tableOffsetHeight);
      });
      $('.table-responsive').on('hide.bs.dropdown', function () {
        $(this).css('padding-bottom', 0);
      });

      // Custom Modal Backdrop

      $('a[data-toggle="modal"]').on('click', () => {
        setTimeout(() => {
          if ($('.modal.custom-modal').hasClass('in')) {
            $('.modal-backdrop').addClass('custom-backdrop');
          }
        }, 500);
      });

      // Email Inbox

      if ($('.clickable-row').length > 0) {
        $('.clickable-row').click(function () {
          window.location = $(this).data('href');
        });
      }

      if ($('.checkbox-all').length > 0) {
        $('.checkbox-all').click(() => {
          $('.checkmail').click();
        });
      }
      if ($('.checkmail').length > 0) {
        $('.checkmail').each(function () {
          $(this).click(function () {
            if ($(this).closest('tr').hasClass('checked')) {
              $(this).closest('tr').removeClass('checked');
            } else {
              $(this).closest('tr').addClass('checked');
            }
          });
        });
      }
      if ($('.mail-important').length > 0) {
        $('.mail-important').click(function () {
          $(this).find('i.fa').toggleClass('fa-star');
          $(this).find('i.fa').toggleClass('fa-star-o');
        });
      }

      // Incoming call popup

      if ($('#incoming_call').length > 0) {
        $(window).on('load', () => {
          $('#incoming_call').modal('show');
          $('body').addClass('call-modal');
        });
      }
    });
    if (BuggyAuth.isAuthenticated() === true) {
      this.props.history.push('/dashboard');
    }
  }

  loginSubmitHandler = (event) => {
    event.preventDefault();
    this.setState({ blocking: true });

    login(this.username.value, this.password.value)
      .then((response) => {
        this.setState({ blocking: false });
        new BuggyApi()
          .getUserInfo()
          .then((response) => {
            if (response.status === 200) {
              this.props.onUserInfoUpdate(response.data);
            }
          })
          .catch((error) => {});
        new BuggyApi()
          .getPusherCredentials()
          .then((response) => {
            if (response.status === 200) {
              const pusherInfo = {
                key: response.data.pusher_key,
                cluster: response.data.pusher_cluster,
              };
              this.props.onPusherInfoUpdate(pusherInfo);
              // pusher = new Pusher(PUSHER_KEY, {
              //   cluster: PUSHER_CLUSTER,
              //   encrypted: true
              // });
              //
              // var channel = pusher.subscribe('fleet-it');
              // channel.bind('order-event', function(data) {
              //   alert(data.message);
              // });
            }
          })
          .catch((errors) => {});

        const { location } = this.props;
        const queryParams = new URLSearchParams(location.search);

        const nextRoute = queryParams.get('next') ?? '/dashboard';

        this.props.history.push(nextRoute);
        this.props.onLogin();
      })
      .catch((error) => {
        this.setState({ blocking: false });
        if (
          error.response
          && error.response.data
          && error.response.data.error
        ) {
          this.setState({
            isInvalidUser: true,
            errorMessage: error.response.data.error,
          });
        } else if (error.response) {
          this.setState({ isInvalidUser: true });
        } else if (error.request) {
          this.setState({ isInvalidUser: true });
        } else {
          this.setState({ isInvalidUser: true });
        }
      });
  };

  render() {
    return !this.props.loggedIn ? (
      <Aux>
        <div className="main-wrapper">
          <div className="account-page">
            <div className="container">
              <h3 className="account-title">FleetIT Login</h3>
              <div className="account-box">
                <div className="account-wrapper">
                  <div className="account-logo">{/* <a href="/"></a> */}</div>
                  <BlockUi tag="div" blocking={this.state.blocking}>
                    <form action="">
                      {this.props.signupSuccessful ? (
                        <div className="row">
                          <div className="col-xs-12">
                            <div className="alert alert-success">
                              <strong>Success!</strong>
                              {' '}
                              Signup successful!
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.isInvalidUser ? (
                        <div className="alert alert-danger">
                          <strong>
                            {this.state.errorMessage
                              ? this.state.errorMessage
                              : 'Oops! Your username or password is incorrect'}
                          </strong>
                        </div>
                      ) : null}
                      <div className="form-group form-focus">
                        <label className="control-label">Username</label>
                        <input
                          className="form-control floating"
                          type="text"
                          ref={(el) => (this.username = el)}
                        />
                      </div>
                      <div className="form-group form-focus">
                        <label className="control-label">Password</label>
                        <input
                          className="form-control floating"
                          type="password"
                          ref={(el) => (this.password = el)}
                        />
                      </div>
                      <div className="form-group text-center">
                        <button
                          className="btn btn-primary btn-block account-btn"
                          type="submit"
                          onClick={this.loginSubmitHandler}
                        >
                          Login
                        </button>
                      </div>
                      <div className="text-center">
                        <Link to="/forgot-password">Forgot your password?</Link>
                        <br />
                        <Link to="/register">
                          Don't have an account? Signup
                        </Link>
                      </div>
                    </form>
                  </BlockUi>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SidebarOverlay />
      </Aux>
    ) : null;
  }
}

const mapStatsToProps = (state) => ({
  signupSuccessful: state.signupSuccess,
});
const mapDispatchToProps = (dispatch) => ({
  onLogin: () => dispatch({ type: actionTypes.LOGIN }),
  onUserInfoUpdate: (userInfo) => dispatch({ type: actionTypes.UPDATE_USER_DETAILS, userInfo }),
  onPusherInfoUpdate: (pusherInfo) => dispatch({
    type: actionTypes.UPDATE_PUSHER_CREDENTIALS,
    pusher_details: pusherInfo,
  }),
});
export default connect(mapStatsToProps, mapDispatchToProps)(Login);
