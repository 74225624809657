import "./ToggleDatesCss.css";
import React, { Component } from "react";
import Aux from "../hoc/Auxiliary";
import BlockUi from "../BlockUi";
import BuggyApi from "../../services/BuggyApi";
import { ColumnChart } from "react-chartkick";
import Chart from "react-apexcharts";
import Datetime from "react-datetime";

class ToggleDatesGraph extends Component {
  state = {
    graph_data: [],
    graph_data2: [],
    graph_data3: [1],
    isDateRangeVisible: false,
    chart_options: {},
    chart_options2: {},
    chart_options3: {
      labels: ["NoEntry"],
      title: { text: "Toll Authority" },
      style: {
        fontSize: "20px",
      },
    },
    isLoading: false,
  };

  translateToApexChartsData = (line_graph_data, donut_graph_data) => {
    let chart_options = [];
    let violations = [];
    let costs = [];
    let total_violations = 0;
    let total_cost = 0;
    let donut_labels = [];
    let donut_data = [];
    line_graph_data.map((entry) => {
      chart_options.push(entry[0]);
      violations.push(entry[1]);
      costs.push(entry[2]);
      total_violations += parseInt(entry[1]);
      total_cost += parseFloat(entry[2]);
      return entry;
    });
    Object.keys(donut_graph_data).map((key) => {
      let key_value = donut_graph_data[key];
      donut_labels.push(key);
      donut_data.push(key_value);
      return true;
    });
    if (donut_labels.length < 1) {
      donut_labels.push("NoEntry");
      donut_data.push(1);
    }
    this.setState({
      chart_options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: true,
        },
        xaxis: {
          categories: chart_options,
        },
        title: {
          text: "Toll Transactions " + total_violations,
          align: "left",
          style: {
            fontSize: "20px",
          },
        },
        colors: ["#0b62a3"],
      },
      chart_options2: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: true,
        },
        xaxis: {
          categories: chart_options,
        },
        title: {
          text: "Total Cost " + total_cost.toFixed(2),
          align: "left",
          style: {
            fontSize: "20px",
          },
        },
        colors: ["#fc8455"],
      },
      chart_options3: {
        labels: donut_labels,
        title: { text: "Toll Authority" },
        style: {
          fontSize: "20px",
        },
      },
      graph_data: [
        {
          name: "Violations",
          data: violations,
        },
      ],
      graph_data2: [
        {
          name: "Cost",
          data: costs,
        },
      ],
      graph_data3: donut_data,
    });
  };

  componentDidMount() {
    this.is_mounted = true;
    this.translateToApexChartsData(this.props.data, this.props.donut_graph);
  }

  componentWillUnmount() {
    this.is_mounted = false;
  }

  hideCustomDateRangeElement = () => {
    this.setState({
      isDateRangeVisible: false,
    });
  };

  showCustomDateRangeElement = () => {
    this.setState({
      isDateRangeVisible: true,
    });
  };

  onDateRangeChanged = (event) => {
    let selected_value = event.target.value;
    this.hideCustomDateRangeElement();

    if (selected_value !== "custom") {
      this.setState({
        isLoading: true,
      });
      new BuggyApi()
        .getStats(selected_value)
        .then((response) => {
          if (this.is_mounted) {
            this.translateToApexChartsData(
              response.data.toggle_graph_values,
              response.data.toggle_graph_exit_plaza_values
            );
            this.setState({
              isLoading: false,
            });
          }
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });
        });
    } else {
      this.showCustomDateRangeElement();
    }
  };

  onApplyButtonClicked = () => {
    const from = this.dateFrom.state.inputValue;
    const to = this.dateTo.state.inputValue;
    if (from !== "" && to !== "") {
      this.setState({
        isLoading: true,
      });
      new BuggyApi()
        .getStats("custom", from, to)
        .then((response) => {
          if (this.is_mounted) {
            this.translateToApexChartsData(
              response.data.toggle_graph_values,
              response.data.toggle_graph_exit_plaza_values
            );
            this.setState({
              isLoading: false,
            });
          }
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });
        });
    }
  };

  isDateAvailable = (current) => {
    const min_date = Datetime.moment(this.props.min_available_date);
    return current.diff(min_date) >= 0;
  };

  render() {
    return (
      <Aux>
        <div className="col-md-12">
          <div className="row">
            <div className="col-sm-5"></div>
            <div className="col-sm-2">
              {this.state.isDateRangeVisible && (
                <div className="form-group form-focus">
                  <label className="control-label">From</label>
                  <div className="cal-icon">
                    <Datetime
                      dateFormat="YYYY-MM-DD"
                      isValidDate={this.isDateAvailable}
                      timeFormat={false}
                      ref={(el) => (this.dateFrom = el)}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="col-sm-2">
              {this.state.isDateRangeVisible && (
                <div className="form-group form-focus">
                  <label className="control-label">To</label>
                  <div className="cal-icon">
                    <Datetime
                      dateFormat="YYYY-MM-DD"
                      isValidDate={this.isDateAvailable}
                      timeFormat={false}
                      ref={(el) => (this.dateTo = el)}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="col-sm-1">
              {this.state.isDateRangeVisible && (
                <button
                  href="#"
                  className="btn btn-success btn-block fleetit-apply-btn"
                  onClick={this.onApplyButtonClicked}
                >
                  Apply
                </button>
              )}
            </div>
            <div className="col-sm-2">
              <div className="pull-right">
                <select
                  onChange={this.onDateRangeChanged}
                  className="form-control"
                  id="date-range-select"
                >
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="last7days">Last 7 days</option>
                  <option value="last14days">Last 14 days</option>
                  <option value="last28days">Last 28 days</option>
                  <option value="custom">Custom</option>
                </select>
              </div>
            </div>
          </div>
          <BlockUi tag="div" blocking={this.state.isLoading}>
            <div className="row top-buffer">
              <div className="col-sm-6">
                <div className="card-box">
                  <Chart
                    options={this.state.chart_options}
                    series={this.state.graph_data}
                    type="line"
                    width="100%"
                    height="350"
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card-box">
                  <Chart
                    options={this.state.chart_options2}
                    series={this.state.graph_data2}
                    type="line"
                    width="100%"
                    height="350"
                  />
                </div>
              </div>
            </div>
            <div className="row top-buffer">
              <div className="col-sm-6">
                <div className="card-box ">
                  <ColumnChart
                    curved={false}
                    label="Toll Transactions"
                    stacked={true}
                    width="100%"
                    height="350px"
                    legend="top"
                    library={{
                      label: {
                        html: "<span>Legend</span>",
                      },
                      legend: {
                        align: "center",
                        text: "Legend",
                        itemStyle: {
                          fontSize: "15px",
                        },
                        squareSymbol: true,
                        symbolRadius: 0,
                      },
                    }}
                    data={this.props.tickets_month_wise}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card-box">
                  <Chart
                    series={this.state.graph_data3}
                    options={this.state.chart_options3}
                    type="donut"
                    width="100%"
                    height="350"
                  />
                </div>
              </div>
            </div>
          </BlockUi>
        </div>
      </Aux>
    );
  }
}

export default ToggleDatesGraph;
