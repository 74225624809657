import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';

import BuggyAuth from '../../services/BuggyAuth';

function PrivateRoute({ component: Component, ...rest }) {
  const { search } = useLocation();

  return (
    <Route
      {...rest}
      render={(props) => (BuggyAuth.isAuthenticated() === true ? (
        <Component {...props} Authentication={BuggyAuth} />
      ) : (
        <Redirect to={{ pathname: '/login', search: new URLSearchParams({ next: `${rest.path}${search}` }).toString() }} />
      ))}
    />
  );
}

export default PrivateRoute;
