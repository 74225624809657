import { LoadingButton } from '@mui/lab';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { MdDownload } from 'react-icons/md';

import { downloadVehicles } from './helpers';

type DownloadVehiclesButtonProps = {
  search: string | undefined;
};

function DownloadVehiclesButton({ search }: DownloadVehiclesButtonProps) {
  const { mutate: download, isLoading } = useMutation({
    mutationKey: ['vehicles', 'download', search],
    mutationFn: () => downloadVehicles({ search }),
    onError: (err: AxiosError) => {
      enqueueSnackbar({
        message: err.message,
        variant: 'error',
      });
    },
  });

  return (
    <LoadingButton
      startIcon={<MdDownload />}
      loading={isLoading}
      onClick={() => download()}
      size="small"
    >
      Download Vehicles
    </LoadingButton>
  );
}

export default DownloadVehiclesButton;
