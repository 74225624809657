/**
 * This was created the replace react-block-ui package
 * since it's no longer maintained and is incompatible with newer versions of react
 */

import { Box, CircularProgress } from "@mui/material";
import React from "react";

// export type BlockUiProps<T = {}> = React.HTMLAttributes<HTMLElement> & {
//   blocking?: boolean,
//   children: React.ReactNode,
//   className?: string,
//   tag?: React.ElementType | string,
// } & T;

const BlockUi = ({ tag, blocking, className, children }) => {
  const Tag = tag || "div";

  return (
    <Tag className={className} style={{ position: "relative" }}>
      {children}
      {blocking && (
        <Box
          sx={{
            position: "absolute",
            height: "100%",
            width: "100%",
            top: 0,
            left: 0,
            backgroundColor: "transparent",
            backdropFilter: "blur(1px)",
            zIndex: 1000,
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={24} />
        </Box>
      )}
    </Tag>
  );
};

BlockUi.defaultProps = {
  tag: "div",
  className: undefined,
  blocking: false,
};

export default BlockUi;
