import { Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

import BlockUi from '../../BlockUi';
import { getIntegrations } from '../data';
import AppCard from './AppCard';
import styles from './AppIntegrations.module.css';

function AppIntegrations() {
  const {
    data: integrations,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['integrations'],
    queryFn: () => getIntegrations(),
  });

  return (
    <BlockUi tag="div" className={styles.main} blocking={isLoading}>
      <h4 className="page-title m-5">Third party integration</h4>
      <Grid container spacing={2}>
        {integrations?.map((appIntegration) => (
          <Grid item xs={12} sm={6} md={4} key={appIntegration.id}>
            <AppCard app={appIntegration} refreshIntegrations={refetch} />
          </Grid>
        ))}
      </Grid>
    </BlockUi>
  );
}

export default AppIntegrations;
