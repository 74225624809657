import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Link, Stack, TextField,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { entries } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import Recaptcha from 'react-recaptcha';
import { useHistory, useLocation } from 'react-router-dom';

import { acceptInvite } from '@/services/fleet';
import { FleetInvitation } from '@/services/fleet/types';

import { acceptInvitationFormSchema, AcceptInvitationFormValues } from './validationSchema';

type AcceptFormProps = {
  invitation: FleetInvitation
};

export default function AcceptForm({ invitation }: AcceptFormProps) {
  const { pathname } = useLocation();
  const history = useHistory();
  const { register, handleSubmit, formState } = useForm<AcceptInvitationFormValues>({
    resolver: zodResolver(acceptInvitationFormSchema),
  });

  const [recaptchaToken, setRecaptchaToken] = useState<string>();

  const recaptchaVerifyCallback = useCallback((token: string) => {
    setRecaptchaToken(token);
  }, []);

  const { mutate: acceptInvitation, isLoading } = useMutation({
    mutationFn: async (formValues: AcceptInvitationFormValues) => {
      if (!recaptchaToken) {
        throw new Error('Recaptcha token missing');
      }

      return acceptInvite(
        invitation.code,
        {
          ...formValues,
          recaptchaToken,
        },
      );
    },
    onSuccess: () => {
      history.replace('/login');
    },
    onError: (error) => {
      if (isAxiosError(error)) {
        if (error.response?.status === 404) {
          enqueueSnackbar({
            message: 'This invitation has already been accepted.',
            variant: 'error',
          });

          return;
        }

        if (error.response?.status === 400) {
          entries(error.response.data).forEach(
            ([key, message]) => enqueueSnackbar({
              variant: 'error',
              message: `${key}: ${message}`,
            }),
          );
        }
      }

      enqueueSnackbar({
        variant: 'error',
        message: 'Something went wrong',
      });
    },
  });

  const onSubmit = useCallback(
    (formValues: AcceptInvitationFormValues) => acceptInvitation(formValues),
    [acceptInvitation],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2} justifyContent="center" width="100%">
        <TextField
          disabled
          fullWidth
          label="Email"
          value={invitation.invited_email}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          {...register('username')}
          fullWidth
          label="Username"
          error={!!formState.errors.username}
          helperText={formState.errors.username?.message}
        />
        <TextField
          {...register('password')}
          type="password"
          fullWidth
          label="Password"
          error={!!formState.errors.password}
          helperText={formState.errors.password?.message}
        />
        <TextField
          {...register('phoneNumber')}
          fullWidth
          label="Phone Number"
          error={!!formState.errors.phoneNumber}
          helperText={formState.errors.phoneNumber?.message}
        />
        <Recaptcha
          sitekey={process.env.REACT_APP_CAPTCHA_SITEKEY}
          render="explicit"
          verifyCallback={recaptchaVerifyCallback}
        />
        <LoadingButton
          size="large"
          disabled={!recaptchaToken}
          type="submit"
          loading={isLoading}
        >
          Sign up
        </LoadingButton>
        <Link
          href={`/login?next=${encodeURIComponent(pathname)}`}
          textAlign="center"
        >
          Already have an account? Login instead.
        </Link>
      </Stack>
    </form>
  );
}
