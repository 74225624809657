import { GridPaginationModel } from '@mui/x-data-grid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import {
  entries, find, map, uniqBy,
} from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React, {
  useCallback, useEffect, useMemo, useReducer, useRef, useState,
} from 'react';
import { useSelector } from 'react-redux';

import HighlightableDataGrid from '@/components/HighlightableDataGrid';
import knownFleets from '@/contants/knownFleets';
import { api } from '@/services/BuggyApi';
import { convertVehicleResponseToPayload } from '@/services/transformers';
import { addVehicle, getVehicles, VehicleAPIResponse } from '@/services/vehicles';
import { DataGridRow } from '@/types/DataGrid';
import { EditAction } from '@/types/EditAction';

import VehicleMessages from '../VehicleMessages';
import AddVehicleFormDialog from './AddVehicle/AddVehicleFormDialog';
import { AddVehicleFormValues } from './AddVehicle/addVehicleSchema';
import { createVehicleTableColumns } from './vehiclesTableColumns';
import VehiclesTableContext, { VehiclesTableContextType } from './vehiclesTableContext';
import { VehiclesTableToolbar } from './VehiclesTableToolbar';

type VehiclesTableProps = {
  search: string | undefined;
};

export function VehiclesTable({ search }: VehiclesTableProps) {
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 25,
  });
  const [rowCount, setRowCount] = useState(0);
  const { userInfo } = useSelector((state: any) => state);
  const queryClient = useQueryClient();
  const [editedRows, dispatchRowEdit] = useReducer(
    (state: VehicleAPIResponse[], action: EditAction<VehicleAPIResponse>) => {
      if (action.type === 'RESET') {
        return [] as VehicleAPIResponse[];
      }

      return uniqBy([action.payload, ...state], 'id');
    },
    [],
  );

  // This is an integration with a legacy component
  // Hence the awkward handling of showing messages.
  const [vehicleMessages, setVehicleMessages] = useState<string[]>([]);
  const messagesRef = useRef<VehicleMessages>(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [updateRowData, setUpdateRowData] = useState<VehicleAPIResponse>();

  const onClickMessages = useCallback((messages: VehicleAPIResponse['notifications']) => {
    setVehicleMessages(map(messages, 'message'));
    messagesRef.current?.onModalShow();
  }, []);

  const onCloseAddVehicles = () => {
    setIsFormOpen(false);
  };

  const queryKey = useMemo(
    () => (
      ['vehicles', paginationModel.page, paginationModel.pageSize, search]),
    [
      paginationModel.page,
      paginationModel.pageSize,
      search,
    ],
  );

  const { data: columns } = useQuery({
    queryKey: ['columns', 'vehicle'],
    queryFn: () => createVehicleTableColumns({ onClickMessages }),
  });

  const { data, isFetching, refetch } = useQuery({
    queryKey,
    queryFn: () => getVehicles({
      offset: paginationModel.page * paginationModel.pageSize,
      limit: paginationModel.pageSize,
      search,
    }),
  });

  const { mutate: removeVehicle } = useMutation({
    mutationKey: queryKey,
    mutationFn: (id: number) => api.deleteVehicle(id),
    onMutate: async (id) => {
      await queryClient.cancelQueries({ queryKey });

      const previousVehicles = queryClient.getQueryData<typeof data>(queryKey);

      queryClient.setQueryData<typeof data>(queryKey, (prev) => {
        if (!prev) {
          return prev;
        }

        const prevClone = {
          ...prev,
          data: {
            ...prev.data,
            objects: [
              ...prev.data.objects,
            ],
          },
        };

        const deletedIndex = prevClone.data.objects.findIndex(({ id: _id }) => _id === id);

        prevClone.data.objects[deletedIndex] = {
          ...prevClone.data.objects[deletedIndex],
          status: 'REQUESTED_REMOVAL',
        };

        return prevClone;
      });

      return { previousVehicles };
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(queryKey, context?.previousVehicles);
      enqueueSnackbar({ variant: 'error', message: 'Failed to delete vehicle' });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const { mutate: createVehicle } = useMutation({
    mutationFn: async (formValues: AddVehicleFormValues) => addVehicle(formValues),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['vehicles'],
      });

      enqueueSnackbar({ variant: 'success', message: 'Successfully requested to activate vehicle!' });
    },
    onError: (error) => {
      let errorMessage = 'An unknown error occurred';

      if (isAxiosError(error)) {
        if (error.response?.status === 400) {
          entries(error.response.data).forEach(
            ([key, message]) => enqueueSnackbar({
              variant: 'error',
              message: `${key}: ${message}`,
            }),
          );

          return;
        }

        errorMessage = error.message;
      }

      enqueueSnackbar({ variant: 'error', message: errorMessage });
    },
  });

  useEffect(() => {
    setRowCount((prevCount) => {
      const count = data?.data.meta.total_count;
      return count !== undefined ? count : prevCount;
    });
  }, [data]);

  const onRemoveVehicle = useCallback((row: VehicleAPIResponse) => {
    removeVehicle(row.id);
  }, [removeVehicle]);

  // This is to Activate a vehicle which is deactivated
  const markExistHandler = useCallback((row: VehicleAPIResponse) => {
    createVehicle(convertVehicleResponseToPayload(row));
  }, [createVehicle]);

  const onClickUpdate = useCallback((row: VehicleAPIResponse) => {
    setIsFormOpen(true);
    setUpdateRowData(row);
  }, []);

  const rows = useMemo(
    () => data?.data.objects.map<DataGridRow<VehicleAPIResponse>>(
      (rowData) => ({
        ...rowData,
        actions: (row) => {
          if (!userInfo.vehicles_change) {
            return undefined;
          }

          if (row.status === 'EXIST') {
            return [
              {
                label: 'Remove',
                handler: onRemoveVehicle,
              },
              {
                label: 'Update',
                handler: onClickUpdate,
              },
            ];
          }

          if (row.status === 'REMOVED') {
            return [
              {
                label: 'Mark as exist',
                handler: markExistHandler,
              },
              {
                label: 'Update',
                handler: onClickUpdate,
              },
            ];
          }
          return undefined;
        },
      }),
    ) || [],
    [data?.data.objects,
      onRemoveVehicle,
      markExistHandler,
      onClickUpdate,
      userInfo.vehicles_change],
  );

  const context = useMemo<VehiclesTableContextType>(() => ({
    refetch: () => refetch(),
    search,
    showLessee: knownFleets.includes(userInfo.fleet_id || -1),
    editedRows,
    dispatchRowEdit,
  }), [editedRows, refetch, search, userInfo.fleet_id]);

  return (
    <VehiclesTableContext.Provider value={context}>
      <HighlightableDataGrid
        columns={columns || []}
        rows={rows}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        rowCount={rowCount}
        autoHeight
        pagination
        loading={isFetching}
        disableRowSelectionOnClick
        getRowClassName={({ id }) => (find(editedRows, { id }) ? 'highlighted' : '')}
        processRowUpdate={(editedRow) => {
          dispatchRowEdit({ payload: editedRow, type: 'ADD' });
          return editedRow;
        }}
        sx={(theme) => ({
          '& p': {
            margin: 'unset',
          },
          backgroundColor: theme.palette.background.paper,
          overflowX: 'scroll',
        })}
        slots={{
          toolbar: VehiclesTableToolbar,
        }}
      />
      <VehicleMessages vehicleMessage={vehicleMessages} ref={messagesRef} />
      <AddVehicleFormDialog
        open={isFormOpen}
        onClose={onCloseAddVehicles}
        maxWidth="xs"
        fullWidth
        showLessee={knownFleets.includes(userInfo.fleet_id || -1)}
        updateData={updateRowData}
      />
    </VehiclesTableContext.Provider>
  );
}

export default VehiclesTable;
