import { last } from 'lodash';

/**
 * Downloads a file from the given URL
 * @param {string} url
 */
export function downloadFileFromURL(url) {
  const name = last(url.split('/'));

  const link = document.createElement('a');
  link.download = name;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export default {};
