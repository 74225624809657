import z, { TypeOf } from 'zod';

const schema = z.object({
  fieldType: z.enum(['TEXT']),
  name: z.string()
    .min(1, 'Please enter a name')
    .max(32, 'Field name can\'t be more than 32 characters'),
});

export type CustomFieldFormValues = TypeOf<typeof schema>;

export default schema;
