'use client';

import {
  InputAdornment, TextField,
} from '@mui/material';
import {
  DataGridPro, GridFilterModel, GridRowId, GridSortModel,
} from '@mui/x-data-grid-pro';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import {
  chain, debounce, entries, filter,
} from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React, {
  ChangeEvent, SyntheticEvent, useCallback, useMemo, useState,
} from 'react';
import { HiSearch } from 'react-icons/hi';

import {
  assignVehicleToTransponder, listTransponders, unassignVehicleFromTransponder,
} from '@/services/transponders';
import { TransponderStatusEnum } from '@/services/transponders/types';
import { getVehicles } from '@/services/vehicles';

import generateTransponderTableColumns from './columns';
import { SelectedTranspondersContext } from './contexts';
import TranspondersTableToolbar from './TranspondersTableToolbar';
import { AssignVehicleParams, UnassignVehicleParams } from './types';

function TranspondersTable() {
  const queryClient = useQueryClient();

  const [search, setSearch] = useState('');
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeSearch = useCallback(debounce(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setSearch(event.target.value);
    },
    500,
  ), []);

  const [pagination, setPagination] = useState({
    pageSize: 25,
    page: 0,
  });

  const filterOptions = useMemo(
    () => (chain(filterModel.items).keyBy('field').mapValues('value').value()),
    [filterModel],
  );

  const [vehicleSearch, setVehicleSearch] = useState('');
  const { data, isLoading } = useQuery({
    queryKey: ['transponders', 'list', pagination.page, pagination.pageSize, search, sortModel, filterModel.items],
    queryFn: () => listTransponders({
      pagination, search, sortModel, filter: filterOptions,
    }),
  });

  const vehicleList = useQuery({
    queryKey: ['vehicles', vehicleSearch],
    queryFn: () => getVehicles({ search: vehicleSearch }),
  });

  const { mutate: assignVehicle } = useMutation({
    mutationFn: async (formValues:AssignVehicleParams) => assignVehicleToTransponder(formValues),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['transponders'],
      });

      enqueueSnackbar({ variant: 'success', message: 'Successfully assigned vehicle!' });
    },
    onError: (error) => {
      let errorMessage = 'An unknown error occurred';

      if (isAxiosError(error)) {
        if (error.response?.status === 400 || error.response?.status === 424) {
          entries(error.response.data).forEach(
            ([key, message]) => enqueueSnackbar({
              variant: 'error',
              message: `${key}: ${message}`,
            }),
          );

          return;
        }

        errorMessage = error.message;
      }

      enqueueSnackbar({ variant: 'error', message: errorMessage });
    },
  });

  const { mutate: unassignVehicle } = useMutation({
    mutationFn: async (formValues:
    UnassignVehicleParams) => unassignVehicleFromTransponder(formValues),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['transponders'],
      });

      enqueueSnackbar({ variant: 'success', message: 'Successfully unassigned vehicle!' });
    },
    onError: (error) => {
      let errorMessage = 'An unknown error occurred';

      if (isAxiosError(error)) {
        if (error.response?.status === 400 || error.response?.status === 424) {
          entries(error.response.data).forEach(
            ([key, message]) => enqueueSnackbar({
              variant: 'error',
              message: `${key}: ${message}`,
            }),
          );

          return;
        }

        errorMessage = error.message;
      }

      enqueueSnackbar({ variant: 'error', message: errorMessage });
    },
  });

  const transponders = useMemo(() => data?.data.results || [], [data?.data.results]);

  const onVehicleSearch = useCallback((e:SyntheticEvent, value:string) => {
    setVehicleSearch(value);
  }, []);

  const columns = useMemo(() => generateTransponderTableColumns(
    {
      vehicleList: vehicleList?.data?.data.objects || [],
      assignVehicle,
      unassignVehicle,
      onVehicleSearch,
    },
  ), [vehicleList?.data, assignVehicle, unassignVehicle, onVehicleSearch]);

  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([]);

  const selectedTransponders = useMemo(
    () => filter(transponders, ({ id }) => selectedRowIds.includes(id)),
    [selectedRowIds, transponders],
  );

  return (
    <SelectedTranspondersContext.Provider value={selectedTransponders}>
      <TextField
        size="small"
        placeholder="Search"
        sx={{ marginY: 1, background: 'white' }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <HiSearch size={23} />
            </InputAdornment>
          ),
        }}
        onChange={onChangeSearch}
      />
      <DataGridPro
        columns={columns}
        rows={transponders}
        autoHeight
        pagination
        loading={isLoading}
        onFilterModelChange={setFilterModel}
        rowCount={data?.data?.count || 0}
        paginationMode="server"
        filterMode="server"
        sx={(theme) => ({
          backgroundColor: theme.palette.background.paper,
        })}
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        paginationModel={pagination}
        onPaginationModelChange={setPagination}
        checkboxSelection
        isRowSelectable={({ row }) => [
          TransponderStatusEnum.PENDING_VEHICLE,
          TransponderStatusEnum.ACTIVE,
        ].includes(row.status)}
        slots={{
          toolbar: TranspondersTableToolbar,
        }}
        onRowSelectionModelChange={(rowIds) => setSelectedRowIds(rowIds)}
      />

    </SelectedTranspondersContext.Provider>
  );
}

export default TranspondersTable;
