import { Button } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { MdOutlineLocalShipping } from 'react-icons/md';

import { useSelectedTranspondersContext } from '../../transponders/contexts';
import CreateShipmentFormDialog from './CreateReturnShipmentFormDialog';

export default function CreateShipmentButton() {
  const selectedTransponders = useSelectedTranspondersContext();
  const [isFormOpen, setIsFormOpen] = useState(false);

  const onClickCreateShipment = useCallback(() => {
    setIsFormOpen(true);
  }, []);

  const onCloseFormDialog = useCallback(() => {
    setIsFormOpen(false);
  }, []);

  return (
    <>
      <Button
        size="small"
        startIcon={<MdOutlineLocalShipping />}
        onClick={onClickCreateShipment}
      >
        Return Transponders
      </Button>
      <CreateShipmentFormDialog
        open={isFormOpen}
        onClose={onCloseFormDialog}
        fullWidth
        initialTransponders={selectedTransponders}
      />
    </>
  );
}
