import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { values } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React, { ChangeEvent, useState } from 'react';

import { getInvoice } from '@/services/billing';

import { INVOICE_CHARGE_TYPE } from './constants';
import exportInvoiceData from './helpers';
import { ExportButtonProps } from './types';

function ExportButton({ invoice }: ExportButtonProps) {
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const [value, setValue] = useState('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const { data } = useQuery({
    queryKey: ['invoice', 'fee', invoice],
    queryFn: () => {
      if (!invoice) return null;
      return getInvoice(invoice);
    },
  });

  const charges = data?.data.invoice.fees?.filter((fee) => fee.item.type === 'charge');

  const hasCharge = (charge: string) => charges?.some(
    (fee) => fee.item.code.includes(charge),
  );

  const { mutate: download } = useMutation({
    mutationFn: async () => {
      setIsExportModalOpen(false);
      enqueueSnackbar({ variant: 'info', message: 'Preparing CSV file...' });

      if (!charges) {
        throw Error('Invoice has no charges');
      }

      const result = charges.find((fee) => fee.item.code.includes(value));
      const dateFrom = result?.from_date;
      const dateTo = result?.to_date;

      if (!(dateFrom && dateTo)) {
        throw Error('Invalid date parameters');
      }

      return exportInvoiceData(value, dateFrom, dateTo);
    },
    onSuccess: () => {
      enqueueSnackbar({ variant: 'success', message: 'File downloaded.' });
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar({ variant: 'error', message: err.message });
    },
  });

  const onClickExportModal = () => {
    setIsExportModalOpen(true);
  };

  const onCloseExportModal = () => {
    setIsExportModalOpen(false);
  };

  return (
    <>
      <Dialog
        open={isExportModalOpen}
        fullWidth
      >
        <DialogTitle>
          Export
        </DialogTitle>
        <DialogContent>
          <FormControl>
            <RadioGroup
              onChange={handleChange}
              value={value}
            >
              {values(INVOICE_CHARGE_TYPE).map(
                ({ charge, label }) => (
                  (hasCharge(charge)
                    && (
                      <FormControlLabel
                        value={charge}
                        control={<Radio />}
                        label={label}
                      />
                    ))
                ),
              )}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseExportModal}>CLOSE</Button>
          <Button
            type="submit"
            onClick={() => download()}
            disabled={!value}
          >
            DOWNLOAD
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        onClick={onClickExportModal}
      >
        EXPORT
      </Button>
    </>
  );
}

export default ExportButton;
