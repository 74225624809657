import {
  Button, Dialog, DialogContent, DialogTitle,
} from '@mui/material';
import React, { useState } from 'react';

import DocumentsTable from './DocumentsTable';

function ViewDocumentButton() {
  const [isOpen, setIsOpen] = useState(false);
  const onClickViewStatus = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button variant="text" onClick={onClickViewStatus}>
        View Status
      </Button>
      <Dialog
        open={isOpen}
        onClose={onClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Uploaded Document Status</DialogTitle>
        <DialogContent>
          <DocumentsTable />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ViewDocumentButton;
