import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import clsx from "clsx";

import styles from "./Logs.module.css";

import BuggyApi from "../../../services/BuggyApi";
import BlockUi from "../../BlockUi";
import { min } from "lodash";
import { Button, Row } from "reactstrap";
import { SearchLogs } from "./SearchLogs";

export const Logs = ({ app, onClose }) => {
  const fleetId = useSelector(({ userInfo }) => userInfo.fleet_id);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();

  const queryResult = useQuery({
    queryKey: ["integration", "logs", page, search],
    queryFn: async () => {
      const api = new BuggyApi();

      return api.getIntegrationLogs(fleetId, app.userSettings.id, search, page);
    },
  });

  const limit = queryResult.data?.data.meta.limit || 0;
  const totalCount = queryResult.data?.data.meta.total_count || 0;
  const lowerBound = (page - 1) * limit + 1;
  const upperBound = min([page * limit, totalCount]);

  if (!app.userSettings) {
    return (
      <div className="alert alert-danger">
        You have not configured this integration
      </div>
    );
  }

  return (
    <BlockUi blocking={queryResult.isLoading}>
      <div className={styles.header_container}>
        <h3>{app.title} Logs</h3>
        <button
          style={{
            borderRadius: "100%",
            border: "none",
            height: 30,
            width: 30,
          }}
          onClick={onClose}
        >
          <i className="fa fa-close" />
        </button>
      </div>
      <SearchLogs onSearch={setSearch} />
      <div className={`table-responsive ${styles.logs_table_container}`}>
        <table
          className={`table table-striped custom-table m-b-0 ${styles.logs_table}`}
        >
          <thead>
            <tr>
              <th>Success</th>
              <th>Action</th>
              <th>Details</th>
              <th>Direction</th>
              <th>Response</th>
              <th>Timestamp</th>
            </tr>
          </thead>
          <tbody>
            {queryResult.data?.data.objects.map(
              ({
                id,
                action,
                details,
                direction,
                response,
                success,
                timestamp,
              }) => (
                <tr key={id}>
                  <td style={{ textAlign: "center" }}>
                    <i
                      className={clsx("fa", success ? "fa-check" : "fa-close")}
                    />
                  </td>
                  <td>{action}</td>
                  <td>{details}</td>
                  <td>{direction}</td>
                  <td style={{ overflow: "auto", maxWidth: "30%" }}>
                    {response}
                  </td>
                  <td>{timestamp}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
      <div className={`dataTables_paginate ${styles.logs_pagination}`}>
        <div className="dataTables_info">
          Showing {lowerBound} to {upperBound} of {totalCount} entries
        </div>
        <Pagination
          activePage={page}
          itemsCountPerPage={limit}
          totalItemsCount={totalCount}
          pageRangeDisplayed={10}
          onChange={setPage}
        />
      </div>
    </BlockUi>
  );
};
