import { LoadingButton } from '@mui/lab';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { MdSave } from 'react-icons/md';
import { Prompt } from 'react-router-dom';

type Props = {
  canSave?: boolean;
  onSave: () => Promise<void>;
  hidden?: boolean;
};

function GridSaveChangesButton({
  canSave = false,
  hidden = false,
  onSave,
}: Props) {
  const { mutate: save, isLoading } = useMutation({
    mutationFn: () => onSave(),
  });

  useEffect(() => {
    if (canSave) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [canSave]);

  if (hidden) {
    return null;
  }

  return (
    <>
      <Prompt when={canSave} message="You have unsaved changes. Are you sure you want to leave the page?" />
      <LoadingButton
        onClick={() => save()}
        startIcon={<MdSave />}
        size="small"
        loading={isLoading}
        disabled={!canSave}
      >
        Save
      </LoadingButton>
    </>
  );
}

export default GridSaveChangesButton;
