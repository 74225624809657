import { VEHICLES_BULK_TEMPLATE, VEHICLES_BULK_TEMPLATE_NEW } from '../../../contants/downloadUrls';
import knownFleets from '../../../contants/knownFleets';
import { api } from '../../../services/BuggyApi';
import { downloadFileFromURL } from '../../../utils/download';

type DownloadVehiclesParams = {
  search?: string;
};

export async function downloadVehicles({
  search,
}: DownloadVehiclesParams) {
  const { data: { url } } = await api.getVehicles(0, search, true);

  downloadFileFromURL(url);
}

export function downloadTemplate(fleetId?: number) {
  const isDownloadNew = knownFleets.includes(fleetId || -1);

  const url = isDownloadNew ? VEHICLES_BULK_TEMPLATE : VEHICLES_BULK_TEMPLATE_NEW;

  downloadFileFromURL(url);
}

export default {};
