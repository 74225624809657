import React from "react";

import ReactModal from "react-modal";

export const Modal = ({
  children,
  isOpen,
  onRequestClose,
  maxHeight,
  padding,
}) => (
  <ReactModal
    isOpen={isOpen}
    style={{
      overlay: {
        zIndex: 9999,
        position: "absolute",
        backgroundColor: "rgba(0, 0, 0, .6)",
      },
      content: {
        border: "none",
        width: "fit-content",
        blockSize: "fit-content",
        minWidth: "40%",
        margin: "0 auto",
        maxHeight,
        padding,
      },
    }}
    ariaHideApp={false}
    onRequestClose={onRequestClose}
  >
    {children}
  </ReactModal>
);

export default Modal;
