import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

class NavLink extends React.Component {
  render() {
    const active = this.props.isActive ? "active" : "";
    const hide =
      (this.props.bot_allowed === false &&
        (this.props.text === "Liability Bot" ||
          this.props.text === "Drivers" ||
          this.props.text === "Liability Bot Violations")) ||
      (this.props.parking_allowed === false &&
        this.props.text === "Parking & Photo Enforcement Violations")
        ? "hidden"
        : "";
    const hidden =
      this.props.fleet_id !== 134 && this.props.text === "Logs" ? "hidden" : "";
    return (
      <li className={"" + active + " " + hide + "" + hidden}>
        <Link to={this.props.path} onClick={() => this.props.onClick()}>
          {this.props.icon}
          <span>&nbsp;&nbsp;&nbsp;</span>
          {this.props.text}
        </Link>
      </li>
    );
  }
}
NavLink.contextTypes = {
  router: PropTypes.object,
};

export default NavLink;
