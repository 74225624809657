import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
  Stack, TextField, Typography,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import currency from 'currency.js';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MdAttachMoney } from 'react-icons/md';
import z, { TypeOf } from 'zod';

import { addWalletFunds } from '@/services/billing';

import { WalletTransactionsList } from '../WalletTransactionsList';
import { WalletModalProps } from './types';

const schema = z.object({
  amount: z.number({
    required_error: 'Amount is required',
    invalid_type_error: 'Amount must be a number',
  }).positive({ message: 'Amount should be greater than zero.' }),
});

type FormValues = TypeOf<typeof schema>;

export function WalletModal(props: WalletModalProps) {
  const {
    open, onClose, walletId, balance,
  } = props;

  const {
    handleSubmit, control, watch,
  } = useForm<FormValues>({ resolver: zodResolver(schema) });

  const queryClient = useQueryClient();

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const amountField = watch('amount');

  const toggleConfirmation = () => {
    setOpenConfirmation((previous) => !previous);
  };

  const mutation = useMutation({
    mutationFn: async (amount: number) => addWalletFunds(walletId, amount),
    mutationKey: [walletId],
    onSuccess: () => {
      toggleConfirmation();
      queryClient.invalidateQueries({
        queryKey: ['transactions'],
      });
    },
    onError: (error) => {
      if (isAxiosError(error)) {
        enqueueSnackbar({ message: error.message, variant: 'error' });
      }
      toggleConfirmation();
    },
  });

  const onSubmit = (data: FormValues) => {
    mutation.mutate(data.amount);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
    >
      <DialogContent>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={10}>
          <Stack>
            <Typography variant="caption">Available balance</Typography>
            <Typography variant="h4" fontFamily="monospace">
              {currency(
                balance || 0,
                { fromCents: true },
              ).format()}
            </Typography>
          </Stack>
          <form onSubmit={handleSubmit(onSubmit)} style={{ flex: 1 }}>
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
              <Controller
                name="amount"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    autoFocus
                    label="Amount"
                    margin="dense"
                    fullWidth
                    variant="outlined"
                    size="small"
                    type="number"
                    onChange={(e) => field.onChange(currency(e.target.value).value)}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <Button
                type="button"
                variant="contained"
                startIcon={<MdAttachMoney />}
                disableElevation
                onClick={toggleConfirmation}
                fullWidth
                sx={{ width: 200 }}
              >
                ADD FUNDS
              </Button>
              <Dialog
                open={openConfirmation}
                maxWidth="xs"
                fullWidth
                disablePortal
              >
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>
                  {`Add ${currency(amountField).format()} to your wallet?`}
                </DialogContent>
                <DialogActions>
                  <Button onClick={toggleConfirmation}>Cancel</Button>
                  <Button type="submit">Confirm</Button>
                </DialogActions>
              </Dialog>
            </Stack>
          </form>
        </Stack>
        <WalletTransactionsList walletId={walletId} />
      </DialogContent>
    </Dialog>
  );
}

export default WalletModal;
