import { Box } from '@mui/material';
import {
  GridColDef,
  GridColumnVisibilityModel,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import React from 'react';

import GridColumnsSelector from '../../GridColumnsSelector';
import GridSaveChangesButton from '../../GridSaveChangesButton';
import RefreshToolbarButton from '../../RefreshToolbarButton';
import { DownloadCSV, DownloadCSVProps } from './DownloadCSV';

type Props = {
  downloadCSVProps: DownloadCSVProps;
  columns: GridColDef[];
  columnVisibilityModel: GridColumnVisibilityModel;
  setColumnVisibilityModel: (model: GridColumnVisibilityModel) => void;
  onSaveVisibilityModel: (model: GridColumnVisibilityModel) => Promise<void>;
  onRefresh: () => void;
  onSave: () => Promise<void>;
  canSave: boolean;
};

function TollsTableToolBar({
  downloadCSVProps,
  columns,
  columnVisibilityModel,
  setColumnVisibilityModel,
  onSaveVisibilityModel,
  onRefresh,
  onSave,
  canSave,
}: Props) {
  return (
    <GridToolbarContainer>
      <GridColumnsSelector
        columns={columns}
        onApply={setColumnVisibilityModel}
        onSave={onSaveVisibilityModel}
        visibilityModel={columnVisibilityModel}
      />
      <GridToolbarDensitySelector />
      <RefreshToolbarButton onRefresh={onRefresh} />
      <DownloadCSV {...downloadCSVProps} />
      <Box flexGrow={1} />
      <GridSaveChangesButton
        onSave={onSave}
        canSave={canSave}
        hidden={!canSave}
      />
    </GridToolbarContainer>
  );
}

export default TollsTableToolBar;
