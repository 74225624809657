import { GridColumnVisibilityModel, GridPaginationModel } from '@mui/x-data-grid';
import { deepClone } from '@mui/x-data-grid/utils/utils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  difference, isEmpty, keys, map, pickBy, uniqBy,
} from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React, {
  useEffect, useMemo, useReducer, useRef, useState,
} from 'react';
import { useSelector } from 'react-redux';

import HighlightableDataGrid from '@/components/HighlightableDataGrid';
import BuggyApi from '@/services/BuggyApi';
import { partialUpdateTicketList } from '@/services/tickets';
import { getUserSettings, setUserSettings } from '@/services/userSettings';
import { EditAction } from '@/types/EditAction';

import AssociateDriver from '../AssociateDriver';
import DriverDetails from '../DriverDetails';
import { TollsOptions, TollsSettings } from '../settings';
import { downloadCSV, emailCSV } from './helpers';
import TollsTableToolBar from './TollsTableToolBar';
import createTollTableColumns from './tollTableColumns';

type Props = {
  plateNumber?: string;
  lessee?: string;
  dateFrom?: string;
  dateTo?: string;
  exitPlaza?: string;
};

// TODO add this into the API response
type TicketResponse = {
  meta: {
    total_count: number;
  };
  objects: {
    ticketID: string;
    billing_status: string;
    driver?: {
      email: string;
    };
    system_remarks?: string;
  }[];
};

const api = new BuggyApi();

const SETTINGS_NAME = 'tolls';

type Row = TicketResponse['objects'][number];

export function TollsTable({
  plateNumber = '',
  lessee = '',
  dateFrom = '',
  dateTo = '',
  exitPlaza = '',
}: Props) {
  const [rowCount, setRowCount] = useState(0);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({});
  const associateDriverRef = useRef<AssociateDriver>(null);
  const driverDetailsRef = useRef<DriverDetails>(null);
  const userInfo = useSelector((state: any) => state.userInfo);
  const queryClient = useQueryClient();

  const [editedRows, dispatch] = useReducer(
    (state: Row[], action: EditAction<Row>) => {
      if (action.type === 'RESET') {
        return [] as Row[];
      }

      return uniqBy([action.payload, ...state], 'ticketID');
    },
    [],
  );

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 25,
  });

  const queryKey = useMemo(() => [
    'tolls',
    plateNumber,
    lessee,
    dateFrom,
    dateTo,
    exitPlaza,
    paginationModel.page,
    paginationModel.pageSize,
  ], [
    dateFrom,
    dateTo,
    exitPlaza,
    lessee,
    paginationModel.page,
    paginationModel.pageSize,
    plateNumber,
  ]);

  const {
    data: tollsData, refetch, isFetching,
  } = useQuery({
    queryKey,
    queryFn: () => api.searchTickets(
      paginationModel.page * paginationModel.pageSize,
      plateNumber,
      lessee,
      dateFrom,
      dateTo,
      exitPlaza,
      paginationModel.pageSize,
    ),
  });

  const { data: billingStatuses } = useQuery({
    queryKey: ['billingStatuses'],
    queryFn: () => api.getAllBillingStatuses(),
    staleTime: Infinity,
  });

  const { mutate: mutateBilling } = useMutation({
    mutationKey: [],
    mutationFn: (
      { id, status }:
      { id: string, status: string },
    ) => api.updateBillingStatus(id, { status }),
    onMutate: async ({ id, status }) => {
      // Optimistic query update
      await queryClient.cancelQueries({ queryKey });

      const previousTickets = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(
        queryKey,
        (prevTickets: { data: TicketResponse } | undefined) => {
          if (!prevTickets) {
            return prevTickets;
          }

          const prevTicketsClone = {
            ...prevTickets,
            data: deepClone(prevTickets.data),
          };

          const updatedTicketIndex = prevTickets.data.objects.findIndex(
            ({ ticketID }) => ticketID === id,
          );

          prevTicketsClone.data.objects[updatedTicketIndex] = {
            ...prevTickets.data.objects[updatedTicketIndex],
            billing_status: status,
          };

          return prevTicketsClone;
        },
      );

      return { previousTickets };
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(queryKey, context?.previousTickets);
      enqueueSnackbar({ variant: 'error', message: 'Failed to update ticket' });
    },
  });

  const { data: columns } = useQuery({
    queryKey: ['columns', 'toll', billingStatuses?.data, mutateBilling, userInfo?.feature_flags],
    queryFn: () => createTollTableColumns({
      billingStatusOptions: billingStatuses?.data || [],
      onBillingStatusChange: (id, status) => mutateBilling({ id, status }),
      onClickAssociateDriver: (id) => associateDriverRef.current?.onModalShow(id, 'EZ_PASS'),
      onClickViewDriverDetails: (driverId) => driverDetailsRef.current?.onModalShow(driverId),
      featureFlags: userInfo?.feature_flags,
    }),
    initialData: [],
  });

  const { data: userSettings } = useQuery({
    queryKey: ['settings', SETTINGS_NAME],
    queryFn: () => getUserSettings<TollsSettings, TollsOptions>(SETTINGS_NAME),
    staleTime: Infinity,
    onSuccess: ({ data: settingsData }) => {
      const allColumns = map(columns, 'field');
      const visibleColumns = settingsData.settings.table?.columns || allColumns;

      const invisibleColumns = difference(allColumns, visibleColumns);

      setColumnVisibilityModel(
        invisibleColumns.reduce((acc: GridColumnVisibilityModel, col) => {
          acc[col] = false;
          return acc;
        }, {}),
      );
    },
  });

  const { mutateAsync: saveColumnVisibilityModel } = useMutation({
    mutationFn: (col: GridColumnVisibilityModel) => {
      const allColumns = map(columns, 'field');
      const invisibleColumns = keys(pickBy(col, (isVisible) => !isVisible));

      const visibleColumns = difference(allColumns, invisibleColumns);

      return setUserSettings(
        SETTINGS_NAME,
        userSettings?.data.settings,
        'table.columns',
        visibleColumns,
      );
    },
    onSuccess: (data, variables) => {
      setColumnVisibilityModel(variables);
    },
  });

  useEffect(() => {
    // This ensures that the pagination does not reset while the data is being fetch
    // Like when changing pages
    setRowCount((prevCount) => {
      const count = tollsData?.data.meta.total_count;
      return count !== undefined ? count : prevCount;
    });
  }, [tollsData]);

  const { mutate: onSaveEditedRows } = useMutation({
    mutationFn: () => partialUpdateTicketList(
      editedRows.map(
        ({ ticketID, driver, system_remarks }) => ({
          id: ticketID,
          driver__email: driver?.email,
          system_remarks,
        }),
      ),
    ),
    onSuccess: () => {
      dispatch({ type: 'RESET' });
      queryClient.invalidateQueries({ queryKey });
    },
    onError: () => {
      enqueueSnackbar({ variant: 'error', message: 'Failed to save changes' });
    },
  });

  return (
    <>
      <HighlightableDataGrid
        columns={columns}
        rows={tollsData?.data.objects || []}
        getRowId={({ ticketID }) => ticketID}
        autoHeight
        pagination
        loading={isFetching}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        rowCount={rowCount}
        disableRowSelectionOnClick
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={setColumnVisibilityModel}
        getRowClassName={({ id }) => (editedRows.find(({ ticketID }) => id === ticketID) ? 'highlighted' : '')}
        processRowUpdate={(newRow) => {
          dispatch({ payload: newRow, type: 'ADD' });
          return newRow;
        }}
        sx={(theme) => ({
          '& p': {
            margin: 'unset',
          },
          backgroundColor: theme.palette.background.paper,
          overflowX: 'scroll',
        })}
        slots={{
          toolbar: TollsTableToolBar,
        }}
        slotProps={{
          toolbar: {
            downloadCSVProps: {
              queryParams: {
                offset: 0,
                plateNumber,
                lessee,
                dateFrom,
                dateTo,
                exitPlaza,
              },
              settingsName: 'tolls',
              downloadCSV,
              emailCSV,
            },
            columns,
            columnVisibilityModel,
            setColumnVisibilityModel,
            onSaveVisibilityModel: saveColumnVisibilityModel,
            onRefresh: refetch,
            onSave: onSaveEditedRows,
            canSave: !isEmpty(editedRows),
          },
        }}
      />
      <AssociateDriver
        ref={associateDriverRef}
        refresh={refetch}
      />
      <DriverDetails
        ref={driverDetailsRef}
        refresh={refetch}
      />
    </>
  );
}

export default TollsTable;
