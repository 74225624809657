import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import currency from 'currency.js';
import React from 'react';
import { MdExpandMore } from 'react-icons/md';

import { UsageDetailsProps } from './types';

function UsageDetails({ usage }: UsageDetailsProps) {
  return (
    <Accordion elevation={0}>
      <AccordionSummary
        expandIcon={<MdExpandMore size={24} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Details</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {usage.charges_usage.map(({ billable_metric, units, amount_cents }) => (
            <ListItem divider key={billable_metric.code}>
              <ListItemText
                primary={billable_metric.name}
                secondary={`${units} units`}
              />
              <ListItemSecondaryAction>
                <Typography
                  fontFamily="monospace"
                  fontWeight="semibold"
                  color="primary"
                >
                  {currency(amount_cents, { fromCents: true }).format()}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}

export default UsageDetails;
