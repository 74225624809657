import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import NavLink from "./NavLink";
import * as actionTypes from "../../store/actions/actions";

const Sidebar = () => {
  const dispatch = useDispatch();
  const { isLoggedIn, links, sidebarOpened, userInfo } = useSelector(
    (state) => state
  );

  const onClickLink = useCallback(
    (linkIndex) => {
      dispatch({ type: actionTypes.UPDATE_NAVIGATION, i: linkIndex });
    },
    [dispatch]
  );

  return (
    isLoggedIn && (
      <div
        className={sidebarOpened ? "sidebar opened " : "sidebar"}
        id="sidebar"
      >
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              {links.map((link, i) => (
                <div id={link.text.split(" ").join("-")}>
                  <NavLink
                    path={link.path}
                    bot_allowed={userInfo.bot_allowed || false}
                    parking_allowed={userInfo.parking_allowed || false}
                    text={link.text}
                    isActive={link.isActive}
                    fleet_id={userInfo.fleet_id || 0}
                    key={link.path}
                    icon={link.icon}
                    onClick={() => onClickLink(i)}
                  />
                </div>
              ))}
            </ul>
          </div>
        </div>
      </div>
    )
  );
};

export default Sidebar;
