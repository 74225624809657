import React, { useCallback } from "react";
import { Button, Row } from "reactstrap";
import DateTime from "react-datetime";

import styles from "./SearchLogs.module.css";
import { Controller, useForm } from "react-hook-form";

export const SearchLogs = ({ onSearch }) => {
  const { control, handleSubmit, register } = useForm({
    defaultValues: {
      from: undefined,
      to: undefined,
      ticket: undefined,
    },
  });

  const onSubmit = useCallback((formValues) => {
    if (!(formValues.to && formValues.from)) {
      onSearch({ ticket: formValues.ticket });
      return;
    }

    onSearch(formValues);
  }, []);

  return (
    <form
      className={styles.search_logs_container}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={styles.search_logs_fields}>
        <div className="form-group">
          <label>Ticket ID</label>
          <input {...register("ticket")} className="form-control" type="text" />
        </div>
        <div>
          <label className="control-label">From</label>
          <div className="cal-icon">
            <Controller
              control={control}
              name="from"
              render={({ field }) => (
                <DateTime
                  dateFormat="YYYY-MM-DD"
                  timeFormat="hh:mm:ss"
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  utc
                />
              )}
            />
          </div>
        </div>
        <div>
          <label className="control-label">To</label>
          <div className="cal-icon">
            <Controller
              control={control}
              name="to"
              render={({ field }) => (
                <DateTime
                  dateFormat="YYYY-MM-DD"
                  timeFormat="hh:mm:ss"
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  utc
                />
              )}
            />
          </div>
        </div>
      </div>
      <Button type="submit" className="col-xs-3" color="primary">
        Search
      </Button>
    </form>
  );
};
