import {
  Chip, Stack, Tooltip, Typography,
} from '@mui/material';
import { GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import React from 'react';
import { HiSparkles } from 'react-icons/hi2';
import { MdNotifications } from 'react-icons/md';

import { getCustomFields } from '@/services/customFields';
import { CustomField } from '@/services/customFields/types';
import { VehicleAPIResponse } from '@/services/vehicles';
import { DataGridRow } from '@/types/DataGrid';

import { STATUS_LABEL_MAP } from './constants';
import AddColumnButton from './CustomColumns';

type CreateVehicleTableColumnsParams = {
  onClickMessages: (messages: VehicleAPIResponse['notifications']) => void
};

export async function getCustomVehicleColumns() {
  let customFields: CustomField[] = [];

  try {
    const { data } = await getCustomFields('vehicle');
    customFields = data;
  } catch (e) {
    customFields = [];
  }

  return customFields.map<GridColDef<DataGridRow<VehicleAPIResponse>>>(({ name }) => ({
    field: name,
    flex: 1,
    headerName: name,
    valueGetter: ({ row }) => row.custom_fields?.[name],
    valueSetter: ({ value, row }) => ({
      ...row,
      custom_fields: { ...row.custom_fields, [name]: value },
    }),
    editable: true,
    renderHeader: ({ field }) => (
      <Stack direction="row" spacing={1} alignItems="center" alignContent="center">
        <Tooltip title="Custom Field">
          <Typography color="grey">
            <HiSparkles />
          </Typography>
        </Tooltip>
        <Tooltip title={field}>
          <Typography>{field}</Typography>
        </Tooltip>
      </Stack>
    ),
  }));
}

export async function createVehicleTableColumns(
  { onClickMessages }: CreateVehicleTableColumnsParams,
) {
  const customColumns = await getCustomVehicleColumns();

  return [
    {
      field: 'plate_number',
      headerName: 'Plate #',
      flex: 1,
    },
    {
      field: 'plate_state',
      headerName: 'State',
      flex: 1,
    },
    {
      field: 'vehicle_make',
      headerName: 'Make',
      flex: 1,
    },
    {
      field: 'vehicle_model',
      headerName: 'Model',
      flex: 1,
    },
    {
      field: 'year',
      headerName: 'Year',
      flex: 1,
    },
    {
      field: 'tracking_code',
      headerName: 'Tracking Code',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: ({ value }) => <Chip label={STATUS_LABEL_MAP[value] || value} size="small" />,
      flex: 1,
    },
    {
      field: 'notifications',
      headerName: 'Messages',
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ value }) => (
        value instanceof Array
          ? (
            <Chip
              label={value?.length}
              size="small"
              variant="outlined"
              icon={<MdNotifications />}
              onClick={() => onClickMessages(value)}
            />
          )
          : null
      ),
      flex: 1,
    },
    ...customColumns,
    {
      field: 'actions',
      type: 'actions',
      renderHeader: () => <AddColumnButton />,
      getActions: ({ row }) => {
        const rowActions = typeof row.actions === 'function' ? row.actions(row) : row.actions;

        if (!rowActions) {
          return [];
        }

        return rowActions.map(
          ({ handler, label }) => (
            <GridActionsCellItem
              showInMenu
              label={label}
              onClick={() => handler(row)}
            />
          ),
        );
      },
    },
  ] satisfies GridColDef<DataGridRow<VehicleAPIResponse>>[];
}

export default createVehicleTableColumns;
