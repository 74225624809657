import { Chip } from '@mui/material';
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridActionsCellItem, GridColDef } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';
import React from 'react';
import {
  MdExpandLess,
  MdExpandMore,
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
} from 'react-icons/md';

import { ShipmentType } from '@/services/transponders/types';

import { SHIPMENT_DIRECTION_WORDING } from './constants';
import ShipmentStatusIndicator from './ShipmentStatusIndicator';

type Action<T> = {
  /**
   * Handler function that runs when the action invoked
   * @param row The grid row for the action
   */
  handler: (row: T) => void,
  /**
   * Action title to be displayed
   */
  title: string,
  /**
   * Function used to filter for visible actions
   * @param row The grid row for the action
   * @returns Whether or not the action is visible to the user
   */
  isActive: (row: T) => boolean
};

type GenerateColumnsParams = {
  actions: Action<ShipmentType>[]
};

export default function generateShipmentTableColumns({ actions = [] }: GenerateColumnsParams) {
  return [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: ({ value: isExpanded, row }) => (
        <Chip
          size="small"
          tabIndex={-1}
          aria-label={isExpanded ? 'Close' : 'Open'}
          icon={isExpanded ? <MdExpandLess /> : <MdExpandMore />}
          label={row.transponders.length}
          sx={{ cursor: 'pointer' }}
        />
      ),
      minWidth: 120,
      renderHeader: () => 'Count',
    },
    {
      field: 'direction',
      headerName: 'Direction',
      renderCell: ({ row }: { row: ShipmentType }) => (
        <Chip
          label={SHIPMENT_DIRECTION_WORDING[row.direction]}
          icon={row.direction === 'OUT'
            ? <MdOutlineKeyboardDoubleArrowLeft />
            : <MdOutlineKeyboardDoubleArrowRight />}
          size="small"
        />
      ),
      disableColumnMenu: true,
      minWidth: 150,
      sortable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: ({ row }: { row: ShipmentType }) => (
        <ShipmentStatusIndicator
          shipment={row}
        />
      ),
      disableColumnMenu: true,
      minWidth: 150,
    },
    {
      field: 'tracking_code',
      headerName: 'Tracking code',
      flex: 1,
      minWidth: 160,
    },
    {
      field: 'courier',
      headerName: 'Courier',
      flex: 1,
      minWidth: 160,
    },
    {
      field: 'shipped_at',
      headerName: 'Shipped At',
      flex: 1,
      minWidth: 200,
      renderCell: ({ row }: { row: ShipmentType }) => (
        <span>
          {row?.shipped_at ? format(parseISO(row?.shipped_at), 'MMM d, yyyy hh:mm aa') : '-'}
        </span>
      ),
    },
    {
      field: 'received_at',
      headerName: 'Received At',
      flex: 1,
      minWidth: 200,
      renderCell: ({ row }: { row: ShipmentType }) => (
        <span>
          {row?.received_at ? format(parseISO(row?.received_at), 'MMM d, yyyy hh:mm aa') : '-'}
        </span>
      ),
    },
    {
      field: 'remarks',
      headerName: 'Remarks',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: ({ row }) => actions
        .filter(({ isActive }) => isActive(row))
        .map(({ handler, title }) => (
          <GridActionsCellItem
            key={title}
            onClick={() => handler(row)}
            showInMenu
            label={title}
          />
        )),
    },
  ] satisfies GridColDef<ShipmentType>[];
}
