import axios from 'axios';

import { fetchDefaultFleetId } from '../utils/defaultFleet';
import BuggyAuth from './BuggyAuth';

const BASE_URL = process.env.REACT_APP_BASE_URL;

let accessToken;

let refreshPromise;

let unauthorizedHandler = () => {};

const fleetAxios = axios.create({
  baseURL: BASE_URL,
  responseType: 'json',
});

const authAxios = axios.create({
  withCredentials: true,
  baseURL: BASE_URL,
  responseType: 'json',
});

fleetAxios.interceptors.request.use((config) => {
  if (!accessToken) {
    return config;
  }

  config.headers.Authorization = `Bearer ${accessToken}`;
  config.headers['Fleet-Id'] = fetchDefaultFleetId();
  return config;
});

fleetAxios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalConfig = error.config;

    if (error.response?.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true;

      await refreshToken();

      return fleetAxios(originalConfig);
    }

    return Promise.reject(error);
  },
);

async function refreshToken() {
  if (refreshPromise === undefined) {
    refreshPromise = authAxios.post('/auth/token/refresh/');
  }
  try {
    const res = await refreshPromise;
    refreshPromise = undefined;
    setAccessToken(res.data.access);
  } catch (e) {
    if (e.response.status === 401) {
      unauthorizedHandler();
    }
  }
}

function setAccessToken(token) {
  accessToken = token;
}

export function setUnauthorizedHandler(handler) {
  unauthorizedHandler = handler;
}

export async function login(username, password) {
  const { data } = await authAxios.post('/auth/token/', { username, password });
  BuggyAuth.setAuthStatus(Boolean(data.access));
  setAccessToken(data.access);
}

export default fleetAxios;
