import { z } from 'zod';

import { App } from '../types';

const createAuthFieldsSchema = () => z.array(
  z.object({
    name: z.string(),
    value: z.string().min(1, 'This field is required.'),
    type: z.string(),
  }),
);

const createCompanySpecificFieldsSchema = (app: App) => (app.title.toLowerCase() === 'fleetio'
  ? z.object({
    entryTypes: z.object({
      // ID number of the entry types
      toll: z.coerce.number(),
      parking: z.coerce.number(),
    }),
    vehicleStatusIds: z.array(z.number()),
  })
  : z.object({}));

export const createIntegrationFormSchema = (app: App) => z.object({
  fleetId: z.number(),
  integrationId: z.number(),
  authFields: createAuthFieldsSchema(),
  config: z
    .object({
      vehicle: z.string(),
      fetchVehiclesOnSave: z.boolean(),
    })
    .merge(createCompanySpecificFieldsSchema(app)),
});

export type IntegrationFormType = z.TypeOf<ReturnType<typeof createIntegrationFormSchema>>;

export default {};
