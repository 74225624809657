import { FleetType } from '../fleet/types';
import { VehicleAPIResponse } from '../vehicles';

export enum TransponderStatusEnum {
  IN_STOCK = 'IN_STOCK',
  IN_SHIPMENT = 'IN_SHIPMENT',
  PENDING_VEHICLE = 'PENDING_VEHICLE',
  ACTIVE = 'ACTIVE',
  REQUESTED_REMOVAL = 'REQUESTED_REMOVAL',
  RETURN_IN_PROGRESS = 'RETURN_IN_PROGRESS',
  RETURNED = 'RETURNED',
  RETURNED_TO_TOLL_AUTH = 'RETURNED_TO_TOLL_AUTH',
  LOST_OR_STOLEN = 'LOST_OR_STOLEN',
  LOST_DEACTIVATED = 'LOST_DEACTIVATED',
}

export type Agency = {
  id: number;
  title: string;
  detail: string;
};

export type TransponderType = {
  id: number;
  vehicle?: VehicleAPIResponse,
  fleet?: FleetType,
  agencies:Agency[],
  number: string,
  status: TransponderStatusEnum
  vehicle_type: string
};

export enum ShipmentStatus {
  PENDING = 'PENDING',
  SHIPPED = 'SHIPPED',
  RECEIVED = 'RECEIVED',
  CANCELLED = 'CANCELLED',
}

export type ShipmentDirection = 'IN' | 'OUT';

export type ShipmentType = {
  id: number;
  fleet: FleetType;
  transponders: TransponderType[];
  status: ShipmentStatus;
  shipped_at: string;
  received_at: string;
  direction: ShipmentDirection;
  remarks: string;
  courier: string;
  tracking_code: string;
};

export type CourierType = {
  value: string;
  text: string;
};

export type ConfirmShipmentParams = {
  courier: string;
  trackingCode: string;
  shipment: ShipmentType;
};

export type CreateReturnShipmentParams = {
  remarks?: string;
  transponders: Pick<TransponderType, 'id'>[]
};
