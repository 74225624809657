import * as z from 'zod';

const uploadFormSchema = z.object({
  documentType: z.enum(['TOLL', 'VIOLATION']),
  files: z.array(z.instanceof(File)).min(1),
});

export type UploadFormType = z.TypeOf<typeof uploadFormSchema>;

export default uploadFormSchema;
