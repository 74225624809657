import secureLocalStorage from "react-secure-storage";

const BuggyAuth = {
  setAuthStatus(isAuthenticated) {
    localStorage.setItem("isAuthenticated", isAuthenticated);
  },
  isAuthenticated() {
    // Previous versions saved username and password in local storage.
    // This is to clear that.
    localStorage.removeItem("username");
    localStorage.removeItem("password");
    localStorage.removeItem("expiration");

    return Boolean(localStorage.getItem("isAuthenticated"));
  },
  signout() {
    localStorage.removeItem("isAuthenticated");
    secureLocalStorage.removeItem("defaultFleetId");
  },
};

export default BuggyAuth;
