import { GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import React from 'react';

import CreateReturnShipmentButton from '../shipments/createReturnShipment/CreateReturnShipmentButton';

export default function TranspondersTableToolbar() {
  return (
    <GridToolbarContainer>
      <CreateReturnShipmentButton />
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}
