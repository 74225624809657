import React, { ReactNode } from 'react';
import { MdTextFields } from 'react-icons/md';

import { FieldType } from '@/services/customFields/types';

export const FIELD_ICON_MAP: Record<FieldType, ReactNode> = {
  TEXT: <MdTextFields size={18} />,
};

export default {};
