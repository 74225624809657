import fleetAxios from '@/services/axios';

import { PostOAuthCodeParams } from './types';

const baseURL = process.env.REACT_APP_BASE_URL_ROOT;

export function consumeOAuthCode(params: PostOAuthCodeParams) {
  return fleetAxios.post(
    '/integrations/motive/auth/consume/',
    params,
    { baseURL },
  );
}

export function saveOAuthCode(params: PostOAuthCodeParams) {
  return fleetAxios.post(
    '/integrations/motive/auth/code/',
    params,
    { baseURL },
  );
}

export default {};
