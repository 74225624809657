import fleetAxios from "./axios";

const BASE_URL_ROOT = process.env.REACT_APP_BASE_URL_ROOT;

export const getAccessToken = (username, password, clientId) => fleetAxios.post(
  '/integrations/api/v1/rentcentric_user_validation/',
  {
    username,
    password,
    client_id: clientId,
  },
  {
    baseURL: BASE_URL_ROOT,
  },
);