import BuggyApi from "../../../services/BuggyApi";

export const setIntegrationEnable = async (app, isEnabled) => {
  const api = new BuggyApi();

  const { data } = await api.updateIntegrationSettings(
    app.userSettings.id,
    // We need to pass integration_schema, because the backend needs the ID
    { ...app.userSettings, enabled: isEnabled, integration_schema: app.id, },
  );

  return data;
}