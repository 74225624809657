import {
  Chip, CircularProgress, Link, MenuItem, Select, Stack, Tooltip, Typography,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { format, parseISO } from 'date-fns';
import { isEmpty } from 'lodash';
import React from 'react';
import { HiSparkles } from 'react-icons/hi2';
import { MdCalendarMonth, MdFileOpen, MdPerson } from 'react-icons/md';

import { getCustomFields } from '@/services/customFields';
import { CustomField } from '@/services/customFields/types';
import { ParkingTicket } from '@/types/api';
import { FeatureFlagMap } from '@/types/FeatureFlags';

type CreateColumnParams = {
  billingStatusOptions: { key: string, value: string }[];
  onBillingStatusChange: (id: number, status: string) => void;
  featureFlags?: FeatureFlagMap,
};

async function getCustomVehicleColumns() {
  let customFields: CustomField[] = [];

  try {
    const { data } = await getCustomFields('vehicle');
    customFields = data;
  } catch (e) {
    customFields = [];
  }

  return customFields.map<GridColDef>(({ name }) => ({
    field: name,
    flex: 1,
    headerName: name,
    minWidth: 150,
    valueGetter: ({ row }) => row.vehicle.custom_fields?.[name],
    renderHeader: ({ field }) => (
      <Stack direction="row" spacing={1} alignItems="center" alignContent="center">
        <Tooltip title="Custom Field">
          <Typography color="grey">
            <HiSparkles />
          </Typography>
        </Tooltip>
        <Tooltip title={field}>
          <Typography>{field}</Typography>
        </Tooltip>
      </Stack>
    ),
  }));
}

async function createParkingTableColumns(
  { featureFlags, billingStatusOptions, onBillingStatusChange }: CreateColumnParams,
) {
  const customColumns = await getCustomVehicleColumns();

  return [
    {
      field: 'ticketID',
      headerName: 'Ticket ID',
    },
    {
      field: 'issuing_authority',
      headerName: 'Issuing Authority',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'notice_number',
      headerName: 'Notice Number',
      minWidth: 150,
    },
    {
      field: 'violation_number',
      headerName: 'Violation Number',
      minWidth: 150,
    },
    {
      field: 'fine_amount',
      headerName: 'Fine Amount',
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ value }) => (!isEmpty(value) ? `$${value}` : null),
    },
    {
      field: 'penalty_amount',
      headerName: 'Penalty Amount',
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ value }) => (!isEmpty(value) ? `$${value}` : null),
    },
    {
      field: 'ticket_date',
      headerName: 'Ticket Date',
      minWidth: 150,
    },
    {
      field: 'vehicle_plate_number',
      headerName: 'Vehicle Plate #',
      minWidth: 150,
    },
    {
      field: 'vehicle_tracking_number',
      headerName: 'Vehicle Tracking Code',
      minWidth: 150,
    },
    {
      field: 'ticket_type',
      headerName: 'Type',
      minWidth: 150,
    },
    ...(featureFlags?.parking_history ? [{
      field: 'history',
      headerName: 'Updated By',
      minWidth: 200,
      renderCell: ({ value }) => (value ? (
        <Tooltip title={format(parseISO(value.date), 'MM-dd-yyyy hh:mm:ss')}>
          <Stack direction="row" spacing={1}>
            <Typography variant="caption">{value.username}</Typography>
            <Chip
              label={format(parseISO(value.date), 'MM-dd-yyyy')}
              size="small"
              icon={<MdCalendarMonth />}
            />
          </Stack>
        </Tooltip>
      ) : null),
    }] as GridColDef[] : []),
    ...(featureFlags?.parking_driver_email
      ? [{
        field: 'driver',
        headerName: 'Renter Email',
        minWidth: 200,
        sortable: false,
        editable: true,
        valueGetter: ({ value }) => value?.email,
        valueSetter: ({ value, row }) => ({ ...row, driver: { ...row?.driver, email: value } }),
        renderCell: ({ value }) => (value ? (
          <Chip
            icon={<MdPerson />}
            label={value}
            size="small"
          />
        ) : null),
      }] as GridColDef[] : []),
    ...(featureFlags?.parking_notes
      ? [{
        field: 'description',
        headerName: 'Notes',
        minWidth: 200,
        sortable: false,
        editable: true,
      }] as GridColDef[] : []),
    {
      field: 'received_date',
      headerName: 'Received Date',
      minWidth: 200,
      renderCell: ({ value }) => (value ? (
        <Chip
          label={value}
          size="small"
          icon={<MdCalendarMonth />}
        />
      ) : null),
    },
    {
      field: 'billing_status',
      headerName: 'Billing Status',
      minWidth: 200,
      sortable: false,
      renderCell: ({ value, row }) => (billingStatusOptions ? (
        <Select
          value={value}
          size="small"
          onChange={(e) => onBillingStatusChange(row.ticketID, e.target.value)}
          fullWidth
          sx={{
            borderRadius: 100,
          }}
        >
          {billingStatusOptions.map(
            ({ key, value: label }) => (
              <MenuItem value={key} key={key}>{label}</MenuItem>
            ),
          )}
        </Select>
      ) : <CircularProgress size={10} />),
    },
    ...customColumns,
    {
      field: 'pdf',
      headerName: 'PDF',
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ value }) => (
        <Link href={value} target="_blank">
          <MdFileOpen size={24} />
        </Link>
      ),
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
    },
  ] as GridColDef<ParkingTicket>[];
}

export default createParkingTableColumns;
