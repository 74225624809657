import {
  Autocomplete,
  Avatar, Box, InputAdornment, MenuItem,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { MdCheckCircle } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import BuggyApi from '../../services/BuggyApi';
import * as actionTypes from '../../store/actions/actions';
import { setDefaultFleetId } from '../../utils/defaultFleet';
import FleetTextField from './FleetTextField';
import { FleetModel, UserFleetModel } from './types';

function FleetSwitch() {
  const dispatch = useDispatch();
  const fleetData = useSelector((state:any) => state.userInfo);
  const [allFleets, setAllFleets] = useState<any>([]);
  const [selectedFleet, setSelectedFleet] = useState<FleetModel | null | undefined>(null);

  const handleChangeFleet = (fleet: FleetModel) => {
    setSelectedFleet(fleet);
    dispatch({ type: actionTypes.PAGE_LOADING, isPageLoading: true });
    window.location.reload();
  };

  const handleFleetFromUser = (fleetData:any) => {
    const tempFleet = {
      title: fleetData?.fleet_title,
      id: fleetData?.fleet_id,
      is_default: true,
      user_fleet_id: fleetData?.user_id,
    };
    setSelectedFleet(tempFleet);
    setAllFleets([tempFleet]);
  };

  useEffect(() => {
    if (selectedFleet?.id) {
      setDefaultFleetId(selectedFleet.id);
    }
  }, [selectedFleet]);

  const getFleets = (fleetData:any) => {
    new BuggyApi()
      .getFleets()
      .then((response) => {
        if (response?.data?.length > 0) {
          const tempFleets = response?.data?.map((userFleet: UserFleetModel) => ({
            ...userFleet.fleet,
            is_default: userFleet.is_default,
            user_fleet_id: userFleet.id,
          }));
          setAllFleets(tempFleets);
          const defaultFleet = tempFleets.find((fleet: FleetModel) => fleet.is_default);
          setSelectedFleet(defaultFleet);
        } else {
          handleFleetFromUser(fleetData);
        }
      })
      .catch((error) => console.log(error?.response));
  };

  const updateDefaultFleet = (fleet:FleetModel) => {
    new BuggyApi()
      .updateDefaultFleet(fleet.user_fleet_id)
      .then(() => handleChangeFleet(fleet))
      .catch(() => enqueueSnackbar({ variant: 'error', message: 'Failed to select Fleet' }));
  };

  useEffect(() => {
    if (fleetData.fleet_id) {
      getFleets(fleetData);
    }
  }, [fleetData]);

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Autocomplete
        id="fleet-select-dropdown"
        sx={{
          width: 220,
          fontWeight: 700,
          fontFamily: 'Montserrat, sans-serif',
        }}
        options={allFleets}
        autoHighlight
        getOptionLabel={(option:FleetModel) => option.title}
        value={selectedFleet}
        renderOption={(props, option) => (
          <MenuItem
            key={option.id}
            onClick={() => (selectedFleet?.id === option.id ? null : updateDefaultFleet(option))}
            sx={{
              display: 'flex', alignItems: 'center', padding: '7px 10px', width: '100%',
            }}
          >
            <Avatar
              sx={{
                width: 32,
                height: 32,
                fontWeight: 700,
                fontFamily: 'Montserrat, sans-serif',
              }}
            >
              {option.title?.slice(0, 1)}
            </Avatar>
            <Box
              sx={{
                ml: 1,
                fontWeight: 700,
                fontFamily: 'Montserrat, sans-serif',
              }}
            >
              {option.title}
            </Box>
            {selectedFleet?.id === option?.id ? (
              <Box display="flex" justifyContent="center" sx={{ ml: 1 }}>
                <MdCheckCircle color="green" />
              </Box>
            ) : null}
          </MenuItem>
        )}
        renderInput={(params) => (
          <FleetTextField
            {...params}
            sx={{ border: 'none', '& fieldset': { border: 'none' }, marginLeft: 1 }}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              sx: {
                color: 'whitesmoke',
                paddingLeft: '15px',
                fontWeight: '700',
                fontFamily: 'Montserrat, sans-serif',
                fontSize: '1.2rem',
              },
              startAdornment: (
                <InputAdornment position="start">
                  <Avatar
                    sx={{
                      width: 32,
                      height: 32,
                      fontWeight: 700,
                      fontFamily: 'Montserrat, sans-serif',
                    }}
                  >
                    {selectedFleet?.title?.slice(0, 1)}
                  </Avatar>
                </InputAdornment>
              ),
            }}
            size="small"
          />
        )}
      />
    </Box>
  );
}

export default FleetSwitch;
