import { Button } from '@mui/material';
import React from 'react';
import { MdRefresh } from 'react-icons/md';

type Props = {
  onRefresh: () => void;
};

function RefreshToolbarButton({ onRefresh }: Props) {
  return (
    <Button
      onClick={onRefresh}
      startIcon={<MdRefresh />}
      size="small"
    >
      Refresh
    </Button>
  );
}

export default RefreshToolbarButton;
