import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

import * as actionTypes from '../../store/actions/actions';
import AppIntegrations from './AppIntegrations/AppIntegrations';

function IntegrationHome() {
  const { path } = useRouteMatch();

  const componentIndex = useSelector((state) => state.componentIndex);
  const dispatch = useDispatch();

  useEffect(() => {
    // Update sidebar highlight
    dispatch({
      type: actionTypes.GET_COMPONENT_INDEX,
      path,
    });

    dispatch({ type: actionTypes.UPDATE_NAVIGATION, i: componentIndex });
  }, [componentIndex, dispatch, path]);

  const routes = useMemo(
    () => ({
      [`${path}/apps`]: {
        component: AppIntegrations,
        name: 'Apps',
      },
    }),
    [path],
  );

  return (
    <div className="page-wrapper slide-nav-toggle">
      <div className="content container-fluid" id="integration-layout">
        <Switch>
          <Route exact path={path}>
            <Redirect to={`${path}/apps`} />
          </Route>
          {Object.entries(routes).map(([path, { component }]) => (
            <Route path={path} component={component} key={path} />
          ))}
        </Switch>
      </div>
    </div>
  );
}

export default IntegrationHome;
