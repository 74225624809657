import {
  Autocomplete, TextField, Tooltip, Typography,
} from '@mui/material';
import { debounce, includes, startCase } from 'lodash';
import React from 'react';

import { Agency, TransponderStatusEnum, TransponderType } from '@/services/transponders/types';

import { equalFilterOperator } from './filterOperators';
import { TransponderTableColumnsParams } from './types';

const allowedStatusAssign = [
  TransponderStatusEnum.ACTIVE,
  TransponderStatusEnum.IN_SHIPMENT,
  TransponderStatusEnum.PENDING_VEHICLE,
];

function generateTransponderTableColumns({
  vehicleList, assignVehicle, unassignVehicle, onVehicleSearch,
}:TransponderTableColumnsParams) {
  const getTitle = (status:TransponderStatusEnum) => {
    if (!includes(allowedStatusAssign, status)) {
      return `Vehicles can't be assigned to transponders in ${startCase(status)} status.`;
    }
    return '';
  };

  const onChangeVehicle = (vehicleId:number, reason:string, transponderId:number) => {
    if (vehicleId) {
      assignVehicle({
        vehicleId,
        transponderId,
      });
    }
    if (reason === 'clear') {
      unassignVehicle({ transponderId });
    }
  };

  return [
    {
      field: 'number',
      headerName: 'Number',
      flex: 1,
      minWidth: 160,
    },
    {
      field: 'vehicle__plate_number',
      headerName: 'Plate#',
      flex: 1,
      minWidth: 160,
      renderCell: ({ row }:{ row: TransponderType }) => (
        <Tooltip
          title={getTitle(row.status)}
        >
          <Autocomplete
            id="vehicles-list"
            options={vehicleList}
            value={row.vehicle}
            disabled={!includes(allowedStatusAssign, row.status)}
            disableCloseOnSelect
            getOptionLabel={(option) => option.plate_number}
            onChange={(e, value, reason) => onChangeVehicle(Number(value?.id), reason, row?.id)}
            onInputChange={debounce(onVehicleSearch, 500)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => (
              <li {...props}>
                {`${option.plate_number}-${option.plate_state}`}
              </li>
            )}
            style={{ width: 500 }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder={row?.vehicle ? '' : 'No Vehicle Selected'}
                sx={{
                  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline':
                    {
                      borderColor: 'transparent',
                    },
                }}
              />
            )}
          />
        </Tooltip>
      ),
    },
    {
      field: 'agencies',
      headerName: 'Agency',
      flex: 1,
      minWidth: 160,
      renderCell: ({ row }:{ row: TransponderType }) => {
        if (!row.agencies.length) return null;
        const agencyTitleList = row?.agencies?.map((agency:Agency) => agency.title).join(', ');
        return (
          <Tooltip title={agencyTitleList}>
            <Typography noWrap sx={{ textOverflow: 'ellipsis' }}>
              {agencyTitleList}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 160,
      renderCell: ({ row }: { row: TransponderType }) => (
        <Typography>
          {startCase(row?.status)}
        </Typography>
      ),
      filterOperators: [
        equalFilterOperator,
      ],
    },
  ];
}

export default generateTransponderTableColumns;
