import { InputAdornment, TextField } from '@mui/material';
import { DataGridPro, DataGridProProps, GridPaginationModel } from '@mui/x-data-grid-pro';
import { useMutation, useQuery } from '@tanstack/react-query';
import { debounce } from 'lodash';
import React, {
  ChangeEvent, useCallback, useMemo, useState,
} from 'react';
import { MdSearch } from 'react-icons/md';

import { listShipments, receiveShipment } from '@/services/transponders';
import { ShipmentStatus, ShipmentType } from '@/services/transponders/types';

import generateShipmentTableColumns from './columns';
import ConfirmShipmentFormDialog from './confirmShipment/ConfirmShipmentFormDialog';
import ShipmentDetails from './ShipmentDetails';
import ShipmentsTableToolbar from './ShipmentsTableToolbar';

export default function ShipmentsTable() {
  const [pagination, setPagination] = useState<GridPaginationModel>({
    pageSize: 25,
    page: 0,
  });
  const [search, setSearch] = useState('');

  const { data, isLoading } = useQuery({
    queryKey: ['shipments', 'list', pagination.page, pagination.pageSize, search],
    queryFn: () => listShipments({ pagination, search }),
  });

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [selectedShipment, setSelectedShipment] = useState<ShipmentType>();

  const onConfirmShipment = useCallback((shipment: ShipmentType) => {
    setSelectedShipment(shipment);
    setIsConfirmDialogOpen(true);
  }, []);

  const onCloseDialog = useCallback(() => {
    setSelectedShipment(undefined);
    setIsConfirmDialogOpen(false);
  }, []);

  const { mutate: receiveSelectedShipment } = useMutation({
    mutationFn: async (shipment: ShipmentType) => receiveShipment(shipment),
  });

  const shipments = data?.data.results || [];

  const columns = useMemo(() => generateShipmentTableColumns({
    actions: [
      {
        title: 'Confirm Shipment',
        handler: onConfirmShipment,
        isActive: (shipment: ShipmentType) => (
          shipment.status === ShipmentStatus.PENDING
          && shipment.direction === 'IN'
        ),
      },
      {
        title: 'Receive Shipment',
        handler: receiveSelectedShipment,
        isActive: (shipment: ShipmentType) => (
          shipment.status === ShipmentStatus.SHIPPED
          && shipment.direction === 'OUT'
        ),
      },
    ],
  }), [onConfirmShipment, receiveSelectedShipment]);

  const getDetailPanelContent = useCallback<NonNullable<DataGridProProps['getDetailPanelContent']>>(
    ({ row }) => (
      <ShipmentDetails
        row={row}
      />
    ),
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeSearch = useCallback(debounce(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setSearch(event.target.value);
    },
    500,
  ), []);

  return (
    <>
      <TextField
        size="small"
        placeholder="Search"
        sx={{ marginRight: 2, marginBottom: 2 }}
        color="primary"
        InputProps={{
          sx: (theme) => ({ bgcolor: theme.palette.background.paper }),
          startAdornment: (
            <InputAdornment position="start">
              <MdSearch />
            </InputAdornment>
          ),
        }}
        onChange={onChangeSearch}
      />
      <DataGridPro
        columns={columns}
        rows={shipments}
        autoHeight
        pagination
        loading={isLoading}
        rowCount={data?.data?.count || 0}
        paginationMode="server"
        sx={(theme) => ({
          backgroundColor: theme.palette.background.paper,
          '& .MuiDataGrid-detailPanel': {
            overflow: 'auto',
          },
        })}
        paginationModel={pagination}
        onPaginationModelChange={setPagination}
        slots={{
          toolbar: ShipmentsTableToolbar,
        }}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={() => 550}
        initialState={{ pinnedColumns: { right: ['actions'] } }}
      />
      {selectedShipment
        && (
        <ConfirmShipmentFormDialog
          open={isConfirmDialogOpen}
          onClose={onCloseDialog}
          shipment={selectedShipment}
        />
        )}
    </>
  );
}
