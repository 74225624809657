import {
  Box,
  Card, CardContent, Typography,
} from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { FleetInvitation } from '@/services/fleet/types';

import AcceptInviteButton from './AcceptButton';
import AcceptForm from './AcceptForm';

type AcceptCardProps = {
  invitation: FleetInvitation
};

export default function AcceptCard({ invitation }: AcceptCardProps) {
  const isAuthenticated = useSelector((state: any) => !!state.isLoggedIn);

  return (
    <Card elevation={0} sx={{ minWidth: 350 }}>
      <CardContent>
        <Typography
          sx={{ fontSize: 14 }}
          color="text.secondary"
          gutterBottom
          textAlign="center"
        >
          You are invited to join:
        </Typography>
        <Typography
          variant="h5"
          component="div"
          textAlign="center"
        >
          {invitation.fleet.title}
        </Typography>
        <Box display="flex" justifyContent="center" pt={4}>
          {isAuthenticated
            ? <AcceptInviteButton invitation={invitation} />
            : <AcceptForm invitation={invitation} />}
        </Box>
      </CardContent>
    </Card>
  );
}
