import React, { Component } from "react";
import BuggyApi from "../../services/BuggyApi";
import "react-select2-wrapper/css/select2.css";
import BlockUi from "../BlockUi";
import "./modal.css";

class AddLiabilityBotLogin extends Component {
  state = {
    isShown: false,
    editMode: false,
    isSuccess: false,
    errorMessage: [],
    blocking: false,
    loginUrlValue:
      "https://a836-ccweb.nyc.gov/CCWeb-1/jsp/frames/frame_main.jsp",
    usernameValue: "",
    passwordValue: "",
    securityCodeValue: "",
    botLoginId: 0,
  };

  onModalShow = (botPreData = null) => {
    document.body.classList.add("modal-open");
    if (botPreData != null) {
      this.setState({
        loginUrlValue: botPreData.login_url,
        usernameValue: botPreData.username,
        passwordValue: botPreData.password,
        securityCodeValue: botPreData.security_code,
        botLoginId: botPreData.id,
        editMode: true,
      });
    }
    this.setState({
      isShown: true,
    });
  };
  onModalClose = () => {
    document.body.classList.remove("modal-open");
    this.setState({
      isSuccess: false,
      isShown: false,
      errorMessage: [],
      loginUrlValue:
        "https://a836-ccweb.nyc.gov/CCWeb-1/jsp/frames/frame_main.jsp",
      usernameValue: "",
      passwordValue: "",
      securityCodeValue: "",
      botLoginId: 0,
      editMode: false,
    });
  };

  onUsernameChangeHandler = (event) => {
    this.setState({ usernameValue: event.target.value });
  };

  onPasswordChangeHandler = (event) => {
    this.setState({ passwordValue: event.target.value });
  };

  onLoginUrlChangeHandler = (event) => {
    this.setState({ loginUrlValue: event.target.value });
  };

  onSecurityCodeChangeHandler = (event) => {
    this.setState({ securityCodeValue: event.target.value });
  };

  addBotLoginClickHandler = (event) => {
    event.preventDefault();
    const formData = {
      login_url: this.state.loginUrlValue,
      username: this.state.usernameValue,
      security_code: this.state.securityCodeValue,
      password: this.state.passwordValue,
    };
    this.setState({ blocking: true, errorMessage: [] });
    new BuggyApi()
      .addBotLogin(formData)
      .then((response) => {
        this.setState({ blocking: false });
        if (response.status === 201) {
          this.setState({
            isSuccess: true,
            errorMessage: [],
            loginUrlValue:
              "https://a836-ccweb.nyc.gov/CCWeb-1/jsp/frames/frame_main.jsp",
            usernameValue: "",
            passwordValue: "",
            securityCodeValue: "",
            botLoginId: 0,
            editMode: false,
          });
          this.props.refresh();
        }
      })
      .catch((error) => {
        this.setState({ blocking: false });
        if (error.response) {
          if (error.response.data) {
            if (error.response.data["liability-bot"]) {
              let errors = [];
              const errorList = error.response.data["liability-bot"];
              for (let key in errorList) {
                let em = "";
                switch (key) {
                  case "login_url":
                    em = "Login URL: ";
                    break;
                  case "password":
                    em = "Password: ";
                    break;
                  case "security_code":
                    em = "Security Code: ";
                    break;
                  case "username":
                    em = "Username: ";
                    break;
                  default:
                    em = "";
                    break;
                }
                em += errorList[key];
                errors.push(em);
              }
              this.setState({
                errorMessage: errors,
              });
            } else {
              const em = "Oops! " + error.response.data;
              this.setState({
                errorMessage: [em],
              });
            }
          } else {
            const em = "Oops! Something went wrong!";
            this.setState({
              errorMessage: [em],
            });
          }
        } else if (error.request) {
          const em = "Oops! Something went wrong!";
          this.setState({
            errorMessage: [em],
          });
        } else {
          /*console.log('Error', error.message);*/
        }
      });
  };

  updateBotLoginClickHandler = (event) => {
    event.preventDefault();
    const formData = {
      login_url: this.state.loginUrlValue,
      username: this.state.usernameValue,
      security_code: this.state.securityCodeValue,
      password: this.state.passwordValue,
    };
    this.setState({ blocking: true, errorMessage: [] });
    new BuggyApi()
      .updateBotLogin(this.state.botLoginId, formData)
      .then((response) => {
        this.setState({ blocking: false });
        if (response.status === 204) {
          this.setState({
            isSuccess: true,
            errorMessage: [],
            // loginUrlValue: 'https://a836-ccweb.nyc.gov/CCWeb-1/jsp/frames/frame_main.jsp',
            // usernameValue: '',
            // passwordValue:'',
            // securityCodeValue:'',
            // botLoginId:0,
            // editMode: false
          });

          this.props.refresh();
        }
      })
      .catch((error) => {
        this.setState({ blocking: false });
        if (error.response) {
          if (error.response.data) {
            if (error.response.data["liability-bot"]) {
              let errors = [];
              const errorList = error.response.data["liability-bot"];
              for (let key in errorList) {
                let em = "";
                switch (key) {
                  case "login_url":
                    em = "Login URL: ";
                    break;
                  case "password":
                    em = "Password: ";
                    break;
                  case "security_code":
                    em = "Security Code: ";
                    break;
                  case "username":
                    em = "Username: ";
                    break;
                  default:
                    em = "";
                    break;
                }
                em += errorList[key];
                errors.push(em);
              }
              this.setState({
                errorMessage: errors,
              });
            } else {
              const em = "Oops! " + error.response.data;
              this.setState({
                errorMessage: [em],
              });
            }
          } else {
            const em = "Oops! Something went wrong!";
            this.setState({
              errorMessage: [em],
            });
          }
        } else if (error.request) {
          const em = "Oops! Something went wrong!";
          this.setState({
            errorMessage: [em],
          });
        } else {
          /*console.log('Error', error.message);*/
        }
      });
  };

  render() {
    return (
      <div
        className={
          this.state.isShown
            ? "modal custom-modal fade in showModal"
            : "modal custom-modal fade hideModal"
        }
        role="dialog"
      >
        <div className="modal-dialog">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={this.onModalClose}
          >
            &times;
          </button>
          <div className="modal-content modal-lg">
            <br />
            <br />
            <br />
            <div className="modal-header text-center">
              <h4 className="modal-title">
                {this.state.editMode ? "Update" : "Add New"} Bot Login
              </h4>
            </div>
            <div className="modal-body">
              <form>
                {this.state.isSuccess ? (
                  <div className="row">
                    <div className="col-sm-6 col-sm-offset-3">
                      <div className="alert alert-success">
                        <strong>Success!</strong> Bot Login{" "}
                        {this.state.editMode ? "updated" : "added"}{" "}
                        successfully!
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.state.errorMessage.length > 0 ? (
                  <div className="row">
                    <div className="col-sm-6 col-sm-offset-3">
                      <div className="alert alert-danger">
                        <ul>
                          {this.state.errorMessage.map((message, i) => (
                            <li key={i}>{message}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ) : null}
                <BlockUi tag="div" blocking={this.state.blocking}>
                  <div className="row">
                    <div className="col-sm-6 col-sm-offset-3">
                      <div className="form-group">
                        <label>Username</label>
                        <input
                          className="form-control"
                          type="text"
                          value={this.state.usernameValue}
                          maxLength={25}
                          onChange={this.onUsernameChangeHandler}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-sm-offset-3">
                      <div className="form-group">
                        <label>Password</label>
                        <input
                          className="form-control"
                          type="text"
                          value={this.state.passwordValue}
                          maxLength={25}
                          onChange={this.onPasswordChangeHandler}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-sm-offset-3">
                      <div className="form-group">
                        <label>Login URL</label>
                        <input
                          className="form-control"
                          type="text"
                          value={this.state.loginUrlValue}
                          onChange={this.onLoginUrlChangeHandler}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-sm-offset-3">
                      <div className="form-group">
                        <label>Security Code</label>
                        <input
                          className="form-control"
                          type="text"
                          value={this.state.securityCodeValue}
                          maxLength={25}
                          onChange={this.onSecurityCodeChangeHandler}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="m-t-20 text-center">
                    <button
                      className="btn btn-primary"
                      type="submit"
                      onClick={
                        this.state.editMode
                          ? this.updateBotLoginClickHandler
                          : this.addBotLoginClickHandler
                      }
                    >
                      {this.state.editMode ? "Update" : "Add"} Login Bot
                    </button>
                  </div>
                </BlockUi>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddLiabilityBotLogin;
