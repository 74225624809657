import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

import { range } from 'lodash';
import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';

import { InvoiceProps } from './types';

function Invoice({ file }: InvoiceProps) {
  const [pages, setNumPages] = useState<number>(0);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <div>
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        {range(pages).map((pageNumber) => (
          <Page
            pageNumber={pageNumber + 1}
            key={pageNumber}
          />
        ))}
      </Document>
    </div>
  );
}

export default Invoice;
