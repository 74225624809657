import React, { Component, Fragment } from "react";
import BuggyApi from "../../services/BuggyApi";
import "react-select2-wrapper/css/select2.css";
import BlockUi from "../BlockUi";
import "./modal.css";

class DriverBulkUpload extends Component {
  state = {
    isShown: false,
    isSuccess: false,
    errorMessage: [],
    blocking: false,
    errorFileUrl: null,
  };

  onModalShow = () => {
    document.body.classList.add("modal-open");
    this.setState({
      isShown: true,
    });
  };

  onModalClose = () => {
    document.body.classList.remove("modal-open");
    this.setState({
      isShown: false,
      isSuccess: false,
      errorMessage: [],
      blocking: false,
    });
  };
  handleDownloadErrorFile = () => {
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);

    // Set the HREF to a Blob representation of the data to be downloaded
    a.href = this.state.errorFileUrl;
    a.target = "_self";
    // Use download attribute to set set desired file name
    a.setAttribute("download", "bulk_upload_error_file.csv");

    // Trigger the download by simulating click
    a.click();

    // Cleanup
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
    this.setState({
      errorFileUrl: null,
    });
  };

  uploadBtnClickHandler = (event) => {
    event.preventDefault();
    /*const formData = {
            csv_upload: this.state.csv_upload
        };*/
    let formData = new FormData();
    formData.set("csv_upload", this.csv_upload.files[0]);
    formData.set("address_validation", this.address_validation.checked);

    if (this.csv_upload.files[0].size > 5242880) {
      const em = "File Size should be less than 5mb";
      this.setState({
        errorMessage: [em],
      });
      return false;
    }

    this.setState({ blocking: true, errorMessage: [] });
    new BuggyApi()
      .uploadDriverCSV(formData)
      .then((response) => {
        this.setState({ blocking: false });
        if (response.status === 201) {
          this.setState({
            isSuccess: true,
            errorMessage: [],
          });
          this.props.refresh();
        } else if (response.status === 200) {
          if (response.headers["content-type"] === "text/csv") {
            if (response.data) {
              let reader = new FileReader();
              let file = new Blob([response.data]);
              reader.onload = () => {
                let obj_url = window.URL.createObjectURL(file);
                this.setState({
                  errorFileUrl: obj_url,
                  errorMessage: [
                    "Your file contained some errors we've provided you the records which had errors",
                  ],
                });
              };
              reader.readAsDataURL(file);
            }
          }
          if (response.data.csv_upload) {
            let errors = [];
            const errorList = response.data;
            for (let key in errorList) {
              let em = "";
              switch (key) {
                case "csv_upload":
                  em = "Upload CSV: ";

                  break;
                case "error":
                  em = "Upload CSV: ";
                  break;
                default:
                  em = "";
                  break;
              }
              if (typeof errorList[key] === "string") {
                em += errorList[key];
                errors.push(em);
              }
              if (typeof errorList[key] === "object") {
                for (let error in errorList[key]) {
                  em = errorList[key][error];
                  errors.push(em);
                }
              }
            }
            this.setState({
              errorMessage: errors,
            });
          }
        } else {
          console.log(response.data.error_message, response);
          this.setState({
            isSuccess: false,
            errorMessage: ["Opps, Something went Wrong."],
          });
          this.props.refresh();
        }
      })
      .catch((error) => {
        this.setState({ blocking: false });
        if (error.response) {
          if (error.response.data !== null) {
            let errors = [];
            const errorList = error.response.data.csv_upload;
            for (let key in errorList) {
              let em = "";
              switch (key) {
                case "csv_upload":
                  em = "Upload CSV: ";
                  break;
                case "error":
                  em = "Upload CSV: ";
                  break;
                default:
                  em = "";
                  break;
              }
              em += errorList[key];
              errors.push(em);
            }
            this.setState({
              errorMessage: errors,
            });
          } else {
            const em = "You don't have permissions";
            this.setState({
              errorMessage: [em],
            });
          }
        } else if (error.request) {
          const em = "Oops! Something went wrong!";
          this.setState({
            errorMessage: [em],
          });
        } else {
          /*console.log('Error', error.message);*/
        }
      });
  };

  render() {
    return (
      <div
        className={
          this.state.isShown
            ? "modal custom-modal fade in showModal"
            : "modal custom-modal fade hideModal"
        }
        role="dialog"
      >
        <div className="modal-dialog">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={this.onModalClose}
          >
            &times;
          </button>
          <div className="modal-content modal-lg">
            <br />
            <br />
            <br />
            <div className="modal-header text-center">
              <h4 className="modal-title">Upload CSV</h4>
            </div>
            <div className="modal-body">
              <form method="post" encType="multipart/form-data">
                {this.state.isSuccess ? (
                  <div className="row">
                    <div className="col-sm-6 col-sm-offset-3">
                      <div className="alert alert-success">
                        <strong>Success!</strong> Drivers added successfully!
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.state.errorMessage.length > 0 ? (
                  <div className="row">
                    <div className="col-sm-6 col-sm-offset-3">
                      <div className="alert alert-danger">
                        <ul>
                          {this.state.errorMessage.map((message, i) => (
                            <li key={i}>{message}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ) : null}
                <BlockUi tag="div" blocking={this.state.blocking}>
                  {this.state.errorFileUrl ? (
                    <Fragment>
                      <div className="m-t-20 text-center">
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={this.handleDownloadErrorFile}
                        >
                          Download Error File
                        </button>
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className="row">
                        <div className="col-sm-6 col-sm-offset-3">
                          <div className="form-group">
                            <label>Upload CSV File</label>
                            <input
                              className="form-control"
                              type="file"
                              ref={(el) => (this.csv_upload = el)}
                              accept=".csv"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6 col-sm-offset-3">
                          <div className="form-group">
                            <label>
                              Address Validation &nbsp;
                              <input
                                className=""
                                type="checkbox"
                                ref={(el) => (this.address_validation = el)}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="m-t-20 text-center">
                        <button
                          className="btn btn-primary"
                          type="submit"
                          onClick={this.uploadBtnClickHandler}
                        >
                          Upload
                        </button>
                      </div>
                    </Fragment>
                  )}
                </BlockUi>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DriverBulkUpload;
