import { Box } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

type PageWrapperProps = PropsWithChildren;

export default function PageWrapper({ children }: PageWrapperProps) {
  const isAuthenticated = useSelector((state: any) => !!state.isLoggedIn);

  return isAuthenticated
    ? (
      <div className="page-wrapper slide-nav-toggle">
        <div className="content container-fluid">{children}</div>
      </div>
    ) : (
      <Box p={10}>
        {children}
      </Box>
    );
}
