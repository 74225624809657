import React, { Component, Fragment } from "react";
import $ from "jquery";
import Pagination from "react-js-pagination";
import BuggyApi from "../../services/BuggyApi";
import "./dateTimePicker.css";
import Datetime from "react-datetime";
import Aux from "../hoc/Auxiliary";
import BlockUi from "../BlockUi";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions/actions";
import Select2 from "react-select2-wrapper";
import DriverDetails from "../Tickets/DriverDetails";
import AssociateDriver from "../Tickets/AssociateDriver";

class Violations extends Component {
  state = {
    violations: [],
    itemsCountPerPage: 20,
    totalItemsCount: 0,
    pageRangeDisplayed: 10,
    activePage: 1,
    focusedIssueFrom: false,
    showAnimation: false,
    // botReport: null,
    botReport: null,
    focusedIssueTo: false,
    focusedRespondByFrom: false,
    focusedRespondByTo: false,
    focusedReportDateFrom: false,
    focusedReportDateTo: false,
    focusedViolationTimeFrom: false,
    focusedViolationTimeTo: false,
    blocking: false,
    liabilityBots: [{ id: "", text: "All Accounts" }],
    ticketStatuses: [
      { id: "", text: "All" },
      { id: "FILLED", text: "Filled" },
      { id: "UNFILLED", text: "Un-Filled" },
    ],
    selectedBot: "",
    selectedTicketStatus: "",
    isAllowed: false,
    focusedPlate: false,
    responseError:
      "You're not authorized to use this feature please contact admin",
  };
  onDriverDetailClickHandler = (evt, driver_id) => {
    this.driverDetail.onModalShow(driver_id);
  };

  onDriverAssociateClickHandler = (ticket_id) => {
    this.driverAssociate.onModalShow(ticket_id, "STATE");
  };
  refreshViolations = () => {
    this.setState({ isLoading: true });
    new BuggyApi().searchViolations().then((response) => {
      if (this.is_mounted) {
        this.setState({
          violations: response.data.objects,
          itemsCountPerPage: response.data.meta.limit,
          totalItemsCount: response.data.meta.total_count,
          isLoading: false,
        });
      }
    });
  };
  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber });
    pageNumber -= 1;
    new BuggyApi()
      .searchViolations(
        this.state.itemsCountPerPage * pageNumber,
        this.searchPlate.value,
        this.dateIssueFrom.state.inputValue,
        this.dateIssueTo.state.inputValue,
        this.dateRespondByTo.state.inputValue,
        this.dateRespondByTo.state.inputValue,
        this.dateReportDateFrom.state.inputValue,
        this.dateReportDateTo.state.inputValue,
        this.violationTimeFrom.state.inputValue,
        this.violationTimeTo.state.inputValue,
        this.state.selectedBot,
        this.state.selectedTicketStatus
      )
      .then((response) => {
        this.setState({
          violations: response.data.objects,
          itemsCountPerPage: response.data.meta.limit,
          totalItemsCount: response.data.meta.total_count,
        });
      });
  };

  onFocusHandlerToggle = (name) => {
    let obj = {};
    obj[name.toString()] = !this.state[name.toString()];
    this.setState(obj);
  };

  componentDidMount() {
    this.is_mounted = true;
    window.$ = window.jQuery = $;
    this.props.getComponentIndex(this.props.location.pathname);
    this.props.onLinkChange(this.props.componentIndex);
    new BuggyApi()
      .getBotLogin()
      .then((response) => {
        const liabilityBots = response.data.objects;
        const data = [];
        data.push({ id: "", text: "All Accounts" });
        liabilityBots.forEach(function (bot) {
          data.push({ id: bot.id, text: bot.username });
        });
        this.setState({
          isAllowed: true,
          liabilityBots: data,
        });
        new BuggyApi().searchViolations().then((response) => {
          if (this.is_mounted) {
            this.setState({
              violations: response.data.objects,
              itemsCountPerPage: response.data.meta.limit,
              totalItemsCount: response.data.meta.total_count,
            });
          }
        });
      })
      .catch((error) => {
        /*console.log('Error', error);*/
        if (error.response) {
          let error_msg =
            "You're not authorized to use this feature please contact admin";
          if (error.response.data) {
            if (error.response.data.detail) {
              error_msg = error.response.data.detail;
            }
          }
          this.setState({
            isAllowed: false,
            responseError: error_msg,
          });
        } else if (error.request) {
        } else {
          /*console.log('Error', error.message);*/
        }
      });
  }

  componentWillUnmount() {
    this.is_mounted = false;
  }

  searchClickHandler = (evt, offset = 0) => {
    const selectedBot = this.liabilityBotDropdown.el.val() || "";
    const selectedTicketStatus =
      this.selectedTicketStatusDropdown.el.val() || "";
    this.setState({
      selectedBot: selectedBot,
      selectedTicketStatus: selectedTicketStatus,
    });
    new BuggyApi()
      .searchViolations(
        offset,
        this.searchPlate.value,
        this.dateIssueFrom.state.inputValue,
        this.dateIssueTo.state.inputValue,
        this.dateRespondByTo.state.inputValue,
        this.dateRespondByTo.state.inputValue,
        this.dateReportDateFrom.state.inputValue,
        this.dateReportDateTo.state.inputValue,
        this.violationTimeFrom.state.inputValue,
        this.violationTimeTo.state.inputValue,
        selectedBot,
        selectedTicketStatus
      )
      .then((response) => {
        if (this.is_mounted) {
          this.setState({
            violations: response.data.objects,
            activePage: 1,
            itemsCountPerPage: response.data.meta.limit,
            totalItemsCount: response.data.meta.total_count,
          });
        }
      })
      .catch((error) => {
        /*console.log('Error', error);*/
        if (error.response) {
        } else if (error.request) {
        } else {
          /*console.log('Error', error.message);*/
        }
      });
  };

  onFocusHandlerTogglePlate = () => {
    this.setState({ focusedPlate: !this.state.focusedPlate });
  };

  onRequestDriverClickHandler = (plate_number) => {
    this.setState({ isLoading: true });
    new BuggyApi()
      .requestDriver(plate_number)
      .then((response) => {
        if (response.status === 200) {
          let offset =
            this.state.itemsCountPerPage * this.state.activePage - 20;
          new BuggyApi().searchViolations(offset).then((response) => {
            if (this.is_mounted) {
              this.setState({
                violations: response.data.objects,
                itemsCountPerPage: response.data.meta.limit,
                totalItemsCount: response.data.meta.total_count,
                isLoading: false,
              });
            }
          });
        }
      })
      .catch((error) => {
        // console.log(error.response);
      });
  };

  downloadCsvClickHandler = (evt, offset = 0) => {
    this.setState({ blocking: true });
    let selectedBot = "";
    let selectedTicketStatus = "";
    let searchPlate = "";
    let dateIssueFrom = "";
    let dateIssueTo = "";
    let dateRespondByFrom = "";
    let dateRespondByTo = "";
    let dateReportDateFrom = "";
    let dateReportDateTo = "";
    let violationTimeFrom = "";
    let violationTimeTo = "";
    if (this.selectedTicketStatusDropdown) {
      selectedBot = this.liabilityBotDropdown.el.val() || "";
      selectedTicketStatus = this.selectedTicketStatusDropdown.el.val() || "";
      searchPlate = this.searchPlate.value;
      dateIssueFrom = this.dateIssueFrom.state.inputValue;
      dateIssueTo = this.dateIssueTo.state.inputValue;
      dateRespondByFrom = this.dateRespondByFrom.state.inputValue;
      dateRespondByTo = this.dateRespondByTo.state.inputValue;
      dateReportDateFrom = this.dateReportDateFrom.state.inputValue;
      dateReportDateTo = this.dateReportDateTo.state.inputValue;
      violationTimeFrom = this.violationTimeFrom.state.inputValue;
      violationTimeTo = this.violationTimeTo.state.inputValue;
    }

    new BuggyApi()
      .getViolationDownloadLink(
        offset,
        searchPlate,
        dateIssueFrom,
        dateIssueTo,
        dateRespondByFrom,
        dateRespondByFrom,
        dateRespondByTo,
        dateReportDateFrom,
        dateReportDateTo,
        violationTimeFrom,
        violationTimeTo,
        selectedBot,
        selectedTicketStatus
      )
      .then((response) => {
        if (this.is_mounted) {
          this.setState({
            blocking: false,
          });
        }

        window.open(response.data.url, "_blank");
      })
      .catch((error) => {
        /*console.log('Error', error);*/
        if (error.response) {
        } else if (error.request) {
        } else {
          /*console.log('Error', error.message);*/
        }
      });
  };
  runLiabilityBot = () => {
    this.setState({
      showAnimation: true,
    });
    new BuggyApi()
      .runLiabilityBot()
      .then((response) => {
        if (response.status === 200) {
          if (response.data) {
            if (response.data.bot_report) {
              this.setState({
                showAnimation: false,
                botReport: response.data.bot_report,
              });
              let offset =
                this.state.itemsCountPerPage * this.state.activePage - 20;
              new BuggyApi().searchViolations(offset).then((response) => {
                if (this.is_mounted) {
                  this.setState({
                    violations: response.data.objects,
                    itemsCountPerPage: response.data.meta.limit,
                    totalItemsCount: response.data.meta.total_count,
                  });
                }
              });
            } else {
              this.setState({
                showAnimation: false,
                botReport: {
                  no_drivers: 0,
                  filled: 0,
                  unfilled: 0,
                  total_time: 0,
                },
              });
            }
          } else {
            this.setState({
              showAnimation: false,
              botReport: {
                no_drivers: 0,
                filled: 0,
                unfilled: 0,
                total_time: 0,
              },
            });
          }
        } else {
          this.setState({
            showAnimation: false,
            botReport: { no_drivers: 0, filled: 0, unfilled: 0, total_time: 0 },
          });
        }
      })
      .catch((error) => {
        this.setState({
          showAnimation: false,
          botReport: { no_drivers: 0, filled: 0, unfilled: 0, total_time: 0 },
        });
      });
  };

  render() {
    return (
      <Aux>
        <div
          className={
            this.props.sidebar_status
              ? "page-wrapper slide-nav-toggle"
              : "page-wrapper"
          }
        >
          <div className="content container-fluid">
            <BlockUi tag="div" blocking={this.state.blocking}>
              <div className="row m-b-20">
                <div className="col-sm-4 col-xs-12">
                  <h4 className="page-title">Toll Transactions</h4>
                </div>
                <div className="col-sm-8 text-right  col-xs-12">
                  <button
                    className="btn btn-primary rounded pull-right"
                    style={{
                      marginLeft: "10px",
                    }}
                    onClick={this.runLiabilityBot}
                  >
                    <i className="fa fa-refresh"></i>&nbsp; RUN LIABILITY BOT
                  </button>

                  <button
                    className="btn btn-primary rounded pull-right mr-2"
                    onClick={this.downloadCsvClickHandler}
                  >
                    <i className="fa fa-cloud-download"></i> &nbsp; Download CSV
                  </button>
                </div>
              </div>
              {this.state.isAllowed ? (
                <Fragment>
                  {this.state.showAnimation ? (
                    <div className="row filter-row ">
                      <div className="col-xs-12 ">
                        <div
                          className="text-center text-primary well well-lg"
                          style={{ minHeight: "70px" }}
                        >
                          <i className="fa fa-refresh fa-spin fa-3x fa-fw "></i>
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {!this.state.showAnimation && this.state.botReport ? (
                    <Fragment>
                      <div className="row ">
                        <div className="col-xs-12">
                          <h4 className="text-capitalize text-primary">
                            BOT RUN REPORT
                          </h4>
                          <h5 className="text-capitalize text-info">
                            Total Time: {this.state.botReport.total_time}{" "}
                          </h5>
                        </div>
                      </div>
                      <div className="row filter-row">
                        <div className="col-xs-4">
                          <div className="well well-lg text-success text-center bg-white">
                            <h4>FILLED</h4>
                            <h4>{this.state.botReport.filled}</h4>
                          </div>
                        </div>
                        <div className="col-xs-4">
                          <div className="well well-lg text-danger text-center bg-white">
                            <h4>DRIVER REQUESTED / NOT FILLED</h4>
                            <h4>{this.state.botReport.no_drivers}</h4>
                          </div>
                        </div>
                        <div className="col-xs-4">
                          <div className="well well-lg text-warning text-center bg-white">
                            <h4>MANUAL FILLED</h4>
                            <h4>{this.state.botReport.unfilled}</h4>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  ) : null}
                  <div className="row filter-row">
                    <div className="col-sm-4 col-xs-12">
                      <div className="row">
                        <div className="col-sm-6 col-xs-6">
                          <div
                            className={
                              this.state.focusedIssueFrom
                                ? "form-group form-focus focused"
                                : "form-group form-focus"
                            }
                          >
                            <label className="control-label">Issue From</label>
                            <div className="cal-icon">
                              <Datetime
                                dateFormat="YYYY-MM-DD"
                                onFocus={() =>
                                  this.onFocusHandlerToggle("focusedIssueFrom")
                                }
                                onBlur={() =>
                                  this.onFocusHandlerToggle("focusedIssueFrom")
                                }
                                timeFormat={false}
                                ref={(el) => (this.dateIssueFrom = el)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-xs-6">
                          <div
                            className={
                              this.state.focusedIssueTo
                                ? "form-group form-focus focused"
                                : "form-group form-focus "
                            }
                          >
                            <label className="control-label">Issue To</label>
                            <div className="cal-icon">
                              <Datetime
                                dateFormat="YYYY-MM-DD"
                                onFocus={() =>
                                  this.onFocusHandlerToggle("focusedIssueTo")
                                }
                                onBlur={() =>
                                  this.onFocusHandlerToggle("focusedIssueTo")
                                }
                                timeFormat={false}
                                ref={(el) => (this.dateIssueTo = el)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 col-xs-12">
                      <div className="row">
                        <div className="col-sm-6 col-xs-6">
                          <div
                            className={
                              this.state.focusedRespondByFrom
                                ? "form-group form-focus focused"
                                : "form-group form-focus"
                            }
                          >
                            <label className="control-label">
                              Respond BY From
                            </label>
                            <div className="cal-icon">
                              <Datetime
                                dateFormat="YYYY-MM-DD"
                                onFocus={() =>
                                  this.onFocusHandlerToggle(
                                    "focusedRespondByFrom"
                                  )
                                }
                                onBlur={() =>
                                  this.onFocusHandlerToggle(
                                    "focusedRespondByFrom"
                                  )
                                }
                                timeFormat={false}
                                ref={(el) => (this.dateRespondByFrom = el)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-xs-6">
                          <div
                            className={
                              this.state.focusedRespondByTo
                                ? "form-group form-focus focused"
                                : "form-group form-focus "
                            }
                          >
                            <label className="control-label">
                              Respond BY To
                            </label>
                            <div className="cal-icon">
                              <Datetime
                                dateFormat="YYYY-MM-DD"
                                onFocus={() =>
                                  this.onFocusHandlerToggle(
                                    "focusedRespondByTo"
                                  )
                                }
                                onBlur={() =>
                                  this.onFocusHandlerToggle(
                                    "focusedRespondByTo"
                                  )
                                }
                                timeFormat={false}
                                ref={(el) => (this.dateRespondByTo = el)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 col-xs-12">
                      <div className="row">
                        <div className="col-sm-6 col-xs-6">
                          <div
                            className={
                              this.state.focusedReportDateFrom
                                ? "form-group form-focus focused"
                                : "form-group form-focus"
                            }
                          >
                            <label className="control-label">
                              Report Date From
                            </label>
                            <div className="cal-icon">
                              <Datetime
                                dateFormat="YYYY-MM-DD"
                                onFocus={() =>
                                  this.onFocusHandlerToggle(
                                    "focusedReportDateFrom"
                                  )
                                }
                                onBlur={() =>
                                  this.onFocusHandlerToggle(
                                    "focusedReportDateFrom"
                                  )
                                }
                                timeFormat={false}
                                ref={(el) => (this.dateReportDateFrom = el)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-xs-6">
                          <div
                            className={
                              this.state.focusedReportDateTo
                                ? "form-group form-focus focused"
                                : "form-group form-focus "
                            }
                          >
                            <label className="control-label">
                              Report Date To
                            </label>
                            <div className="cal-icon">
                              <Datetime
                                dateFormat="YYYY-MM-DD"
                                onFocus={() =>
                                  this.onFocusHandlerToggle(
                                    "focusedReportDateTo"
                                  )
                                }
                                onBlur={() =>
                                  this.onFocusHandlerToggle(
                                    "focusedReportDateTo"
                                  )
                                }
                                timeFormat={false}
                                ref={(el) => (this.dateReportDateTo = el)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 col-xs-12">
                      <div className="row">
                        <div className="col-sm-6 col-xs-6">
                          <div
                            className={
                              this.state.focusedViolationTimeFrom
                                ? "form-group form-focus focused"
                                : "form-group form-focus"
                            }
                          >
                            <label className="control-label">
                              Violation Time From
                            </label>
                            <div className="cal-icon">
                              <Datetime
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                                onFocus={() =>
                                  this.onFocusHandlerToggle(
                                    "focusedViolationTimeFrom"
                                  )
                                }
                                onBlur={() =>
                                  this.onFocusHandlerToggle(
                                    "focusedViolationTimeFrom"
                                  )
                                }
                                ref={(el) => (this.violationTimeFrom = el)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-xs-6">
                          <div
                            className={
                              this.state.focusedViolationTimeTo
                                ? "form-group form-focus focused"
                                : "form-group form-focus "
                            }
                          >
                            <label className="control-label">
                              Violation Time To
                            </label>
                            <div className="cal-icon">
                              <Datetime
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                                onFocus={() =>
                                  this.onFocusHandlerToggle(
                                    "focusedViolationTimeTo"
                                  )
                                }
                                onBlur={() =>
                                  this.onFocusHandlerToggle(
                                    "focusedViolationTimeTo"
                                  )
                                }
                                ref={(el) => (this.violationTimeTo = el)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-2 col-xs-12">
                      <div
                        className={
                          this.state.focusedPlate
                            ? "form-group form-focus focused"
                            : "form-group form-focus"
                        }
                      >
                        <label className="control-label">Plate#</label>
                        <input
                          type="text"
                          className="form-control floating"
                          onFocus={this.onFocusHandlerTogglePlate}
                          onBlur={this.onFocusHandlerTogglePlate}
                          ref={(el) => (this.searchPlate = el)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-2 col-xs-12">
                      <Select2
                        ref={(el) => (this.liabilityBotDropdown = el)}
                        className="selector"
                        data={this.state.liabilityBots}
                        value={this.state.selectedBot}
                        options={{
                          width: "100%",
                          minimumResultsForSearch: 5,
                        }}
                      />
                    </div>
                    <div className="col-sm-2 col-xs-12">
                      <Select2
                        ref={(el) => (this.selectedTicketStatusDropdown = el)}
                        className="selector"
                        data={this.state.ticketStatuses}
                        value={this.state.selectedTicketStatus}
                        options={{
                          width: "100%",
                          minimumResultsForSearch: 5,
                        }}
                      />
                    </div>
                    <div className="col-sm-2 col-xs-12">
                      <button
                        type="button"
                        className="btn btn-success btn-block"
                        onClick={this.searchClickHandler}
                      >
                        {" "}
                        Search
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <table className="table table-striped custom-table m-b-0 ">
                          <thead>
                            <tr>
                              <th>Plate#</th>
                              <th>ID-ST-TYP</th>
                              <th>Summons#</th>
                              <th>Open</th>
                              <th>Issue Date</th>
                              <th>Violation Time</th>
                              <th>Respond BY</th>
                              <th>Report Date</th>
                              <th>Status</th>
                              <th>Included in Invoice</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.violations.map((violation, index) => {
                              let fill_status = null;

                              if (violation.filled_status === "FILLED") {
                                fill_status = (
                                  <span className="label label-success-border">
                                    Filled
                                  </span>
                                );
                              } else if (
                                violation.filled_status === "FILLED_MANUALLY"
                              ) {
                                fill_status = (
                                  <span className="label label-warning-border">
                                    Manual Filled
                                  </span>
                                );
                              } else {
                                fill_status = (
                                  <span className="label label-danger-border">
                                    Unfilled
                                  </span>
                                );
                              }

                              let has_driver = null;
                              if (
                                (violation.driver || !violation.driver) &&
                                violation.filled_status === "FILLED_MANUALLY"
                              ) {
                                has_driver = <span>N/A</span>;
                              } else if (violation.driver) {
                                has_driver = (
                                  <div>
                                    {violation.driver.id +
                                      " - " +
                                      violation.driver.first_name +
                                      " " +
                                      violation.driver.last_name}
                                    <button
                                      type="button"
                                      onClick={(event) => {
                                        this.onDriverDetailClickHandler(
                                          event,
                                          violation.driver.id
                                        );
                                      }}
                                      style={{ marginLeft: 10 }}
                                      className="btn btn-outline-info text-info"
                                    >
                                      <i className="fa fa-eye"></i>
                                    </button>
                                  </div>
                                );
                              } else {
                                has_driver = (
                                  <Fragment>
                                    <button
                                      className="btn btn-sm btn-primary"
                                      onClick={() =>
                                        this.onDriverAssociateClickHandler(
                                          violation.id
                                        )
                                      }
                                    >
                                      Manually Associate Driver
                                    </button>
                                    <button
                                      className="btn btn-sm btn-primary"
                                      onClick={() =>
                                        this.onRequestDriverClickHandler(
                                          violation.plate
                                        )
                                      }
                                    >
                                      Request Driver
                                    </button>
                                  </Fragment>
                                );
                              }

                              return (
                                <tr key={index}>
                                  <td>{violation.plate}</td>
                                  <td>{violation.id_st_typ}</td>
                                  <td>{violation.summons_no}</td>
                                  <td>{violation.open_with_currency}</td>
                                  <td>{violation.issue_date}</td>
                                  <td>{violation.violation_time}</td>
                                  <td>{violation.respond_by}</td>
                                  <td>{violation.report_date}</td>
                                  <td>{fill_status}</td>
                                  <td>N/A</td>
                                  <td>{has_driver}</td>
                                  {/*<td>{violation.id}</td>*/}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-5">
                      <div className="dataTables_info">
                        Showing{" "}
                        {this.state.itemsCountPerPage *
                          (this.state.activePage - 1)}{" "}
                        to&nbsp;
                        {this.state.itemsCountPerPage *
                          this.state.activePage}{" "}
                        of&nbsp;
                        {this.state.totalItemsCount} entries
                      </div>
                    </div>
                    <div className="col-sm-7">
                      <div className="dataTables_paginate">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={this.state.itemsCountPerPage}
                          totalItemsCount={this.state.totalItemsCount}
                          pageRangeDisplayed={this.state.pageRangeDisplayed}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    </div>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div style={{ textAlign: "lef", color: "#9e9e9e" }}>
                    <h2>Error: {this.state.responseError}</h2>
                  </div>
                </Fragment>
              )}
            </BlockUi>
          </div>
        </div>
        <DriverDetails
          ref={(el) => (this.driverDetail = el)}
          refresh={this.refreshViolations}
        />
        <AssociateDriver
          ref={(el) => (this.driverAssociate = el)}
          refresh={this.refreshViolations}
        />
      </Aux>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    componentIndex: state.componentIndex,
    sidebar_status: state.sidebarOpened,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onLinkChange: (i) => dispatch({ type: actionTypes.UPDATE_NAVIGATION, i }),
    getComponentIndex: (path) =>
      dispatch({ type: actionTypes.GET_COMPONENT_INDEX, path }),
  };
};
export default connect(mapStatesToProps, mapDispatchToProps)(Violations);
