import { GridFilterOperator } from '@mui/x-data-grid-pro';

import StatusSelect from '@/pages/transponders/filterCustomInput/StatusSelect';
import { TransponderStatusEnum, TransponderType } from '@/services/transponders/types';

// eslint-disable-next-line import/prefer-default-export
export const equalFilterOperator = {
  label: 'Equals',
  getApplyFilterFn: (item) => ({ value }) => value === item.value,
  value: 'equals',
  InputComponent: StatusSelect,
  InputComponentProps: {
    options: Object.values(TransponderStatusEnum)
      .map((status) => ({ label: status, value: status })),
  },
} satisfies GridFilterOperator<TransponderType>;
