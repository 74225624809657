import qs from 'qs';

import fleetAxios from '../axios';
import {
  BalanceResponse,
  DownloadInvoiceResponse,
  GetInvoicesParams,
  InvoiceResponse,
  ListResponse,
  PayInvoiceResponse,
  PaymentCallbacks,
  UsageResponse,
  WalletResponse,
  WalletTransactionResponse,
} from './types';

const BASE_URL_ROOT = process.env.REACT_APP_BASE_URL_ROOT;

export function listInvoices(params: GetInvoicesParams = {}) {
  const { paymentStatus } = params;

  const queryParams = qs.stringify({
    payment_status: paymentStatus,
  });

  return fleetAxios.get<ListResponse<'invoices', DownloadInvoiceResponse>>(
    `/billing/invoices/?${queryParams}`,
    {
      baseURL: BASE_URL_ROOT,
    },
  );
}

export function getBalance() {
  return fleetAxios.get<BalanceResponse>(
    '/billing/balance/',
    {
      baseURL: BASE_URL_ROOT,
    },
  );
}

/**
 * Triggers the generation of PDF and returns the invoice
 * @param id Invoice ID
 */
export function downloadInvoice(id: string) {
  return fleetAxios.post<{ invoice: DownloadInvoiceResponse }>(
    `/billing/invoices/${id}/download/`,
    null,
    {
      baseURL: BASE_URL_ROOT,
    },
  );
}

export function getInvoice(invoiceId: string) {
  return fleetAxios.get<{ invoice: InvoiceResponse }>(
    `/billing/invoices/${invoiceId}/`,
    {
      baseURL: BASE_URL_ROOT,
    },
  );
}

export function getInvoiceDataExportUrl(type: string, dateFrom: string, dateTo: string) {
  return fleetAxios.get<{ url: string }>(
    `/billing/export/${type}/`,
    {
      baseURL: BASE_URL_ROOT,
      params: {
        date_from: dateFrom,
        date_to: dateTo,
      },
    },
  );
}

export function createPaymentUrl(invoiceId: string, callbacks: PaymentCallbacks) {
  return fleetAxios.post<PayInvoiceResponse>(
    `/billing/invoices/${invoiceId}/pay/`,
    {
      success_url: callbacks.successUrl,
      cancel_url: callbacks.cancelUrl,
    },
    {
      baseURL: BASE_URL_ROOT,
    },
  );
}

export function getCurrentUsage() {
  return fleetAxios.get<UsageResponse>(
    '/billing/usage/',
    {
      baseURL: BASE_URL_ROOT,
    },
  );
}

export function getWallet() {
  return fleetAxios.get<WalletResponse>(
    '/billing/wallet/',
    {
      baseURL: BASE_URL_ROOT,
    },
  );
}

export function listWalletTransactions(id: string) {
  return fleetAxios.get<ListResponse<'wallet_transactions', WalletTransactionResponse>>(
    `/billing/wallet/${id}/transactions/`,
    {
      baseURL: BASE_URL_ROOT,
    },
  );
}

export function addWalletFunds(wallet_id: string, amount: number) {
  const data = {
    wallet_transaction: {
      wallet_id,
      paid_credits: String(amount),
    },
  };
  return fleetAxios.post<WalletTransactionResponse>(
    `/billing/wallet/${wallet_id}/transactions/`,
    JSON.stringify(data),
    {
      baseURL: BASE_URL_ROOT,
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
}

export * from './types';
