import { map, mapKeys, snakeCase } from 'lodash';
import qs from 'qs';

import fleetAxios from './axios';
import { ListResponse } from './types';
import { USA_STATES } from './USA_STATS';
// eslint-disable-next-line import/no-named-as-default
import vehicleType from './VehicleTypes';

const [states] = map(USA_STATES, 'id');
const [VehicleTypes] = map(vehicleType, 'id');

type GetVehiclesParams = {
  offset?: number;
  limit?: number;
  search?: string;
};

export type VehicleAPIResponse = {
  id: number;
  lessee_renter: string;
  plate_number: string;
  plate_state: typeof states;
  status: 'PENDING' | 'EXIST' | 'REMOVED' | 'REQUESTED_REMOVAL';
  tracking_code: string;
  vehicle_make: string;
  vehicle_model: string;
  vehicle_type:typeof VehicleTypes;
  year: string;
  notifications: {
    message: string
  }[];
  custom_fields: Record<string, string>
};

export type AddVehiclePayload = {
  plateNumber: string;
  plateState: string;
  trackingCode: string;
  year: number;
  vehicleMake: string;
  vehicleModel: string;
  vehicleType: typeof VehicleTypes;
  customFields?: Record<string, string>;
  lesseeRenter?: string | null;
};

export function getVehicles({ offset, limit, search }: GetVehiclesParams) {
  const query = qs.stringify({
    offset,
    limit,
    q: search?.toUpperCase(),
  });

  return fleetAxios.get<ListResponse<VehicleAPIResponse>>(`/vehicle/?${query}`);
}

export function updateVehicle(id: number, updates: Record<string, unknown>) {
  const snakeCasePayload = mapKeys(updates, (_, key) => snakeCase(key));
  return fleetAxios.patch(`/vehicle/${id}/`, snakeCasePayload);
}

export function addVehicle(payload: AddVehiclePayload) {
  const snakeCasePayload = mapKeys(payload, (_, key) => snakeCase(key));

  return fleetAxios.post('/vehicle/', snakeCasePayload);
}

export default {};
