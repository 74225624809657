import './dateTimePicker.css';

import React, { Component } from 'react';
import Datetime from 'react-datetime';
import { connect } from 'react-redux';

import * as actionTypes from '../../store/actions/actions';
import ParkingTicketsTable from './components/ParkingTicketsTable';

class Parking extends Component {
  constructor() {
    super();
    this.state = {
      focusedFrom: false,
      focusedTo: false,
      searchPlateNumber: undefined,
      dateFrom: undefined,
      dateTo: undefined,
    };
  }

  componentDidMount() {
    this.props.getComponentIndex(this.props.location.pathname);
    this.props.onLinkChange(this.props.componentIndex);
  }

  onFocusHandlerDateFrom = () => {
    this.setState({ focusedFrom: true });
  };

  onFocusHandlerDateTo = () => {
    this.setState({ focusedTo: true });
  };

  onBlurHandlerDateFrom = () => {
    this.setState({ focusedFrom: false });
  };

  onBlurHandlerDateTo = () => {
    this.setState({ focusedTo: false });
  };

  searchClickHandler = () => {
    this.setState({
      searchPlateNumber: this.searchPlateNumber.value,
      dateFrom: this.dateFrom.state.inputValue,
      dateTo: this.dateTo.state.inputValue,
    });
  };

  render() {
    const { searchPlateNumber, dateFrom, dateTo } = this.state;

    return (
      <div
        className={
            this.props.sidebar_status
              ? 'page-wrapper slide-nav-toggle'
              : 'page-wrapper'
          }
      >
        <div className="content container-fluid">
          <div className="row">
            <div className="col-xs-6 ">
              <h4 className="page-title">Parking/Traffic Tickets</h4>
            </div>
          </div>
          <div className="row filter-row">
            <div className="col-sm-3 col-xs-6">
              <div className="form-group form-focus">
                <label className="control-label">Plate#</label>
                <input
                  type="text"
                  className="form-control floating"
                  ref={(el) => (this.searchPlateNumber = el)}
                />
              </div>
            </div>

            <div className="col-sm-6 col-xs-12">
              <div className="row">
                <div className="col-sm-6 col-xs-6">
                  <div
                    className={
                          this.state.focusedFrom
                            ? 'form-group form-focus focused'
                            : 'form-group form-focus'
                        }
                  >
                    <label className="control-label">From</label>
                    <div className="cal-icon">
                      <Datetime
                        dateFormat="YYYY-MM-DD"
                        onFocus={this.onFocusHandlerDateFrom}
                        onBlur={this.onBlurHandlerDateFrom}
                        timeFormat={false}
                        ref={(el) => (this.dateFrom = el)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-xs-6">
                  <div
                    className={
                          this.state.focusedTo
                            ? 'form-group form-focus focused'
                            : 'form-group form-focus '
                        }
                  >
                    <label className="control-label">To</label>
                    <div className="cal-icon">
                      <Datetime
                        dateFormat="YYYY-MM-DD"
                        onFocus={this.onFocusHandlerDateTo}
                        onBlur={this.onBlurHandlerDateTo}
                        timeFormat={false}
                        ref={(el) => (this.dateTo = el)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-3 col-xs-6">
              <button
                type="button"
                className="btn btn-success btn-block"
                onClick={this.searchClickHandler}
              >
                Search
              </button>
            </div>
          </div>
          <div className="row">
            <ParkingTicketsTable
              filter={{ plateNumber: searchPlateNumber, dateFrom, dateTo }}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStatesToProps = (state) => ({
  componentIndex: state.componentIndex,
  sidebar_status: state.sidebarOpened,
  fleet_info: state.userInfo,
});
const mapDispatchToProps = (dispatch) => ({
  onLinkChange: (i) => dispatch({ type: actionTypes.UPDATE_NAVIGATION, i }),
  getComponentIndex: (path) => dispatch({ type: actionTypes.GET_COMPONENT_INDEX, path }),
});
export default connect(mapStatesToProps, mapDispatchToProps)(Parking);
