import {
  Box, Card, CardContent, CircularProgress, Stack, Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { isNull } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { MdCheckCircle, MdError } from 'react-icons/md';
import { Redirect, useLocation } from 'react-router-dom';

import BuggyAuth from '@/services/BuggyAuth';
import { consumeOAuthCode, saveOAuthCode } from '@/services/integrations/motive';

export default function MotiveAuth() {
  const { search, pathname } = useLocation();
  const searchParams = new URLSearchParams(search);

  // We have to handle authentication check here so that we can send the auth code.
  // Otherwise it doesn't get sent because of the auth guard.
  const isAuthenticated = useMemo(() => BuggyAuth.isAuthenticated(), []);

  const code = searchParams.get('code');

  useEffect(() => {
    if (!isNull(code)) {
      saveOAuthCode({ code });
    }
  }, [code]);

  const { isLoading, isError, isSuccess } = useQuery({
    queryKey: ['motive', 'auth', code],
    queryFn: () => {
      if (isNull(code)) {
        throw new Error('Code is not a string');
      }

      if (isAuthenticated) {
        return consumeOAuthCode({ code });
      }

      return undefined;
    },
  });

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          search: new URLSearchParams({ next: `${pathname}${search}` }).toString(),
        }}
      />
    );
  }

  return (
    <div className="page-wrapper slide-nav-toggle">
      <div className="content container-fluid">
        <Box display="flex" justifyContent="center">
          <Card>
            <CardContent>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Typography variant="h6">
                  Authenticating with
                </Typography>
                <img
                  src="https://gomotive.com/wp-content/uploads/2023/05/motive-dark-logo.svg"
                  alt="Motive Logo"
                />
              </Stack>
              <Stack display="flex" my={3} alignItems="center">
                {isLoading && <CircularProgress /> }
                {isError && (
                  <>
                    <Typography sx={({ palette }) => ({ color: palette.error.main })}>
                      <MdError size={48} />
                    </Typography>
                    <Typography variant="caption">
                      Failed to install Motive.
                    </Typography>
                  </>
                )}
                {isSuccess && (
                  <>
                    <Typography sx={({ palette }) => ({ color: palette.success.main })}>
                      <MdCheckCircle size={48} />
                    </Typography>
                    <Typography variant="caption">
                      Successfully installed Motive. You may close this page.
                    </Typography>
                  </>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Box>
      </div>
    </div>
  );
}
