import {
  Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select,
} from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { VEHICLE_FETCH_STRATEGY_OPTIONS } from './constants';
import FleetioConfigForm from './KnownConfigurations/FleetioConfigForm';
import RentCentric from './KnownConfigurations/RentCentric';

const ConfigurationFormComponents = {
  fleetio: FleetioConfigForm,
  'rent centric': RentCentric,
};

function ConfigurationForm({ app }) {
  const { control } = useFormContext();

  const CompanyConfigurationForm = ConfigurationFormComponents[app.title.toLowerCase()];

  return (
    <>
      {CompanyConfigurationForm && (
        <>
          <CompanyConfigurationForm />
          <hr />
        </>
      )}
      <div className="row m-t-10">
        <FormGroup sx={{ padding: 2 }}>
          <Controller
            control={control}
            name="config.vehicle"
            render={({ field }) => (
              <FormControl>
                <InputLabel>Fetch Vehicles</InputLabel>
                <Select
                  fullWidth
                  label="Fetch Vehicles"
                  {...field}
                >
                  {VEHICLE_FETCH_STRATEGY_OPTIONS.map(({ text, value }) => (
                    <MenuItem value={value} key={value}>
                      {text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="config.fetchVehiclesOnSave"
            render={({ field }) => (
              <FormControlLabel
                label="Fetch vehicles on save"
                control={<Checkbox {...field} checked={field.value} />}
              />
            )}
          />

        </FormGroup>
      </div>
    </>
  );
}

export default ConfigurationForm;
