import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { FieldError, Merge } from 'react-hook-form';
import { MdUploadFile } from 'react-icons/md';

type FileUploaderProps = {
  fileError: Merge<FieldError, (FieldError | undefined)[]> | undefined;
  onDropFiles: (files: File[]) => void;
};

function FileUploader({ fileError, onDropFiles } : FileUploaderProps) {
  const theme = useTheme();
  const { getInputProps, getRootProps } = useDropzone({ onDrop: onDropFiles });

  return (
    <Box
      {...getRootProps()}
      sx={{
        border: fileError
          ? `2px dashed ${theme.palette.error}`
          : `2px dashed ${theme.palette.grey[500]}`,
        borderRadius: 1,
        color: theme.palette.grey[500],
        cursor: 'pointer',
      }}
      height={240}
      display="flex"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
    >
      <input {...getInputProps()} />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <MdUploadFile size={48} />
        <Typography variant="h4">
          Drop files here or click to add.
        </Typography>
      </Box>
    </Box>
  );
}

export default FileUploader;
