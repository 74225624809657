import { map } from 'lodash';
import z, { TypeOf } from 'zod';

export const shipmentFormSchema = z.object({
  transponders: z.object({
    id: z.number(),
    number: z.string(),
  })
    .array()
    .min(1)
    .refine((items) => new Set(map(items, 'id')).size === items.length, {
      message: 'Transponders in a shipment must be unique',
    }),
  remarks: z.string().optional(),
});

export type ShipmentFormType = TypeOf<typeof shipmentFormSchema>;
