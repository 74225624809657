import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Select2 from 'react-select2-wrapper';
import Switch from 'react-switch';

import BuggyApi from '../../services/BuggyApi';
import * as actionTypes from '../../store/actions/actions';
import BlockUi from '../BlockUi';
import { DRIVER_INVOICE_AMOUNT_TYPES, SUMMARY_EMAIL_CHOICES } from './constants';

class Settings extends Component {
  state = {
    summary_email_frequency: 'WEEKLY',
    driver_invoice_frequency: 'WEEKLY',
    driver_invoice_amount_choice: 'CASH_COST',
    has_driver_invoice: false,
    custom_fee: null,
    invoice_logo: null,
    blocking: false,
    errorMessage: [],
    summary_email_choices: [],
    driver_invoice_amount_types: [],
    isSuccess: false,
    activeTab: 1,
    changeLogo: false,
  };

  summaryChoiceChangeHandler = (e) => {
    this.setState({ summary_email_frequency: e.target.value });
  };

  driverInvoiceSummaryChoiceChangeHandler = (e) => {
    this.setState({ driver_invoice_frequency: e.target.value });
  };

  driverInvoiceFeeChoiceChangeHandler = (e) => {
    this.setState({ driver_invoice_amount_choice: e.target.value });
  };

  componentWillMount() {
    this.props.getComponentIndex(this.props.location.pathname);
    this.props.onLinkChange(this.props.componentIndex);
    this.getSettings();
  }

  getSettings = () => {
    new BuggyApi()
      .getSettings()
      .then((response) => {
        const default_choice = response.data.summary_email_frequency
          ? response.data.summary_email_frequency
          : 'WEEKLY';
        const driver_invoice_amount_types = response.data
          .driver_invoice_amount_types
          ? response.data.driver_invoice_amount_types
          : 'CASH_COST';
        this.setState({
          summary_email_frequency: default_choice,
          summary_email_choices: response.data.summary_email_choices,
          driver_invoice_amount_types,
          driver_invoice_amount_choice:
            response.data.driver_invoice_amount_choice,
          driver_invoice_frequency: response.data.driver_invoice_frequency,
          custom_fee: response.data.custom_fee,
          has_driver_invoice: response.data.has_driver_invoice,
          invoice_logo: response.data.invoice_logo,
        });
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  onTabChange = (tab_id) => {
    if (tab_id === 1) {
      this.getSettings();
    }
    if (tab_id === 2) {
      this.getSettings();
    }
    this.setState({ activeTab: tab_id });
  };

  onValueChangeHandler = (name, event) => {
    const new_value = {};
    new_value[name] = event.target.value;
    this.setState(new_value);
  };

  handleChange = (checked) => {
    this.setState({ has_driver_invoice: checked });
    if (!checked) {
      this.updateSettingClickHandler();
    }
  };

  updateSettingClickHandler = (e = null) => {
    if (e) {
      e.preventDefault();
    }
    const formData = new FormData();
    formData.append(
      'summary_email_frequency',
      this.state.summary_email_frequency,
    );
    if (this.state.has_driver_invoice === true) {
      formData.append('has_driver_invoice', 1);
      formData.append(
        'driver_invoice_amount_choice',
        this.state.driver_invoice_amount_choice,
      );
      formData.append(
        'driver_invoice_frequency',
        this.state.driver_invoice_frequency,
      );
      if (this.state.driver_invoice_amount_choice === 'CUSTOM_FEE') {
        formData.append('custom_fee', this.state.custom_fee);
      }
      if (this.upload_logo) {
        formData.append('invoice_logo', this.upload_logo.files[0]);
      }
    } else {
      formData.append('has_driver_invoice', 0);
    }
    this.setState({ blocking: true, errorMessage: [] });
    new BuggyApi()
      .updateSettings(formData)
      .then((response) => {
        this.setState({ blocking: false });
        if (response.status === 201 || response.status === 200) {
          if (response.data.error) {
            const errors = [];
            const errorList = response.data.error;
            for (const key in errorList) {
              let em = '';
              switch (key) {
                case 'summary_email_frequency':
                  em = 'Summary Email Frequency: ';
                  break;
                case 'invoice_logo':
                  em = 'Fleet Logo: ';
                  break;
                case 'driver_invoice_frequency':
                  em = 'Driver Invoice Frequency: ';
                  break;
                case 'driver_invoice_amount_choice':
                  em = 'Driver Invoice Amount Choice: ';
                  break;
                case 'custom_fee':
                  em = 'Custom Fee: ';
                  break;
                case 'has_driver_invoice':
                  em = 'Has Invoice: ';
                  break;
                default:
                  em = '';
                  break;
              }
              em += errorList[key];
              errors.push(em);
            }
            this.setState({
              isSuccess: false,
              errorMessage: errors,
            });
          } else if (response.status === 201 || response.status === 200) {
            this.setState({
              isSuccess: true,
              errorMessage: [],
            });
            setTimeout(() => {
              this.setState({
                isSuccess: false,
              });
            }, 2000);
          }
        }
      })
      .catch((error) => {
        this.setState({ blocking: false });
        if (error.response) {
          if (error.response.data) {
            const errors = [];
            const errorList = error.response.data;
            for (const key in errorList) {
              let em = '';
              switch (key) {
                case 'summary_email_frequency':
                  em = 'Summary Email Frequency: ';
                  break;
                default:
                  em = '';
                  break;
              }
              em += errorList[key];
              errors.push(em);
            }
            this.setState({
              errorMessage: errors,
            });
          } else {
            /* console.log(error.response.data.error_message); */
            const em = "You don't have permissions";
            this.setState({
              errorMessage: [em],
            });
          }
        } else if (error.request) {
          const em = 'Oops! Something went wrong!';
          this.setState({
            errorMessage: [em],
          });
        } else {
          /* console.log('Error', error.message); */
        }
      });
  };

  render() {
    return (
      <div
        className={
          this.props.sidebar_status
            ? 'page-wrapper slide-nav-toggle'
            : 'page-wrapper'
        }
      >
        <div className="content container-fluid">
          {/* <div className="row">
                        <div className="col-xs-8">
                            <h4 className="page-title">Settings</h4>
                        </div>
                        <div className="col-xs-4 text-right m-b-30">

                        </div>
                    </div> */}
          <div className="card-box tab-box">
            <div className="row user-tabs">
              <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                <ul className="nav nav-tabs tabs nav-tabs-bottom">
                  <li
                    className={`col-sm-3 ${
                      this.state.activeTab === 1 ? 'active' : ''
                    }`}
                  >
                    <a
                      href
                      data-toggle="tab"
                      onClick={() => {
                        this.onTabChange(1);
                      }}
                    >
                      General Settings
                    </a>
                  </li>
                  <li
                    className={`col-sm-3 ${
                      this.state.activeTab === 2 ? 'active' : ''
                    }`}
                  >
                    <a
                      href
                      data-toggle="tab"
                      onClick={() => {
                        this.onTabChange(2);
                      }}
                    >
                      Invoice Settings
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="tab-content  profile-tab-content">
                <div
                  className={`tab-pane fade ${
                    this.state.activeTab === 1 ? 'in active' : ''
                  }`}
                >
                  <div className="row">
                    <div className="col-xs-12">
                      <h4 className="modal-title">General Settings</h4>
                    </div>
                    <div className="col-md-12">
                      <form>
                        {this.state.isSuccess ? (
                          <div className="row">
                            <div className="col-sm-6 col-sm-offset-3">
                              <div className="alert alert-success">
                                <strong>Success!</strong>
                                {' '}
                                Settings updated
                                successfully!
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {this.state.errorMessage.length > 0 ? (
                          <div className="row">
                            <div className="col-sm-6 col-sm-offset-3">
                              <div className="alert alert-danger">
                                <ul>
                                  {this.state.errorMessage.map((message, i) => (
                                    <li key={i}>{message}</li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <BlockUi tag="div" blocking={this.state.blocking}>
                          <div className="row">
                            <div className="col-sm-6 col-sm-offset-3">
                              <div className="form-group">
                                <label>Summary Email Frequency</label>
                                <Select2
                                  className="select"
                                  data={SUMMARY_EMAIL_CHOICES}
                                  value={this.state.summary_email_frequency}
                                  options={{
                                    width: '100%',
                                    placeholder: 'Select Email Frequency',
                                    minimumResultsForSearch: -1,
                                  }}
                                  onChange={this.summaryChoiceChangeHandler}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="m-t-20 text-center">
                            <button
                              className="btn btn-primary"
                              type="submit"
                              onClick={this.updateSettingClickHandler}
                            >
                              Update Settings
                            </button>
                          </div>
                        </BlockUi>
                      </form>
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-pane fade ${
                    this.state.activeTab === 2 ? 'in active' : ''
                  }`}
                >
                  <div className="row">
                    <div className="col-xs-12">
                      <h4 className="modal-title">Invoice Settings</h4>
                    </div>
                    <div className="col-md-12">
                      <form>
                        {this.state.isSuccess ? (
                          <div className="row">
                            <div className="col-sm-6 col-sm-offset-3">
                              <div className="alert alert-success">
                                <strong>Success!</strong>
                                {' '}
                                Settings updated
                                successfully!
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {this.state.errorMessage.length > 0 ? (
                          <div className="row">
                            <div className="col-sm-6 col-sm-offset-3">
                              <div className="alert alert-danger">
                                <ul>
                                  {this.state.errorMessage.map((message, i) => (
                                    <li key={i}>{message}</li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <BlockUi tag="div" blocking={this.state.blocking}>
                          {this.state.has_driver_invoice ? (
                            <>
                              <div className="row">
                                <div className="col-sm-6 col-sm-offset-3">
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      marginBottom: 15,
                                    }}
                                  >
                                    <p
                                      style={{
                                        display: 'inline',
                                        margin: 0,
                                        marginRight: 20,
                                      }}
                                    >
                                      <strong>Want to Invoice Driver?</strong>
                                    </p>
                                    <Switch
                                      onChange={this.handleChange}
                                      checked={this.state.has_driver_invoice}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-6 col-sm-offset-3">
                                  <div className="form-group">
                                    <label>
                                      Driver Invoice Email Frequency
                                    </label>
                                    <Select2
                                      className="select"
                                      data={SUMMARY_EMAIL_CHOICES}
                                      value={
                                        this.state.driver_invoice_frequency
                                      }
                                      options={{
                                        width: '100%',
                                        placeholder: 'Select Email Frequency',
                                        minimumResultsForSearch: -1,
                                      }}
                                      onChange={
                                        this
                                          .driverInvoiceSummaryChoiceChangeHandler
                                      }
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label>Invoice Fee Choice</label>
                                    <Select2
                                      className="select"
                                      data={DRIVER_INVOICE_AMOUNT_TYPES}
                                      value={
                                        this.state.driver_invoice_amount_choice
                                      }
                                      options={{
                                        width: '100%',
                                        placeholder: 'Select Email Frequency',
                                        minimumResultsForSearch: -1,
                                      }}
                                      onChange={
                                        this.driverInvoiceFeeChoiceChangeHandler
                                      }
                                    />
                                  </div>
                                  {this.state.driver_invoice_amount_choice
                                    === 'CUSTOM_FEE' && (
                                    <div className="form-group">
                                      <label>Invoice Free (Custom)</label>
                                      <input
                                        className="form-control"
                                        type="number"
                                        required
                                        value={this.state.custom_fee}
                                        onChange={(event) => this.onValueChangeHandler(
                                          'custom_fee',
                                          event,
                                        )}
                                      />
                                    </div>
                                  )}
                                  {(!this.state.invoice_logo
                                    || this.state.changeLogo) && (
                                    <div className="form-group">
                                      <label>Upload PNG File</label>
                                      <input
                                        className="form-control"
                                        type="file"
                                        ref={(el) => (this.upload_logo = el)}
                                        accept=".png"
                                      />
                                    </div>
                                  )}
                                  {this.state.invoice_logo && (
                                    <div className="form-group">
                                      <img
                                        alt="fleet logo"
                                        src={this.state.invoice_logo}
                                      />
                                      <br />
                                      <button
                                        type="button"
                                        onClick={() => {
                                          this.setState({
                                            changeLogo: !this.state.changeLogo,
                                          });
                                        }}
                                        className="btn btn-primary"
                                      >
                                        Change Logo
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="m-t-20 text-center">
                                <button
                                  className="btn btn-primary"
                                  type="submit"
                                  onClick={this.updateSettingClickHandler}
                                >
                                  Update Settings
                                </button>
                              </div>
                            </>
                          ) : (
                            <div className="row">
                              <div className="col-sm-9 col-sm-offset-3">
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <p
                                    style={{
                                      display: 'inline',
                                      margin: 0,
                                      marginRight: 20,
                                    }}
                                  >
                                    <strong>Want to Invoice Driver?</strong>
                                  </p>
                                  <Switch
                                    onChange={this.handleChange}
                                    checked={this.state.has_driver_invoice}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </BlockUi>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStatesToProps = (state) => ({
  componentIndex: state.componentIndex,
  sidebar_status: state.sidebarOpened,
});
const mapDispatchToProps = (dispatch) => ({
  onLinkChange: (i) => dispatch({ type: actionTypes.UPDATE_NAVIGATION, i }),
  getComponentIndex: (path) => dispatch({ type: actionTypes.GET_COMPONENT_INDEX, path }),
});
export default connect(mapStatesToProps, mapDispatchToProps)(Settings);
