import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Radio,
} from "@mui/material";
import { useForm } from "react-hook-form";

function SelectEmail({
  open,
  onClose,
  title,
  message,
  CTAMessage,
  options,
  onSelect,
}) {
  const { handleSubmit, register, watch } = useForm({
    defaultValues: {
      selected: undefined,
    },
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        <FormGroup>
          {options.map(({ value, label }) => (
            <FormControlLabel
              key={value}
              control={<Radio />}
              value={value}
              label={label}
              checked={watch("selected") === value}
              {...register("selected")}
            />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit(onSelect)}>{CTAMessage}</Button>
      </DialogActions>
    </Dialog>
  );
}

export function useDialogSelector({ options, title, message, CTAMessage }) {
  const [isOpen, setIsOpen] = useState(false);

  const resolve = useRef();
  const reject = useRef();

  const getEmail = useCallback(() => {
    setIsOpen(true);
    return new Promise((res, rej) => {
      resolve.current = res;
      reject.current = rej;
    });
  }, []);

  const onSelect = useCallback(({ selected }) => {
    resolve.current(selected);
    setIsOpen(false);
  }, []);

  const selectEmailDialog = useMemo(
    () => (
      <SelectEmail
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title={title}
        message={message}
        CTAMessage={CTAMessage}
        options={options}
        onSelect={onSelect}
      />
    ),
    [isOpen, title, message, CTAMessage, options, onSelect]
  );

  return { getEmail, selectEmailDialog };
}
