import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Switch,
} from '@mui/material';
import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { useMutation } from '@tanstack/react-query';
import {
  difference,
  keys, map, pickBy,
} from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdViewWeek } from 'react-icons/md';

type GridColumnsSelectorProps = {
  onApply: (visibilityModel: GridColumnVisibilityModel) => void;
  onSave: (visibilityModel: GridColumnVisibilityModel) => Promise<void>;
  columns: GridColDef[],
  visibilityModel: GridColumnVisibilityModel;
};

type FormValues = {
  save: boolean;
  columns: string[];
};

function GridColumnsSelector({
  onApply, onSave, columns, visibilityModel,
}: GridColumnsSelectorProps) {
  const [isOpen, setIsOpen] = useState(false);

  const visibleColumns = useMemo(() => {
    const allColumns = map(columns, 'field');
    const invisibleColumns = keys(pickBy(visibilityModel, (isVisible) => !isVisible));

    return difference(allColumns, invisibleColumns);
  }, [columns, visibilityModel]);

  const {
    handleSubmit, register, watch, resetField,
  } = useForm<FormValues>({
    defaultValues: {
      save: false,
      columns: visibleColumns,
    },
  });

  const currentVisibleColumns = watch('columns');

  const onClickOpen = () => {
    resetField('columns', { defaultValue: visibleColumns });
    setIsOpen(true);
  };

  const onClose = () => setIsOpen(false);

  const { isLoading, mutateAsync: saveSettings } = useMutation({
    mutationFn: (newModel: GridColumnVisibilityModel) => onSave(newModel),
  });

  const onSubmit = useCallback(async ({ columns: newVisibleColumns, save }: FormValues) => {
    const allColumns = map(columns, 'field');

    const invisibleColumns = difference(allColumns, newVisibleColumns);

    const newModel = invisibleColumns.reduce((acc: GridColumnVisibilityModel, col) => {
      acc[col] = false;
      return acc;
    }, {});

    if (save) {
      await saveSettings(newModel);
    } else {
      onApply(newModel);
    }

    setIsOpen(false);
  }, [columns, onApply, saveSettings]);

  return (
    <>
      <Button
        startIcon={<MdViewWeek size={18} />}
        onClick={onClickOpen}
        size="small"
      >
        Columns
      </Button>
      <Dialog
        open={isOpen}
        fullWidth
        onClose={onClose}
      >
        <DialogTitle>
          Columns
        </DialogTitle>
        <DialogContent>
          <FormGroup>
            {columns.map(({ headerName, field }) => (
              <FormControlLabel
                key={field}
                label={headerName}
                control={<Switch />}
                value={field}
                checked={currentVisibleColumns.includes(field)}
                {...register('columns')}
              />
            ))}
          </FormGroup>
          <Divider />
          <FormControlLabel
            label="Save settings"
            control={<Checkbox />}
            checked={watch('save')}
            {...register('save')}
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton
            onClick={handleSubmit(onSubmit)}
            loading={isLoading}
          >
            Apply
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default GridColumnsSelector;
