import {
  createContext, Dispatch, SetStateAction, useContext,
} from 'react';

type ContextType = {
  isFetching: boolean;
  setIsFetching: Dispatch<SetStateAction<boolean>>
};

const IntegrationFormContext = createContext<ContextType>({
  isFetching: false,
  setIsFetching: () => null,
});

export const useIntegrationFormContext = () => useContext(IntegrationFormContext);

export default IntegrationFormContext;
