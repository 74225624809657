import React, {Component} from 'react';
import Aux from '../hoc/Auxiliary'


class StatsWidget extends Component {

    componentDidMount() {
        this.is_mounted = true;
    }
    componentWillMount(){

    }
    componentWillUnmount() {
        this.is_mounted = false;
    }
    render(){
        return (
            <Aux>
                <div className="col-md-6 col-sm-6 col-lg-3">
                    <div className="dash-widget clearfix card-box card-stats-top">
                        <span className="dash-widget-icon"><i className="fa fa-cubes" aria-hidden="true"></i></span>
                        <div className="dash-widget-info">
                            <h3>{this.props.tickets_today}</h3>
                            <span style={{fontSize:"15px"}}>Toll Transactions today</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-3">
                    <div className="dash-widget clearfix card-box card-stats-top">
                        <span className="dash-widget-icon"><i className="fa fa-usd" aria-hidden="true"></i></span>
                        <div className="dash-widget-info">
                            <h3>{this.props.cost_last_month}</h3>
                            <span style={{fontSize:"15px"}}>Cost in last month</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-3">
                    <div className="dash-widget clearfix card-box card-stats-top">
                        <span className="dash-widget-icon"><i className="fa fa-usd" aria-hidden="true"></i></span>
                        <div className="dash-widget-info">
                            <h3>{this.props.cost_current_month}</h3>
                            <span style={{fontSize:"15px"}}>Cost in current month</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-3">
                    <div className="dash-widget clearfix card-box card-stats-top">
                        <span className="dash-widget-icon"><i className="fa fa-car" aria-hidden="true"></i></span>
                        <div className="dash-widget-info">
                            <h3>{this.props.vehicle_count}</h3>
                            <span style={{fontSize:"15px"}}>Vehicles</span>
                        </div>
                    </div>
                </div>
            </Aux>
        );
    }
}
export default StatsWidget;